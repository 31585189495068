import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Dimmer, Loader, Table } from 'semantic-ui-react';
import { Modal as ModalItem, Pagination, TitleHead, ContextMenu } from '../app/common';
import FormItem from './ItemForm';
import { Menu, Item, contextMenu, animation, theme } from 'react-contexify';
import {
  itemDelete,
  itemIndex,
  itemShow,
  itemOpenModal,
  itemCloseModal,
  itemActionStore,
  itemActionUpdate,
  itemPaginate,
} from './actions';
import { ITEM_MODULE_NAME } from '../constants';

class Items extends Component {
  constructor(props) {
    super(props);
    this.state = {
      actionType: null,
      data: null,
    };
  }

  componentDidMount() {
    const { isAuthenticated, fnItemPaginate } = this.props;
    if (isAuthenticated) fnItemPaginate();
  }

  toRender = {
    id: {
      type: 'number',
      visible: false,
      name: '',
    },
    code: {
      type: 'string',
      visible: true,
      name: 'codigo',
    },
    name: {
      type: 'string',
      visible: true,
      name: 'nombre',
    },
    category_id: {
      type: 'number',
      visible: true,
      name: 'categoría ID',
    },
    status: {
      type: 'number',
      visible: true,
      name: 'Estado',
    },
  };

  renderTable = (data) => {
    const allowed = [];
    Object.keys(this.toRender).map((k) => {
      allowed.push(k);
    });

    return data.map((ag) =>
      Object.keys(ag)
        .filter((key) => allowed.includes(key))
        .reduce((obj, key) => {
          obj[key] = { ...{ value: ag[key] }, ...this.toRender[key] };
          return obj;
        }, {})
    );
  };

  openContextMenu = (e, data) => {
    const {
      fnItemModalOpen,
      fnItemShow,
      fnItemActionUpdate,
      fnItemActionStore,
      fnItemDelete,
    } = this.props;
    e.preventDefault();
    contextMenu.show({
      id: 'menu_id',
      event: e,
      props: {
        data: { ...data },
        fnModalOpen: fnItemModalOpen,
        fnShow: fnItemShow,
        fnActionUpdate: fnItemActionUpdate,
        fnDelete: fnItemDelete,
        fnActionStore: fnItemActionStore,
      },
    });
  };

  render() {
    const {
      payload,
      isFetching,
      isModalOpen,
      fnItemPaginate,
      fnItemModalClose,
      actionType,
    } = this.props;
    const { items, pagination } = payload;
    return (
      <Fragment>
        <TitleHead title={ITEM_MODULE_NAME} />
        <div className={'row'}>
          <div className={'col-md-12'}>
            <div className={'main-card mb-3 card'}>
              <div className={'card-body'}>
                <h5 className={'card-title'}>
                  {/*Name in singular :v*/}
                  {ITEM_MODULE_NAME.substr(0, ITEM_MODULE_NAME.length - 1)} Lists
                </h5>
                <div className={'row'}>
                  <Pagination
                    title={`Show ${ITEM_MODULE_NAME.toLowerCase()} by`}
                    {...pagination}
                    fnIndex={fnItemPaginate}
                  />
                </div>
                <div className={'row'}>
                  <div className="col-md-12">
                    <ModalItem
                      modalTitle={'Tours'}
                      isOpen={isModalOpen}
                      actionType={actionType}
                      onClose={fnItemModalClose}
                      isFetchingButton={isFetching && isModalOpen}
                      size={'large'}
                    >
                      <FormItem />
                    </ModalItem>
                    <Dimmer.Dimmable blurring={true} dimmed={isFetching}>
                      <Dimmer active={isFetching} inverted={true}>
                        <Loader>Loading</Loader>
                      </Dimmer>
                      <Table compact={true} celled={true}>
                        <Table.Header>
                          <Table.Row>
                            {Object.keys(this.toRender).map((key, index) => {
                              return index === 0 ? (
                                <Fragment key={1}>
                                  <Table.HeaderCell>#</Table.HeaderCell>
                                  {this.toRender[key].visible && (
                                    <Table.HeaderCell>{this.toRender[key].name}</Table.HeaderCell>
                                  )}
                                </Fragment>
                              ) : (
                                this.toRender[key].visible && (
                                  <Table.HeaderCell>{this.toRender[key].name}</Table.HeaderCell>
                                )
                              );
                            })}
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {items.length > 0 ? (
                            this.renderTable(items).map((data, index) => {
                              const { per_page, to, total } = pagination;
                              const row =
                                (total > per_page ? to - per_page : to - total) + index + 1;
                              const rows = [];
                              //Pushing Rows number
                              if (data.id) {
                                rows.push(<Table.Cell>{row}</Table.Cell>);
                              }
                              // Push Other cells
                              Object.keys(data).filter((key) => {
                                if (data[key].visible) {
                                  rows.push(<Table.Cell>{data[key].value}</Table.Cell>);
                                }
                              });
                              return (
                                <Table.Row
                                  key={items[index].id}
                                  onContextMenu={(e) => this.openContextMenu(e, items[index])}
                                >
                                  {rows}
                                </Table.Row>
                              );
                            })
                          ) : (
                            <Table.Row onContextMenu={(e) => this.openContextMenu(e, [])}>
                              <Table.Cell>Table is empty...</Table.Cell>
                            </Table.Row>
                          )}
                        </Table.Body>
                      </Table>
                      <ContextMenu canStatus={items.length > 0} canUpdate={items.length > 0} />
                    </Dimmer.Dimmable>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateProps = (state, ownProps = {}) => ({
  isFetching: state.items.isFetching,
  payload: state.items.payload,
  errors: state.items.errors,
  isModalOpen: state.items.isModalOpen,
  actionType: state.items.payload.actionType,
  isAuthenticated: state.app.payload.isAuthenticated,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  fnItemPaginate: (forRelation, page, items) => {
    dispatch(itemPaginate(forRelation, page, items));
  },
  fnItemDelete: (id) => {
    dispatch(itemDelete(id));
  },
  fnItemShow: (data) => {
    dispatch(itemShow(data));
  },
  fnItemModalOpen: () => {
    dispatch(itemOpenModal());
  },
  fnItemModalClose: () => {
    dispatch(itemCloseModal());
  },
  fnItemActionStore: () => {
    dispatch(itemActionStore());
  },
  fnItemActionUpdate: () => {
    dispatch(itemActionUpdate());
  },
});

export default connect(
  mapStateProps,
  mapDispatchToProps
)(Items);
