import types from './types';
import { errorModel } from '../app/model';
import userModel from '../user/model';

export const INITIAL_STATE = {
  isFetching: false,
  payload: {
    message: null,
  },
  errors: { ...errorModel },
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.LOGIN_FAIL:
      return {
        ...state,
        isFetching: false,
        errors: { ...state.errors, ...action.errors },
      };
    case types.LOGIN_FETCH:
      return {
        ...state,
        isFetching: true,
      };
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        payload: { ...state.payload, ...action.payload },
      };
    default:
      return state;
  }
};

export default reducer;
