import React, { Component } from 'react';
import { Form, Grid } from 'semantic-ui-react';
import modelShippingHotelCompany from './model';
import { connect } from 'react-redux';
import { shippingHotelCompanyStore, shippingHotelCompanyUpdate } from './actions';
import PropTypes from 'prop-types';
import shippingHotelCompanies from './reducer';

class ShippingHotelCompanyForm extends Component {
  constructor(props) {
    super(props);
    const { actionType, shippingHotelCompany } = props;
    this.state = {
      model: actionType === 'store' ? modelShippingHotelCompany : shippingHotelCompany,
      errorForm: {},
    };
  }

  componentWillReceiveProps(nextProps) {
    const { errors } = nextProps;
    this.setState({ errorForm: { ...errors.errors } });
  }
  changeValue = (input) => {
    const { name, value } = input;
    let newValue = {};
    newValue[name] = value;
    this.setState(
      ({ model }) => ({ model: { ...model, ...newValue } }),
      () => {
        this.setState(({ errorForm }) => {
          let newErrors = Object.keys(errorForm)
            .filter((key) => key !== name)
            .reduce((obj, key) => {
              obj[key] = errorForm[key];
              return obj;
            }, {});
          return { errorForm: newErrors };
        });
      }
    );
  };
  render() {
    const { name } = this.state.errorForm;
    const { model } = this.state;
    return (
      <Form
        id={'form'}
        onSubmit={(e) => {
          const { model } = this.state;
          const {
            actionType,
            fnShippingHotelCompanyStore,
            fnShippingHotelCompanyUpdate,
          } = this.props;
          switch (actionType) {
            case 'store':
              fnShippingHotelCompanyStore(model);
              break;
            case 'update':
              fnShippingHotelCompanyUpdate(model.id, model);
              break;
            default:
              break;
          }
        }}
      >
        <Grid centered columns={2}>
          <Grid.Column>
            <Form.Input
              value={model.name}
              name={'name'}
              autoComplete={'off'}
              onChange={(e, data) => this.changeValue(data)}
              error={name === undefined ? false : { content: name, pointing: 'below' }}
              label="Name:"
              placeholder="Add Name"
            />
          </Grid.Column>
        </Grid>
      </Form>
    );
  }
}

const mapStateToProps = (state) => ({
  isFetching: state.shippingHotelCompanies.isFetching,
  errors: state.shippingHotelCompanies.errors,
  isFetchingshippingHotelCompanies: state.shippingHotelCompanies.isFetching,
  actionType: state.shippingHotelCompanies.payload.actionType,
  shippingHotelCompany: state.shippingHotelCompanies.payload.shippingHotelCompany,
});

const mapDispatchToProps = (dispatch) => ({
  fnShippingHotelCompanyStore: (data) => {
    dispatch(shippingHotelCompanyStore(data));
  },
  fnShippingHotelCompanyUpdate: (id, data) => {
    dispatch(shippingHotelCompanyUpdate(id, data));
  },
});

ShippingHotelCompanyForm.propTypes = {
  isFetching: PropTypes.bool,
  errors: PropTypes.PropTypes.exact({
    errors: PropTypes.object,
    message: PropTypes.string,
  }),
  actionType: PropTypes.oneOf(['store', 'update']),
  fnShippingHotelCompanyStore: PropTypes.func,
  fnShippingHotelCompanyUpdate: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShippingHotelCompanyForm);
