import sloteService from './service';
import {
  sloteDeleteFail,
  sloteDeleteFetch,
  sloteDeleteSuccess,
  sloteIndexFail,
  sloteIndexFetch,
  sloteIndexSuccess,
  sloteShowFail,
  sloteShowFetch,
  sloteShowSuccess,
  sloteStoreFail,
  sloteStoreFetch,
  sloteStoreSuccess,
  sloteUpdateFail,
  sloteUpdateFetch,
  sloteUpdateSuccess,
  sloteActionTypeStore,
  sloteActionTypeUpdate,
  sloteModalClose,
  sloteModalOpen,
} from './dispatch';
import appService from '../app/service';
import { RELATIONS } from '../slots/model';

export const sloteDelete = (id) => async (dispatch) => {
  dispatch(sloteDeleteFetch());
  dispatch(sloteIndexFetch());
  try {
    const { data } = await sloteService.sloteDelete(id);
    dispatch(sloteDeleteSuccess({ payload: data }));
    dispatch(slotePaginate());
  } catch (e) {
    const { error } = e;
    dispatch(sloteDeleteFail({ error }));
  }
};

///SlotePaginateRelation////
export const sloteRelationPaginate = (
  forRelation = false,
  params = null,
  page = null,
  items = null
) => async (dispatch, getState) => {
  dispatch(sloteIndexFetch());
  try {
    //If is not paginates values, Get default value from state
    page = page ? page : getState().sales.payload.pagination.current_page;
    items = items ? items : getState().sales.payload.pagination.per_page;
    forRelation = forRelation ? appService.appSerialize(RELATIONS) : null;
    let serializedParams = '';
    if (params && typeof params === 'object') {
      Object.keys(params).map((param, key, array) => {
        serializedParams += `${param}=${params[param]}`;
        if (array[key + 1] !== undefined) serializedParams += '&';
      });
    } else serializedParams = params;
    const { data, meta } = await sloteService.sloteIndex(
      forRelation,
      serializedParams,
      page,
      items
    );
    const { pagination } = getState().items.payload;
    dispatch(sloteIndexSuccess({ slots: data, pagination: { ...pagination, ...meta } }));
  } catch (e) {
    dispatch(sloteIndexFail(e));
  }
};
////////////////////////////

export const slotePaginate = (forRelation = false, page = null, items = null) => async (
  dispatch,
  getState
) => {
  dispatch(sloteIndexFetch());
  try {
    //If is not paginates values, Get default value from state
    page = page ? page : getState().slots.payload.pagination.current_page;
    items = items ? items : getState().slots.payload.pagination.per_page;
    // forRelation = forRelation ? appService.appSerialize(RELATIONS) : null;

    const { data, meta } = await sloteService.sloteIndex(forRelation, null, page, items);
    dispatch(sloteIndexSuccess({ slots: data, pagination: meta }));
  } catch (e) {
    dispatch(sloteIndexFail(e));
  }
};

export const slotIndex = (forRelation = false, params = null) => async (dispatch, getState) => {
  dispatch(sloteIndexFetch());
  try {
    forRelation = forRelation ? appService.appSerialize(RELATIONS) : null;
    let serializedParams = '';
    if (params && typeof params === 'object') {
      Object.keys(params).map((param, key, array) => {
        serializedParams += `${param}=${params[param]}`;
        if (array[key + 1] !== undefined) serializedParams += '&';
      });
    } else serializedParams = params;
    const { data, meta } = await sloteService.sloteIndex(forRelation, serializedParams);
    dispatch(sloteIndexSuccess({ slots: data }));
  } catch (e) {
    dispatch(sloteIndexFail(e));
  }
};
////slow con peticion///
// export const sloteShow = (id) => async (dispatch) => {
//   dispatch(sloteShowFetch());
//   try {
//     const { data } = await sloteService.sloteShow(id);
//     dispatch(sloteShowSuccess({ slote: data }));
//   } catch (e) {
//     const { error } = e;
//     dispatch(sloteShowFail({ error }));
//   }
// };

export const sloteShow = (data) => async (dispatch) => {
  dispatch(sloteShowSuccess({ slot: data }));
};
export const sloteStore = (data) => async (dispatch) => {
  dispatch(sloteStoreFetch());
  dispatch(sloteIndexFetch());
  try {
    const { message } = await sloteService.sloteStore(data);
    dispatch(sloteModalClose());
    dispatch(sloteStoreSuccess({ message }));
    dispatch(slotePaginate());
    // dispatch(rateIndexSuccess());
  } catch (e) {
    const { errors, message } = e;
    dispatch(sloteStoreFail({ errors, message }));
  }
};

export const sloteUpdate = (id, data) => async (dispatch) => {
  dispatch(sloteUpdateFetch());
  dispatch(sloteIndexFetch());
  try {
    const { message } = await sloteService.sloteUpdate(id, data);
    dispatch(sloteModalClose());
    dispatch(sloteUpdateSuccess({ message }));
    dispatch(slotePaginate());
  } catch (e) {
    const { errors, message } = e;
    dispatch(sloteUpdateFail({ errors, message }));
  }
};

export const sloteOpenModal = () => (dispatch) => {
  dispatch(sloteModalOpen());
};

export const sloteCloseModal = () => (dispatch) => {
  dispatch(sloteModalClose());
};

export const sloteActionStore = () => (dispatch) => {
  dispatch(sloteActionTypeStore({ actionType: 'store' }));
};

export const sloteActionUpdate = () => (dispatch) => {
  dispatch(sloteActionTypeUpdate({ actionType: 'update' }));
};

export const sloteDetails = (rate_id) => async () => {
  let data = {};
  try {
    data = await sloteService.sloteDetails(rate_id);
  } catch (e) {
    data.data = [];
  }

  return data;
};
