import loginTypes from './types';
//LOGIN DISPATCH
export const loginFail = (payload) => ({
  type: loginTypes.LOGIN_FAIL,
  errors: { ...payload },
});
export const loginFetch = () => ({
  type: loginTypes.LOGIN_FETCH,
});
export const loginSuccess = (payload) => ({
  type: loginTypes.LOGIN_SUCCESS,
  payload: { ...payload },
});
