import meetingPointService from './service';
import {
  meetingPointDeleteFail,
  meetingPointDeleteFetch,
  meetingPointDeleteSuccess,
  meetingPointIndexFail,
  meetingPointIndexFetch,
  meetingPointIndexSuccess,
  meetingPointShowFail,
  meetingPointShowFetch,
  meetingPointShowSuccess,
  meetingPointStoreFail,
  meetingPointStoreFetch,
  meetingPointStoreSuccess,
  meetingPointUpdateFail,
  meetingPointUpdateFetch,
  meetingPointUpdateSuccess,
  meetingPointActionTypeStore,
  meetingPointModalClose,
  meetingPointModalOpen,
  meetingPointTypeUpdate,
} from './dispatch';
import { RELATIONS } from './model';
import appService from '../app/service';
export const meetingPointDelete = (id) => async (dispatch) => {
  dispatch(meetingPointDeleteFetch());
  try {
    const { data, message } = await meetingPointService.meetingPointDelete(id);
    dispatch(meetingPointDeleteSuccess({ payload: data, message: message }));
  } catch (e) {
    const { error } = e;
    dispatch(meetingPointDeleteFail({ error }));
  }
};

export const meetingPointPaginate = (forRelation = false, page = null, items = null) => async (
  dispatch,
  getState
) => {
  dispatch(meetingPointIndexFetch());
  try {
    //If is not paginates values, Get default value from state
    page = page ? page : getState().meetingPoints.payload.pagination.current_page;
    items = items ? items : getState().meetingPoints.payload.pagination.per_page;
    forRelation = forRelation ? appService.appSerialize(RELATIONS) : null;
    const { data, meta } = await meetingPointService.meetingPointIndex(
      forRelation,
      null,
      page,
      items
    );
    dispatch(meetingPointIndexSuccess({ meetingPoints: data, pagination: meta }));
  } catch (e) {
    dispatch(meetingPointIndexFail(e));
  }
};

export const meetingPointIndex = (forRelation = false, params = null) => async (dispatch) => {
  dispatch(meetingPointIndexFetch());
  try {
    forRelation = forRelation ? appService.appSerialize(RELATIONS) : null;
    let serializedParams = '';
    if (params && typeof params === 'object') {
      Object.keys(params).map((param, key, array) => {
        serializedParams += `${param}=${params[param]}`;
        if (array[key + 1] !== undefined) serializedParams += '&';
      });
    } else serializedParams = params;
    const { data, meta } = await meetingPointService.meetingPointIndex(
      forRelation,
      serializedParams
    );
    dispatch(meetingPointIndexSuccess({ meetingPoints: data, pagination: meta }));
  } catch (e) {
    dispatch(meetingPointIndexFail(e));
  }
};

export const meetingPointShow = (data) => async (dispatch) => {
  dispatch(meetingPointShowSuccess({ meetingPoint: data }));
};

export const meetingPointStore = (data) => async (dispatch) => {
  dispatch(meetingPointStoreFetch());
  try {
    const { message } = await meetingPointService.meetingPointStore(data);
    dispatch(meetingPointCloseModal());
    dispatch(meetingPointIndexFetch());
    dispatch(meetingPointStoreSuccess({ message }));
  } catch (e) {
    const { errors, message } = e;
    dispatch(meetingPointStoreFail({ errors, message }));
  }
};

export const meetingPointUpdate = (id, data) => async (dispatch) => {
  dispatch(meetingPointUpdateFetch());
  try {
    const { message } = await meetingPointService.meetingPointUpdate(id, data);
    dispatch(meetingPointCloseModal());
    dispatch(meetingPointIndexFetch());
    dispatch(meetingPointUpdateSuccess({ message }));
  } catch (e) {
    const { errors, message } = e;
    dispatch(meetingPointUpdateFail({ errors, message }));
  }
};

export const itemMeetingPoints = (item_id) => async (dispatch) => {
  dispatch(meetingPointIndexFetch());
  try {
    const { data } = await meetingPointService.itemMeetingPoints(item_id);
    dispatch(meetingPointIndexSuccess({ meetingPoints: data }));
  } catch (e) {
    dispatch(meetingPointIndexFail(e));
  }
};

export const meetingPointOpenModal = () => (dispatch) => {
  dispatch(meetingPointModalOpen());
};

export const meetingPointCloseModal = () => (dispatch) => {
  dispatch(meetingPointModalClose());
};

export const meetingPointActionStore = () => (dispatch) => {
  dispatch(meetingPointActionTypeStore({ actionType: 'store' }));
};

export const meetingPointActionUpdate = () => (dispatch) => {
  dispatch(meetingPointTypeUpdate({ actionType: 'update' }));
};
