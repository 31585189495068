import React, { Component } from 'react';
import { Form, Grid } from 'semantic-ui-react';
import modelTransportationCategory from './model';
import { connect } from 'react-redux';
import { transportationCategoryStore, transportationCategoryUpdate } from './actions';
import PropTypes from 'prop-types';

class TransportationCategoryForm extends Component {
  constructor(props) {
    super(props);
    const { actionType, transportationCategory } = props;
    this.state = {
      model: actionType === 'store' ? modelTransportationCategory : transportationCategory,
      errorForm: {},
    };
  }

  componentWillReceiveProps(nextProps) {
    const { errors } = nextProps;
    this.setState({ errorForm: { ...errors.errors } });
  }
  changeValue = (input) => {
    const { name, value } = input;
    let newValue = {};
    newValue[name] = value;
    this.setState(
      ({ model }) => ({ model: { ...model, ...newValue } }),
      () => {
        this.setState(({ errorForm }) => {
          let newErrors = Object.keys(errorForm)
            .filter((key) => key !== name)
            .reduce((obj, key) => {
              obj[key] = errorForm[key];
              return obj;
            }, {});
          return { errorForm: newErrors };
        });
      }
    );
  };
  render() {
    const { name } = this.state.errorForm;
    const { model } = this.state;
    return (
      <Form
        id={'form'}
        onSubmit={(e) => {
          const { model } = this.state;
          const {
            actionType,
            fnTransportationCategoryStore,
            fnTransportationCategoryUpdate,
          } = this.props;
          switch (actionType) {
            case 'store':
              fnTransportationCategoryStore(model);
              break;
            case 'update':
              fnTransportationCategoryUpdate(model.id, model);
              break;
            default:
              break;
          }
        }}
      >
        <Grid centered columns={2}>
          <Grid.Column>
            <Form.Input
              value={model.name}
              name={'name'}
              autoComplete={'off'}
              onChange={(e, data) => this.changeValue(data)}
              error={name === undefined ? false : { content: name, pointing: 'below' }}
              label="Name:"
              placeholder="Add Name"
            />
          </Grid.Column>
        </Grid>
      </Form>
    );
  }
}

const mapStateToProps = (state) => ({
  isFetching: state.transportationCategories.isFetching,
  errors: state.transportationCategories.errors,
  isFetchingTransportationCategories: state.transportationCategories.isFetching,
  actionType: state.transportationCategories.payload.actionType,
  transportationCategory: state.transportationCategories.payload.transportationCategory,
});

const mapDispatchToProps = (dispatch) => ({
  fnTransportationCategoryStore: (data) => {
    dispatch(transportationCategoryStore(data));
  },
  fnTransportationCategoryUpdate: (id, data) => {
    dispatch(transportationCategoryUpdate(id, data));
  },

});

TransportationCategoryForm.propTypes = {
  isFetching: PropTypes.bool,
  errors: PropTypes.PropTypes.exact({
    errors: PropTypes.object,
    message: PropTypes.string,
  }),
  actionType: PropTypes.oneOf(['store', 'update']),
  fnTransportationCategoryStore: PropTypes.func,
  fnTransportationCategoryUpdate: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransportationCategoryForm);
