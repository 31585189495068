import PropTypes from 'prop-types';
import api from '../api';

const departureService = {
  departureDelete(id) {
    return new Promise((resolve, reject) => {
      api
        .delete(`/departures/${id}`)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },

  departureIndex(relation = null, params = null, page = null, items = null) {
    return new Promise((resolve, reject) => {
      api
        .get(
          `/departures${page ? `/page/${page}` : ''}${items ? `/items/${items}` : ''}${
            relation ? `/${relation}` : ''
          }${params ? `?${params}` : ''}`
        )
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },

  departureShow(id) {
    return new Promise((resolve, reject) => {
      api
        .get(`/departures/${id}`)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },

  departureStore(data) {
    return new Promise((resolve, reject) => {
      api
        .post('/departures', data)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },

  departureUpdate(id, data) {
    return new Promise((resolve, reject) => {
      api
        .put(`/departures/${id}`, data)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },
  meetingPointDepartures(meeting_point_id) {
    return new Promise((resolve, reject) => {
      api
        .get(`/meeting_points/departures/${meeting_point_id}`)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },
};

departureService.propTypes = {
  departureIndex: PropTypes.func.isRequired,
  departureShow: PropTypes.func.isRequired,
};

export default departureService;
