import PropTypes from 'prop-types';
import api from '../api';

const transportationService = {
  transportationDelete(id) {
    return new Promise((resolve, reject) => {
      api
        .delete(`/transportations/${id}`)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },

  transportationIndex(relation = null, params = null, page = null, items = null) {
    return new Promise((resolve, reject) => {
      api
        .get(
          `/transportations${page ? `/page/${page}` : ''}${items ? `/items/${items}` : ''}${
            relation ? `/${relation}` : ''
          }${params ? `?${params}` : ''}`
        )
        .then((response) => response.data)
        .then(resolve)
        .catch(reject);
    });
  },

  transportationShow(id) {
    return new Promise((resolve, reject) => {
      api
        .get(`/transportations/${id}`)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },

  transportationStore(data) {
    return new Promise((resolve, reject) => {
      api
        .post('/transportations', data)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },

  transportationUpdate(id, data) {
    return new Promise((resolve, reject) => {
      api
        .put(`/transportations/${id}`, data)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },
};

transportationService.propTypes = {
  transportationIndex: PropTypes.func.isRequired,
  transportationShow: PropTypes.func.isRequired,
};

export default transportationService;
