import React, { Component } from 'react';
import { connect } from 'react-redux';
import { appCheckStorage } from './actions';
import RedirectIfAuthenticated from './autentication/RedirectIfAuthenticated';
import AuthenticateRoute from './autentication/AuthenticateRoute';
import './App.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import 'semantic-ui/dist/semantic.min.css';
import 'react-contexify/dist/ReactContexify.min.css';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  CATEGORY_ROUTE,
  CRUISE_ARRIVAL_ROUTE,
  DASHBOARD_ROUTE,
  DEPARTURE_ROUTE,
  ITEM_ROUTE,
  LOGIN_ROUTE,
  MEETING_POINT_ROUTE,
  MOVEMENT_TYPE_ROUTE,
  PAYMENT_METHOD_ROUTE,
  PAYMENT_TYPE_ROUTE,
  POINT_SALE_ROUTE,
  PROCESS_STATUS_ROUTE,
  RATE_ROUTE,
  SELLER_ROUTE,
  SHIPPING_HOTEL_COMPANY_ROUTE,
  TRANSPORTATION_CATEGORY_ROUTE,
  TRANSPORTATION_ROUTE,
  USER_ROUTE,
  VENDOR_ROUTE,
  SLOT_ROUTE,
  SALE_ROUTE,
} from '../constants';
import { Layout, LoginLayout } from './layout';
import CruiseArrival from '../cruise_arrival/CruiseArrival';
import MovementType from '../movement_type/MovementType';
import Slote from '../slots/Slots';
import PaymentMethod from '../payment_method/PaymentMethod';
import ProcessStatus from '../process_status/ProcessStatus';
import Seller from '../sellers/Seller';
import ShippingHotelCompany from '../shipping_hotel_company/ShippingHotelCompany';
import Category from '../category/Category';
import TransportationCategory from '../transportation_category/TransportationCategory';
import PaymentType from '../payment_type/PaymentType';
import Transportation from '../transportation/Transportation';
import Departure from '../departure/Departure';
import Item from '../item/Item';
import User from '../user/User';
import Vendor from '../vendor/Vendor';
import Rate from '../rate/Rates';
import PointSale from '../point_sale/PointSale';
import MeetingPoint from '../meeting_point/MeetingPoint';
import Login from '../login/Login';
import Dashboard from '../dashboard/Dashboard';
import Sales from '../sales/Sale';

class App extends Component {
  constructor(props) {
    super(props);
    const { fnAppCheckStorage } = props;
    fnAppCheckStorage();
  }

  render() {
    const { isFetching, isAuthenticated } = this.props;
    return (
      !isFetching &&
      isAuthenticated !== null && (
        <Switch>
          <RedirectIfAuthenticated
            path={LOGIN_ROUTE}
            redirectPath={DASHBOARD_ROUTE}
            isAuthenticated={isAuthenticated}
            component={(props) => (
              <LoginLayout {...props}>
                <Login />
              </LoginLayout>
            )}
            exact={true}
          />
          <AuthenticateRoute
            path={DASHBOARD_ROUTE}
            authenticatePath={LOGIN_ROUTE}
            isAuthenticated={isAuthenticated}
            exact={true}
            component={(props) => (
              <Layout {...props}>
                <Dashboard />
              </Layout>
            )}
          />
          <AuthenticateRoute
            exact={true}
            isAuthenticated={isAuthenticated}
            authenticatePath={LOGIN_ROUTE}
            path={USER_ROUTE}
            component={(props) => (
              <Layout {...props}>
                <User />
              </Layout>
            )}
          />
          <AuthenticateRoute
            exact={true}
            isAuthenticated={isAuthenticated}
            authenticatePath={LOGIN_ROUTE}
            path={DEPARTURE_ROUTE}
            component={(props) => (
              <Layout {...props}>
                <Departure />
              </Layout>
            )}
          />
          <AuthenticateRoute
            exact={true}
            isAuthenticated={isAuthenticated}
            authenticatePath={LOGIN_ROUTE}
            path={MEETING_POINT_ROUTE}
            component={(props) => (
              <Layout {...props}>
                <MeetingPoint />
              </Layout>
            )}
          />
          <AuthenticateRoute
            exact={true}
            isAuthenticated={isAuthenticated}
            authenticatePath={LOGIN_ROUTE}
            path={ITEM_ROUTE}
            component={(props) => (
              <Layout {...props}>
                <Item />
              </Layout>
            )}
          />
          <AuthenticateRoute
            exact={true}
            isAuthenticated={isAuthenticated}
            authenticatePath={LOGIN_ROUTE}
            path={VENDOR_ROUTE}
            component={(props) => (
              <Layout {...props}>
                <Vendor />
              </Layout>
            )}
          />
          <AuthenticateRoute
            exact={true}
            isAuthenticated={isAuthenticated}
            authenticatePath={LOGIN_ROUTE}
            path={POINT_SALE_ROUTE}
            component={(props) => (
              <Layout {...props}>
                <PointSale />
              </Layout>
            )}
          />
          <AuthenticateRoute
            exact={true}
            isAuthenticated={isAuthenticated}
            authenticatePath={LOGIN_ROUTE}
            path={PAYMENT_TYPE_ROUTE}
            component={(props) => (
              <Layout {...props}>
                <PaymentType />
              </Layout>
            )}
          />
          <AuthenticateRoute
            exact={true}
            isAuthenticated={isAuthenticated}
            authenticatePath={LOGIN_ROUTE}
            path={PROCESS_STATUS_ROUTE}
            component={(props) => (
              <Layout {...props}>
                <ProcessStatus />
              </Layout>
            )}
          />
          <AuthenticateRoute
            exact={true}
            isAuthenticated={isAuthenticated}
            authenticatePath={LOGIN_ROUTE}
            path={SLOT_ROUTE}
            component={(props) => (
              <Layout {...props}>
                <Slote />
              </Layout>
            )}
          />
          <AuthenticateRoute
            exact={true}
            isAuthenticated={isAuthenticated}
            authenticatePath={LOGIN_ROUTE}
            path={SELLER_ROUTE}
            component={(props) => (
              <Layout {...props}>
                <Seller />
              </Layout>
            )}
          />
          <AuthenticateRoute
            exact={true}
            isAuthenticated={isAuthenticated}
            authenticatePath={LOGIN_ROUTE}
            path={MOVEMENT_TYPE_ROUTE}
            component={(props) => (
              <Layout {...props}>
                <MovementType />
              </Layout>
            )}
          />
          <AuthenticateRoute
            exact={true}
            isAuthenticated={isAuthenticated}
            authenticatePath={LOGIN_ROUTE}
            path={RATE_ROUTE}
            component={(props) => (
              <Layout {...props}>
                <Rate />
              </Layout>
            )}
          />
          <AuthenticateRoute
            exact={true}
            isAuthenticated={isAuthenticated}
            authenticatePath={LOGIN_ROUTE}
            path={CRUISE_ARRIVAL_ROUTE}
            component={(props) => (
              <Layout {...props}>
                <CruiseArrival />
              </Layout>
            )}
          />
          <AuthenticateRoute
            exact={true}
            isAuthenticated={isAuthenticated}
            authenticatePath={LOGIN_ROUTE}
            path={SHIPPING_HOTEL_COMPANY_ROUTE}
            component={(props) => (
              <Layout {...props}>
                <ShippingHotelCompany />
              </Layout>
            )}
          />
          <AuthenticateRoute
            exact={true}
            isAuthenticated={isAuthenticated}
            authenticatePath={LOGIN_ROUTE}
            path={CATEGORY_ROUTE}
            component={(props) => (
              <Layout {...props}>
                <Category />
              </Layout>
            )}
          />
          <AuthenticateRoute
            exact={true}
            isAuthenticated={isAuthenticated}
            authenticatePath={LOGIN_ROUTE}
            path={TRANSPORTATION_ROUTE}
            component={(props) => (
              <Layout {...props}>
                <Transportation />
              </Layout>
            )}
          />
          <AuthenticateRoute
            exact={true}
            isAuthenticated={isAuthenticated}
            authenticatePath={LOGIN_ROUTE}
            path={TRANSPORTATION_CATEGORY_ROUTE}
            component={(props) => (
              <Layout {...props}>
                <TransportationCategory />
              </Layout>
            )}
          />
          <AuthenticateRoute
            exact={true}
            isAuthenticated={isAuthenticated}
            authenticatePath={LOGIN_ROUTE}
            path={PAYMENT_TYPE_ROUTE}
            component={(props) => (
              <Layout {...props}>
                <PaymentMethod />
              </Layout>
            )}
          />
          <AuthenticateRoute
            exact={true}
            isAuthenticated={isAuthenticated}
            authenticatePath={LOGIN_ROUTE}
            path={SALE_ROUTE}
            component={(props) => (
              <Layout {...props}>
                <Sales />
              </Layout>
            )}
          />
          <Route
            path={'*'}
            component={() => <Redirect to={isAuthenticated ? DASHBOARD_ROUTE : LOGIN_ROUTE} />}
          />
        </Switch>
      )
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.app.payload.isAuthenticated,
  isFetching: state.app.isFetching,
});

const mapDispatchToProps = (dispatch) => ({
  fnAppCheckStorage: () => {
    dispatch(appCheckStorage());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
