import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import rootReducer from './rootReducer';
import { actions as toastrActions } from 'react-redux-toastr';

const typeStatesSuccess = {
  STORE_SUCCESS: '_STORE_SUCCESS',
  UPDATE_SUCCESS: '_UPDATE_SUCCESS',
  DESTROY_SUCCESS: '_DESTROY_SUCCESS',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
};

const actionTypeIndexSuccess = (type) => {
  return typeStatesSuccess.LOGIN_SUCCESS === type;
};

const actionTypeEndStoreSuccess = (type) => {
  const store = typeStatesSuccess.STORE_SUCCESS;
  return type.substr(type.length - store.length) === store;
};

const actionTypeEndUpdateSuccess = (type) => {
  const update = typeStatesSuccess.UPDATE_SUCCESS;
  return type.substr(type.length - update.length) === update;
};

const actionTypeEndDeleteSuccess = (type) => {
  const destroy = typeStatesSuccess.DESTROY_SUCCESS;
  return type.substr(type.length - destroy.length) === destroy;
};

const alertMiddleware = ({ dispatch }) => (next) => (action) => {
  if (
    actionTypeEndStoreSuccess(action.type) ||
    actionTypeEndUpdateSuccess(action.type) ||
    actionTypeEndDeleteSuccess(action.type) ||
    actionTypeIndexSuccess(action.type)
  ) {
    dispatch(
      toastrActions.add({
        type: 'success',
        title: 'SUCCESS',
        position: 'top-right',
        attention: true,
        message: action.payload.message,
        options: {
          timeOut: 5000,
          showCloseButton: true,
          progressBar: true,
          removeOnHover: true,
        },
      })
    );
  }
  next(action);
};

const configureStore = () =>
  createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk, alertMiddleware)));

export default configureStore();
