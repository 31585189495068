import saleTypes from './types';
// DESTROY DISPATCH
export const saleDeleteFail = (payload) => {
  return {
    type: saleTypes.SALE_DESTROY_FAIL,
    errors: {
      ...payload,
    },
  };
};
export const saleDeleteFetch = () => ({
  type: saleTypes.SALE_DESTROY_FETCH,
});
export const saleDeleteSuccess = (payload) => ({
  type: saleTypes.SALE_DESTROY_SUCCESS,
  payload: { ...payload },
});

// INDEX DISPATCH
export const saleIndexFail = (payload) => ({
  type: saleTypes.SALE_INDEX_FAIL,
  payload,
});

export const saleIndexFetch = () => ({
  type: saleTypes.SALE_INDEX_FETCH,
});

export const saleIndexSuccess = (payload) => ({
  type: saleTypes.SALE_INDEX_SUCCESS,
  payload: {
    ...payload,
  },
});

// SHOW DISPATCH
export const saleShowFail = (payload) => ({
  type: saleTypes.SALE_SHOW_FAIL,
  errors: {
    ...payload,
  },
});
export const saleShowFetch = () => ({
  type: saleTypes.SALE_SHOW_FETCH,
});
export const saleShowSuccess = (payload) => ({
  type: saleTypes.SALE_SHOW_SUCCESS,
  payload: {
    ...payload,
  },
});

// STORE DISPATCH
export const saleStoreFail = (payload) => {
  return {
    type: saleTypes.SALE_STORE_FAIL,
    errors: {
      ...payload,
    },
  };
};

export const saleStoreFetch = () => ({
  type: saleTypes.SALE_STORE_FETCH,
});
export const saleStoreSuccess = (payload) => ({
  type: saleTypes.SALE_STORE_SUCCESS,
  payload: { ...payload },
});

// UPDATE DISPATCH
export const saleUpdateFail = (payload) => {
  return {
    type: saleTypes.SALE_UPDATE_FAIL,
    errors: {
      ...payload,
    },
  };
};
export const saleUpdateFetch = () => ({
  type: saleTypes.SALE_UPDATE_FETCH,
});
export const saleUpdateSuccess = (payload) => ({
  type: saleTypes.SALE_UPDATE_SUCCESS,
  payload: {
    ...payload,
  },
});
export const saleModalOpen = () => ({
  type: saleTypes.SALE_MODAL_OPEN,
});
export const saleModalClose = () => ({
  type: saleTypes.SALE_MODAL_CLOSE,
});
export const saleActionTypeStore = (payload) => ({
  type: saleTypes.SALE_ACTION_TYPE_STORE,
  payload: { ...payload },
});
export const saleActionTypeUpdate = (payload) => ({
  type: saleTypes.SALE_ACTION_TYPE_UPDATE,
  payload: { ...payload },
});
