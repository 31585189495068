import transportationService from './service';
import {
  transportationDeleteFail,
  transportationDeleteFetch,
  transportationDeleteSuccess,
  transportationIndexFail,
  transportationIndexFetch,
  transportationIndexSuccess,
  transportationShowFail,
  transportationShowFetch,
  transportationShowSuccess,
  transportationStoreFail,
  transportationStoreFetch,
  transportationStoreSuccess,
  transportationUpdateFail,
  transportationUpdateFetch,
  transportationUpdateSuccess,
  transportationActionTypeStore,
  transportationActionTypeUpdate,
  transportationModalClose,
  transportationModalOpen,
} from './dispatch';
import { departureCloseModal, departureIndex } from '../departure/actions';
import { vendorShowSuccess } from '../vendor/dispatch';
import { sloteIndexFetch } from '../slots/dispatch';
import { RELATIONS } from '../transportation/model';
import appService from '../app/service';

export const transportationDelete = (id) => async (dispatch) => {
  dispatch(transportationDeleteFetch());
  try {
    const { data, message } = await transportationService.transportationDelete(id);
    dispatch(transportationDeleteSuccess({ payload: data, message: message }));
    dispatch(transportationIndex());
  } catch (e) {
    const { error } = e;
    dispatch(transportationDeleteFail({ error }));
  }
};

export const transportationPaginate = (
  forRelation = false,
  params = null,
  page = null,
  items = null
) => async (dispatch, getState) => {
  dispatch(sloteIndexFetch());
  try {
    //If is not paginates values, Get default value from state
    page = page ? page : getState().sales.payload.pagination.current_page;
    items = items ? items : getState().sales.payload.pagination.per_page;
    forRelation = forRelation ? appService.appSerialize(RELATIONS) : null;
    let serializedParams = '';
    if (params && typeof params === 'object') {
      Object.keys(params).map((param, key, array) => {
        serializedParams += `${param}=${params[param]}`;
        if (array[key + 1] !== undefined) serializedParams += '&';
      });
    } else serializedParams = params;

    const { data, meta } = await transportationService.transportationIndex(
      forRelation,
      serializedParams,
      page,
      items
    );
    const { pagination } = getState().transportations.payload;
    dispatch(
      transportationIndexSuccess({ transportations: data, pagination: { ...pagination, ...meta } })
    );
  } catch (e) {
    dispatch(transportationIndexFail(e));
  }
};

export const transportationIndex = (forRelation = false, page = null, items = null) => async (
  dispatch,
  getState
) => {
  dispatch(transportationIndexFetch());
  try {
    //If is not paginates values, Get default value from state
    if (!forRelation) {
      page = page ? page : getState().transportations.payload.pagination.current_page;
      items = items ? items : getState().transportations.payload.pagination.per_page;
    }
    const { data, meta } = await transportationService.transportationIndex(page, items);
    dispatch(transportationIndexSuccess({ transportations: data, pagination: meta }));
  } catch (e) {
    dispatch(transportationIndexFail(e));
  }
};
export const transportationShow = (data) => (dispatch) => {
  dispatch(transportationShowSuccess({ transportation: data }));
};
// export const transportationShow = (id) => async (dispatch) => {
//   dispatch(transportationShowFetch());
//   try {
//     const { data } = await transportationService.transportationShow(id);
//     dispatch(transportationShowSuccess({ transportation: data }));
//   } catch (e) {
//     const { error } = e;
//     dispatch(transportationShowFail({ error }));
//   }
// };

export const transportationStore = (data) => async (dispatch) => {
  dispatch(transportationStoreFetch());
  try {
    const { message } = await transportationService.transportationStore(data);
    dispatch(transportationStoreSuccess({ message }));
    dispatch(transportationCloseModal());
    dispatch(transportationPaginate(true));
  } catch (e) {
    const { errors, message } = e;
    dispatch(transportationStoreFail({ errors, message }));
  }
};

export const transportationUpdate = (id, data) => async (dispatch) => {
  dispatch(transportationUpdateFetch());
  try {
    const { message } = await transportationService.transportationUpdate(id, data);
    dispatch(transportationUpdateSuccess({ message }));
    dispatch(transportationCloseModal());
    dispatch(transportationPaginate(true));
  } catch (e) {
    const { errors, message } = e;
    dispatch(transportationUpdateFail({ errors, message }));
  }
};

export const transportationOpenModal = () => (dispatch) => {
  dispatch(transportationModalOpen());
};

export const transportationCloseModal = () => (dispatch) => {
  dispatch(transportationModalClose());
};

export const transportationActionStore = () => (dispatch) => {
  dispatch(transportationActionTypeStore({ actionType: 'store' }));
};

export const transportationActionUpdate = () => (dispatch) => {
  dispatch(transportationActionTypeUpdate({ actionType: 'update' }));
};
