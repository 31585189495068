import PropTypes from 'prop-types';
import api from '../api';

const categoryService = {
  categoryDelete(id) {
    return new Promise((resolve, reject) => {
      api
        .delete(`/categories/${id}`)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },

  categoryIndex(page = null, items = null) {
    return new Promise((resolve, reject) => {
      api
        .get(`/categories${page ? `/page/${page}` : ''}${items ? `/items/${items}` : ''}`)
        .then((response) => response.data)
        .then(resolve)
        .catch(reject);
    });
  },

  categoryShow(id) {
    return new Promise((resolve, reject) => {
      api
        .get(`/categories/${id}`)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },

  categoryStore(data) {
    return new Promise((resolve, reject) => {
      api
        .post('/categories', data)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },

  categoryUpdate(id, data) {
    return new Promise((resolve, reject) => {
      api
        .put(`/categories/${id}`, data)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },
};

categoryService.propTypes = {
  categoryIndex: PropTypes.func.isRequired,
  categoryShow: PropTypes.func.isRequired,
};

export default categoryService;
