import api from '../api';

const loginService = {
  userLogin(data) {
    return new Promise((resolve, reject) => {
      api
        .post('/user/login', data)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },
};

export default loginService;
