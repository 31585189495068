export const MODULE_NAME = 'Users';
export const ROUTE = '/users';

const User = {
  id: null,
  name: null,
  email: null,
  status: null,
  created_at: null,
  updated_at: null,
};

export default User;
