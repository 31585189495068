import types from './types';
import processStatusModel from './model';
import { paginationModel, errorModel } from './../app/model';

export const INITIAL_STATE = {
  isFetching: false,
  payload: {
    processStatus: { ...processStatusModel },
    processStatuses: [],
    pagination: { ...paginationModel },
    message: null,
  },
  errors: { ...errorModel },
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.PROCESS_STATUS_DESTROY_FAIL:
      return {
        ...state,
        isFetching: false,
        errors: { ...state.errors, ...action.errors },
      };
    case types.PROCESS_STATUS_DESTROY_FETCH:
      return {
        ...state,
        isFetching: true,
      };
    case types.PROCESS_STATUS_DESTROY_SUCCESS:
      return {
        ...state,
        isFetching: false,
        payload: { ...state.payload, ...action.payload },
      };
    case types.PROCESS_STATUS_INDEX_FAIL:
      return {
        ...state,
        isFetching: false,
        error: { ...state.errors, ...action.payload },
      };
    case types.PROCESS_STATUS_INDEX_FETCH:
      return {
        ...state,
        isFetching: true,
      };
    case types.PROCESS_STATUS_INDEX_SUCCESS:
      return {
        ...state,
        isFetching: false,
        payload: { ...state.payload, ...action.payload },
      };
    case types.PROCESS_STATUS_SHOW_FAIL:
      return {
        ...state,
        isFetching: false,
        errors: { ...state.errors, ...action.errors },
      };
    case types.PROCESS_STATUS_SHOW_FETCH:
      return {
        ...state,
        isFetching: true,
      };
    case types.PROCESS_STATUS_SHOW_SUCCESS:
      return {
        ...state,
        isFetching: false,
        payload: { ...state.payload, ...action.payload },
      };
    case types.PROCESS_STATUS_STORE_FAIL:
      return {
        ...state,
        isFetching: false,
        errors: { ...state.errors, ...action.errors },
      };
    case types.PROCESS_STATUS_STORE_FETCH:
      return {
        ...state,
        isFetching: true,
      };
    case types.PROCESS_STATUS_STORE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        payload: { ...state.payload, ...action.payload },
      };
    case types.PROCESS_STATUS_UPDATE_FAIL:
      return {
        ...state,
        isFetching: false,
        errors: { ...state.errors, ...action.errors },
      };
    case types.PROCESS_STATUS_UPDATE_FETCH:
      return {
        ...state,
        isFetching: true,
      };
    case types.PROCESS_STATUS_UPDATE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        payload: { ...state.payload, ...action.payload },
      };
    default:
      return state;
  }
};

export default reducer;
