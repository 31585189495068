import React, { Component } from 'react';
import { Form, Grid } from 'semantic-ui-react';
import modelTransportation from './model';
import { connect } from 'react-redux';
import { transportationStore, transportationUpdate } from './actions';
import { transportationCategoryIndex } from '../transportation_category/actions';
import { slotIndex } from '../slots/actions';
import _ from 'lodash';

import PropTypes from 'prop-types';

class TransportationForm extends Component {
  constructor(props) {
    super(props);

    const { actionType, transportation } = props;
    this.state = {
      model: actionType === 'store' ? modelTransportation : transportation,
      relations: {
        transportationsCategories: [],
      },
      errorForm: {},
    };
    const { fnTransportationCategories, fnSlots } = props;
    fnTransportationCategories();
    fnSlots();
  }
  componentWillReceiveProps(nextProps) {
    const { transportationCategories, errors, slots } = nextProps;
    Object.keys(errors.errors).map((error) => {
      if (Object.keys(errors.errors).length !== Object.keys(this.props.errors.errors).length) {
        this.setState({ errorForm: { ...errors.errors } });
      }
    });
    this.setState(() => {
      //vendors
      const enableTransportationCategorie = transportationCategories.filter(
        (transportationCategory) => transportationCategory.status === 1
      );
      let selectTransportationCategories = {};
      selectTransportationCategories.transportationsCategories = enableTransportationCategorie.map(
        (transportationCategory) =>
          Object.keys(transportationCategory)
            .filter((key) => {
              return key === 'id' || key === 'name';
            })
            .reduce((obj) => {
              obj = {
                value: transportationCategory.id,
                text: transportationCategory.name,
                key: transportationCategory.id,
              };
              return obj;
            }, {})
      );
      //Slots
      const enableSlots = slots.filter(
        (transportationCategory) => transportationCategory.status === 1
      );
      let selectSlots = {};
      selectSlots.slots = enableSlots.map((transportationCategory) =>
        Object.keys(transportationCategory)
          .filter((key) => {
            return key === 'id' || key === 'name';
          })
          .reduce((obj) => {
            obj = {
              value: transportationCategory.id,
              text: transportationCategory.name,
              key: transportationCategory.id,
            };
            return obj;
          }, {})
      );
      return {
        relations: {
          ...selectTransportationCategories,
          ...selectSlots,
        },
      };
    });
    // const { errors } = nextProps;
    // this.setState({ errorForm: { ...errors.errors } });
  }
  changeValue = (input) => {
    const { name, value } = input;
    let newValue = {};
    newValue[name] = value;
    this.setState(
      ({ model }) => ({ model: { ...model, ...newValue } }),
      () => {
        this.setState(({ errorForm }) => {
          let newErrors = Object.keys(errorForm)
            .filter((key) => key !== name)
            .reduce((obj, key) => {
              obj[key] = errorForm[key];
              return obj;
            }, {});
          return { errorForm: newErrors };
        });
      }
    );
  };

  appendSlot = (data) => {
    const { slots } = this.props;
    const { value } = data;
    const slotsValue = value.map((slotId) => {
      const { id, name, code, capacity } = _.find(slots, { id: slotId });
      return { id, name, code, capacity };
    });
    this.setState((prevState) => ({
      ...prevState,
      model: { ...prevState.model, slots: slotsValue },
    }));
  };

  render() {
    const { name, transportation_category_id } = this.state.errorForm;
    const { model, relations } = this.state;
    let defaultSlotsSelected = model.slots.length === 0 ? [] : model.slots.map((slot) => slot.id);
    return (
      <Form
        id={'form'}
        onSubmit={(e) => {
          const { model } = this.state;
          const { actionType, fnTransportationStore, fnTransportationUpdate } = this.props;
          switch (actionType) {
            case 'store':
              fnTransportationStore(model);
              break;
            case 'update':
              fnTransportationUpdate(model.id, model);
              break;
            default:
              break;
          }
        }}
      >
        <Grid stackable columns={1}>
          <Grid.Column>
            <Form.Input
              value={model.name}
              name={'name'}
              autoComplete={'off'}
              onChange={(e, data) => this.changeValue(data)}
              error={name === undefined ? false : { content: name, pointing: 'below' }}
              label="Name:"
              placeholder="Add Name"
            />
          </Grid.Column>
          <Grid.Column>
            <Form.Select
              value={model.transportation_category_id}
              name={'transportation_category_id'}
              label="Transportation category:"
              error={
                transportation_category_id === undefined
                  ? false
                  : { content: transportation_category_id, pointing: 'below' }
              }
              placeholder="Add Transportation category"
              options={relations.transportationsCategories}
              onChange={(e, data) => this.changeValue(data)}
            />
          </Grid.Column>


          <Grid.Column>
            <Form.Select
              multiple
              value={defaultSlotsSelected}
              name={'slots'}
              label={'Slots'}
              placeholder={'Select Slots'}
              options={relations.slots}
              onChange={(e, data) => this.appendSlot(data)}
            />
          </Grid.Column>
        </Grid>
      </Form>
    );
  }
}

const mapStateToProps = (state) => ({
  isFetching: state.transportations.isFetching,
  errors: state.transportations.errors,
  actionType: state.transportations.payload.actionType,
  transportation: state.transportations.payload.transportation,
  transportationCategories: state.transportationCategories.payload.transportationCategories,
  slots: state.slots.payload.slots,
});

const mapDispatchToProps = (dispatch) => ({
  fnTransportationStore: (data) => {
    dispatch(transportationStore(data));
  },
  fnTransportationCategories: () => {
    dispatch(transportationCategoryIndex(true));
  },
  fnTransportationUpdate: (id, data) => {
    dispatch(transportationUpdate(id, data));
  },
  fnSlots: () => {
    dispatch(slotIndex());
  },
});

TransportationForm.propTypes = {
  isFetching: PropTypes.bool,
  errors: PropTypes.PropTypes.exact({
    errors: PropTypes.object,
    message: PropTypes.string,
  }),
  actionType: PropTypes.oneOf(['store', 'update']),
  transportation: PropTypes.object,
  fnTransportationStore: PropTypes.func,
  fnTransportationUpdate: PropTypes.func,
  fnTransportationCategories: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransportationForm);
