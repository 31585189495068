import paymentTypes from './types';
// DESTROY DISPATCH
export const paymentTypeDeleteFail = (payload) => {
  return {
    type: paymentTypes.PAYMENT_TYPE_DESTROY_FAIL,
    errors: {
      ...payload,
    },
  };
};
export const paymentTypeDeleteFetch = () => ({
  type: paymentTypes.PAYMENT_TYPE_DESTROY_FETCH,
});
export const paymentTypeDeleteSuccess = (payload) => ({
  type: paymentTypes.PAYMENT_TYPE_DESTROY_SUCCESS,
  payload: { ...payload },
});

// INDEX DISPATCH
export const paymentTypeIndexFail = (payload) => ({
  type: paymentTypes.PAYMENT_TYPE_INDEX_FAIL,
  payload,
});

export const paymentTypeIndexFetch = () => ({
  type: paymentTypes.PAYMENT_TYPE_INDEX_FETCH,
});

export const paymentTypeIndexSuccess = (payload) => ({
  type: paymentTypes.PAYMENT_TYPE_INDEX_SUCCESS,
  payload: {
    ...payload,
  },
});

// SHOW DISPATCH
export const paymentTypeShowFail = (payload) => ({
  type: paymentTypes.PAYMENT_TYPE_SHOW_FAIL,
  errors: {
    ...payload,
  },
});
export const paymentTypeShowFetch = () => ({
  type: paymentTypes.PAYMENT_TYPE_SHOW_FETCH,
});
export const paymentTypeShowSuccess = (payload) => ({
  type: paymentTypes.PAYMENT_TYPE_SHOW_SUCCESS,
  payload: {
    ...payload,
  },
});

// STORE DISPATCH
export const paymentTypeStoreFail = (payload) => {
  return {
    type: paymentTypes.PAYMENT_TYPE_STORE_FAIL,
    errors: {
      ...payload,
    },
  };
};

export const paymentTypeStoreFetch = () => ({
  type: paymentTypes.PAYMENT_TYPE_STORE_FETCH,
});
export const paymentTypeStoreSuccess = (payload) => ({
  type: paymentTypes.PAYMENT_TYPE_STORE_SUCCESS,
  payload: { ...payload },
});

// UPDATE DISPATCH
export const paymentTypeUpdateFail = (payload) => {
  return {
    type: paymentTypes.PAYMENT_TYPE_UPDATE_FAIL,
    errors: {
      ...payload,
    },
  };
};
export const paymentTypeUpdateFetch = () => ({
  type: paymentTypes.PAYMENT_TYPE_UPDATE_FETCH,
});
export const paymentTypeUpdateSuccess = (payload) => ({
  type: paymentTypes.PAYMENT_TYPE_UPDATE_SUCCESS,
  payload: {
    ...payload,
  },
});
