import PropTypes from 'prop-types';
import api from '../api';

const vendorCategoryService = {
  vendorCategoryIndex(page = null, items = null) {
    return new Promise((resolve, reject) => {
      api
        .get(`/vendor_categories${page ? `/page/${page}` : ''}${items ? `/items/${items}` : ''}`)
        .then((response) => response.data)
        .then(resolve)
        .catch(reject);
    });
  },

  vendorCategoryShow(id) {
    return new Promise((resolve, reject) => {
      api
        .get(`/vendor_categories/${id}`)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },
};

vendorCategoryService.propTypes = {
  vendorCategoryIndex: PropTypes.func.isRequired,
  vendorCategoryShow: PropTypes.func.isRequired,
};

export default vendorCategoryService;
