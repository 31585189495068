import transportationCategoryService from './service';
import {
  transportationCategoryDeleteFail,
  transportationCategoryDeleteFetch,
  transportationCategoryDeleteSuccess,
  transportationCategoryIndexFail,
  transportationCategoryIndexFetch,
  transportationCategoryIndexSuccess,
  transportationCategoryShowFail,
  transportationCategoryShowFetch,
  transportationCategoryShowSuccess,
  transportationCategoryStoreFail,
  transportationCategoryStoreFetch,
  transportationCategoryStoreSuccess,
  transportationCategoryUpdateFail,
  transportationCategoryUpdateFetch,
  transportationCategoryUpdateSuccess,
  transportationCategoryActionTypeStore,
  transportationCategoryActionTypeUpdate,
  transportationCategoryModalClose,
  transportationCategoryModalOpen,
} from './dispatch';
import appService from '../app/service';
import { RELATIONS } from './model';
import { transportationCloseModal, transportationIndex } from '../transportation/actions';
import { transportationShowSuccess } from '../transportation/dispatch';

export const transportationCategoryDelete = (id) => async (dispatch) => {
  dispatch(transportationCategoryDeleteFetch());
  try {
    const { data } = await transportationCategoryService.transportationCategoryDelete(id);
    dispatch(transportationCategoryDeleteSuccess({ payload: data }));
    dispatch(transportationCategoryCloseModal());
    dispatch(transportationCategoryIndex());
  } catch (e) {
    const { error } = e;
    dispatch(transportationCategoryDeleteFail({ error }));
  }
};

export const transportationCategoryPaginate = (
  forRelation = false,
  page = null,
  items = null
) => async (dispatch, getState) => {
  dispatch(transportationCategoryIndexFetch());
  try {
    //If is not paginates values, Get default value from state
    page = page ? page : getState().transportationCategories.payload.pagination.current_page;
    items = items ? items : getState().transportationCategories.payload.pagination.per_page;
    forRelation = forRelation ? appService.appSerialize(RELATIONS) : null;

    const { data, meta } = await transportationCategoryService.transportationCategoryIndex(
      forRelation,
      null,
      page,
      items
    );
    dispatch(
      transportationCategoryIndexSuccess({ transportationCategories: data, pagination: meta })
    );
  } catch (e) {
    dispatch(transportationCategoryIndexFail(e));
  }
};

export const transportationCategoryIndex = (forRelation = false, params = null) => async (
  dispatch
) => {
  dispatch(transportationCategoryIndexFetch());
  try {
    forRelation = forRelation ? appService.appSerialize(RELATIONS) : null;
    let serializedParams = '';
    if (params && typeof params === 'object') {
      Object.keys(params).map((param, key, array) => {
        serializedParams += `${param}=${params[param]}`;
        if (array[key + 1] !== undefined) serializedParams += '&';
      });
    } else serializedParams = params;
    const { data, meta } = await transportationCategoryService.transportationCategoryIndex(
      forRelation,
      serializedParams
    );
    dispatch(transportationCategoryIndexSuccess({ transportationCategories: data, meta: meta }));
  } catch (e) {
    dispatch(transportationCategoryIndexFail(e));
  }
};

export const asyncTransportationCategoryShow = (id) => async (dispatch) => {
  dispatch(transportationCategoryShowFetch());
  try {
    const { data } = await transportationCategoryService.transportationCategoryShow(id);
    dispatch(transportationCategoryShowSuccess({ transportationCategory: data }));
  } catch (e) {
    const { error } = e;
    dispatch(transportationCategoryShowFail({ error }));
  }
};

export const transportationCategoryShow = (data) => (dispatch) => {
  dispatch(transportationCategoryShowSuccess({ transportationCategory: data }));
};

export const transportationCategoryStore = (data) => async (dispatch) => {
  dispatch(transportationCategoryStoreFetch());
  try {
    const { message } = await transportationCategoryService.transportationCategoryStore(data);
    dispatch(transportationCategoryStoreSuccess({ message }));
    dispatch(transportationCategoryCloseModal());
    dispatch(transportationCategoryIndex());
  } catch (e) {
    const { errors, message } = e;
    dispatch(transportationCategoryStoreFail({ errors, message }));
  }
};

export const transportationCategoryUpdate = (id, data) => async (dispatch) => {
  dispatch(transportationCategoryUpdateFetch());
  try {
    const { message } = await transportationCategoryService.transportationCategoryUpdate(id, data);
    dispatch(transportationCategoryUpdateSuccess({ message }));
    dispatch(transportationCategoryCloseModal());
    dispatch(transportationCategoryIndex());
  } catch (e) {
    const { errors, message } = e;
    dispatch(transportationCategoryUpdateFail({ errors, message }));
  }
};

export const transportationCategoryOpenModal = () => (dispatch) => {
  dispatch(transportationCategoryModalOpen());
};

export const transportationCategoryCloseModal = () => (dispatch) => {
  dispatch(transportationCategoryModalClose());
};

export const transportationCategoryActionStore = () => (dispatch) => {
  dispatch(transportationCategoryActionTypeStore({ actionType: 'store' }));
};

export const transportationCategoryActionUpdate = () => (dispatch) => {
  dispatch(transportationCategoryActionTypeUpdate({ actionType: 'update' }));
};
