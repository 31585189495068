import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Dimmer, Loader, Table, Button } from 'semantic-ui-react';
import { Menu, contextMenu, animation, theme } from 'react-contexify';
import {
  vendorDelete,
  vendorIndex,
  vendorShow,
  vendorStore,
  vendorUpdate,
  vendorActionStore,
  vendorActionUpdate,
  vendorCloseModal,
  vendorOpenModal,
  vendorPaginate,
} from './actions';
import { VENDOR_MODULE_NAME } from '../constants';
import { TitleHead, ContextMenu, Pagination, Modal as ModalVendor } from '../app/common';
import VendorForm from './VendorForm';
import PropTypes from 'prop-types';

class Vendors extends Component {
  state = {
    // isModalOpen: false,
  };

  componentDidMount() {
    this.props.fnVendorPaginate(true);
  }

  toRender = {
    id: {
      type: 'number',
      visible: false,
      name: '',
    },
    name: {
      type: 'string',
      visible: true,
      name: 'name',
    },
    initials: {
      type: 'string',
      visible: true,
      name: 'Initials',
    },
    interface_code: {
      type: 'string',
      visible: true,
      name: 'Interface Code',
    },
    vendor_category_id: {
      type: 'number',
      visible: true,
      name: 'Vendor Category',
    },
    status: {
      type: 'number',
      visible: true,
      name: 'Status',
    },
  };

  renderTable = (data) => {
    const allowed = [];
    Object.keys(this.toRender).map((k) => {
      allowed.push(k);
    });

    return data.map((ag) =>
      Object.keys(ag)
        .filter((key) => allowed.includes(key))
        .reduce((obj, key) => {
          obj[key] = { ...{ value: ag[key] }, ...this.toRender[key] };
          return obj;
        }, {})
    );
  };

  openContextMenu = (e, data) => {
    const {
      fnVendorDelete,
      fnVendorModalOpen,
      fnVendorActionStore,
      fnVendorActionUpdate,
      fnVendorShow,
    } = this.props;
    e.preventDefault();
    contextMenu.show({
      id: 'menu_id',
      event: e,
      props: {
        data: { ...data },
        fnDelete: fnVendorDelete,
        fnModalOpen: fnVendorModalOpen,
        fnActionStore: fnVendorActionStore,
        fnActionUpdate: fnVendorActionUpdate,
        fnShow: fnVendorShow,
      },
    });
  };

  render() {
    const { payload, isFetching, fnVendorIndex, isModalOpen, fnVendorModalClose } = this.props;
    const { vendors, pagination } = payload;
    return (
      <Fragment>
        <TitleHead title={VENDOR_MODULE_NAME} />
        <div className={'row'}>
          <div className={'col-md-12'}>
            <ModalVendor
              isOpen={isModalOpen}
              actionType={payload.actionType}
              modalTitle={VENDOR_MODULE_NAME}
              isFetchingButton={isFetching && isModalOpen}
              onClose={fnVendorModalClose}
            >
              <VendorForm />
            </ModalVendor>
            <div className={'main-card mb-3 card'}>
              <div className={'card-body'}>
                <h5 className={'card-title'}>
                  {VENDOR_MODULE_NAME.substr(0, VENDOR_MODULE_NAME.length - 1)} Lists
                </h5>
                <div className={'row'}>
                  <Pagination
                    title={`Show ${VENDOR_MODULE_NAME.toLowerCase()} by`}
                    {...pagination}
                    fnIndex={fnVendorIndex}
                  />
                  <div className="col-md-12">
                    <Dimmer.Dimmable blurring={true} dimmed={isFetching}>
                      <Dimmer active={isFetching} inverted={true}>
                        <Loader>Loading</Loader>
                      </Dimmer>
                      <Table compact={true} celled={true}>
                        <Table.Header>
                          <Table.Row>
                            {Object.keys(this.toRender).map((key, index) => {
                              return index === 0 ? (
                                <Fragment key={1}>
                                  <Table.HeaderCell>#</Table.HeaderCell>
                                  {this.toRender[key].visible && (
                                    <Table.HeaderCell>{this.toRender[key].name}</Table.HeaderCell>
                                  )}
                                </Fragment>
                              ) : (
                                this.toRender[key].visible && (
                                  <Table.HeaderCell>{this.toRender[key].name}</Table.HeaderCell>
                                )
                              );
                            })}
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {this.renderTable(vendors).map((data, index) => {
                            const { per_page, to, total } = pagination;
                            const row = (total > per_page ? to - per_page : to - total) + index + 1;
                            const rows = [];
                            //Pushing Rows number
                            if (data.id) {
                              rows.push(<Table.Cell>{row}</Table.Cell>);
                            }
                            // Push Other cells
                            Object.keys(data).filter((key) => {
                              if (data[key].visible) {
                                rows.push(<Table.Cell>{data[key].value}</Table.Cell>);
                              }
                            });
                            return (
                              <Table.Row
                                onContextMenu={(e) => this.openContextMenu(e, vendors[index])}
                              >
                                {rows}
                              </Table.Row>
                            );
                          })}
                        </Table.Body>
                      </Table>
                      <ContextMenu />
                    </Dimmer.Dimmable>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateProps = (state, ownProps = {}) => ({
  isFetching: state.vendors.isFetching,
  payload: state.vendors.payload,
  errors: state.vendors.errors,
  isModalOpen: state.vendors.isModalOpen,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  fnVendorIndex: (forRelation, page, items) => {
    dispatch(vendorIndex(forRelation, page, items));
  },
  fnVendorDelete: (id) => {
    dispatch(vendorDelete(id));
  },
  fnVendorPaginate: (forRelation, page, items) => {
    dispatch(vendorPaginate(forRelation, page, items));
  },
  fnVendorShow: (id) => {
    dispatch(vendorShow(id));
  },
  fnVendorStore: (data) => {
    dispatch(vendorStore(data));
  },
  fnVendorUpdate: (id, data) => {
    dispatch(vendorUpdate(id, data));
  },
  fnVendorActionStore: () => {
    dispatch(vendorActionStore());
  },
  fnVendorActionUpdate: () => {
    dispatch(vendorActionUpdate());
  },
  fnVendorModalOpen: () => {
    dispatch(vendorOpenModal());
  },
  fnVendorModalClose: () => {
    dispatch(vendorCloseModal());
  },
});

Vendors.propTypes = {
  fnVendorModalOpen: PropTypes.func,
  fnVendorModalClose: PropTypes.func,
  fnVendorShow: PropTypes.func,
  fnVendorActionStore: PropTypes.func,
  fnVendorPaginate: PropTypes.func,
  fnVendorActionUpdate: PropTypes.func,
  isFetching: PropTypes.bool,
  isModalOpen: PropTypes.bool,
  payload: PropTypes.exact({
    vendor: PropTypes,
    vendors: PropTypes,
    pagination: PropTypes,
    message: PropTypes.string,
    actionType: PropTypes.oneOf(['store', 'update']),
  }),
  errors: PropTypes.object,
};

export default connect(
  mapStateProps,
  mapDispatchToProps
)(Vendors);
