import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Dimmer, Loader, Table } from 'semantic-ui-react';
import { contextMenu } from 'react-contexify';
import {
  sloteDelete,
  slotePaginate,
  sloteShow,
  sloteStore,
  sloteUpdate,
  sloteActionStore,
  sloteActionUpdate,
  sloteCloseModal,
  sloteOpenModal,
  sloteRelationPaginate,
} from './actions';
import { SLOT_MODULE_NAME } from '../constants';
import { TitleHead, ContextMenu, Pagination, Modal as ModalSlote } from '../app/common';
import PropTypes from 'prop-types';
import SloteForm from './SlotsForm';
class Slote extends Component {
  state = {
    isModalOpen: false,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.fnpaginateSlotRelation(true);
  }

  toRender = {
    id: {
      type: 'number',
      visible: false,
      name: '',
    },
    code: {
      type: 'string',
      visible: true,
      name: 'code',
    },
    name: {
      type: 'string',
      visible: true,
      name: 'name',
    },
    capacity: {
      type: 'number',
      visible: true,
      name: 'capacity',
    },
    status: {
      type: 'number',
      visible: true,
      name: 'status',
    },
  };

  renderTable = (data) => {
    const allowed = [];
    Object.keys(this.toRender).map((k) => {
      allowed.push(k);
    });

    return data.map((ag) =>
      Object.keys(ag)
        .filter((key) => allowed.includes(key))
        .reduce((obj, key) => {
          // console.log()
          obj[key] = { ...{ value: ag[key] }, ...this.toRender[key] };
          return obj;
        }, {})
    );
  };

  openContextMenu = (e, data) => {
    const {
      fnSlotDelete,
      fnSlotModalOpen,
      fnSloteActionStore,
      fnSloteActionUpdate,
      fnSloteShow,
    } = this.props;
    e.preventDefault();
    contextMenu.show({
      id: 'menu_id',
      event: e,
      props: {
        data: { ...data },
        fnDelete: fnSlotDelete,
        fnModalOpen: fnSlotModalOpen,
        fnActionStore: fnSloteActionStore,
        fnActionUpdate: fnSloteActionUpdate,
        fnShow: fnSloteShow,
      },
    });
  };

  render() {
    const { payload, isFetching, fnSlotePaginate, isModalOpen, fnSlotModalClose } = this.props;
    const { slots, pagination } = payload;
    return (
      <Fragment>
        <TitleHead title={SLOT_MODULE_NAME} />
        <div className={'row'}>
          <div className={'col-md-12'}>
            <ModalSlote
              isOpen={isModalOpen}
              actionType={payload.actionType}
              modalTitle={SLOT_MODULE_NAME}
              isFetchingButton={isFetching && isModalOpen}
              onClose={fnSlotModalClose}
            >
              <SloteForm />
            </ModalSlote>
            <div className={'main-card mb-3 card'}>
              <div className={'card-body'}>
                <h5 className={'card-title'}>
                  {SLOT_MODULE_NAME.substr(0, SLOT_MODULE_NAME.length - 1)} Lists
                </h5>
                <div className={'row'}>
                  <Pagination
                    title={`Show ${SLOT_MODULE_NAME.toLowerCase()} by`}
                    {...pagination}
                    fnIndex={fnSlotePaginate}
                  />
                  <div className="col-md-12">
                    <Dimmer.Dimmable blurring={true} dimmed={isFetching}>
                      <Dimmer active={isFetching} inverted={true}>
                        <Loader>Loading</Loader>
                      </Dimmer>
                      <Table compact={true} celled={true}>
                        <Table.Header>
                          <Table.Row>
                            {Object.keys(this.toRender).map((key, index) => {
                              return index === 0 ? (
                                <Fragment key={1}>
                                  <Table.HeaderCell>#</Table.HeaderCell>
                                  {this.toRender[key].visible && (
                                    <Table.HeaderCell>{this.toRender[key].name}</Table.HeaderCell>
                                  )}
                                </Fragment>
                              ) : (
                                this.toRender[key].visible && (
                                  <Table.HeaderCell>{this.toRender[key].name}</Table.HeaderCell>
                                )
                              );
                            })}
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {slots.length > 0 ?
                            this.renderTable(slots).map((data, index) => {
                              const { per_page, to, total } = pagination;
                              const row =
                                (total > per_page ? to - per_page : to - total) + index + 1;
                              const rows = [];
                              //Pushing Rows number
                              if (data.id) {
                                rows.push(<Table.Cell>{row}</Table.Cell>);
                              }
                              // Push Other cells
                              Object.keys(data).filter((key) => {
                                if (data[key].visible) {
                                  rows.push(<Table.Cell>{data[key].value}</Table.Cell>);
                                }
                              });
                              return (
                                <Table.Row
                                  onContextMenu={(e) => this.openContextMenu(e, slots[index])}
                                >
                                  {rows}
                                </Table.Row>
                              );
                            }) : <Table.Row onContextMenu={(e) => this.openContextMenu(e, [])}><Table.Cell>Table is empty...</Table.Cell></Table.Row>}
                        </Table.Body>
                      </Table>
                      <ContextMenu canStatus={slots.length > 0} canUpdate={slots.length > 0} />
                    </Dimmer.Dimmable>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateProps = (state, ownProps = {}) => ({
  isFetching: state.slots.isFetching,
  payload: state.slots.payload,
  errors: state.slots.errors,
  isModalOpen: state.slots.isModalOpen,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  fnSlotePaginate: (forRelation, page, items) => {
    dispatch(slotePaginate(forRelation, page, items));
  },
  fnSlotDelete: (id) => {
    dispatch(sloteDelete(id));
  },
  fnpaginateSlotRelation: (forRelation, page = null, items = null) => {
    dispatch(sloteRelationPaginate(forRelation, null, page, items));
  },
  fnSloteShow: (id) => {
    dispatch(sloteShow(id));
  },
  fnSloteActionStore: () => {
    dispatch(sloteActionStore());
  },
  fnSloteActionUpdate: () => {
    dispatch(sloteActionUpdate());
  },
  fnSlotModalOpen: () => {
    dispatch(sloteOpenModal());
  },
  fnSlotModalClose: () => {
    dispatch(sloteCloseModal());
  },
});

Slote.propTypes = {
  fnSloteModalOpen: PropTypes.func,
  fnSloteModalClose: PropTypes.func,
  // fnRateShow: PropTypes.func,
  fnSloteActionStore: PropTypes.func,
  // fnRateActionUpdate: PropTypes.func,
  isFetching: PropTypes.bool,
  isModalOpen: PropTypes.bool,
  payload: PropTypes.exact({
    slot: PropTypes,
    slots: PropTypes,
    pagination: PropTypes,
    message: PropTypes.string,
    actionType: PropTypes.oneOf(['store', 'update']),
  }),
  errors: PropTypes.object,
};

export default connect(
  mapStateProps,
  mapDispatchToProps
)(Slote);
