import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Dimmer, Loader, Table } from 'semantic-ui-react';
import { contextMenu } from 'react-contexify';
import {
  rateDelete,
  ratePaginate,
  rateShow,
  rateStore,
  rateUpdate,
  rateActionStore,
  rateActionUpdate,
  rateCloseModal,
  rateOpenModal,
} from './actions';
import { RATE_MODULE_NAME, VENDOR_MODULE_NAME } from '../constants';
import { TitleHead, ContextMenu, Pagination, Modal as ModalRate } from '../app/common';
import PropTypes from 'prop-types';
import RateForm from './RateForm';
class Rate extends Component {
  state = {
    isModalOpen: false,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.fnRatePaginate(true);
  }

  toRender = {
    id: {
      type: 'number',
      visible: false,
      name: '',
    },
    vendor_id: {
      type: 'string',
      visible: true,
      name: 'Vendor id',
    },
    status: {
      type: 'string',
      visible: true,
      name: 'Status',
    },
  };

  renderTable = (data) => {
    const allowed = [];
    Object.keys(this.toRender).map((k) => {
      allowed.push(k);
    });

    return data.map((ag) =>
      Object.keys(ag)
        .filter((key) => allowed.includes(key))
        .reduce((obj, key) => {
          // console.log()
          obj[key] = { ...{ value: ag[key] }, ...this.toRender[key] };
          return obj;
        }, {})
    );
  };

  openContextMenu = (e, data) => {
    const {
      fnRateDelete,
      fnRateModalOpen,
      fnRateActionStore,
      fnRateActionUpdate,
      fnRateShow,
    } = this.props;
    e.preventDefault();
    contextMenu.show({
      id: 'menu_id',
      event: e,
      props: {
        data: { ...data },
        fnDelete: fnRateDelete,
        fnModalOpen: fnRateModalOpen,
        fnActionStore: fnRateActionStore,
        fnActionUpdate: fnRateActionUpdate,
        fnShow: fnRateShow,
      },
    });
  };

  render() {
    const { payload, isFetching, fnRatePaginate, isModalOpen, fnRateModalClose } = this.props;
    const { rates, pagination } = payload;
    return (
      <Fragment>
        <TitleHead title={RATE_MODULE_NAME} />
        <div className={'row'}>
          <div className={'col-md-12'}>
            <ModalRate
              isOpen={isModalOpen}
              actionType={payload.actionType}
              modalTitle={RATE_MODULE_NAME}
              isFetchingButton={isFetching && isModalOpen}
              onClose={fnRateModalClose}
            >
              <RateForm />
            </ModalRate>
            <div className={'main-card mb-3 card'}>
              <div className={'card-body'}>
                <h5 className={'card-title'}>
                  {RATE_MODULE_NAME.substr(0, RATE_MODULE_NAME.length - 1)} Lists
                </h5>
                <div className={'row'}>
                  <Pagination
                    title={`Show ${RATE_MODULE_NAME.toLowerCase()} by`}
                    {...pagination}
                    fnIndex={fnRatePaginate}
                  />
                  <div className="col-md-12">
                    <Dimmer.Dimmable blurring={true} dimmed={isFetching}>
                      <Dimmer active={isFetching} inverted={true}>
                        <Loader>Loading</Loader>
                      </Dimmer>
                      <Table compact={true} celled={true}>
                        <Table.Header>
                          <Table.Row>
                            {Object.keys(this.toRender).map((key, index) => {
                              return index === 0 ? (
                                <Fragment key={1}>
                                  <Table.HeaderCell>#</Table.HeaderCell>
                                  {this.toRender[key].visible && (
                                    <Table.HeaderCell>{this.toRender[key].name}</Table.HeaderCell>
                                  )}
                                </Fragment>
                              ) : (
                                this.toRender[key].visible && (
                                  <Table.HeaderCell>{this.toRender[key].name}</Table.HeaderCell>
                                )
                              );
                            })}
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {rates.length > 0 ? (
                            this.renderTable(rates).map((data, index) => {
                              const { per_page, to, total } = pagination;
                              const row =
                                (total > per_page ? to - per_page : to - total) + index + 1;
                              const rows = [];
                              //Pushing Rows number
                              if (data.id) {
                                rows.push(<Table.Cell>{row}</Table.Cell>);
                              }
                              // Push Other cells
                              Object.keys(data).filter((key) => {
                                if (data[key].visible) {
                                  rows.push(<Table.Cell>{data[key].value}</Table.Cell>);
                                }
                              });
                              return (
                                <Table.Row
                                  onContextMenu={(e) => this.openContextMenu(e, rates[index])}
                                >
                                  {rows}
                                </Table.Row>
                              );
                            })
                          ) : (
                            <Table.Row onContextMenu={(e) => this.openContextMenu(e, [])}>
                              <Table.Cell>Table is empty...</Table.Cell>
                            </Table.Row>
                          )}
                        </Table.Body>
                      </Table>
                      <ContextMenu canUpdate={rates.length > 0} canStatus={rates.length > 0} />
                    </Dimmer.Dimmable>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateProps = (state, ownProps = {}) => ({
  isFetching: state.rates.isFetching,
  payload: state.rates.payload,
  errors: state.rates.errors,
  isModalOpen: state.rates.isModalOpen,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  fnRatePaginate: (forRelation, page, items) => {
    dispatch(ratePaginate(forRelation, page, items));
  },
  fnRateDelete: (id) => {
    dispatch(rateDelete(id));
  },
  fnRateShow: (id) => {
    dispatch(rateShow(id));
  },
  fnRateStore: (data) => {
    dispatch(rateStore(data));
  },
  fnRateUpdate: (id, data) => {
    dispatch(rateUpdate(id, data));
  },
  fnRateActionStore: () => {
    dispatch(rateActionStore());
  },
  fnRateActionUpdate: () => {
    dispatch(rateActionUpdate());
  },
  fnRateModalOpen: () => {
    dispatch(rateOpenModal());
  },
  fnRateModalClose: () => {
    dispatch(rateCloseModal());
  },
});

Rate.propTypes = {
  fnRateModalOpen: PropTypes.func,
  fnRateModalClose: PropTypes.func,
  fnRateShow: PropTypes.func,
  fnRateActionStore: PropTypes.func,
  fnRateActionUpdate: PropTypes.func,
  isFetching: PropTypes.bool,
  isModalOpen: PropTypes.bool,
  payload: PropTypes.exact({
    rate: PropTypes,
    rates: PropTypes,
    pagination: PropTypes,
    message: PropTypes.string,
    actionType: PropTypes.oneOf(['store', 'update']),
  }),
  errors: PropTypes.object,
};

export default connect(
  mapStateProps,
  mapDispatchToProps
)(Rate);
