export const MODULE_NAME = 'Categories';
export const ROUTE = '/categories';

const Category = {
  id: null,
  name: null,
  status: null,
  created_id: null,
  created_at: null,
  updated_at: null,
};

export default Category;
