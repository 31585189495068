const types = {
  // FOR CRUD CATEGORY
  CATEGORY_DESTROY_FAIL: 'CATEGORY_DESTROY_FAIL',
  CATEGORY_DESTROY_FETCH: 'CATEGORY_DESTROY_FETCH',
  CATEGORY_DESTROY_SUCCESS: 'CATEGORY_DESTROY_SUCCESS',
  CATEGORY_INDEX_FAIL: 'CATEGORY_INDEX_FAIL',
  CATEGORY_INDEX_FETCH: 'CATEGORY_FETCH',
  CATEGORY_INDEX_SUCCESS: 'CATEGORY_INDEX_SUCCESS',
  CATEGORY_SHOW_FAIL: 'CATEGORY_SHOW_FAIL',
  CATEGORY_SHOW_FETCH: 'CATEGORY_SHOW_FETCH',
  CATEGORY_SHOW_SUCCESS: 'CATEGORY_SHOW_SUCCESS',
  CATEGORY_STORE_FAIL: 'CATEGORY_STORE_FAIL',
  CATEGORY_STORE_FETCH: 'CATEGORY_STORE_FETCH',
  CATEGORY_STORE_SUCCESS: 'CATEGORY_STORE_SUCCESS',
  CATEGORY_UPDATE_FAIL: 'CATEGORY_UPDATE_FAIL',
  CATEGORY_UPDATE_FETCH: 'CATEGORY_UPDATE_FETCH',
  CATEGORY_UPDATE_SUCCESS: 'CATEGORY_UPDATE_SUCCESS',
};

export const typeStates = {
  FAIL: '_FAIL',
  FETCH: '_FETCH',
  SUCCESS: '_SUCCESS',
};

export default types;
