const types = {
  // FOR CRUD SELLER
  SELLER_DESTROY_FAIL: 'SELLER_DESTROY_FAIL',
  SELLER_DESTROY_FETCH: 'SELLER_DESTROY_FETCH',
  SELLER_DESTROY_SUCCESS: 'SELLER_DESTROY_SUCCESS',
  SELLER_INDEX_FAIL: 'SELLER_INDEX_FAIL',
  SELLER_INDEX_FETCH: 'SELLER_INDEX_FETCH',
  SELLER_INDEX_SUCCESS: 'SELLER_INDEX_SUCCESS',
  SELLER_SHOW_FAIL: 'SELLER_SHOW_FAIL',
  SELLER_SHOW_FETCH: 'SELLER_SHOW_FETCH',
  SELLER_SHOW_SUCCESS: 'SELLER_SHOW_SUCCESS',
  SELLER_STORE_FAIL: 'SELLER_STORE_FAIL',
  SELLER_STORE_FETCH: 'SELLER_STORE_FETCH',
  SELLER_STORE_SUCCESS: 'SELLER_STORE_SUCCESS',
  SELLER_UPDATE_FAIL: 'SELLER_UPDATE_FAIL',
  SELLER_UPDATE_FETCH: 'SELLER_UPDATE_FETCH',
  SELLER_UPDATE_SUCCESS: 'SELLER_UPDATE_SUCCESS',
  // FOR MODAL SELLER
  SELLER_MODAL_OPEN: 'SELLER_MODAL_OPEN',
  SELLER_MODAL_CLOSE: 'SELLER_MODAL_CLOSE',
  SELLER_ACTION_TYPE_STORE: 'SELLER_ACTION_CREATE_TYPE',
  SELLER_ACTION_TYPE_UPDATE: 'SELLER_ACTION_UPDATE_TYPE',
};

export const typeStates = {
  FAIL: '_FAIL',
  FETCH: '_FETCH',
  SUCCESS: '_SUCCESS',
};

export default types;
