import React, { Component, useState } from 'react';
import {
  Grid,
  Input,
  Select,
  GridRow,
  Form,
  GridColumn,
  FormField,
  Checkbox,
  Segment,
  Icon,
  List,
  ListHeader,
  ListItem,
  Accordion,
  ListContent,
  ListDescription,
  ListIcon,
  AccordionTitle,
  AccordionContent,
} from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { OptionDetail } from '../option/model';

export const RateTabInformation = (props) => {
  const { name, date_start, vendor_id, date_end } = props.errorForm;
  const { relations, model, changeValue, onAddItem } = props;
  const defaultValues = model.rate_details.map((defaultValue) => defaultValue.item_id);
  return (
    <React.Fragment>
      <Grid centered columns={2}>
        <Grid.Column>
          <Form.Input
            value={model.rate_list.name}
            name={'name'}
            autoComplete={'off'}
            onChange={(e, data) => changeValue(data)}
            error={name === undefined ? false : { content: name, pointing: 'below' }}
            label="Name:"
            placeholder="Add Name"
          />
        </Grid.Column>
        <Grid.Column>
          <Form.Select
            value={model.vendor_id}
            name={'vendor_id'}
            options={relations.vendors}
            autoComplete={'off'}
            onChange={(e, data) => {
              changeValue(data);
            }}
            error={vendor_id === undefined ? false : { content: vendor_id, pointing: 'below' }}
            label="Vendor:"
            placeholder="Select Vendor"
          />
        </Grid.Column>
        <Grid.Column>
          <DateInput
            placeholder="Date start"
            name={'date_start'}
            value={model.date_start}
            dateFormat={'YYYY-MM-DD'}
            label={'Date start'}
            animation="none"
            error={date_start === undefined ? false : { content: date_start, pointing: 'below' }}
            icon={false}
            onChange={(e, data) => changeValue(data)}
            closable={true}
            autoComplete="off"
          />
        </Grid.Column>
        <Grid.Column>
          <DateInput
            placeholder="Date end"
            name={'date_end'}
            value={model.date_end}
            minDate={model.date_start === '' ? true : false}
            dateFormat={'YYYY-MM-DD'}
            readOnly={model.date_start === '' ? true : false}
            label={'Date End'}
            animation="none"
            error={date_end === undefined ? false : { content: date_end, pointing: 'below' }}
            icon={false}
            onChange={(e, data) => changeValue(data)}
            closable={true}
            autoComplete="off"
          />
        </Grid.Column>
      </Grid>
      <Grid centered columns={1}>
        <Grid.Column>
          <FormField
            control={Select}
            multiple
            fluid
            defaultValue={defaultValues}
            label={'Items'}
            placeholder={'Choose items'}
            options={relations.items}
            onChange={(e, data) => onAddItem(data)}
          />
        </Grid.Column>
      </Grid>
      <Grid centered columns={3}>
        <Grid.Column>
          <Form.Select
            value={model.rate_list.currency_id}
            name={'currency_id'}
            options={relations.currencies}
            autoComplete={'off'}
            onChange={(e, data) => changeValue(data)}
            // error={vendor_id === undefined ? false : { content: vendor_id, pointing: 'below' }}
            label="Currency:"
            placeholder="Select Currency"
          />
        </Grid.Column>
        <Grid.Column></Grid.Column>
        <Grid.Column></Grid.Column>
      </Grid>
    </React.Fragment>
  );
};
export const RateTabDetails = (props) => {
  const { detail, changeValueDetailsPrice, changeValueDetails, decimalTransform, itemKey } = props;
  const { optionables } = detail;
  let optionCode = optionables.code;
  const [index, setIndex] = useState(null);
  return (
    <React.Fragment>
      <Grid centered columns={3}>
        <Grid.Column>
          <Form.Input
            value={detail.commission}
            indexItemKey={itemKey}
            name={'commission'}
            autoComplete={'off'}
            onChange={(e, data) => {
              const newValue = {};
              const { name, value } = data;
              newValue.name = name;
              newValue.indexItemKey = itemKey;
              newValue.value = value > 0 ? parseInt(value) : 0;
              changeValueDetailsPrice(newValue);
            }}
            label="Commission:"
            placeholder="Add Commission"
          />
        </Grid.Column>
      </Grid>
      <Grid centered columns={2}>
        {optionCode !== null ? (
          <Accordion fluid styled key={detail.id}>
            <AccordionTitle
              active={index === 0}
              index={0}
              onClick={() => {
                const newIndex = index === 0 ? null : 0;
                setIndex(newIndex);
              }}
            >
              <Icon name={'dropdown'} />
              {detail.optionables.name}
            </AccordionTitle>
            <AccordionContent active={index === 0}>
              <Grid centered>
                <GridRow verticalAlign={'middle'}>
                  <Grid columns={2}>
                    {detail.optionables.option_details &&
                      detail.optionables.option_details.map((options, key) => {
                        const option = { ...OptionDetail, ...options };
                        return (
                          <Grid.Column stretched>
                            <Segment>
                              <List divided relaxed>
                                <ListItem>
                                  <ListContent>
                                    <ListHeader>{option.name}</ListHeader>
                                    <Form.Input
                                      value={decimalTransform(
                                        option.price - (option.price * detail.commission) / 100,
                                        2
                                      )}
                                      // value={detail.public_price}
                                      name={'price'}
                                      index={key}
                                      // indexOptionable={keyOptionable}
                                      indexItemKey={itemKey}
                                      autoComplete={'off'}
                                      // default={0}
                                      min={0}
                                      readOnly={true}
                                      onChange={(e, data) => changeValueDetailsPrice(data)}
                                      // error={name === undefined ? false : { content: name, pointing: 'below' }}
                                      label="Net Price:"
                                      placeholder="Net Price"
                                    />
                                    <Form.Input
                                      value={option.price}
                                      name={'public_price'}
                                      index={key}
                                      autoComplete={'off'}
                                      default={0}
                                      min={0}
                                      readOnly={true}
                                      type={'number'}
                                      label="Public price:"
                                      placeholder="Public price"
                                    />
                                  </ListContent>
                                </ListItem>
                              </List>
                            </Segment>
                          </Grid.Column>
                        );
                      })}
                  </Grid>
                </GridRow>
              </Grid>
            </AccordionContent>
          </Accordion>
        ) : (
          <React.Fragment>
            <Grid.Column stretched>
              <Form.Input
                value={decimalTransform(
                  detail.optionables.price - (detail.optionables.price * detail.commission) / 100,
                  2
                )}
                name={'price'}
                autoComplete={'off'}
                // default={0}
                min={0}
                // type={'number'}
                // error={name === undefined ? false : { content: name, pointing: 'below' }}
                label="Net Price:"
                placeholder="Net Price"
              />
              <Form.Input
                value={detail.optionables.price}
                name={'public_price'}
                autoComplete={'off'}
                default={0}
                min={0}
                readOnly={true}
                type={'number'}
                label="Public price:"
                placeholder="Public price"
              />
            </Grid.Column>
          </React.Fragment>
        )}
      </Grid>
    </React.Fragment>
  );
};
const CommonPropTypes = {
  model: PropTypes.object,
  relations: PropTypes.object,
  changeValue: PropTypes.func,
  errorForm: PropTypes.object,
};

RateTabInformation.propTypes = { ...CommonPropTypes, vendorCategories: PropTypes.array };
export const RateTabVendor = connect(
  (state) => ({
    vendorCategories: state.vendorCategories.payload.vendorCategories,
  }),
  null
);
