import processStatusService from './service';
import {
  processStatusDeleteFail,
  processStatusDeleteFetch,
  processStatusDeleteSuccess,
  processStatusIndexFail,
  processStatusIndexFetch,
  processStatusIndexSuccess,
  processStatusShowFail,
  processStatusShowFetch,
  processStatusShowSuccess,
  processStatusStoreFail,
  processStatusStoreFetch,
  processStatusStoreSuccess,
  processStatusUpdateFail,
  processStatusUpdateFetch,
  processStatusUpdateSuccess,
} from './dispatch';

export const processStatusDelete = (id) => async (dispatch) => {
  dispatch(processStatusDeleteFetch());
  try {
    const { data, message } = await processStatusService.processStatusDelete(id);
    dispatch(processStatusDeleteSuccess({ payload: data, message: message }));
  } catch (e) {
    const { error } = e;
    dispatch(processStatusDeleteFail({ error }));
  }
};

export const processStatusIndex = (forRelation = false, page = null, items = null) => async (
  dispatch,
  getState
) => {
  dispatch(processStatusIndexFetch());
  try {
    if (!forRelation) {
      //If is not paginates values, Get default value from state
      page = page ? page : getState().processStatus.payload.pagination.current_page;
      items = items ? items : getState().processStatus.payload.pagination.per_page;
    }

    const { data, meta } = await processStatusService.processStatusIndex(page, items);
    dispatch(processStatusIndexSuccess({ processStatuses: data, pagination: meta }));
  } catch (e) {
    dispatch(processStatusIndexFail(e));
  }
};

export const processStatusShow = (id) => async (dispatch) => {
  dispatch(processStatusShowFetch());
  try {
    const { data } = await processStatusService.processStatusShow(id);
    dispatch(processStatusShowSuccess({ processStatus: data }));
  } catch (e) {
    const { error } = e;
    dispatch(processStatusShowFail({ error }));
  }
};

export const processStatusStore = (data) => async (dispatch) => {
  dispatch(processStatusStoreFetch());
  try {
    const { message } = await processStatusService.processStatusStore(data);
    dispatch(processStatusStoreSuccess({ message }));
  } catch (e) {
    const { errors, message } = e;
    dispatch(processStatusStoreFail({ errors, message }));
  }
};

export const processStatusUpdate = (id, data) => async (dispatch) => {
  dispatch(processStatusUpdateFetch());
  try {
    const { message } = await processStatusService.processStatusUpdate(id, data);
    dispatch(processStatusUpdateSuccess({ message }));
  } catch (e) {
    const { errors, message } = e;
    dispatch(processStatusUpdateFail({ errors, message }));
  }
};
