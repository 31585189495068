import userServices from './service';
import {
  userDeleteFail,
  userDeleteFetch,
  userDeleteSuccess,
  userIndexFail,
  userIndexFetch,
  userIndexSuccess,
  userShowFail,
  userShowFetch,
  userShowSuccess,
  userStoreFail,
  userStoreFetch,
  userStoreSuccess,
  userUpdateFail,
  userUpdateFetch,
  userUpdateSuccess,
} from './dispatch';

export const userDelete = (id) => async (dispatch) => {
  dispatch(userDeleteFetch());
  try {
    const { data, message } = await userServices.userDelete(id);
    dispatch(userDeleteSuccess({ payload: data, message: message }));
  } catch (e) {
    const { error } = e;
    dispatch(userDeleteFail({ error }));
  }
};

export const userIndex = (forRelation = false, page = null, items = null) => async (
  dispatch,
  getState
) => {
  dispatch(userIndexFetch());
  try {
    //If is not paginates values, Get default value from state
    if (!forRelation) {
      page = page ? page : getState().users.payload.pagination.current_page;
      items = items ? items : getState().users.payload.pagination.per_page;
    }
    const { data, meta } = await userServices.userIndex(page, items);
    dispatch(userIndexSuccess({ users: data, pagination: meta }));
  } catch (e) {
    dispatch(userIndexFail(e));
  }
};

export const userShow = (id) => async (dispatch) => {
  dispatch(userShowFetch());
  try {
    const { data } = await userServices.userShow(id);
    dispatch(userShowSuccess({ user: data }));
  } catch (e) {
    const { error } = e;
    dispatch(userShowFail({ error }));
  }
};

export const userStore = (data) => async (dispatch) => {
  dispatch(userStoreFetch());
  try {
    const { message } = await userServices.userStore(data);
    dispatch(userStoreSuccess({ message }));
  } catch (e) {
    const { errors, message } = e;
    dispatch(userStoreFail({ errors, message }));
  }
};

export const userUpdate = (id, data) => async (dispatch) => {
  dispatch(userUpdateFetch());
  try {
    const { message } = await userServices.userUpdate(id, data);
    dispatch(userUpdateSuccess({ message }));
  } catch (e) {
    const { errors, message } = e;
    dispatch(userUpdateFail({ errors, message }));
  }
};
