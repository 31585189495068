import itemTypes from './types';
// DESTROY DISPATCH
export const itemDeleteFail = (payload) => {
  return {
    type: itemTypes.ITEM_DESTROY_FAIL,
    errors: {
      ...payload,
    },
  };
};
export const itemDeleteFetch = () => ({
  type: itemTypes.ITEM_DESTROY_FETCH,
});
export const itemDeleteSuccess = (payload) => ({
  type: itemTypes.ITEM_DESTROY_SUCCESS,
  payload: { ...payload },
});

// INDEX DISPATCH
export const itemIndexFail = (payload) => ({
  type: itemTypes.ITEM_INDEX_FAIL,
  payload,
});

export const itemIndexFetch = () => ({
  type: itemTypes.ITEM_INDEX_FETCH,
});

export const itemIndexSuccess = (payload) => ({
  type: itemTypes.ITEM_INDEX_SUCCESS,
  payload: {
    ...payload,
  },
});

// SHOW DISPATCH
export const itemShowFail = (payload) => ({
  type: itemTypes.ITEM_SHOW_FAIL,
  errors: {
    ...payload,
  },
});
export const itemShowFetch = () => ({
  type: itemTypes.ITEM_SHOW_FETCH,
});
export const itemShowSuccess = (payload) => ({
  type: itemTypes.ITEM_SHOW_SUCCESS,
  payload: {
    ...payload,
  },
});

// STORE DISPATCH
export const itemStoreFail = (payload) => {
  return {
    type: itemTypes.ITEM_STORE_FAIL,
    errors: {
      ...payload,
    },
  };
};

export const itemStoreFetch = () => ({
  type: itemTypes.ITEM_STORE_FETCH,
});
export const itemStoreSuccess = (payload) => ({
  type: itemTypes.ITEM_STORE_SUCCESS,
  payload: { ...payload },
});

// UPDATE DISPATCH
export const itemUpdateFail = (payload) => {
  return {
    type: itemTypes.ITEM_UPDATE_FAIL,
    errors: {
      ...payload,
    },
  };
};
export const itemUpdateFetch = () => ({
  type: itemTypes.ITEM_UPDATE_FETCH,
});
export const itemUpdateSuccess = (payload) => ({
  type: itemTypes.ITEM_UPDATE_SUCCESS,
  payload: {
    ...payload,
  },
});
export const itemModalOpen = () => ({
  type: itemTypes.ITEM_MODAL_OPEN,
});
export const itemModalClose = () => ({
  type: itemTypes.ITEM_MODAL_CLOSE,
});
export const itemActionTypeStore = (payload) => ({
  type: itemTypes.ITEM_ACTION_TYPE_STORE,
  payload: { ...payload },
});
export const itemActionTypeUpdate = (payload) => ({
  type: itemTypes.ITEM_ACTION_TYPE_UPDATE,
  payload: { ...payload },
});
