import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Dimmer, Loader, Table, Button } from 'semantic-ui-react';
import { Menu, Item, contextMenu, animation, theme } from 'react-contexify';
import {
  movementTypeDelete,
  movementTypeIndex,
  movementTypeShow,
  movementTypeStore,
  movementTypeUpdate,
} from './actions';
import { MOVEMENT_TYPE_MODULE_NAME } from '../constants';
import { TitleHead } from '../app/common';
const onClick = ({ event, props }) => {
  alert(`${props.name} was updated`);
};
const ContenxtMenu = (props) => {
  console.log(props);
  return (
    <Menu
      id={'menu_id'}
      animation={animation.fade}
      style={{ zIndex: 99, height: 'auto' }}
      theme={theme.dark}
    >
      <Item onClick={(e) => onClick(e)}>Update</Item>
    </Menu>
  );
};

class MovementType extends Component {
  state = {
    isModalOpen: false,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.fnMovementTypeIndex();
  }

  toRender = {
    id: {
      type: 'number',
      visible: false,
      name: '',
    },
    name: {
      type: 'string',
      visible: true,
      name: 'Nombre',
    },
    status: {
      type: 'string',
      visible: true,
      name: 'Status',
    },
  };

  renderTable = (data) => {
    const allowed = [];
    Object.keys(this.toRender).map((k) => {
      allowed.push(k);
    });

    return data.map((ag) =>
      Object.keys(ag)
        .filter((key) => allowed.includes(key))
        .reduce((obj, key) => {
          // console.log()
          obj[key] = { ...{ value: ag[key] }, ...this.toRender[key] };
          return obj;
        }, {})
    );
  };

  openContextMenu = (e, data) => {
    e.preventDefault();
    contextMenu.show({
      id: 'menu_id',
      event: e,
      props: { ...data },
    });
  };

  render() {
    const { payload, isFetching } = this.props;
    const { movementTypes, pagination } = payload;
    return (
      <Fragment>
        <TitleHead title={MOVEMENT_TYPE_MODULE_NAME} />
        <div className={'row'}>
          <div className={'col-md-12'}>
            <div className={'main-card mb-3 card'}>
              <div className={'card-body'}>
                <h5 className={'card-title'}>Card Title</h5>
                <div className={'row'}>
                  <div className="col-md-12">
                    <Dimmer.Dimmable blurring={true} dimmed={isFetching}>
                      <Dimmer active={isFetching} inverted={true}>
                        <Loader>Loading</Loader>
                      </Dimmer>
                      <Table compact={true} celled={true}>
                        <Table.Header>
                          <Table.Row>
                            {Object.keys(this.toRender).map((key, index) => {
                              return index === 0 ? (
                                <Fragment key={1}>
                                  <Table.HeaderCell>#</Table.HeaderCell>
                                  {this.toRender[key].visible && (
                                    <Table.HeaderCell>{this.toRender[key].name}</Table.HeaderCell>
                                  )}
                                </Fragment>
                              ) : (
                                this.toRender[key].visible && (
                                  <Table.HeaderCell>{this.toRender[key].name}</Table.HeaderCell>
                                )
                              );
                            })}
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {this.renderTable(movementTypes).map((data, index) => {
                            const { per_page, to, total } = pagination;
                            const row = (total > per_page ? to - per_page : to - total) + index + 1;
                            const rows = [];
                            //Pushing Rows number
                            if (data.id) {
                              rows.push(<Table.Cell>{row}</Table.Cell>);
                            }
                            // Push Other cells
                            Object.keys(data).filter((key) => {
                              if (data[key].visible) {
                                rows.push(<Table.Cell>{data[key].value}</Table.Cell>);
                              }
                            });
                            return (
                              <Table.Row
                                onContextMenu={(e) => this.openContextMenu(e, movementTypes[index])}
                              >
                                {rows}
                              </Table.Row>
                            );
                          })}
                        </Table.Body>
                      </Table>
                      <ContenxtMenu />
                    </Dimmer.Dimmable>
                  </div>
                </div>
              </div>
            </div>
            <Button
              onClick={() =>
                this.props.fnMovementTypeStore({
                  name: 'cguemez tour1',
                })
              }
            >
              store
            </Button>
            <Button onClick={() => this.props.fnMovementTypeShow(10)}>show</Button>
            <Button onClick={() => this.props.fnMovementTypeDelete(10)}>Delete</Button>
            <Button
              onClick={() =>
                this.props.fnMovementTypeUpdate(11, {
                  name: 'cguemez tour12',
                })
              }
            >
              Update
            </Button>
          </div>
          {/*{user.id !== null ? alert(`Usuario Cargado ${user.name}`) : ''}*/}
        </div>
      </Fragment>
    );
  }
}

const mapStateProps = (state, ownProps = {}) => ({
  isFetching: state.movementTypes.isFetching,
  payload: state.movementTypes.payload,
  errors: state.movementTypes.errors,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  fnMovementTypeIndex: (forRelation, page, items) => {
    dispatch(movementTypeIndex(forRelation, page, items));
  },
  fnMovementTypeDelete: (id) => {
    dispatch(movementTypeDelete(id));
  },
  fnMovementTypeShow: (id) => {
    dispatch(movementTypeShow(id));
  },
  fnMovementTypeStore: (data) => {
    dispatch(movementTypeStore(data));
  },
  fnMovementTypeUpdate: (id, data) => {
    dispatch(movementTypeUpdate(id, data));
  },
});

MovementType.propTypes = {};

export default connect(
  mapStateProps,
  mapDispatchToProps
)(MovementType);
