import sellerService from './service';
import {
  sellerDeleteFail,
  sellerDeleteFetch,
  sellerDeleteSuccess,
  sellerIndexFail,
  sellerIndexFetch,
  sellerIndexSuccess,
  sellerShowFail,
  sellerShowFetch,
  sellerShowSuccess,
  sellerStoreFail,
  sellerStoreFetch,
  sellerStoreSuccess,
  sellerUpdateFail,
  sellerUpdateFetch,
  sellerUpdateSuccess,
  sellerModalOpen,
  sellerModalClose,
  sellerActionTypeStore,
  sellerActionTypeUpdate,
} from './dispatch';
import { transportationCloseModal, transportationIndex } from '../transportation/actions';
import { transportationShowSuccess } from '../transportation/dispatch';

export const sellerDelete = (id) => async (dispatch) => {
  dispatch(sellerDeleteFetch());
  try {
    const { data } = await sellerService.sellerDelete(id);
    dispatch(sellerDeleteSuccess({ payload: data }));
  } catch (e) {
    const { error } = e;
    dispatch(sellerDeleteFail({ error }));
  }
};

export const sellerIndex = (forRelation = false, page = null, items = null) => async (
  dispatch,
  getState
) => {
  dispatch(sellerIndexFetch());
  try {
    //If is not paginates values, Get default value from state
    if (!forRelation) {
      page = page ? page : getState().sellers.payload.pagination.current_page;
      items = items ? items : getState().sellers.payload.pagination.per_page;
    }

    const { data, meta } = await sellerService.sellerIndex(page, items);
    dispatch(sellerIndexSuccess({ sellers: data, pagination: meta }));
  } catch (e) {
    dispatch(sellerIndexFail(e));
  }
};

export const sellerShow = (data) => (dispatch) => {
  dispatch(sellerShowSuccess({ seller: data }));
};
// export const sellerShow = (id) => async (dispatch) => {
//   dispatch(sellerShowFetch());
//   try {
//     const { data } = await sellerService.sellerShow(id);
//     dispatch(sellerShowSuccess({ sellers: data }));
//   } catch (e) {
//     const { error } = e;
//     dispatch(sellerShowFail({ error }));
//   }
// };

export const sellerStore = (data) => async (dispatch) => {
  dispatch(sellerStoreFetch());
  try {
    const { message } = await sellerService.sellerStore(data);
    dispatch(sellerStoreSuccess({ message }));
    dispatch(sellerCloseModal());
    dispatch(sellerIndex());
  } catch (e) {
    const { errors, message } = e;
    dispatch(sellerStoreFail({ errors, message }));
  }
};

export const sellerUpdate = (id, data) => async (dispatch) => {
  dispatch(sellerUpdateFetch());
  try {
    const { message } = await sellerService.sellerUpdate(id, data);
    dispatch(sellerUpdateSuccess({ message }));
    dispatch(sellerCloseModal());
    dispatch(sellerIndex());
  } catch (e) {
    const { errors, message } = e;
    dispatch(sellerUpdateFail({ errors, message }));
  }
};
export const sellerOpenModal = () => (dispatch) => {
  dispatch(sellerModalOpen());
};

export const sellerCloseModal = () => (dispatch) => {
  dispatch(sellerModalClose());
};

export const sellerActionStore = () => (dispatch) => {
  dispatch(sellerActionTypeStore({ actionType: 'store' }));
};

export const sellerActionUpdate = () => (dispatch) => {
  dispatch(sellerActionTypeUpdate({ actionType: 'update' }));
};
