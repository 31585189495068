const appService = {
  async appStorageAuthentication(data) {
    await null;
    const dataStorage = JSON.stringify(data);
    return localStorage.setItem('app_credentials', dataStorage);
  },
  async appDestroyAuthentication() {
    return localStorage.removeItem('app_credentials');
  },
  async appShowAuthentication() {
    await null;
    try {
      const dataStorage = localStorage.getItem('app_credentials');
      return dataStorage !== null ? JSON.parse(dataStorage) : dataStorage;
    } catch (e) {
      // throw e.message;
    }
  },
  appSerialize(arrays) {
    let ArrayEmpty = [];
    arrays.map((array, key) => {
      let keyVal = key;
      let lengthArray = array.length;
      let arrayQoutes = '"' + array + '"';
      let ArrayForm = 'i:' + keyVal + ';' + 's:' + lengthArray + ':' + arrayQoutes;
      let list = ArrayEmpty.push(ArrayForm);
    });
    let StringArray = ArrayEmpty.toString();
    let NewString = 'a:' + arrays.length + ':' + '{' + StringArray + ';' + '}';
    let stringFinal = NewString.replace(/,/g, ';');
    let stringFinalBase64 = btoa(stringFinal);
    return stringFinalBase64;
  },
};

export default appService;
