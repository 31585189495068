import React, { Fragment } from 'react';
import { Pagination as SemanticPagination, Dropdown } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const DropdownItems = [
  { value: 0, text: 10 },
  { value: 1, text: 20 },
  { value: 2, text: 30 },
  { value: 3, text: 40 },
  { value: 4, text: 50 },
];

const Pagination = ({ title, current_page, last_page, per_page, fnIndex, total }) => {
  const defaultValue = DropdownItems.find((item) => item.text === per_page);
  const filterDrodownItems = DropdownItems.filter((item) => item.text <= total);
  return (
    <Fragment>
      <div className={'col-md-3'} style={{ marginTop: '1%' }}>
        {title}:{' '}
        <Dropdown
          inline
          options={filterDrodownItems}
          defaultValue={defaultValue.value}
          onChange={(event, data) => {
            const { value } = data;
            let items = DropdownItems.find((item) => item.value === value);
            //Value of option selected
            let { text } = items;
            if (text > total) {
              current_page = 1;
            }
            fnIndex(false, current_page, text);
          }}
        />
      </div>
      <div className={'col-md-8'}>
        <SemanticPagination
          activePage={current_page}
          totalPages={last_page}
          onPageChange={(event, data) => {
            const { activePage } = data;
            fnIndex(true, activePage, per_page);
          }}
        />
      </div>
    </Fragment>
  );
};

Pagination.propTypes = {
  title: PropTypes.string,
  current_page: PropTypes.number,
  last_page: PropTypes.number,
  per_page: PropTypes.number,
  fnIndex: PropTypes.func,
  total: PropTypes.number,
};

export default Pagination;
