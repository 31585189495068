export const MODULE_NAME = 'Meeting Points';
export const ROUTE = '/meeting-points';
export const RELATIONS = ['departures'];

const MeetingPoint = {
  id: null,
  name: null,
  description: null,
  status: null,
  created_id: null,
  created_at: null,
  updated_at: null,
};

export default MeetingPoint;
