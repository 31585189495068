import cruiseArrivalTypes from './types';

// DESTROY DISPATCH
export const cruiseArrivalDeleteFail = (payload) => {
  return {
    type: cruiseArrivalTypes.CRUISE_ARRIVAL_DESTROY_FAIL,
    errors: {
      ...payload,
    },
  };
};
export const cruiseArrivalDeleteFetch = () => ({
  type: cruiseArrivalTypes.CRUISE_ARRIVAL_DESTROY_FETCH,
});
export const cruiseArrivalDeleteSuccess = (payload) => ({
  type: cruiseArrivalTypes.CRUISE_ARRIVAL_DESTROY_SUCCESS,
  payload,
});

// INDEX DISPATCH
export const cruiseArrivalIndexFail = (payload) => ({
  type: cruiseArrivalTypes.CRUISE_ARRIVAL_INDEX_FAIL,
  payload,
});

export const cruiseArrivalIndexFetch = () => ({
  type: cruiseArrivalTypes.CRUISE_ARRIVAL_INDEX_FETCH,
});

export const cruiseArrivalIndexSuccess = (payload) => ({
  type: cruiseArrivalTypes.CRUISE_ARRIVAL_INDEX_SUCCESS,
  payload: {
    ...payload,
  },
});

// SHOW DISPATCH
export const cruiseArrivalShowFail = (payload) => ({
  type: cruiseArrivalTypes.CRUISE_ARRIVAL_SHOW_FAIL,
  errors: {
    ...payload,
  },
});
export const cruiseArrivalShowFetch = () => ({
  type: cruiseArrivalTypes.CRUISE_ARRIVAL_SHOW_FETCH,
});
export const cruiseArrivalShowSuccess = (payload) => ({
  type: cruiseArrivalTypes.CRUISE_ARRIVAL_SHOW_SUCCESS,
  payload: {
    ...payload,
  },
});

// STORE DISPATCH
export const cruiseArrivalStoreFail = (payload) => {
  return {
    type: cruiseArrivalTypes.CRUISE_ARRIVAL_STORE_FAIL,
    errors: {
      ...payload,
    },
  };
};

export const cruiseArrivalStoreFetch = () => ({
  type: cruiseArrivalTypes.CRUISE_ARRIVAL_STORE_FETCH,
});
export const cruiseArrivalStoreSuccess = (payload) => ({
  type: cruiseArrivalTypes.CRUISE_ARRIVAL_STORE_SUCCESS,
  payload,
});

// UPDATE DISPATCH
export const cruiseArrivalUpdateFail = (payload) => {
  return {
    type: cruiseArrivalTypes.CRUISE_ARRIVAL_UPDATE_FAIL,
    errors: {
      ...payload,
    },
  };
};
export const cruiseArrivalUpdateFetch = () => ({
  type: cruiseArrivalTypes.CRUISE_ARRIVAL_UPDATE_FETCH,
});
export const cruiseArrivalUpdateSuccess = (payload) => ({
  type: cruiseArrivalTypes.CRUISE_ARRIVAL_UPDATE_SUCCESS,
  payload: {
    ...payload,
  },
});

export const cruiseArrivalModalOpen = () => ({
  type: cruiseArrivalTypes.CRUISE_ARRIVAL_MODAL_OPEN,
});
export const cruiseArrivalModalClose = () => ({
  type: cruiseArrivalTypes.CRUISE_ARRIVAL_MODAL_CLOSE,
});
export const cruiseArrivalActionTypeStore = (payload) => ({
  type: cruiseArrivalTypes.CRUISE_ARRIVAL_ACTION_TYPE_STORE,
  payload: { ...payload },
});
export const cruiseArrivalActionTypeUpdate = (payload) => ({
  type: cruiseArrivalTypes.CRUISE_ARRIVAL_ACTION_TYPE_UPDATE,
  payload: { ...payload },
});
