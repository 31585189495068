import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Grid, Button } from 'semantic-ui-react';
import { saleDetailModel, saleDetailOption } from './model';
import { meetingPointDepartures } from '../departure/actions';
import { itemMeetingPoints } from '../meeting_point/actions';

const OtionDetail = ({
  code,
  option_details,
  onChangeValueChildren,
  keyItem,
  keyDetailIndex,
  pax,
  details,
  price,
  id,
  option_id,
  name,
  DataOption,
}) => {
  return (
    <Form.Group>
      <Form.Input
        name={DataOption.OptionCode === null ? 'SNC' : `${DataOption.OptionCode}${id}`}
        // name={`${DataOption.OptionCode}${id}`}
        value={DataOption.OptionCode === null ? details.pax : pax}
        keyIndex={keyDetailIndex}
        type={'number'}
        autoComplete={'off'}
        onChange={(e, data) => {
          onChangeValueChildren(keyItem, data);
        }}
        label={DataOption.OptionCode === null ? 'Quantity' : `${DataOption.OptionCode} ${name}`}
        // label={`${DataOption.OptionCode} ${name}`}
        placeholder="Agrega pax"
      />
    </Form.Group>
  );
};

let SaleFormDetail = (
  {
    relations,
    date_operation,
    item_id,
    transportation_id,
    options,
    meeting_point,
    errorDetails,
    departure_id,
    keyItem,
    onRemoveItem,
    onChangeDetails,
    getChildrenForItem,
    changeMeeting,
    changeDeparture,
    onChangeValueChildren,
    detalles,
    fnMeetingPointDepartures,
    items,
  },
  props
) => {
  let itemID = errorDetails[keyItem] === undefined ? null : errorDetails[keyItem].item_id;
  let OptionDetailFalse = options.code === undefined ? false : options.option_details;
  let OptionDetail = options.code === undefined ? options : options.option_details;
  let OptionName = options.name === undefined ? false : options.name;
  let OptionCode = options.code === undefined ? false : options.code;
  let optionCodeNull = options.code === undefined ? false : options.code;
  let DataOption = { OptionName, OptionCode };
  let dateOperation =
    errorDetails[keyItem] === undefined ? null : errorDetails[keyItem].date_operation;

  const [opt, addOpt] = useState([]);

  /*Options for transportations*/
  let transportationOptions = [];
  let optionOptions = [];
  if (item_id !== null && items.length > 0) {
    const { transportations, optionables } = items.find((item) => item.id === item_id);
    transportationOptions = transportations.map((transportation) => ({
      key: transportation.id,
      value: transportation.id,
      text: transportation.name,
    }));

    if (optionables.option_details !== undefined) {
      const { option_details } = optionables;
      optionOptions = option_details.map((detail) => ({key: detail.id, value: detail.id,text: detail.name}));
    }
  }
  return (
    <React.Fragment>
      <Grid stackable columns={2}>
        <Grid.Column>
          <Form.Select
            name={'item_id'}
            value={item_id}
            label="Item:"
            error={
              errorDetails.item_id === null
                ? false
                : { content: errorDetails.item_id, pointing: 'below' }
            }
            placeholder="Add Item"
            options={relations.items}
            onChange={(e, data) => {
              onChangeDetails(data, keyItem);
              getChildrenForItem(keyItem, data.value);
              changeMeeting(data.value);
            }}
          />
        </Grid.Column>
        <Grid.Column>
          <Form.Select
            name={'transportation_id'}
            value={transportation_id}
            label="Transportation:"
            placeholder="Select Transportation"
            error={
              errorDetails.transportation_id === null
                ? false
                : { content: errorDetails.transportation_id, pointing: 'below' }
            }
            options={transportationOptions}
            onChange={(e, data) => {
              onChangeDetails(data, keyItem);
            }}
          />
        </Grid.Column>
        <Grid.Column>
          <Form.Select
            name={'meeting_point'}
            value={meeting_point}
            label="Meeting Point:"
            placeholder="Select Departure"
            error={
              errorDetails.meeting_point === null
                ? false
                : { content: errorDetails.meeting_point, pointing: 'below' }
            }
            options={relations.meetingPoints}
            onChange={(e, data) => {
              onChangeDetails(data, keyItem);
              changeDeparture(data.value);
            }}
          />
        </Grid.Column>
        <Grid.Column>
          <Form.Select
            name={'departure_id'}
            value={departure_id}
            label="Departure:"
            error={
              errorDetails.departure_id === null
                ? false
                : { content: errorDetails.departure_id, pointing: 'below' }
            }
            placeholder="Select Departure"
            options={relations.departures}
            onChange={(e, data) => onChangeDetails(data, keyItem)}
          />
        </Grid.Column>
      </Grid>
      <Grid>
        <Grid.Column>
          <Form.Select
            name={'multi_options'}
            multiple={true}
            label={'Add an Option'}
            placeholder={'Select some options..'}
            options={optionOptions}
            onChange={(e, data) => {
              const { optionables } = items.find((item) => item.id === item_id);
              const {option_details} = optionables;
              const optionSelected = data.value.map((v) => option_details.find((detail) => detail.id === v));
              addOpt(optionSelected);
            }}
          />
        </Grid.Column>
      </Grid>
      <Grid stackable columns={2}>
        {opt.length > 0 && opt.map((option, key) => (
          <OtionDetail
            {...option}
            onChangeValueChildren={onChangeValueChildren}
            DataOption={DataOption}
            details={OptionDetail}
            keyItem={keyItem}
            keyDetailIndex={key}
          />
        ))}
      </Grid>
      <Grid centered columns={2} className={'top-10 mb-3'}>
        <Button size={'tiny'} color={'red'} type="button" onClick={() => onRemoveItem(keyItem)}>
          Delete
        </Button>
      </Grid>
    </React.Fragment>
  );
};
SaleFormDetail.propTypes = {
  fnItemMeetingPoints: PropTypes.func,
  fnMeetingPointDepartures: PropTypes.func,
};
const mapStateProps = (state) => ({
  items: state.items.payload.items,
});
const mapDispatchToProps = (dispatch) => ({
  fnItemMeetingPoints: (item_id) => {
    return dispatch(itemMeetingPoints(item_id));
  },
  fnMeetingPointDepartures: (meeting_point_id) => {
    return dispatch(meetingPointDepartures(meeting_point_id));
  },
});
export default connect(
  mapStateProps,
  mapDispatchToProps
)(SaleFormDetail);
