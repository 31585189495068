export const MODULE_NAME = 'rate_lists';
export const ROUTE = '/rate_lists';

const RateList = {
  id: null,
  name: null,
  currency_id: null,
  created_id: null,
  created_at: null,
  updated_at: null,
};

export default RateList;
