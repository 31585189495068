import userTypes from './types';
// DESTROY DISPATCH
export const userDeleteFail = (payload) => {
  return {
    type: userTypes.USER_DESTROY_FAIL,
    errors: {
      ...payload,
    },
  };
};
export const userDeleteFetch = () => ({
  type: userTypes.USER_DESTROY_FETCH,
});
export const userDeleteSuccess = (payload) => ({
  type: userTypes.USER_DESTROY_SUCCESS,
  payload,
});

// INDEX DISPATCH
export const userIndexFail = (payload) => ({
  type: userTypes.USER_INDEX_FAIL,
  payload,
});

export const userIndexFetch = () => ({
  type: userTypes.USER_INDEX_FETCH,
});

export const userIndexSuccess = (payload) => ({
  type: userTypes.USER_INDEX_SUCCESS,
  payload: {
    ...payload,
  },
});

// SHOW DISPATCH
export const userShowFail = (payload) => ({
  type: userTypes.USER_SHOW_FAIL,
  errors: {
    ...payload,
  },
});
export const userShowFetch = () => ({
  type: userTypes.USER_SHOW_FETCH,
});
export const userShowSuccess = (payload) => ({
  type: userTypes.USER_SHOW_SUCCESS,
  payload: {
    ...payload,
  },
});

// STORE DISPATCH
export const userStoreFail = (payload) => {
  return {
    type: userTypes.USER_STORE_FAIL,
    errors: {
      ...payload,
    },
  };
};

export const userStoreFetch = () => ({
  type: userTypes.USER_STORE_FETCH,
});
export const userStoreSuccess = (payload) => ({
  type: userTypes.USER_STORE_SUCCESS,
  payload,
});

// UPDATE DISPATCH
export const userUpdateFail = (payload) => {
  return {
    type: userTypes.USER_UPDATE_FAIL,
    errors: {
      ...payload,
    },
  };
};
export const userUpdateFetch = () => ({
  type: userTypes.USER_UPDATE_FETCH,
});
export const userUpdateSuccess = (payload) => ({
  type: userTypes.USER_UPDATE_SUCCESS,
  payload: {
    ...payload,
  },
});
