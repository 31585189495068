import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Dimmer, Loader, Table, Button } from 'semantic-ui-react';
import { Menu, Item, contextMenu, animation, theme } from 'react-contexify';
import {
  meetingPointDelete,
  meetingPointShow,
  meetingPointStore,
  meetingPointUpdate,
  meetingPointActionUpdate,
  meetingPointActionStore,
  meetingPointCloseModal,
  meetingPointOpenModal,
  meetingPointPaginate,
} from './actions';
import { MEETING_POINT_MODULE_NAME } from '../constants';
import { TitleHead, ContextMenu, Pagination, Modal as ModalMeetingPoint } from '../app/common';
import MeetingPointForm from './MeetingPointForm';
import PropTypes from 'prop-types';

class MeetingPoint extends Component {
  state = {
    isModalOpen: false,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.fnMeetingPointPaginate();
  }

  toRender = {
    id: {
      type: 'number',
      visible: false,
      name: '',
    },
    name: {
      type: 'string',
      visible: true,
      name: 'nombre',
    },
    description: {
      type: 'string',
      visible: true,
      name: 'Descripción',
    },
    status: {
      type: 'string',
      visible: true,
      name: 'Status',
    },
  };

  renderTable = (data) => {
    const allowed = [];
    Object.keys(this.toRender).map((k) => {
      allowed.push(k);
    });

    return data.map((ag) =>
      Object.keys(ag)
        .filter((key) => allowed.includes(key))
        .reduce((obj, key) => {
          // console.log()
          obj[key] = { ...{ value: ag[key] }, ...this.toRender[key] };
          return obj;
        }, {})
    );
  };

  openContextMenu = (e, data) => {
    const {
      fnMeetingPointDelete,
      fnMeetingPointModalOpen,
      fnMeetingPointActionStore,
      fnMeetingPointActionUpdate,
      fnMeetingPointShow,
    } = this.props;
    e.preventDefault();
    contextMenu.show({
      id: 'menu_id',
      event: e,
      props: {
        data: { ...data },
        fnDelete: fnMeetingPointDelete,
        fnModalOpen: fnMeetingPointModalOpen,
        fnActionStore: fnMeetingPointActionStore,
        fnActionUpdate: fnMeetingPointActionUpdate,
        fnShow: fnMeetingPointShow,
      },
    });
  };

  render() {
    const {
      payload,
      isFetching,
      fnMeetingPointPaginate,
      isModalOpen,
      fnMeetingPointModalClose,
    } = this.props;
    const { meetingPoints, pagination } = payload;
    return (
      <Fragment>
        <TitleHead title={MEETING_POINT_MODULE_NAME} />
        <div className={'row'}>
          <div className={'col-md-12'}>
            <div className={'main-card mb-3 card'}>
              <div className={'card-body'}>
                <h5 className={'card-title'}>
                  {MEETING_POINT_MODULE_NAME.substr(0, MEETING_POINT_MODULE_NAME.length - 1)} Lists
                </h5>
                <div className={'row'}>
                  <Pagination
                    title={`Show ${MEETING_POINT_MODULE_NAME.toLowerCase()} by`}
                    {...pagination}
                    fnIndex={fnMeetingPointPaginate}
                  />
                  <div className="col-md-12">
                    <ModalMeetingPoint
                      isOpen={isModalOpen}
                      actionType={payload.actionType}
                      modalTitle={MEETING_POINT_MODULE_NAME}
                      size={'mini'}
                      isFetchingButton={isFetching && isModalOpen}
                      onClose={fnMeetingPointModalClose}
                    >
                      <MeetingPointForm />
                    </ModalMeetingPoint>
                    <Dimmer.Dimmable blurring={true} dimmed={isFetching}>
                      <Dimmer active={isFetching} inverted={true}>
                        <Loader>Loading</Loader>
                      </Dimmer>
                      <Table compact={true} celled={true}>
                        <Table.Header>
                          <Table.Row>
                            {Object.keys(this.toRender).map((key, index) => {
                              return index === 0 ? (
                                <Fragment key={1}>
                                  <Table.HeaderCell>#</Table.HeaderCell>
                                  {this.toRender[key].visible && (
                                    <Table.HeaderCell>{this.toRender[key].name}</Table.HeaderCell>
                                  )}
                                </Fragment>
                              ) : (
                                this.toRender[key].visible && (
                                  <Table.HeaderCell>{this.toRender[key].name}</Table.HeaderCell>
                                )
                              );
                            })}
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {this.renderTable(meetingPoints).map((data, index) => {
                            const { per_page, to, total } = pagination;
                            const row = (total > per_page ? to - per_page : to - total) + index + 1;
                            const rows = [];
                            //Pushing Rows number
                            if (data.id) {
                              rows.push(<Table.Cell>{row}</Table.Cell>);
                            }
                            // Push Other cells
                            Object.keys(data).filter((key) => {
                              if (data[key].visible) {
                                rows.push(<Table.Cell>{data[key].value}</Table.Cell>);
                              }
                            });
                            return (
                              <Table.Row
                                onContextMenu={(e) => this.openContextMenu(e, meetingPoints[index])}
                              >
                                {rows}
                              </Table.Row>
                            );
                          })}
                        </Table.Body>
                      </Table>
                      <ContextMenu />
                    </Dimmer.Dimmable>
                  </div>
                </div>
              </div>
            </div>
            <Button
              onClick={() =>
                this.props.fnMeetingPointstore({
                  transportation_id: 2,
                  name: 'Meeting Point JSX',
                  category_id: 2,
                  description: 'este es mi primer MEeeting Point desde jsx',
                })
              }
            >
              store
            </Button>
            <Button onClick={() => this.props.fnMeetingPointShow()}>show</Button>
            <Button onClick={() => this.props.fnMeetingPointDelete()}>Delete</Button>
            <Button
              onClick={() =>
                this.props.fnMeetingPointUpdate(11, {
                  transportation_id: 2,
                  name: 'Meeting Point JSX',
                  category_id: 2,
                  description: ' hasta actualizo we este es mi primer MEeeting Point desde jsx',
                })
              }
            >
              Update
            </Button>
          </div>
          {/*{user.id !== null ? alert(`Usuario Cargado ${user.name}`) : ''}*/}
        </div>
      </Fragment>
    );
  }
}

const mapStateProps = (state, ownProps = {}) => ({
  isFetching: state.meetingPoints.isFetching,
  payload: state.meetingPoints.payload,
  errors: state.meetingPoints.errors,
  isModalOpen: state.meetingPoints.isModalOpen,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  // fnMeetingPointIndex: (forRelation, page, items) => {
  //   dispatch(meetingPointIndex(forRelation, page, items));
  // },
  fnMeetingPointPaginate: (forRelation, page, items) => {
    dispatch(meetingPointPaginate(forRelation, page, items));
  },
  fnMeetingPointDelete: (id) => {
    dispatch(meetingPointDelete(id));
  },
  fnMeetingPointShow: (id) => {
    dispatch(meetingPointShow(id));
  },
  fnMeetingPointStore: (data) => {
    dispatch(meetingPointStore(data));
  },
  fnMeetingPointUpdate: (id, data) => {
    dispatch(meetingPointUpdate(id, data));
  },
  fnMeetingPointModalOpen: () => {
    dispatch(meetingPointOpenModal());
  },
  fnMeetingPointModalClose: () => {
    dispatch(meetingPointCloseModal());
  },
  fnMeetingPointActionStore: () => {
    dispatch(meetingPointActionStore());
  },
  fnMeetingPointActionUpdate: () => {
    dispatch(meetingPointActionUpdate());
  },
});

MeetingPoint.propTypes = {
  fnMeetingPointDelete: PropTypes.func,
  fnMeetingPointShow: PropTypes.func,
  fnMeetingPointOpen: PropTypes.func,
  fnMeetingPointClose: PropTypes.func,
  fnMeetingPointActionStore: PropTypes.func,
  fnMeetingPointActionUpdate: PropTypes.func,
  isFetching: PropTypes.bool,
  isModalOpen: PropTypes.bool,
  payload: PropTypes.exact({
    meetingPoint: PropTypes,
    meetingPoints: PropTypes,
    pagination: PropTypes,
    message: PropTypes.string,
    actionType: PropTypes.oneOf(['store', 'update']),
  }),
  errors: PropTypes.object,
};

export default connect(
  mapStateProps,
  mapDispatchToProps
)(MeetingPoint);
