import types from './types';

export const INITIAL_STATE = {
  isFetching: false,
  payload: {
    isAuthenticated: null,
    scrollPosition: 0,
  },
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.APP_FETCH_LOCAL_STORAGE:
      return {
        ...state,
        isFetching: true,
      };
    case types.APP_STORE_AUTHENTICATION:
      return {
        ...state,
        isFetching: false,
        payload: { ...state.payload, ...action.payload },
      };
    case types.APP_DESTROY_AUTHENTICATION:
      return {
        ...state,
        isFetching: false,
        payload: { ...state.payload, ...action.payload },
      };
    case types.APP_SET_SCROLL:
      return {
        ...state,
        payload: { ...state.payload, ...action.payload },
      };
    default:
      return state;
  }
};

export default reducer;
