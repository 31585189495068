import React, { Component } from 'react';
import { Form, Grid } from 'semantic-ui-react';
import modelVendor from './model';
import _ from 'lodash';
import { connect } from 'react-redux';
import { vendorStore, vendorUpdate } from './actions';
import moment from 'moment';
import { vendorCategoryIndex } from '../vendor_category/actions';
import PropTypes from 'prop-types';

class VendorForm extends Component {
  constructor(props) {
    super(props);
    const { actionType, vendor } = props;
    this.state = {
      model: actionType === 'store' ? modelVendor : vendor,
      relations: {
        vendor_categories: [],
      },
      errorForm: {},
    };
    const { fnVendorCategories } = props;
    fnVendorCategories();
  }

  componentWillReceiveProps(nextProps) {
    const { vendorsCategories, errors } = nextProps;
    this.setState({ errorForm: { ...errors.errors } });
    this.setState(() => {
      //vendors
      const enableVendorCategorie = vendorsCategories.filter((vendor) => vendor.status === 1);
      let selectVendorCategories = {};
      selectVendorCategories.vendorsCategories = enableVendorCategorie.map((vendor) =>
        Object.keys(vendor)
          .filter((key) => {
            return key === 'id' || key === 'name';
          })
          .reduce((obj) => {
            obj = { value: vendor.id, text: vendor.name, key: vendor.id };
            return obj;
          }, {})
      );
      return {
        relations: {
          ...selectVendorCategories,
        },
      };
    });
  }
  changeValue = (input) => {
    const { name, value } = input;
    let newValue = {};
    newValue[name] = value;
    this.setState(
      ({ model }) => ({ model: { ...model, ...newValue } }),
      () => {
        this.setState(({ errorForm }) => {
          let newErrors = Object.keys(errorForm)
            .filter((key) => key !== name)
            .reduce((obj, key) => {
              obj[key] = errorForm[key];
              return obj;
            }, {});
          return { errorForm: newErrors };
        });
      }
    );
  };
  render() {
    const { name, initials, vendor_category_id, interface_code } = this.state.errorForm;
    let { actionType } = this.props;
    const { model, relations } = this.state;
    const { rates } = model;
    let dateToday = moment()._d;
    let dateTodayFormat = moment(dateToday).format('YYYY-MM-DD');
    let listaDefault = _.find(rates, { default: 1 });
    let defaultValueid = listaDefault === undefined ? false : listaDefault.id;

    let allRatesPrueba = _.map(rates, function(rate) {
      let dateStart = rate.date_start;
      let dateEnd = rate.date_end;
      let { rate_list } = rate;
      if (dateEnd === null) {
        let datelavel = ` ${rate_list.name} (${dateStart} - )`;
        return {
          value: rate.id,
          text: datelavel,
          key: rate.id,
        };
      } else {
        if (moment(dateTodayFormat).isBetween(dateStart, dateEnd)) {
          let datelavel = ` ${rate_list.name} (${dateStart} - ${dateEnd})`;
          return {
            value: rate.id,
            text: datelavel,
            key: rate.id,
          };
        } else {
          let datelavel = ` ${rate_list.name} (${dateStart} - ${dateEnd})`;
          return {
            value: rate.id,
            text: datelavel,
            key: rate.id,
            disabled: true,
          };
        }
      }
    });
    return (
      <Form
        id={'form'}
        onSubmit={(e) => {
          const { model } = this.state;
          const { actionType, fnVendorStore, fnVendorUpdate } = this.props;
          switch (actionType) {
            case 'store':
              fnVendorStore(model);
              break;
            case 'update':
              fnVendorUpdate(model.id, model);
              break;
            default:
              break;
          }
        }}
      >
        <Grid stackable columns={2}>
          <Grid.Column>
            <Form.Input
              value={model.name}
              name={'name'}
              autoComplete={'off'}
              onChange={(e, data) => this.changeValue(data)}
              error={name === undefined ? false : { content: name, pointing: 'below' }}
              label="Name:"
              placeholder="Add Name"
            />
          </Grid.Column>
          <Grid.Column>
            <Form.Input
              value={model.initials}
              name={'initials'}
              autoComplete={'off'}
              onChange={(e, data) => this.changeValue(data)}
              error={initials === undefined ? false : { content: initials, pointing: 'below' }}
              label="Initials:"
              placeholder="Add Initials"
            />
          </Grid.Column>
          <Grid.Column>
            <Form.Input
              value={model.interface_code}
              name={'interface_code'}
              error={
                interface_code === undefined
                  ? false
                  : { content: interface_code, pointing: 'below' }
              }
              autoComplete={'off'}
              onChange={(e, data) => this.changeValue(data)}
              label="Interface code:"
              placeholder="Add Interface_code"
            />
          </Grid.Column>
          <Grid.Column>
            <Form.Select
              value={model.vendor_category_id}
              name={'vendor_category_id'}
              label="Vendors:"
              error={
                vendor_category_id === undefined
                  ? false
                  : { content: vendor_category_id, pointing: 'below' }
              }
              placeholder="Add Vendor Category"
              options={relations.vendorsCategories}
              onChange={(e, data) => this.changeValue(data)}
            />
          </Grid.Column>

          {actionType === 'update' ? (
            <Grid.Column>
              <Form.Select
                // value={rate_id}
                name={'rate_id'}
                defaultValue={defaultValueid}
                label="Select List Price:"
                placeholder="Lista de precio por defecto:"
                options={allRatesPrueba}
                onChange={(e, data) => this.changeValue(data)}
              />
            </Grid.Column>
          ) : (
            ''
          )}

          {/*<Grid.Column></Grid.Column>*/}
        </Grid>
      </Form>
    );
  }
}

const mapStateToProps = (state) => ({
  isFetching: state.vendors.isFetching,
  errors: state.vendors.errors,
  isFetchingVendorsCategories: state.vendorCategories.isFetching,
  vendorsCategories: state.vendorCategories.payload.vendorCategories,
  actionType: state.vendors.payload.actionType,
  vendor: state.vendors.payload.vendor,
});

const mapDispatchToProps = (dispatch) => ({
  fnVendorStore: (data) => {
    dispatch(vendorStore(data));
  },

  fnVendorCategories: () => {
    dispatch(vendorCategoryIndex(true));
  },
  fnVendorUpdate: (id, data) => {
    dispatch(vendorUpdate(id, data));
  },
});

VendorForm.propTypes = {
  isFetching: PropTypes.bool,
  errors: PropTypes.PropTypes.exact({
    errors: PropTypes.object,
    message: PropTypes.string,
  }),
  actionType: PropTypes.oneOf(['store', 'update']),
  fnVendorStore: PropTypes.func,
  fnVendorUpdate: PropTypes.func,
  fnGetRateList: PropTypes.func,
  fnVendorCategories: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VendorForm);
