export const MODULE_NAME = 'Sales';
export const ROUTE = '/sales';
export const RELATIONS = ['reference', 'details'];
export const saleModel = {
  id: null,
  folio: '',
  amount: null,
  seller_id: null,
  rate_id: null,
  rate_list_id: null,
  movement_type_id: null,
  process_status: null,
  vendor_id: null,
  status: null,
  date_operation: null,
  created_id: null,
  updated_id: null,
  created_at: null,
  updated_at: null,
  sale_details: [],
  sale_references: {},
};

export const saleDetailModel = {
  item_id: null,
  transportation_id: null,
  departure_id: null,
  meeting_point: null,
  options: {},
};

export const saleDetailOption = {
  code: '',
  option_details: {
    name: '',
    id: null,
    pax: 0,
  },
};

export const saleReferenceModel = {
  client_name: '',
  client_lastname: '',
  email: '',
  ubication: '',
  reference_folio: '',
  shipping_hotel_company_id: '',
  balance: 0,
  phone: 0,
};
