import React, { Component } from 'react';
import { Button, Modal as SemanticModal } from 'semantic-ui-react';
import PropTypes from 'prop-types';

class Modal extends Component {
  state = { open: false, isFetching: false };
  constructor(props) {
    super(props);
  }

  render() {
    const {
      children,
      modalTitle,
      isOpen,
      actionType,
      onClose,
      isFetchingButton,
      size,
    } = this.props;
    return (
      <React.Fragment>
        <SemanticModal size={size} dimmer={'blurring'} open={isOpen}>
          <SemanticModal.Header>{modalTitle}</SemanticModal.Header>
          <SemanticModal.Content>{children}</SemanticModal.Content>
          <SemanticModal.Actions>
            <Button
              loading={isFetchingButton}
              positive
              icon="checkmark"
              labelPosition="right"
              content={actionType === 'store' ? 'Save' : 'Update'}
              form={'form'}
            />
            <Button color="black" onClick={onClose}>
              Close
            </Button>
          </SemanticModal.Actions>
        </SemanticModal>
      </React.Fragment>
    );
  }
}

Modal.propTypes = {
  size: PropTypes.oneOf(['mini', 'tiny', 'small', 'large', 'fullscreen']),
  isOpen: PropTypes.bool.isRequired,
  classModal: PropTypes.string,
  actionType: PropTypes.oneOf(['store', 'update']).isRequired,
  onClose: PropTypes.func.isRequired,
  isFetchingButton: PropTypes.bool.isRequired,
};

export default Modal;
