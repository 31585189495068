import api from '../api';

const optionService = {
  optionIndex: (relation = null, params = null) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/options${relation ? `/${relation}` : ''}${params ? `?${params}` : ''}`)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },
};

export default optionService;
