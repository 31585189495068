import PropTypes from 'prop-types';
import api from '../api';

const pointSaleService = {
  pointSaleDelete(id) {
    return new Promise((resolve, reject) => {
      api
        .delete(`/point_sales/${id}`)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },

  pointSaleIndex(page = null, items = null) {
    return new Promise((resolve, reject) => {
      api
        .get(`/point_sales${page ? `/page/${page}` : ''}${items ? `/items/${items}` : ''}`)
        .then((response) => response.data)
        .then(resolve)
        .catch(reject);
    });
  },

  pointSaleShow(id) {
    return new Promise((resolve, reject) => {
      api
        .get(`/point_sales/${id}`)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },

  pointSaleStore(data) {
    return new Promise((resolve, reject) => {
      api
        .post('/point_sales', data)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },

  pointSaleUpdate(id, data) {
    return new Promise((resolve, reject) => {
      api
        .put(`/point_sales/${id}`, data)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },
};

pointSaleService.propTypes = {
  pointSaleIndex: PropTypes.func.isRequired,
  pointSaleShow: PropTypes.func.isRequired,
};

export default pointSaleService;
