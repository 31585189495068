const types = {
  // FOR CRUD RATES
  SLOTE_DESTROY_FAIL: 'SLOTE_DESTROY_FAIL',
  SLOTE_DESTROY_FETCH: 'SLOTE_DESTROY_FETCH',
  SLOTE_DESTROY_SUCCESS: 'SLOTE_DESTROY_SUCCESS',
  SLOTE_INDEX_FAIL: 'SLOTE_INDEX_FAIL',
  SLOTE_INDEX_FETCH: 'SLOTE_INDEX_FETCH',
  SLOTE_INDEX_SUCCESS: 'SLOTE_INDEX_SUCCESS',
  SLOTE_SHOW_FAIL: 'SLOTE_SHOW_FAIL',
  SLOTE_SHOW_FETCH: 'SLOTE_SHOW_FETCH',
  SLOTE_SHOW_SUCCESS: 'SLOTE_SHOW_SUCCESS',
  SLOTE_STORE_FAIL: 'SLOTE_STORE_FAIL',
  SLOTE_STORE_FETCH: 'SLOTE_STORE_FETCH',
  SLOTE_STORE_SUCCESS: 'SLOTE_STORE_SUCCESS',
  SLOTE_UPDATE_FAIL: 'SLOTE_UPDATE_FAIL',
  SLOTE_UPDATE_FETCH: 'SLOTE_UPDATE_FETCH',
  SLOTE_UPDATE_SUCCESS: 'SLOTE_UPDATE_SUCCESS',
  // FOR MODAL
  SLOTE_MODAL_OPEN: ' SLOTE_MODAL_OPEN',
  SLOTE_MODAL_CLOSE: 'SLOTE_MODAL_CLOSE',
  SLOTE_ACTION_TYPE_STORE: 'SLOTE_ACTION_CREATE_TYPE',
  SLOTE_ACTION_TYPE_UPDATE: 'SLOTE_ACTION_UPDATE_TYPE',
};

export const typeStates = {
  FAIL: '_FAIL',
  FETCH: '_FETCH',
  SUCCESS: '_SUCCESS',
};

export default types;
