import React, { Component } from 'react';
import { Form, Grid, Tab } from 'semantic-ui-react';
import { rateModel, rateDetailModel } from './model';
import { connect } from 'react-redux';
import { rateStore, rateUpdate } from './actions';
import PropTypes from 'prop-types';
import { RateTabInformation, RateTabDetails } from './RateTabs';
import { vendorIndex } from '../vendor/actions';
import { currencyIndex } from '../currency/actions';
import { itemIndex } from '../item/actions';
import update from 'immutability-helper';

class RateForm extends Component {
  constructor(props) {
    super(props);
    const { actionType, rate, fnVendorIndex, fnCurrencyIndex } = props;
    fnVendorIndex();
    fnCurrencyIndex();
    this.state = {
      model: { ...rateModel },
      // model: actionType === 'store' ? rateModel : rate,
      relations: {
        vendors: [],
        currencies: [],
        items: [],
      },
      errorForm: {},
    };
  }
  componentWillReceiveProps(nextProps) {
    const { vendors, currencies, errors, items, actionType, rate, fnItemIndex } = nextProps;
    const { vendor_id } = rate;
    // console.log(this.props.rate.vendor_id);
    if (actionType === 'update') {
      console.log(this.props.rate.vendor_id);
      this.setState({ model: rate });
      // console.log(thismodel.vendor_id.length);
      // const { vendor_id } = rate;
      // fnItemIndex(true, { vendor_id: vendor_id });
    }
    this.setState({ errorForm: { ...errors.errors } });
    this.setState(() => {
      //vendors
      const enableVendors = vendors.filter((vendor) => vendor.status === 1);
      let selectVendors = {};
      selectVendors.vendors = enableVendors.map((vendor) =>
        Object.keys(vendor)
          .filter((key) => {
            return key === 'id' || key === 'name';
          })
          .reduce((obj) => {
            obj = { value: vendor.id, text: vendor.name, key: vendor.id };
            return obj;
          }, {})
      );
      //items

      const enableItems = items === undefined ? false : items.filter((item) => item.status === 1);
      // const enableItems = items.filter((item) => item.status === 1);
      let selectItems = {};
      let ItemSelect =
        items === undefined
          ? false
          : (selectItems.items = enableItems.map((item) =>
              Object.keys(item)
                .filter((key) => {
                  return key === 'id' || key === 'name';
                })
                .reduce((obj) => {
                  obj = { value: item.id, text: item.name, key: item.id };
                  return obj;
                }, {})
            ));
      //currency
      const enableCurrencies = currencies.filter((currency) => currency);
      let selectCurrencies = {};
      selectCurrencies.currencies = enableCurrencies.map((currency) =>
        Object.keys(currency)
          .filter((key) => {
            return key === 'id' || key === 'name';
          })
          .reduce((obj) => {
            obj = { value: currency.id, text: currency.name, key: currency.id };
            return obj;
          }, {})
      );
      return {
        relations: {
          ...selectVendors,
          ...selectCurrencies,
          ...selectItems,
        },
      };
    });
  }

  changeVendorItemValue = (input) => {
    const { name, value } = input;
  };

  changeValue = (input) => {
    const { name, value } = input;
    let newValue = {};
    newValue[name] = value;
    if (name === 'currency_id' || name === 'name') {
      this.setState(
        ({ model }) => ({
          model: { ...model, rate_list: { ...model.rate_list, ...newValue } },
        }),
        () => {
          this.setState(({ errorForm }) => {
            let newErrors = Object.keys(errorForm)
              .filter((key) => key !== name)
              .reduce((obj, key) => {
                obj[key] = errorForm[key];
                return obj;
              }, {});
            return { errorForm: newErrors };
          });
        }
      );
    } else {
      if (name == 'vendor_id') {
        const { fnItemIndex } = this.props;
        fnItemIndex(true, { vendor_id: value });
      }
      this.setState(
        ({ model }) => ({ model: { ...model, ...newValue } }),
        () => {
          this.setState(({ errorForm }) => {
            let newErrors = Object.keys(errorForm)
              .filter((key) => key !== name)
              .reduce((obj, key) => {
                obj[key] = errorForm[key];
                return obj;
              }, {});
            return { errorForm: newErrors };
          });
        }
      );
    }
  };

  changeOptionDetailsPrice = (input) => {
    const { value, index, indexOptionable, indexItemKey, name } = input;
    let newValue = {};
    newValue[name] = value;
    let valfinal = value > 100 ? 100 : value < 0 ? 0 : value;
    const { model } = this.state;
    const { rate_details } = model;
    let optionablesModelState = rate_details[indexItemKey].optionables;
    let newStateCommision = update(rate_details, {
      [indexItemKey]: {
        commission: { $set: valfinal },
      },
    });
    this.setState({
      model: { ...model, rate_details: newStateCommision },
    });
  };
  roundFunc(value, decimals) {
    return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
  }
  onAddItem = (input) => {
    const { rate_details } = this.state.model;
    const { value } = input;
    let addDetail = [];

    switch (value.length > rate_details.length) {
      case true:
        const { items } = this.props;
        //Aqui estoy agregando
        if (value.length === 1) {
          let tours = items.find((item) => item.id === value[0]);
          //Si state es vacio agrego de una vez
          // tours.optionables = tours.optionables.map((tour) => {
          //   // const details = tour.option_details.map((detail) => ({ ...detail, price: 0 }));
          //   return { ...tour };
          //   // return { ...tour, option_details: details };
          // });
          addDetail = rate_details.concat({
            ...rateDetailModel,
            item_id: tours.id,
            name: tours.name,
            optionables: tours.optionables,
          });
        } else {
          //Si el state tiene mayor que uno, busco la diferencia.
          const findingLost = value.filter(
            (value) =>
              !rate_details.some((detail) => {
                // console.log(`!${detail.item_id} === ${value} = ${detail.item_id === value}`);
                return detail.item_id === value;
              })
          );
          addDetail = findingLost.map((value) => {
            let tours = items.find((item) => item.id === value);
            // tours.optionables = tours.optionables.map((tour) => {
            //   // const details = tour.option_details.map((detail) => ({ ...detail, price: 0 }));
            //   // console.log(tour);
            //   return { ...tour };
            //   // return { ...tour, option_details: details };
            // });
            return {
              ...rateDetailModel,
              item_id: tours.id,
              name: tours.name,
              optionables: tours.optionables,
            };
          });
          addDetail = rate_details.concat(addDetail);
        }
        break;
      case false:
        //Aqui lo elmine
        const findingLost = rate_details.filter(
          (detail) =>
            !value.some((value) => {
              // console.log(`!${detail.item_id} === ${value} = ${detail.item_id === value}`);
              return detail.item_id === value;
            })
        );
        addDetail = rate_details.filter(
          (detail) => !findingLost.some((lost) => detail.item_id === lost.item_id)
        );
        break;
    }
    this.setState(({ model }) => ({ model: { ...model, rate_details: addDetail } }));
  };
  panes = () => {
    const { rate_details } = this.state.model;
    const currentPanes = [
      {
        menuItem: 'Information',
        render: () => (
          <RateTabInformation
            {...this.state}
            onAddItem={this.onAddItem}
            changeValue={this.changeValue}
          />
        ),
      },
    ];
    rate_details.map((detail, key) => {
      currentPanes.push({
        menuItem: detail.name,
        render: () => (
          <RateTabDetails
            key={detail.id}
            detail={detail}
            onAddItem={this.onAddItem}
            itemKey={key}
            decimalTransform={this.roundFunc}
            changeValueDetails={this.onChangeDetails}
            changeValueDetailsPrice={this.changeOptionDetailsPrice}
          />
        ),
      });
      return true;
    });
    return currentPanes;
  };
  render() {
    const { name } = this.state.errorForm;
    const { model } = this.state;
    return (
      <Form
        id={'form'}
        onSubmit={(e) => {
          const { model } = this.state;
          const { actionType, fnRateStore, fnRateUpdate } = this.props;
          switch (actionType) {
            case 'store':
              fnRateStore(model);
              break;
            case 'update':
              fnRateUpdate(model.id, model);
              break;
            default:
              break;
          }
        }}
      >
        <Tab menu={{ fluid: true, vertical: true, tabular: true }} panes={this.panes()} />
      </Form>
    );
  }
}

const mapStateToProps = (state) => ({
  isFetching: state.rates.isFetching,
  errors: state.rates.errors,
  isFetchingRates: state.rates.isFetching,
  rate: state.rates.payload.rate,
  actionType: state.rates.payload.actionType,
  vendors: state.vendors.payload.vendors,
  isFetchingCurrencies: state.rates.isFetching,
  currencies: state.currencies.payload.currencies,
  isFetchingItems: state.items.isFetching,
  items: state.items.payload.items,
});

const mapDispatchToProps = (dispatch) => ({
  fnRateStore: (data) => {
    dispatch(rateStore(data));
  },
  fnVendorIndex: () => {
    dispatch(vendorIndex(true));
  },
  fnCurrencyIndex: () => {
    dispatch(currencyIndex(true));
  },
  fnItemIndex: (forRelation, params) => {
    dispatch(itemIndex(forRelation, params));
  },
  fnRateUpdate: (id, data) => {
    dispatch(rateUpdate(id, data));
  },
});

RateForm.propTypes = {
  isFetching: PropTypes.bool,
  errors: PropTypes.PropTypes.exact({
    errors: PropTypes.object,
    message: PropTypes.string,
  }),
  actionType: PropTypes.oneOf(['store', 'update']),
  fnRateStore: PropTypes.func,
  fnRateUpdate: PropTypes.func,
  fnVendorIndex: PropTypes.func,
  fnItemIndex: PropTypes.func,
  fnCurrencyIndex: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RateForm);
