import transportationCategoryTypes from './types';
// DESTROY DISPATCH
export const transportationCategoryDeleteFail = (payload) => {
  return {
    type: transportationCategoryTypes.TRANSPORTATION_CATEGORY_DESTROY_FAIL,
    errors: {
      ...payload,
    },
  };
};
export const transportationCategoryDeleteFetch = () => ({
  type: transportationCategoryTypes.TRANSPORTATION_CATEGORY_DESTROY_FETCH,
});
export const transportationCategoryDeleteSuccess = (payload) => ({
  type: transportationCategoryTypes.TRANSPORTATION_CATEGORY_DESTROY_SUCCESS,
  payload,
});

// INDEX DISPATCH
export const transportationCategoryIndexFail = (payload) => ({
  type: transportationCategoryTypes.TRANSPORTATION_CATEGORY_INDEX_FAIL,
  payload,
});

export const transportationCategoryIndexFetch = () => ({
  type: transportationCategoryTypes.TRANSPORTATION_CATEGORY_INDEX_FETCH,
});

export const transportationCategoryIndexSuccess = (payload) => ({
  type: transportationCategoryTypes.TRANSPORTATION_CATEGORY_INDEX_SUCCESS,
  payload: {
    ...payload,
  },
});

// SHOW DISPATCH
export const transportationCategoryShowFail = (payload) => ({
  type: transportationCategoryTypes.TRANSPORTATION_CATEGORY_SHOW_FAIL,
  errors: {
    ...payload,
  },
});
export const transportationCategoryShowFetch = () => ({
  type: transportationCategoryTypes.TRANSPORTATION_CATEGORY_SHOW_FETCH,
});
export const transportationCategoryShowSuccess = (payload) => ({
  type: transportationCategoryTypes.TRANSPORTATION_CATEGORY_SHOW_SUCCESS,
  payload: {
    ...payload,
  },
});

// STORE DISPATCH
export const transportationCategoryStoreFail = (payload) => {
  return {
    type: transportationCategoryTypes.TRANSPORTATION_CATEGORY_STORE_FAIL,
    errors: {
      ...payload,
    },
  };
};

export const transportationCategoryStoreFetch = () => ({
  type: transportationCategoryTypes.TRANSPORTATION_CATEGORY_STORE_FETCH,
});
export const transportationCategoryStoreSuccess = (payload) => ({
  type: transportationCategoryTypes.TRANSPORTATION_CATEGORY_STORE_SUCCESS,
  payload,
});

// UPDATE DISPATCH
export const transportationCategoryUpdateFail = (payload) => {
  return {
    type: transportationCategoryTypes.TRANSPORTATION_CATEGORY_UPDATE_FAIL,
    errors: {
      ...payload,
    },
  };
};
export const transportationCategoryUpdateFetch = () => ({
  type: transportationCategoryTypes.TRANSPORTATION_CATEGORY_UPDATE_FETCH,
});
export const transportationCategoryUpdateSuccess = (payload) => ({
  type: transportationCategoryTypes.TRANSPORTATION_CATEGORY_UPDATE_SUCCESS,
  payload: {
    ...payload,
  },
});
//MODALS DISPATCH
export const transportationCategoryModalOpen = () => ({
  type: transportationCategoryTypes.TRANSPORTATION_CATEGORY_MODAL_OPEN,
});
export const transportationCategoryModalClose = () => ({
  type: transportationCategoryTypes.TRANSPORTATION_CATEGORY_MODAL_CLOSE,
});

//ACTION TYPES DISPATCH
export const transportationCategoryActionTypeStore = (payload) => ({
  type: transportationCategoryTypes.TRANSPORTATION_CATEGORY_ACTION_TYPE_STORE,
  payload: { ...payload },
});
export const transportationCategoryActionTypeUpdate = (payload) => ({
  type: transportationCategoryTypes.TRANSPORTATION_CATEGORY_ACTION_TYPE_UPDATE,
  payload: { ...payload },
});
