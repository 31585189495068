import PropTypes from 'prop-types';
import api from '../api';

const paymentMethodService = {
  paymentMethodDelete(id) {
    return new Promise((resolve, reject) => {
      api
        .delete(`/payment_methods/${id}`)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },

  paymentMethodIndex(page = null, items = null) {
    return new Promise((resolve, reject) => {
      api
        .get(`/payment_methods${page ? `/page/${page}` : ''}${items ? `/items/${items}` : ''}`)
        .then((response) => response.data)
        .then(resolve)
        .catch(reject);
    });
  },

  paymentMethodShow(id) {
    return new Promise((resolve, reject) => {
      api
        .get(`/payment_methods/${id}`)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },

  paymentMethodStore(data) {
    return new Promise((resolve, reject) => {
      api
        .post('/payment_methods', data)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },

  paymentMethodUpdate(id, data) {
    return new Promise((resolve, reject) => {
      api
        .put(`/payment_methods/${id}`, data)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },
};

paymentMethodService.propTypes = {
  paymentMethodIndex: PropTypes.func.isRequired,
  paymentMethodShow: PropTypes.func.isRequired,
};

export default paymentMethodService;
