import optionTypes from './types';

export const optionIndexFail = (payload) => ({
  type: optionTypes.OPTION_INDEX_FAIL,
  errors: {
    ...payload,
  },
});
export const optionIndexFetch = () => ({
  type: optionTypes.OPTION_INDEX_FETCH,
});
export const optionIndexSuccess = (payload) => ({
  type: optionTypes.OPTION_INDEX_SUCCESS,
  payload: {
    ...payload,
  },
});
