export const MODULE_NAME = 'Payment Types';
export const ROUTE = '/payment-types';

const PaymentType = {
  id: null,
  name: null,
  status: null,
  created_id: null,
  created_at: null,
  updated_at: null,
};

export default PaymentType;
