import PropTypes from 'prop-types';
import api from '../api';

const shippingHotelCompanyService = {
  shippingHotelCompanyDelete(id) {
    return new Promise((resolve, reject) => {
      api
        .delete(`/shipping_hotel_companies/${id}`)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },

  shippingHotelCompanyIndex(page = null, items = null) {
    return new Promise((resolve, reject) => {
      api
        .get(`/shipping_hotel_companies${page ? `/page/${page}` : ''}${items ? `/items/${items}` : ''}`)
        .then((response) => response.data)
        .then(resolve)
        .catch(reject);
    });
  },

  shippingHotelCompanyShow(id) {
    return new Promise((resolve, reject) => {
      api
        .get(`/shipping_hotel_companies/${id}`)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },

  shippingHotelCompanyStore(data) {
    return new Promise((resolve, reject) => {
      api
        .post('/shipping_hotel_companies', data)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },

  shippingHotelCompanyUpdate(id, data) {
    return new Promise((resolve, reject) => {
      api
        .put(`/shipping_hotel_companies/${id}`, data)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },
};

shippingHotelCompanyService.propTypes = {
  shippingHotelCompanyIndex: PropTypes.func.isRequired,
  shippingHotelCompanyShow: PropTypes.func.isRequired,
};

export default shippingHotelCompanyService;
