import React, { Component } from 'react';
import { Form } from 'semantic-ui-react';
import ModelCruiseArrival from './model';
import { pointSaleIndex } from '../point_sale/actions';
import { connect } from 'react-redux';
import {
  cruiseArrivalOpenModal,
  cruiseArrivalCloseModal,
  cruiseArrivalStore,
  cruiseArrivalUpdate,
  cruiseArrivalDelete,
} from './actions';

import { TimeInput, DateInput } from 'semantic-ui-calendar-react';
import moment from 'moment/moment';
import pointSales from '../point_sale/reducer';
import PropTypes from 'prop-types';

class CruiseArrivalForm extends Component {
  // model = this.props.actionType === 'store' ? ModelCruiseArrival : this.props.cruiseArrival;

  constructor(props) {
    super(props);
    const { actionType, cruiseArrival, cruiseArrivals } = props;
    console.log(cruiseArrival);
    this.state = {
      // check_in: '',
      // check_out: '',
      model: actionType === 'store' ? ModelCruiseArrival : cruiseArrival,
      relations: {
        point_sales: [],
      },
      errorForm: {},
    };
    const { fnPointSaleIndex } = props;
    fnPointSaleIndex();
  }

  changeValue = (input) => {
    const { name, value } = input;
    let newValue = {};
    newValue[name] = value;
    this.setState(
      ({ model }) => ({ model: { ...model, ...newValue } }),
      () => {
        this.setState(({ errorForm }) => {
          let newErrors = Object.keys(errorForm)
            .filter((key) => key !== name)
            .reduce((obj, key) => {
              obj[key] = errorForm[key];
              return obj;
            }, {});
          return { errorForm: newErrors };
        });
      }
    );
  };
  componentWillReceiveProps(nextProps) {
    const { errors, pointSales, actionType, cruiseArrival } = nextProps;
    console.log(nextProps);
    if (actionType === 'update') {
      this.setState({ model: cruiseArrival });
    }
    this.setState({ errorForm: { ...errors.errors } });
    this.setState(() => {
      //PointSle
      const enablePointSales = pointSales.filter((pointSale) => pointSale.status === 1);
      let selectPointSales = {};
      selectPointSales.pointSales = enablePointSales.map((pointSale) =>
        Object.keys(pointSale)
          .filter((key) => {
            return key === 'id' || key === 'name';
          })
          .reduce((obj) => {
            obj = { value: pointSale.id, text: pointSale.name, key: pointSale.id };
            return obj;
          }, {})
      );
      return {
        relations: {
          ...selectPointSales,
        },
      };
    });
  }
  render() {
    const { relations, model, time } = this.state;
    const { date_arrival, point_sale_id, check_in, check_out } = this.state.errorForm;
    return (
      <Form
        id={'form'}
        onSubmit={(e) => {
          const { model } = this.state;
          console.log(model)
          const { actionType, fnCruiseArrivalStore, fnCruiseArrivalUpdate } = this.props;
          switch (actionType) {
            case 'store':
              fnCruiseArrivalStore(model);
              break;
            case 'update':
              fnCruiseArrivalUpdate(model.id, model);
              break;
            default:
              break;
          }
        }}
      >
        <div className={'col-auto'}>
          <DateInput
            placeholder="Date"
            dateFormat={'YYYY-MM-DD'}
            name={'date_arrival'}
            minDate={moment().format('YYYY-MM-DD')}
            value={model.date_arrival}
            label={'Date Arrival'}
            animation="none"
            error={
              date_arrival === undefined ? false : { content: date_arrival, pointing: 'below' }
            }
            popupPosition={'bottom center'}
            icon={false}
            onChange={(e, data) => this.changeValue(data)}
            closable={true}
            autoComplete="off"
          />
        </div>
        <div className={'col-auto'}>
          <TimeInput
            placeholder={'Check in:'}
            dateFormat={'YYYY-MM-DD'}
            name={'check_in'}
            value={model.check_in}
            label={'Check in:'}
            animation="none"
            error={check_in === undefined ? false : { content: check_in, pointing: 'below' }}
            popupPosition={'bottom center'}
            icon={false}
            onChange={(e, data) => this.changeValue(data)}
            closable={true}
            autoComplete="off"
          />
        </div>
        <div className={'col-auto'}>
          <TimeInput
            placeholder="Check out"
            name={'check_out'}
            value={model.check_out}
            label={'Check out'}
            animation="none"
            error={check_out === undefined ? false : { content: check_out, pointing: 'below' }}
            popupPosition={'bottom center'}
            icon={false}
            onChange={(e, data) => this.changeValue(data)}
            closable={true}
            autoComplete="off"
          />
        </div>
        <div className={'col-auto'}>
          <Form.Select
            value={model.point_sale_id}
            name={'point_sale_id'}
            label="Point Sale:"
            error={
              point_sale_id === undefined ? false : { content: point_sale_id, pointing: 'below' }
            }
            placeholder="Add Point Sale"
            options={relations.pointSales}
            onChange={(e, data) => this.changeValue(data)}
          />
        </div>
      </Form>
    );
  }
}

const mapStateProps = (state) => ({
  isFetching: state.cruiseArrivals.isFetching,
  errors: state.cruiseArrivals.errors,
  payload: state.cruiseArrivals.payload,
  cruiseArrivals: state.cruiseArrivals.payload.cruiseArrivals,
  cruiseArrival: state.cruiseArrivals.payload.cruiseArrival,
  isModalOpen: state.cruiseArrivals.isModalOpen,
  actionType: state.cruiseArrivals.payload.actionType,
  pointSales: state.pointSales.payload.pointSales,
});

const mapDispatchToProps = (dispatch) => ({
  fnCruiseArrivalStore: (data) => {
    dispatch(cruiseArrivalStore(data));
  },
  fnCruiseArrivalUpdate: (id, data) => {
    dispatch(cruiseArrivalUpdate(id, data));
  },
  fnPointSaleIndex: () => {
    dispatch(pointSaleIndex(true));
  },
  // fnIndexCategory: () => {
  //   dispatch(categoryIndex());
  // },
  fnCruiseArrivalModalClose: () => {
    dispatch(cruiseArrivalCloseModal());
  },
});
CruiseArrivalForm.propTypes = {
  isFetching: PropTypes.bool,
  errors: PropTypes.PropTypes.exact({
    errors: PropTypes.object,
    message: PropTypes.string,
  }),
  actionType: PropTypes.oneOf(['store', 'update']),
  fnCruiseArrivalStore: PropTypes.func,
  fnCruiseArrivalUpdate: PropTypes.func,
};
export default connect(
  mapStateProps,
  mapDispatchToProps
)(CruiseArrivalForm);
