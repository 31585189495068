import appTypes from './types';

export const localStorageFail = (payload) => ({
  type: appTypes.APP_FAIL_LOCAL_STORAGE,
  errors: { ...payload },
});

export const localStorageFetch = () => ({
  type: appTypes.APP_FETCH_LOCAL_STORAGE,
});

export const localStorageStoreAuthentication = (payload) => ({
  type: appTypes.APP_STORE_AUTHENTICATION,
  payload: { ...payload },
});

export const localStorageDestroyAuthentication = (payload) => ({
  type: appTypes.APP_DESTROY_AUTHENTICATION,
  payload: { ...payload },
});

export const setScrollOnApp = (payload) => ({
  type: appTypes.APP_SET_SCROLL,
  payload: { ...payload },
});
