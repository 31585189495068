import loginService from './service';
// import history from '../app/history';
import { appStoreAuthentication } from '../app/actions';

import { loginFail, loginFetch, loginSuccess } from './dispatch';

export const loginUser = (userData, history) => async (dispatch) => {
  dispatch(loginFetch());
  try {
    const { data } = await loginService.userLogin(userData);
    const dataMessage = {};
    dataMessage.message = `Login successful, welcome ${data.user.name}`;
    dispatch(appStoreAuthentication(data));
    dispatch(loginSuccess(dataMessage));
    // history.replace('/tours');
  } catch (e) {
    const { errors, message } = e;
    dispatch(loginFail({ errors, message }));
  }
};
