import React from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router-dom';

const AuthenticateRoute = ({ component, authenticatePath, isAuthenticated, ...props }) => {
  const Component = component;
  const render = (renderProps) => {
    let element = (
      <Redirect to={{ pathname: authenticatePath, sate: { from: renderProps.location } }} />
    );
    if (isAuthenticated) {
      element = <Component {...renderProps} />;
    }
    return element;
  };
  return <Route {...props} render={render} />;
};

export default AuthenticateRoute;
