import shippingHotelCompanyTypes from './types';
// DESTROY DISPATCH
export const shippingHotelCompanyDeleteFail = (payload) => {
  return {
    type: shippingHotelCompanyTypes.SHIPPING_HOTEL_COMPANY_DESTROY_FAIL,
    errors: {
      ...payload,
    },
  };
};
export const shippingHotelCompanyDeleteFetch = () => ({
  type: shippingHotelCompanyTypes.SHIPPING_HOTEL_COMPANY_DESTROY_FETCH,
});
export const shippingHotelCompanyDeleteSuccess = (payload) => ({
  type: shippingHotelCompanyTypes.SHIPPING_HOTEL_COMPANY_DESTROY_SUCCESS,
  payload,
});

// INDEX DISPATCH
export const shippingHotelCompanyIndexFail = (payload) => ({
  type: shippingHotelCompanyTypes.SHIPPING_HOTEL_COMPANY_INDEX_FAIL,
  payload,
});

export const shippingHotelCompanyIndexFetch = () => ({
  type: shippingHotelCompanyTypes.SHIPPING_HOTEL_COMPANY_INDEX_FETCH,
});

export const shippingHotelCompanyIndexSuccess = (payload) => ({
  type: shippingHotelCompanyTypes.SHIPPING_HOTEL_COMPANY_INDEX_SUCCESS,
  payload: {
    ...payload,
  },
});

// SHOW DISPATCH
export const shippingHotelCompanyShowFail = (payload) => ({
  type: shippingHotelCompanyTypes.SHIPPING_HOTEL_COMPANY_SHOW_FAIL,
  errors: {
    ...payload,
  },
});
export const shippingHotelCompanyShowFetch = () => ({
  type: shippingHotelCompanyTypes.SHIPPING_HOTEL_COMPANY_SHOW_FETCH,
});
export const shippingHotelCompanyShowSuccess = (payload) => ({
  type: shippingHotelCompanyTypes.SHIPPING_HOTEL_COMPANY_SHOW_SUCCESS,
  payload: {
    ...payload,
  },
});

// STORE DISPATCH
export const shippingHotelCompanyStoreFail = (payload) => {
  return {
    type: shippingHotelCompanyTypes.SHIPPING_HOTEL_COMPANY_STORE_FAIL,
    errors: {
      ...payload,
    },
  };
};

export const shippingHotelCompanyStoreFetch = () => ({
  type: shippingHotelCompanyTypes.SHIPPING_HOTEL_COMPANY_STORE_FETCH,
});
export const shippingHotelCompanyStoreSuccess = (payload) => ({
  type: shippingHotelCompanyTypes.SHIPPING_HOTEL_COMPANY_STORE_SUCCESS,
  payload,
});

// UPDATE DISPATCH
export const shippingHotelCompanyUpdateFail = (payload) => {
  return {
    type: shippingHotelCompanyTypes.SHIPPING_HOTEL_COMPANY_UPDATE_FAIL,
    errors: {
      ...payload,
    },
  };
};
export const shippingHotelCompanyUpdateFetch = () => ({
  type: shippingHotelCompanyTypes.SHIPPING_HOTEL_COMPANY_UPDATE_FETCH,
});
export const shippingHotelCompanyUpdateSuccess = (payload) => ({
  type: shippingHotelCompanyTypes.SHIPPING_HOTEL_COMPANY_UPDATE_SUCCESS,
  payload: {
    ...payload,
  },
});

//Modal
export const shippingHotelCompanyModalOpen = () => ({
  type: shippingHotelCompanyTypes.SHIPPING_HOTEL_COMPANY_MODAL_OPEN,
});
export const shippingHotelCompanyModalClose = () => ({
  type: shippingHotelCompanyTypes.SHIPPING_HOTEL_COMPANY_MODAL_CLOSE,
});
export const shippingHotelCompanyActionTypeStore = (payload) => ({
  type: shippingHotelCompanyTypes.SHIPPING_HOTEL_COMPANY_ACTION_TYPE_STORE,
  payload: { ...payload },
});
export const shippingHotelCompanyActionTypeUpdate = (payload) => ({
  type: shippingHotelCompanyTypes.SHIPPING_HOTEL_COMPANY_ACTION_TYPE_UPDATE,
  payload: { ...payload },
});
