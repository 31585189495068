import currencyService from './service';
import {
  currencyDeleteFail,
  currencyDeleteFetch,
  currencyDeleteSuccess,
  currencyIndexFail,
  currencyIndexFetch,
  currencyIndexSuccess,
  currencyShowFail,
  currencyShowFetch,
  currencyShowSuccess,
  currencyStoreFail,
  currencyStoreFetch,
  currencyStoreSuccess,
  currencyUpdateFail,
  currencyUpdateFetch,
  currencyUpdateSuccess,
} from './dispatch';

export const currencyDelete = (id) => async (dispatch) => {
  dispatch(currencyDeleteFetch());
  try {
    const { data } = await currencyService.currencyDelete(id);
    dispatch(currencyDeleteSuccess({ payload: data }));
  } catch (e) {
    const { error } = e;
    dispatch(currencyDeleteFail({ error }));
  }
};

export const currencyIndex = (forRelation = false, page = null, items = null) => async (
  dispatch,
  getState
) => {
  dispatch(currencyIndexFetch());
  try {
    //If is not paginates values, Get default value from state
    if (!forRelation) {
      page = page ? page : getState().currencies.payload.pagination.current_page;
      items = items ? items : getState().currencies.payload.pagination.per_page;
    }

    const { data, meta } = await currencyService.currencyIndex(page, items);
    dispatch(currencyIndexSuccess({ currencies: data, pagination: meta }));
  } catch (e) {
    dispatch(currencyIndexFail(e));
  }
};

export const currencyShow = (data) => (dispatch) => {
  dispatch(currencyShowSuccess({ currency: data }));
};


export const currencyStore = (data) => async (dispatch) => {
  dispatch(currencyStoreFetch());
  try {
    const { message } = await currencyService.currencyStore(data);
    dispatch(currencyStoreSuccess({ message }));
    dispatch(currencyIndex());
  } catch (e) {
    const { errors, message } = e;
    dispatch(currencyStoreFail({ errors, message }));
  }
};

export const currencyUpdate = (id, data) => async (dispatch) => {
  dispatch(currencyUpdateFetch());
  try {
    const { message } = await currencyService.currencyUpdate(id, data);
    dispatch(currencyUpdateSuccess({ message }));
    dispatch(currencyIndex());
  } catch (e) {
    const { errors, message } = e;
    dispatch(currencyUpdateFail({ errors, message }));
  }
};
