export const MODULE_NAME = 'Cruise Arrivals';
export const ROUTE = '/cruise-arrivals';

const CruiseArrival = {
  id: null,
  check_in: null,
  check_out: null,
  created_id: null,
  created_at: null,
  updated_at: null,
  updated_id: null,
  date_arrival: null,
  point_sale_id: null,
};

export default CruiseArrival;
