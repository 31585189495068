import { combineReducers } from 'redux';
import { reducer as toastr } from 'react-redux-toastr';

// Reducers
import users from './user/reducer';
import departures from './departure/reducer';
import meetingPoints from './meeting_point/reducer';
import items from './item/reducer';
import vendors from './vendor/reducer';
import pointSales from './point_sale/reducer';
import paymentTypes from './payment_type/reducer';
import processStatus from './process_status/reducer';
import shippingHotelCompanies from './shipping_hotel_company/reducer';
import sellers from './sellers/reducer';
import movementTypes from './movement_type/reducer';
import rates from './rate/reducer';
import cruiseArrivals from './cruise_arrival/reducer';
import categories from './category/reducer';
import transportations from './transportation/reducer';
import transportationCategories from './transportation_category/reducer';
import paymentMethods from './payment_method/reducer';
import login from './login/reducer';
import sales from './sales/reducer';
import app from './app/reducer';
import vendorCategories from './vendor_category/reducer';
import currencies from './currency/reducer';
import rateLists from './rate_list/reducer';
import options from './option/reducer';
import slots from './slots/reducer';

const rootReducer = combineReducers({
  app,
  login,
  users,
  departures,
  meetingPoints,
  items,
  vendors,
  pointSales,
  paymentTypes,
  processStatus,
  shippingHotelCompanies,
  sellers,
  movementTypes,
  rates,
  cruiseArrivals,
  categories,
  transportations,
  transportationCategories,
  paymentMethods,
  sales,
  currencies,
  rateLists,
  vendorCategories,
  options,
  slots,
  toastr,
});

export default rootReducer;
