import itemService from './service';
import {
  itemDeleteFail,
  itemDeleteFetch,
  itemDeleteSuccess,
  itemIndexFail,
  itemIndexFetch,
  itemIndexSuccess,
  itemShowFail,
  itemShowFetch,
  itemShowSuccess,
  itemStoreFail,
  itemStoreFetch,
  itemStoreSuccess,
  itemUpdateFail,
  itemUpdateFetch,
  itemUpdateSuccess,
  itemModalOpen,
  itemModalClose,
  itemActionTypeStore,
  itemActionTypeUpdate,
} from './dispatch';
import { RELATIONS } from './model';
import appService from '../app/service';
export const itemDelete = (id) => async (dispatch, getState) => {
  dispatch(itemDeleteFetch());
  dispatch(itemIndexFetch());
  try {
    const { data, message } = await itemService.itemDelete(id);
    dispatch(itemPaginate());
    dispatch(itemDeleteSuccess({ payload: data, message: message }));
  } catch (e) {
    const { error } = e;
    dispatch(itemDeleteFail({ error }));
  }
};

export const itemPaginate = (forRelation = false, page = null, items = null) => async (
  dispatch,
  getState
) => {
  dispatch(itemIndexFetch());
  try {
    //If is not paginates values, Get default value from state
    if (!forRelation) {
      page = page ? page : getState().items.payload.pagination.current_page;
      items = items ? items : getState().items.payload.pagination.per_page;
    }
    const { data, meta } = await itemService.itemIndex(forRelation, null, page, items);
    //Always get initial state default values.
    const { pagination } = getState().items.payload;
    dispatch(
      itemIndexSuccess({
        items: data,
        pagination: { ...pagination, ...meta },
      })
    );
  } catch (e) {
    dispatch(itemIndexFail(e));
  }
};

export const itemIndex = (forRelation = false, params = null) => async (dispatch) => {
  dispatch(itemIndexFetch());
  try {
    forRelation = forRelation ? appService.appSerialize(RELATIONS) : null;
    let serializedParams = '';
    if (params && typeof params === 'object') {
      Object.keys(params).map((param, key, array) => {
        serializedParams += `${param}=${params[param]}`;
        if (array[key + 1] !== undefined) serializedParams += '&';
      });
    } else serializedParams = params;
    const { data, meta } = await itemService.itemIndex(forRelation, serializedParams);
    dispatch(itemIndexSuccess({ items: data, pagination: meta }));
  } catch (e) {
    dispatch(itemIndexFail(e));
  }
};

export const itemShowAsync = (item) => async (dispatch) => {
  dispatch(itemShowFetch());
  try {
    const { data } = await itemService.itemShow(item);
    dispatch(itemShowSuccess({ item: data }));
  } catch (e) {
    const { error } = e;
    dispatch(itemShowFail({ error }));
  }
};

// export const itemShow = (data) => (dispatch) => {
//   dispatch(itemShowSuccess({ item: data }));
// };

export const itemShow = (item) => async (dispatch) => {
  dispatch(itemShowFetch());
  try {
    const relation = appService.appSerialize(RELATIONS);
    const { data } = await itemService.itemShow(item.id, relation);
    dispatch(itemShowSuccess({ item: data }));
  } catch (e) {
    const { error } = e;
    dispatch(itemShowFail({ error }));
  }
};

export const itemStore = (data) => async (dispatch) => {
  dispatch(itemStoreFetch());
  dispatch(itemIndexFetch());
  try {
    const { message } = await itemService.itemStore(data);
    dispatch(itemCloseModal());
    dispatch(itemIndexSuccess());
    dispatch(itemStoreSuccess({ message }));
  } catch (e) {
    const { errors, message } = e;
    dispatch(itemStoreFail({ errors, message }));
  }
};

export const itemUpdate = (id, data) => async (dispatch) => {
  dispatch(itemUpdateFetch());
  dispatch(itemIndexFetch());
  try {
    const { message, result } = await itemService.itemUpdate(id, data);
    dispatch(itemUpdateSuccess({ message }));
    dispatch(itemCloseModal());
    dispatch(itemIndexSuccess());
    dispatch(itemPaginate());
  } catch (e) {
    const { errors, message } = e;
    dispatch(itemUpdateFail({ errors, message }));
  }
};

export const itemOpenModal = () => (dispatch) => {
  dispatch(itemModalOpen());
};

export const itemCloseModal = () => (dispatch) => {
  dispatch(itemModalClose());
};

export const itemActionStore = () => (dispatch) => {
  dispatch(itemActionTypeStore({ actionType: 'store' }));
};

export const itemActionUpdate = () => (dispatch) => {
  dispatch(itemActionTypeUpdate({ actionType: 'update' }));
};
