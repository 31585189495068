export const MODULE_NAME = 'Process Statuses';
export const ROUTE = '/process-statuses';

const ProcessStatus = {
  id: null,
  name: null,
  code: null,
  status: null,
  created_id: null,
  created_at: null,
  updated_at: null,
};

export default ProcessStatus;
