import Option from '../option/model';
export const MODULE_NAME = 'Items';
export const ROUTE = '/items';
export const RELATIONS = ['meeting_points', 'transportations', 'optionables'];

const Item = {
  id: null,
  name: '',
  code: '',
  category_id: null,
  status: null,
  created_id: null,
  created_at: null,
  updated_at: null,
  item_details: [],
  base_price: 0,
  item_transportations: [],
  item_option: { ...Option },
};

export default Item;
