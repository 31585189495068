import PropTypes from 'prop-types';
import api from '../api';

const paymentTypeService = {
  paymentTypeDelete(id) {
    return new Promise((resolve, reject) => {
      api
        .delete(`/payment_types/${id}`)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },

  paymentTypeIndex(page = null, items = null) {
    return new Promise((resolve, reject) => {
      api
        .get(`/payment_types${page ? `/page/${page}` : ''}${items ? `/items/${items}` : ''}`)
        .then((response) => response.data)
        .then(resolve)
        .catch(reject);
    });
  },

  paymentTypeShow(id) {
    return new Promise((resolve, reject) => {
      api
        .get(`/payment_types/${id}`)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },

  paymentTypeStore(data) {
    return new Promise((resolve, reject) => {
      api
        .post('/payment_types', data)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },

  paymentTypeUpdate(id, data) {
    return new Promise((resolve, reject) => {
      api
        .put(`/payment_types/${id}`, data)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },
};

paymentTypeService.propTypes = {
  paymentTypeIndex: PropTypes.func.isRequired,
  paymentTypeShow: PropTypes.func.isRequired,
};

export default paymentTypeService;
