import rateTypes from './types';
// DESTROY DISPATCH
export const rateDeleteFail = (payload) => {
  return {
    type: rateTypes.RATE_DESTROY_FAIL,
    errors: {
      ...payload,
    },
  };
};
export const rateDeleteFetch = () => ({
  type: rateTypes.RATE_DESTROY_FETCH,
});
export const rateDeleteSuccess = (payload) => ({
  type: rateTypes.RATE_DESTROY_SUCCESS,
  payload,
});

// INDEX DISPATCH
export const rateIndexFail = (payload) => ({
  type: rateTypes.RATE_INDEX_FAIL,
  payload,
});

export const rateIndexFetch = () => ({
  type: rateTypes.RATE_INDEX_FETCH,
});

export const rateIndexSuccess = (payload) => ({
  type: rateTypes.RATE_INDEX_SUCCESS,
  payload: {
    ...payload,
  },
});

// SHOW DISPATCH
export const rateShowFail = (payload) => ({
  type: rateTypes.RATE_SHOW_FAIL,
  errors: {
    ...payload,
  },
});
export const rateShowFetch = () => ({
  type: rateTypes.RATE_SHOW_FETCH,
});
export const rateShowSuccess = (payload) => ({
  type: rateTypes.RATE_SHOW_SUCCESS,
  payload: {
    ...payload,
  },
});

// STORE DISPATCH
export const rateStoreFail = (payload) => {
  return {
    type: rateTypes.RATE_STORE_FAIL,
    errors: {
      ...payload,
    },
  };
};

export const rateStoreFetch = () => ({
  type: rateTypes.RATE_STORE_FETCH,
});
export const rateStoreSuccess = (payload) => ({
  type: rateTypes.RATE_STORE_SUCCESS,
  payload,
});

// UPDATE DISPATCH
export const rateUpdateFail = (payload) => {
  return {
    type: rateTypes.RATE_UPDATE_FAIL,
    errors: {
      ...payload,
    },
  };
};
export const rateUpdateFetch = () => ({
  type: rateTypes.RATE_UPDATE_FETCH,
});
export const rateUpdateSuccess = (payload) => ({
  type: rateTypes.RATE_UPDATE_SUCCESS,
  payload: {
    ...payload,
  },
});

//Modal
export const rateModalOpen = () => ({
  type: rateTypes.RATE_MODAL_OPEN,
});
export const rateModalClose = () => ({
  type: rateTypes.RATE_MODAL_CLOSE,
});
export const rateActionTypeStore = (payload) => ({
  type: rateTypes.RATE_ACTION_TYPE_STORE,
  payload: { ...payload },
});
export const rateActionTypeUpdate = (payload) => ({
  type: rateTypes.RATE_ACTION_TYPE_UPDATE,
  payload: { ...payload },
});
