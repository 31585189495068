import pointSaleService from './service';
import {
  pointSaleDeleteFail,
  pointSaleDeleteFetch,
  pointSaleDeleteSuccess,
  pointSaleIndexFail,
  pointSaleIndexFetch,
  pointSaleIndexSuccess,
  pointSaleShowFail,
  pointSaleShowFetch,
  pointSaleShowSuccess,
  pointSaleStoreFail,
  pointSaleStoreFetch,
  pointSaleStoreSuccess,
  pointSaleUpdateFail,
  pointSaleUpdateFetch,
  pointSaleUpdateSuccess,
  pointSaleActionTypeStore,
  pointSaleActionTypeUpdate,
  pointSaleModalClose,
  pointSaleModalOpen,
} from './dispatch';


export const pointSaleDelete = (id) => async (dispatch) => {
  dispatch(pointSaleDeleteFetch());
  try {
    const { data, message } = await pointSaleService.pointSaleDelete(id);
    dispatch(pointSaleDeleteSuccess({ payload: data, message: message }));
    dispatch(pointSaleCloseModal());
    dispatch(pointSaleIndex());
  } catch (e) {
    const { error } = e;
    dispatch(pointSaleDeleteFail({ error }));
  }
};

export const pointSaleIndex = (forRelation = false, page = null, items = null) => async (
  dispatch,
  getState
) => {
  dispatch(pointSaleIndexFetch());
  try {
    //If is not paginates values, Get default value from state
    if (!forRelation) {
      page = page ? page : getState().pointSales.payload.pagination.current_page;
      items = items ? items : getState().pointSales.payload.pagination.per_page;
    }

    const { data, meta } = await pointSaleService.pointSaleIndex(page, items);
    dispatch(pointSaleIndexSuccess({ pointSales: data, pagination: meta }));
  } catch (e) {
    dispatch(pointSaleIndexFail(e));
  }
};

export const pointSaleShow = (data) => (dispatch) => {
  dispatch(pointSaleShowSuccess({ pointSale: data }));
};

// export const pointSaleShow = (id) => async (dispatch) => {
//   dispatch(pointSaleShowFetch());
//   try {
//     const { data } = await pointSaleService.pointSaleShow(id);
//     dispatch(pointSaleShowSuccess({ pointSale: data }));
//   } catch (e) {
//     const { error } = e;
//     dispatch(pointSaleShowFail({ error }));
//   }
// };

export const pointSaleStore = (data) => async (dispatch) => {
  dispatch(pointSaleStoreFetch());
  try {
    const { message } = await pointSaleService.pointSaleStore(data);
    dispatch(pointSaleStoreSuccess({ message }));
    dispatch(pointSaleCloseModal());
    dispatch(pointSaleIndex());
  } catch (e) {
    const { errors, message } = e;
    dispatch(pointSaleStoreFail({ errors, message }));
  }
};

export const pointSaleUpdate = (id, data) => async (dispatch) => {
  dispatch(pointSaleUpdateFetch());
  try {
    const { message } = await pointSaleService.pointSaleUpdate(id, data);
    dispatch(pointSaleUpdateSuccess({ message }));
    dispatch(pointSaleCloseModal());
    dispatch(pointSaleIndex());
  } catch (e) {
    const { errors, message } = e;
    dispatch(pointSaleUpdateFail({ errors, message }));
  }
};
export const pointSaleOpenModal = () => (dispatch) => {
  dispatch(pointSaleModalOpen());
};

export const pointSaleCloseModal = () => (dispatch) => {
  dispatch(pointSaleModalClose());
};

export const pointSaleActionStore = () => (dispatch) => {
  dispatch(pointSaleActionTypeStore({ actionType: 'store' }));
};

export const pointSaleActionUpdate = () => (dispatch) => {
  dispatch(pointSaleActionTypeUpdate({ actionType: 'update' }));
};
