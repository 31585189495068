import departureTypes from './types';
// DESTROY DISPATCH
export const departureDeleteFail = (payload) => {
  return {
    type: departureTypes.DEPARTURE_DESTROY_FAIL,
    errors: {
      ...payload,
    },
  };
};
export const departureDeleteFetch = () => ({
  type: departureTypes.DEPARTURE_DESTROY_FETCH,
});
export const departureDeleteSuccess = (payload) => ({
  type: departureTypes.DEPARTURE_DESTROY_SUCCESS,
  payload: { ...payload },
});

// INDEX DISPATCH
export const departureIndexFail = (payload) => ({
  type: departureTypes.DEPARTURE_INDEX_FAIL,
  errors: { ...payload },
});

export const departureIndexFetch = () => ({
  type: departureTypes.DEPARTURE_INDEX_FETCH,
});

export const departureIndexSuccess = (payload) => ({
  type: departureTypes.DEPARTURE_INDEX_SUCCESS,
  payload: {
    ...payload,
  },
});

// SHOW DISPATCH
export const departureShowFail = (payload) => ({
  type: departureTypes.DEPARTURE_SHOW_FAIL,
  errors: {
    ...payload,
  },
});
export const departureShowFetch = () => ({
  type: departureTypes.DEPARTURE_SHOW_FETCH,
});
export const departureShowSuccess = (payload) => ({
  type: departureTypes.DEPARTURE_SHOW_SUCCESS,
  payload: {
    ...payload,
  },
});

// STORE DISPATCH
export const departureStoreFail = (payload) => {
  return {
    type: departureTypes.DEPARTURE_STORE_FAIL,
    errors: {
      ...payload,
    },
  };
};

export const departureStoreFetch = () => ({
  type: departureTypes.DEPARTURE_STORE_FETCH,
});
export const departureStoreSuccess = (payload) => ({
  type: departureTypes.DEPARTURE_STORE_SUCCESS,
  payload,
});

// UPDATE DISPATCH
export const departureUpdateFail = (payload) => {
  return {
    type: departureTypes.DEPARTURE_UPDATE_FAIL,
    errors: {
      ...payload,
    },
  };
};
export const departureUpdateFetch = () => ({
  type: departureTypes.DEPARTURE_UPDATE_FETCH,
});
export const departureUpdateSuccess = (payload) => ({
  type: departureTypes.DEPARTURE_UPDATE_SUCCESS,
  payload: {
    ...payload,
  },
});

//MODALS DISPATCH
export const departureModalOpen = () => ({
  type: departureTypes.DEPARTURE_MODAL_OPEN,
});
export const departureModalClose = () => ({
  type: departureTypes.DEPARTURE_MODAL_CLOSE,
});

//ACTION TYPES DISPATCH
export const departureActionTypeStore = (payload) => ({
  type: departureTypes.DEPARTURE_ACTION_TYPE_STORE,
  payload: { ...payload },
});
export const departureActionTypeUpdate = (payload) => ({
  type: departureTypes.DEPARTURE_ACTION_TYPE_UPDATE,
  payload: { ...payload },
});
