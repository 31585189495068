import movementTypeService from './service';
import {
  movementTypeDeleteFail,
  movementTypeDeleteFetch,
  movementTypeDeleteSuccess,
  movementTypeIndexFail,
  movementTypeIndexFetch,
  movementTypeIndexSuccess,
  movementTypeShowFail,
  movementTypeShowFetch,
  movementTypeShowSuccess,
  movementTypeStoreFail,
  movementTypeStoreFetch,
  movementTypeStoreSuccess,
  movementTypeUpdateFail,
  movementTypeUpdateFetch,
  movementTypeUpdateSuccess,
} from './dispatch';

export const movementTypeDelete = (id) => async (dispatch) => {
  dispatch(movementTypeDeleteFetch());
  try {
    const { data, message } = await movementTypeService.movementTypeDelete(id);
    dispatch(movementTypeDeleteSuccess({ payload: data, message: message }));
  } catch (e) {
    const { error } = e;
    dispatch(movementTypeDeleteFail({ error }));
  }
};

export const movementTypeIndex = (forRelation = false, page = null, items = null) => async (
  dispatch,
  getState
) => {
  dispatch(movementTypeIndexFetch());
  try {
    //If is not paginates values, Get default value from state
    if (!forRelation) {
      page = page ? page : getState().movementTypes.payload.pagination.current_page;
      items = items ? items : getState().movementTypes.payload.pagination.per_page;
    }

    const { data, meta } = await movementTypeService.movementTypeIndex(page, items);
    dispatch(movementTypeIndexSuccess({ movementTypes: data, pagination: meta }));
  } catch (e) {
    dispatch(movementTypeIndexFail(e));
  }
};

export const movementTypeShow = (id) => async (dispatch) => {
  dispatch(movementTypeShowFetch());
  try {
    const { data } = await movementTypeService.movementTypeShow(id);
    dispatch(movementTypeShowSuccess({ movementType: data }));
  } catch (e) {
    const { error } = e;
    dispatch(movementTypeShowFail({ error }));
  }
};

export const movementTypeStore = (data) => async (dispatch) => {
  dispatch(movementTypeStoreFetch());
  try {
    const { message } = await movementTypeService.movementTypeStore(data);
    dispatch(movementTypeStoreSuccess(message));
  } catch (e) {
    const { errors, message } = e;
    dispatch(movementTypeStoreFail({ errors, message }));
  }
};

export const movementTypeUpdate = (id, data) => async (dispatch) => {
  dispatch(movementTypeUpdateFetch());
  try {
    const { message } = await movementTypeService.movementTypeUpdate(id, data);
    dispatch(movementTypeUpdateSuccess({ message }));
  } catch (e) {
    const { errors, message } = e;
    dispatch(movementTypeUpdateFail({ errors, message }));
  }
};
