import PropTypes from 'prop-types';
import api from '../api';

const sellerService = {
  sellerDelete(id) {
    return new Promise((resolve, reject) => {
      api
        .delete(`/sellers/${id}`)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },

  sellerIndex(page = null, items = null) {
    return new Promise((resolve, reject) => {
      api
        .get(`/sellers${page ? `/page/${page}` : ''}${items ? `/items/${items}` : ''}`)
        .then((response) => response.data)
        .then(resolve)
        .catch(reject);
    });
  },

  sellerShow(id) {
    return new Promise((resolve, reject) => {
      api
        .get(`/sellers/${id}`)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },

  sellerStore(data) {
    return new Promise((resolve, reject) => {
      api
        .post('/sellers', data)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },

  sellerUpdate(id, data) {
    return new Promise((resolve, reject) => {
      api
        .put(`/sellers/${id}`, data)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },
};

sellerService.propTypes = {
  sellerIndex: PropTypes.func.isRequired,
  sellerShow: PropTypes.func.isRequired,
};

export default sellerService;
