import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { appSetScrollPosition } from '../actions';
import { Link } from 'react-router-dom';
import SideBarItem from './SideBarItem';
import {
  CATEGORY_MODULE_NAME,
  CATEGORY_ROUTE,
  CRUISE_ARRIVAL_MODULE_NAME,
  CRUISE_ARRIVAL_ROUTE,
  DASHBOARD_MODULE_NAME,
  DASHBOARD_ROUTE,
  DEPARTURE_ROUTE,
  DEPARTURE_MODULE_NAME,
  ITEM_MODULE_NAME,
  ITEM_ROUTE,
  MEETING_POINT_MODULE_NAME,
  MEETING_POINT_ROUTE,
  MOVEMENT_TYPE_MODULE_NAME,
  MOVEMENT_TYPE_ROUTE,
  PAYMENT_METHOD_MODULE_NAME,
  PAYMENT_METHOD_ROUTE,
  PAYMENT_TYPE_MODULE_NAME,
  PAYMENT_TYPE_ROUTE,
  POINT_SALE_MODULE_NAME,
  POINT_SALE_ROUTE,
  PROCESS_STATUS_MODULE_NAME,
  PROCESS_STATUS_ROUTE,
  RATE_MODULE_NAME,
  RATE_ROUTE,
  SELLER_MODULE_NAME,
  SELLER_ROUTE,
  SHIPPING_HOTEL_COMPANY_MODULE_NAME,
  SHIPPING_HOTEL_COMPANY_ROUTE,
  TRANSPORTATION_CATEGORY_MODULE_NAME,
  TRANSPORTATION_CATEGORY_ROUTE,
  TRANSPORTATION_MODULE_NAME,
  TRANSPORTATION_ROUTE,
  USER_MODULE_NAME,
  USER_ROUTE,
  VENDOR_MODULE_NAME,
  VENDOR_ROUTE,
  SALE_MODULE_NAME,
  SALE_ROUTE,
  SLOT_MODULE_NAME,
  SLOT_ROUTE,
} from '../../constants';

class SideBar extends Component {
  contentScrollRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      scrollPosition: 0,
      menuItems: [
        {
          active: false,
          label: DASHBOARD_MODULE_NAME,
          icon: 'pe-7s-tools',
          heading: 'Dashboard',
          to: DASHBOARD_ROUTE,
          content: false,
        },
        {
          active: false,
          label: 'Cruise Lines',
          icon: 'pe-7s-tools',
          heading: 'Cruise Lines',
          to: false,
          content: [
            {
              to: CRUISE_ARRIVAL_ROUTE,
              label: CRUISE_ARRIVAL_MODULE_NAME,
              active: false,
            },
          ],
        },
        {
          active: false,
          label: 'Resources',
          icon: 'pe-7s-tools',
          heading: 'Resources',
          to: false,
          content: [
            {
              to: SALE_ROUTE,
              label: SALE_MODULE_NAME,
              active: false,
            },
            {
              to: RATE_ROUTE,
              label: RATE_MODULE_NAME,
              active: false,
            },
            {
              to: VENDOR_ROUTE,
              label: VENDOR_MODULE_NAME,
              active: false,
            },
            {
              to: ITEM_ROUTE,
              label: ITEM_MODULE_NAME,
              active: false,
            },
            {
              to: SELLER_ROUTE,
              label: SELLER_MODULE_NAME,
              active: false,
            },
            {
              to: MEETING_POINT_ROUTE,
              label: MEETING_POINT_MODULE_NAME,
              active: false,
            },
            {
              to: DEPARTURE_ROUTE,
              label: DEPARTURE_MODULE_NAME,
              active: false,
            },
            {
              to: TRANSPORTATION_ROUTE,
              label: TRANSPORTATION_MODULE_NAME,
              active: false,
            },
            {
              to: SLOT_ROUTE,
              label: SLOT_MODULE_NAME,
              active: false
            },
            {
              to: TRANSPORTATION_CATEGORY_ROUTE,
              label: TRANSPORTATION_CATEGORY_MODULE_NAME,
              active: false,
            },
            {
              to: POINT_SALE_ROUTE,
              label: POINT_SALE_MODULE_NAME,
              active: false,
            },
            {
              to: SHIPPING_HOTEL_COMPANY_ROUTE,
              label: SHIPPING_HOTEL_COMPANY_MODULE_NAME,
              active: false,
            },
          ],
        },
        {
          active: false,
          label: 'Configurations',
          icon: 'pe-7s-tools',
          heading: 'Configurations',
          to: false,
          content: [
            { to: USER_ROUTE, label: USER_MODULE_NAME, active: false },
            { to: PAYMENT_TYPE_ROUTE, label: PAYMENT_TYPE_MODULE_NAME, active: false },
            { to: PAYMENT_METHOD_ROUTE, label: PAYMENT_METHOD_MODULE_NAME, active: false },
            { to: PROCESS_STATUS_ROUTE, label: PROCESS_STATUS_MODULE_NAME, active: false },
            { to: CATEGORY_ROUTE, label: CATEGORY_MODULE_NAME, active: false },
            { to: MOVEMENT_TYPE_ROUTE, label: MOVEMENT_TYPE_MODULE_NAME, active: false },
          ],
        },
      ],
    };
  }

  componentDidMount() {
    const { pathname, scrollPosition } = this.props;
    this.setState(({ menuItems }) => {
      let newState = menuItems.map((parent, keyParent) => {
        let itemObj = { ...parent };
        if (parent.content !== false && parent.to === false) {
          const content = parent.content.map((child, keyChild) => {
            let itemChildObj = { ...child };
            if (child.to === pathname) {
              itemChildObj = { ...child, active: true };
              itemObj = { ...itemObj, active: true, content: itemChildObj };
            }
            return itemChildObj;
          });
          itemObj = { ...itemObj, content: content };
        } else {
          if (parent.to === pathname) {
            itemObj = { ...itemObj, active: true };
          }
        }
        return itemObj;
      });
      return { menuItems: newState };
    });
    setTimeout(() => {
      this.scrollDefault(scrollPosition);
    });
  }

  scrollDefault = (position) => {
    this.contentScrollRef.current.scroll({
      top: position,
    });
  };

  toggleMenu = (keyValue) => {
    this.setState(({ menuItems }) => {
      const newState = menuItems.map((item, key) => {
        let itemObj = { ...item };
        if (key === keyValue) {
          itemObj = { ...itemObj, active: !item.active };
        }
        return itemObj;
      });
      return { menuItems: newState };
    });
    //dispatch default scroll positon
    const { fnAppSetScrollPosition } = this.props;
    const { scrollPosition } = this.state;
    fnAppSetScrollPosition(scrollPosition);
  };

  render() {
    return (
      <div className={'app-sidebar sidebar-shadow'}>
        <div
          className="scrollbar-sidebar ps--active-y scrollable"
          ref={this.contentScrollRef}
          onScroll={(e) => this.setState({ scrollPosition: e.target.scrollTop })}
        >
          <div className="app-sidebar__inner">
            <ul className="vertical-nav-menu">
              {this.state.menuItems.map((item, key) => {
                return (
                  <Fragment>
                    {item.heading && <li className="app-sidebar__heading">{item.heading}</li>}
                    <SideBarItem {...item} keyMap={key} toggleMenu={this.toggleMenu}>
                      {item.content &&
                        item.content.map((children) => {
                          return (
                            <li>
                              <Link
                                onClick={(e) =>
                                  this.setState({ scrollPosition: e.target.scrollTop })
                                }
                                to={children.to}
                                className={children.active ? 'mm-active' : ''}
                              >
                                <i
                                  className={`metismenu-icon ${children.icon ? children.icon : ''}`}
                                />
                                {children.label}
                              </Link>
                            </li>
                          );
                        })}
                    </SideBarItem>
                  </Fragment>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

const mapSateToProps = (state) => ({
  scrollPosition: state.app.payload.scrollPosition,
});

const mapDispatchToProps = (dispatch) => ({
  fnAppSetScrollPosition: (position) => {
    dispatch(appSetScrollPosition(position));
  },
});

export default connect(
  mapSateToProps,
  mapDispatchToProps
)(SideBar);
