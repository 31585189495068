const types = {
  // FOR CRUD DEPARTURES
  DEPARTURE_DESTROY_FAIL: 'DEPARTURE_DESTROY_FAIL',
  DEPARTURE_DESTROY_FETCH: 'DEPARTURE_DESTROY_FETCH',
  DEPARTURE_DESTROY_SUCCESS: 'DEPARTURE_DESTROY_SUCCESS',
  DEPARTURE_INDEX_FAIL: 'DEPARTURE_INDEX_FAIL',
  DEPARTURE_INDEX_FETCH: 'DEPARTURE_INDEX_FETCH',
  DEPARTURE_INDEX_SUCCESS: 'DEPARTURE_INDEX_SUCCESS',
  DEPARTURE_SHOW_FAIL: 'DEPARTURE_SHOW_FAIL',
  DEPARTURE_SHOW_FETCH: 'DEPARTURE_SHOW_FETCH',
  DEPARTURE_SHOW_SUCCESS: 'DEPARTURE_SHOW_SUCCESS',
  DEPARTURE_STORE_FAIL: 'DEPARTURE_STORE_FAIL',
  DEPARTURE_STORE_FETCH: 'DEPARTURE_STORE_FETCH',
  DEPARTURE_STORE_SUCCESS: 'DEPARTURE_STORE_SUCCESS',
  DEPARTURE_UPDATE_FAIL: 'DEPARTURE_UPDATE_FAIL',
  DEPARTURE_UPDATE_FETCH: 'DEPARTURE_UPDATE_FETCH',
  DEPARTURE_UPDATE_SUCCESS: 'DEPARTURE_UPDATE_SUCCESS',
  DEPARTURE_MODAL_OPEN: ' DEPARTURE_MODAL_OPEN',
  DEPARTURE_MODAL_CLOSE: 'DEPARTURE_MODAL_CLOSE',
  DEPARTURE_ACTION_TYPE_STORE: 'DEPARTURE_ACTION_CREATE_TYPE',
  DEPARTURE_ACTION_TYPE_UPDATE: 'DEPARTURE_ACTION_UPDATE_TYPE',
};

export const typeStates = {
  FAIL: '_FAIL',
  FETCH: '_FETCH',
  SUCCESS: '_SUCCESS',
};

export default types;
