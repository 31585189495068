import PropTypes from 'prop-types';
import api from '../api';

const processStatusService = {
  processStatusDelete(id) {
    return new Promise((resolve, reject) => {
      api
        .delete(`/process_statuses/${id}`)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },

  processStatusIndex(page = null, items = null) {
    return new Promise((resolve, reject) => {
      api
        .get(`/process_statuses${page ? `/page/${page}` : ''}${items ? `/items/${items}` : ''}`)
        .then((response) => response.data)
        .then(resolve)
        .catch(reject);
    });
  },

  processStatusShow(id) {
    return new Promise((resolve, reject) => {
      api
        .get(`/process_statuses/${id}`)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },

  processStatusStore(data) {
    return new Promise((resolve, reject) => {
      api
        .post('/process_statuses', data)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },

  processStatusUpdate(id, data) {
    return new Promise((resolve, reject) => {
      api
        .put(`/process_statuses/${id}`, data)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },
};

processStatusService.propTypes = {
  processStatusIndex: PropTypes.func.isRequired,
  processStatusShow: PropTypes.func.isRequired,
};

export default processStatusService;
