import saleService from './service';
import { localStorageStoreAuthentication } from '../app/dispatch';
import {
  saleDeleteFail,
  saleDeleteFetch,
  saleDeleteSuccess,
  saleIndexFail,
  saleIndexFetch,
  saleIndexSuccess,
  saleShowFail,
  saleShowFetch,
  saleShowSuccess,
  saleStoreFail,
  saleStoreFetch,
  saleStoreSuccess,
  saleUpdateFail,
  saleUpdateFetch,
  saleUpdateSuccess,
  saleModalOpen,
  saleModalClose,
  saleActionTypeStore,
  saleActionTypeUpdate,
} from './dispatch';
import appService from '../app/service';
import { RELATIONS } from '../sales/model';
export const test = (forRelation = false, params = null, page = null, items = null) => async (
  dispatch,
  getState
) => {
  dispatch(saleIndexFetch());
  try {
    //If is not paginates values, Get default value from state
    page = page ? page : getState().sales.payload.pagination.current_page;
    items = items ? items : getState().sales.payload.pagination.per_page;
    forRelation = forRelation ? appService.appSerialize(RELATIONS) : null;
    let serializedParams = '';
    if (params && typeof params === 'object') {
      Object.keys(params).map((param, key, array) => {
        serializedParams += `${param}=${params[param]}`;
        if (array[key + 1] !== undefined) serializedParams += '&';
      });
    } else serializedParams = params;
    const { data, meta } = await saleService.saleIndex(forRelation, serializedParams, page, items);
    const { pagination } = getState().items.payload;
    dispatch(saleIndexSuccess({ sales: data, pagination: { ...pagination, ...meta } }));
  } catch (e) {
    dispatch(saleIndexFail(e));
  }
};

export const saleDelete = (id) => async (dispatch, getState) => {
  dispatch(saleDeleteFetch());
  try {
    const { data, message } = await saleService.saleDelete(id);
    dispatch(saleDeleteSuccess({ payload: data, message: message }));
    dispatch(saleIndex());
  } catch (e) {
    const { error } = e;
    dispatch(saleDeleteFail({ error }));
  }
};
export const salePaginate = (forRelation = false, page = null, items = null) => async (
  dispatch,
  getState
) => {
  dispatch(saleIndexFetch());
  try {
    //If is not paginates values, Get default value from state
    page = page ? page : getState().sales.payload.pagination.current_page;
    items = items ? items : getState().sales.payload.pagination.per_page;
    // forRelation = forRelation ? appService.appSerialize(RELATIONS) : null;

    const { data, meta } = await saleService.saleIndex(forRelation, null, page, items);
    dispatch(saleIndexSuccess({ sales: data, pagination: meta }));
  } catch (e) {
    dispatch(saleIndexFail(e));
  }
};
export const saleIndex = (forRelation = false, page = null, items = null) => async (
  dispatch,
  getState
) => {
  dispatch(saleIndexFetch());
  try {
    if (!forRelation) {
      //If is not paginates values, Get default value from state
      page = page ? page : getState().sales.payload.pagination.current_page;
      items = items ? items : getState().sales.payload.pagination.per_page;
    }
    const { data, meta } = await saleService.saleIndex(page, items);
    dispatch(saleIndexSuccess({ sales: data, pagination: meta }));
  } catch (e) {
    dispatch(saleIndexFail(e));
  }
};

export const saleShowAsync = (id) => async (dispatch) => {
  dispatch(saleShowFetch());
  try {
    const { data } = await saleService.saleShow(id);
    dispatch(saleShowSuccess({ sale: data }));
  } catch (e) {
    const { error } = e;
    dispatch(saleShowFail({ error }));
  }
};

export const saleShow = (data) => (dispatch) => {
  dispatch(saleShowSuccess({ sale: data }));
};

export const saleStore = (data) => async (dispatch) => {
  dispatch(saleStoreFetch());
  try {
    const { message } = await saleService.saleStore(data);
    dispatch(saleStoreSuccess({ message }));
    dispatch(saleCloseModal());
    dispatch(test(true));
  } catch (e) {
    const { errors, message } = e;
    dispatch(saleStoreFail({ errors, message }));
  }
};

export const saleUpdate = (id, data) => async (dispatch) => {
  dispatch(saleUpdateFetch());
  try {
    const { message, result } = await saleService.saleUpdate(id, data);
    dispatch(saleUpdateSuccess({ message }));
    dispatch(saleCloseModal());
    dispatch(saleIndex());
  } catch (e) {
    const { errors, message } = e;
    dispatch(saleUpdateFail({ errors, message }));
  }
};

export const saleOpenModal = () => (dispatch) => {
  dispatch(saleModalOpen());
};

export const saleCloseModal = () => (dispatch) => {
  dispatch(saleModalClose());
};

export const saleActionStore = () => (dispatch) => {
  dispatch(saleActionTypeStore({ actionType: 'store' }));
};

export const saleActionUpdate = () => (dispatch) => {
  dispatch(saleActionTypeUpdate({ actionType: 'update' }));
};
