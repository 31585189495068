import types from './types';
import categoryModel from './model';
import { paginationModel, errorModel } from './../app/model';

export const INITIAL_STATE = {
  isFetching: false,
  payload: {
    category: { ...categoryModel },
    categories: [],
    pagination: { ...paginationModel },
    message: null,
  },
  errors: { ...errorModel },
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.CATEGORY_DESTROY_FAIL:
      return {
        ...state,
        isFetching: false,
        errors: { ...state.errors, ...action.errors },
      };
    case types.CATEGORY_DESTROY_FETCH:
      return {
        ...state,
        isFetching: true,
      };
    case types.CATEGORY_DESTROY_SUCCESS:
      return {
        ...state,
        isFetching: false,
        payload: { ...state.payload, ...action.payload },
      };
    case types.CATEGORY_INDEX_FAIL:
      return {
        ...state,
        isFetching: false,
        error: { ...state.errors, ...action.payload },
      };
    case types.CATEGORY_INDEX_FETCH:
      return {
        ...state,
        isFetching: true,
      };
    case types.CATEGORY_INDEX_SUCCESS:
      return {
        ...state,
        isFetching: false,
        payload: { ...state.payload, ...action.payload },
      };
    case types.CATEGORY_SHOW_FAIL:
      return {
        ...state,
        isFetching: false,
        errors: { ...state.errors, ...action.errors },
      };
    case types.CATEGORY_SHOW_FETCH:
      return {
        ...state,
        isFetching: true,
      };
    case types.CATEGORY_SHOW_SUCCESS:
      return {
        ...state,
        isFetching: false,
        payload: { ...state.payload, ...action.payload },
      };
    case types.CATEGORY_STORE_FAIL:
      return {
        ...state,
        isFetching: false,
        errors: { ...state.errors, ...action.errors },
      };
    case types.CATEGORY_STORE_FETCH:
      return {
        ...state,
        isFetching: true,
      };
    case types.CATEGORY_STORE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        payload: { ...state.payload, ...action.payload },
      };
    case types.CATEGORY_UPDATE_FAIL:
      return {
        ...state,
        isFetching: false,
        errors: { ...state.errors, ...action.errors },
      };
    case types.CATEGORY_UPDATE_FETCH:
      return {
        ...state,
        isFetching: true,
      };
    case types.CATEGORY_UPDATE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        payload: { ...state.payload, ...action.payload },
      };
    default:
      return state;
  }
};

export default reducer;
