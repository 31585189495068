const types = {
  // FOR CRUD DEPARTURES
  PAYMENT_TYPE_DESTROY_FAIL: 'PAYMENT_TYPE_DESTROY_FAIL',
  PAYMENT_TYPE_DESTROY_FETCH: 'PAYMENT_TYPE_DESTROY_FETCH',
  PAYMENT_TYPE_DESTROY_SUCCESS: 'PAYMENT_TYPE_DESTROY_SUCCESS',
  PAYMENT_TYPE_INDEX_FAIL: 'PAYMENT_TYPE_INDEX_FAIL',
  PAYMENT_TYPE_INDEX_FETCH: 'PAYMENT_TYPE_INDEX_FETCH',
  PAYMENT_TYPE_INDEX_SUCCESS: 'PAYMENT_TYPE_INDEX_SUCCESS',
  PAYMENT_TYPE_SHOW_FAIL: 'PAYMENT_TYPE_SHOW_FAIL',
  PAYMENT_TYPE_SHOW_FETCH: 'PAYMENT_TYPE_SHOW_FETCH',
  PAYMENT_TYPE_SHOW_SUCCESS: 'PAYMENT_TYPE_SHOW_SUCCESS',
  PAYMENT_TYPE_STORE_FAIL: 'PAYMENT_TYPE_STORE_FAIL',
  PAYMENT_TYPE_STORE_FETCH: 'PAYMENT_TYPE_STORE_FETCH',
  PAYMENT_TYPE_STORE_SUCCESS: 'PAYMENT_TYPE_STORE_SUCCESS',
  PAYMENT_TYPE_UPDATE_FAIL: 'PAYMENT_TYPE_UPDATE_FAIL',
  PAYMENT_TYPE_UPDATE_FETCH: 'PAYMENT_TYPE_UPDATE_FETCH',
  PAYMENT_TYPE_UPDATE_SUCCESS: 'PAYMENT_TYPE_UPDATE_SUCCESS',
};

export const typeStates = {
  FAIL: '_FAIL',
  FETCH: '_FETCH',
  SUCCESS: '_SUCCESS',
};

export default types;
