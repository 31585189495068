import PropTypes from 'prop-types';
import api from '../api';

const transportationCategoryService = {
  transportationCategoryDelete(id) {
    return new Promise((resolve, reject) => {
      api
        .delete(`/transportation_categories/${id}`)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },

  transportationCategoryIndex(relation = null, params = null, page = null, items = null) {
    return new Promise((resolve, reject) => {
      api
        .get(
          `/transportation_categories${page ? `/page/${page}` : ''}${
            items ? `/items/${items}` : ''
          }${relation ? `/${relation}` : ''}${params ? `?${params}` : ''}`
        )
        .then((response) => response.data)
        .then(resolve)
        .catch(reject);
    });
  },

  transportationCategoryShow(id) {
    return new Promise((resolve, reject) => {
      api
        .get(`/transportation_categories/${id}`)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },

  transportationCategoryStore(data) {
    return new Promise((resolve, reject) => {
      api
        .post('/transportation_categories', data)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },

  transportationCategoryUpdate(id, data) {
    return new Promise((resolve, reject) => {
      api
        .put(`/transportation_categories/${id}`, data)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },
};

transportationCategoryService.propTypes = {
  transportationCategoryIndex: PropTypes.func.isRequired,
  transportationCategoryShow: PropTypes.func.isRequired,
};

export default transportationCategoryService;
