export const MODULE_NAME = 'Departures';
export const ROUTE = '/departures';
export const RELATIONS = ['meeting_points'];

const Departure = {
  id: null,
  name: null,
  departure_time: null,
  description: null,
  status: null,
  created_id: null,
  created_at: null,
  updated_at: null,
};

export default Departure;
