import PropTypes from 'prop-types';
import api from '../api';

const rateService = {
  rateDelete(id) {
    return new Promise((resolve, reject) => {
      api
        .delete(`/rates/${id}`)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },

  rateIndex(relation = null, params = null, page = null, items = null) {
    return new Promise((resolve, reject) => {
      api
        .get(
          `/rates${page ? `/page/${page}` : ''}${items ? `/items/${items}` : ''}${
            relation ? `/${relation}` : ''
          }${params ? `?${params}` : ''}`
        )
        .then((response) => response.data)
        .then(resolve)
        .catch(reject);
    });
  },

  rateDetails(rate_id) {
    return new Promise((resolve, reject) => {
      api
        .get(`/rates/item_list/${rate_id}`)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },

  rateShow(id) {
    return new Promise((resolve, reject) => {
      api
        .get(`/rates/${id.id}`)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },

  rateStore(data) {
    return new Promise((resolve, reject) => {
      api
        .post('/rates', data)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },

  rateUpdate(id, data) {
    return new Promise((resolve, reject) => {
      api
        .put(`/rates/${id}`, data)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },
};

rateService.propTypes = {
  rateIndex: PropTypes.func.isRequired,
  rateShow: PropTypes.func.isRequired,
};

export default rateService;
