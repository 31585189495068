import departureService from './service';
import {
  departureDeleteFail,
  departureDeleteFetch,
  departureDeleteSuccess,
  departureIndexFail,
  departureIndexFetch,
  departureIndexSuccess,
  departureShowFail,
  departureShowFetch,
  departureShowSuccess,
  departureStoreFail,
  departureStoreFetch,
  departureStoreSuccess,
  departureUpdateFail,
  departureUpdateFetch,
  departureUpdateSuccess,
  departureModalOpen,
  departureModalClose,
  departureActionTypeStore,
  departureActionTypeUpdate,
} from './dispatch';
import appService from '../app/service';
import { RELATIONS } from './model';

export const departureDelete = (id) => async (dispatch) => {
  dispatch(departureDeleteFetch());
  try {
    const { data, message } = await departureService.departureDelete(id);
    dispatch(departureDeleteSuccess({ message: message }));
    dispatch(departurePaginate());
  } catch (e) {
    const { error } = e;
    dispatch(departureDeleteFail({ error }));
  }
};

export const departurePaginate = (forRelation = false, page = null, items = null) => async (
  dispatch,
  getState
) => {
  dispatch(departureIndexFetch());
  try {
    //If is not paginates values, Get default value from state
    page = page ? page : getState().departures.payload.pagination.current_page;
    items = items ? items : getState().departures.payload.pagination.per_page;
    forRelation = forRelation ? appService.appSerialize(RELATIONS) : null;

    const { data, meta } = await departureService.departureIndex(forRelation, null, page, items);
    dispatch(departureIndexSuccess({ departures: data, pagination: meta }));
  } catch (e) {
    dispatch(departureIndexFail(e));
  }
};

export const departureIndex = (forRelation = false, params = null) => async (dispatch) => {
  dispatch(departureIndexFetch());
  try {
    forRelation = forRelation ? appService.appSerialize(RELATIONS) : null;
    let serializedParams = '';
    if (params && typeof params === 'object') {
      Object.keys(params).map((param, key, array) => {
        serializedParams += `${param}=${params[param]}`;
        if (array[key + 1] !== undefined) serializedParams += '&';
      });
    } else serializedParams = params;
    const { data, meta } = await departureService.departureIndex(forRelation, serializedParams);
    dispatch(departureIndexSuccess({ departures: data, pagination: meta }));
  } catch (e) {
    dispatch(departureIndexFail(e));
  }
};

export const departureShowAsync = (id) => async (dispatch) => {
  dispatch(departureShowFetch());
  try {
    const { data } = await departureService.departureShow(id);
    dispatch(departureShowSuccess({ departure: data }));
  } catch (e) {
    const { error } = e;
    dispatch(departureShowFail({ error }));
  }
};

export const departureShow = (data) => (dispatch) => {
  dispatch(departureShowSuccess({ departure: data }));
};

export const departureStore = (data) => async (dispatch) => {
  dispatch(departureStoreFetch());
  try {
    const { message } = await departureService.departureStore(data);
    dispatch(departureStoreSuccess({ message }));
    dispatch(departureCloseModal());
    dispatch(departurePaginate());
  } catch (e) {
    const { errors, message } = e;
    dispatch(departureStoreFail({ errors, message }));
  }
};

export const departureUpdate = (id, data) => async (dispatch) => {
  dispatch(departureUpdateFetch());
  try {
    const { message } = await departureService.departureUpdate(id, data);
    dispatch(departureUpdateSuccess({ message }));
    dispatch(departureCloseModal());
    dispatch(departurePaginate());
  } catch (e) {
    const { errors, message } = e;
    dispatch(departureUpdateFail({ errors, message }));
  }
};

export const departureOpenModal = () => (dispatch) => {
  dispatch(departureModalOpen());
};

export const departureCloseModal = () => (dispatch) => {
  dispatch(departureModalClose());
};

export const departureActionStore = () => (dispatch) => {
  dispatch(departureActionTypeStore({ actionType: 'store' }));
};

export const departureActionUpdate = () => (dispatch) => {
  dispatch(departureActionTypeUpdate({ actionType: 'update' }));
};

export const meetingPointDepartures = (meeting_point_id) => async (dispatch) => {
  dispatch(departureIndexFetch());
  try {
    //If is not paginates values, Get default value from state
    const { data } = await departureService.meetingPointDepartures(meeting_point_id);
    dispatch(departureIndexSuccess({ departures: data }));
  } catch (e) {
    dispatch(departureIndexFail(e));
  }
};
