export const MODULE_NAME = 'Vendors';
export const ROUTE = '/vendors';
export const RELATIONS = ['rates.rate_list'];
const Vendor = {
  id: null,
  name: '',
  initials: null,
  interface_code: null,
  vendor_category_id: null,
  rate_id: null,
  status: null,
  created_id: null,
  created_at: null,
  updated_at: null,
};

export default Vendor;
