import meetingPointTypes from './types';
// DESTROY DISPATCH
export const meetingPointDeleteFail = (payload) => {
  return {
    type: meetingPointTypes.MEETING_POINT_DESTROY_FAIL,
    errors: {
      ...payload,
    },
  };
};
export const meetingPointDeleteFetch = () => ({
  type: meetingPointTypes.MEETING_POINT_DESTROY_FETCH,
});
export const meetingPointDeleteSuccess = (payload) => ({
  type: meetingPointTypes.MEETING_POINT_DESTROY_SUCCESS,
  payload,
});

// INDEX DISPATCH
export const meetingPointIndexFail = (payload) => ({
  type: meetingPointTypes.MEETING_POINT_INDEX_FAIL,
  payload,
});

export const meetingPointIndexFetch = () => ({
  type: meetingPointTypes.MEETING_POINT_INDEX_FETCH,
});

export const meetingPointIndexSuccess = (payload) => ({
  type: meetingPointTypes.MEETING_POINT_INDEX_SUCCESS,
  payload: {
    ...payload,
  },
});

// SHOW DISPATCH
export const meetingPointShowFail = (payload) => ({
  type: meetingPointTypes.MEETING_POINT_SHOW_FAIL,
  errors: {
    ...payload,
  },
});
export const meetingPointShowFetch = () => ({
  type: meetingPointTypes.MEETING_POINT_SHOW_FETCH,
});
export const meetingPointShowSuccess = (payload) => ({
  type: meetingPointTypes.MEETING_POINT_SHOW_SUCCESS,
  payload: {
    ...payload,
  },
});

// STORE DISPATCH
export const meetingPointStoreFail = (payload) => {
  return {
    type: meetingPointTypes.MEETING_POINT_STORE_FAIL,
    errors: {
      ...payload,
    },
  };
};

export const meetingPointStoreFetch = () => ({
  type: meetingPointTypes.MEETING_POINT_STORE_FETCH,
});
export const meetingPointStoreSuccess = (payload) => ({
  type: meetingPointTypes.MEETING_POINT_STORE_SUCCESS,
  payload: {
    ...payload,
  },
});

// UPDATE DISPATCH
export const meetingPointUpdateFail = (payload) => {
  return {
    type: meetingPointTypes.MEETING_POINT_UPDATE_FAIL,
    errors: {
      ...payload,
    },
  };
};
export const meetingPointUpdateFetch = () => ({
  type: meetingPointTypes.MEETING_POINT_UPDATE_FETCH,
});
export const meetingPointUpdateSuccess = (payload) => ({
  type: meetingPointTypes.MEETING_POINT_UPDATE_SUCCESS,
  payload: {
    ...payload,
  },
});
//Modal
export const meetingPointModalOpen = () => ({
  type: meetingPointTypes.MEETING_POINT_MODAL_OPEN,
});
export const meetingPointModalClose = () => ({
  type: meetingPointTypes.MEETING_POINT_MODAL_CLOSE,
});
export const meetingPointActionTypeStore = (payload) => ({
  type: meetingPointTypes.MEETING_POINT_ACTION_TYPE_STORE,
  payload: { ...payload },
});
export const meetingPointTypeUpdate = (payload) => ({
  type: meetingPointTypes.MEETING_POINT_ACTION_TYPE_UPDATE,
  payload: { ...payload },
});
