import React from 'react';
import { animation, theme } from 'react-contexify/lib/index';
import { Item, Menu } from 'react-contexify';
import PropTypes from 'prop-types';

const ContextMenu = ({ canAdd = true, canUpdate = true, canStatus = true }) => {
  return (
    <Menu
      id={'menu_id'}
      animation={animation.fade}
      style={{ zIndex: 99, height: 'auto' }}
      theme={theme.dark}
    >
      {canAdd && (
        <Item
          onClick={({ event, props }) => {
            const { fnModalOpen, fnActionStore } = props;
            fnActionStore();
            fnModalOpen();
          }}
        >
          Add new
        </Item>
      )}

      {canUpdate && (
        <Item
          onClick={({ event, props }) => {
            const { data, fnModalOpen, fnActionUpdate, fnShow } = props;
            fnShow(data);
            fnActionUpdate();
            fnModalOpen();
          }}
        >
          Update
        </Item>
      )}

      {canStatus && (
        <Item
          onClick={({ event, props }) => {
            const { data, fnDelete } = props;
            fnDelete(data.id);
          }}
        >
          Switch State
        </Item>
      )}
    </Menu>
  );
};

ContextMenu.propTypes = {
  canAdd: PropTypes.bool,
  canUpdate: PropTypes.bool,
  canStatus: PropTypes.bool,
};

export default ContextMenu;
