const types = {
  // FOR CRUD USERS
  USER_DESTROY_FAIL: 'USER_DESTROY_FAIL',
  USER_DESTROY_FETCH: 'USER_DESTROY_FETCH',
  USER_DESTROY_SUCCESS: 'USER_DESTROY_SUCCESS',
  USER_INDEX_FAIL: 'USER_INDEX_FAIL',
  USER_INDEX_FETCH: 'USER_INDEX_FETCH',
  USER_INDEX_SUCCESS: 'USER_INDEX_SUCCESS',
  USER_SHOW_FAIL: 'USER_SHOW_FAIL',
  USER_SHOW_FETCH: 'USER_SHOW_FETCH',
  USER_SHOW_SUCCESS: 'USER_SHOW_SUCCESS',
  USER_STORE_FAIL: 'USER_STORE_FAIL',
  USER_STORE_FETCH: 'USER_STORE_FETCH',
  USER_STORE_SUCCESS: 'USER_STORE_SUCCESS',
  USER_UPDATE_FAIL: 'USER_UPDATE_FAIL',
  USER_UPDATE_FETCH: 'USER_UPDATE_FETCH',
  USER_UPDATE_SUCCESS: 'USER_UPDATE_SUCCESS',
};

export const typeStates = {
  FAIL: '_FAIL',
  FETCH: '_FETCH',
  SUCCESS: '_SUCCESS',
};

export default types;
