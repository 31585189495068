import processStatus from './types';
// DESTROY DISPATCH
export const processStatusDeleteFail = (payload) => {
  return {
    type: processStatus.PROCESS_STATUS_DESTROY_FAIL,
    errors: {
      ...payload,
    },
  };
};
export const processStatusDeleteFetch = () => ({
  type: processStatus.PROCESS_STATUS_DESTROY_FETCH,
});
export const processStatusDeleteSuccess = (payload) => ({
  type: processStatus.PROCESS_STATUS_DESTROY_SUCCESS,
  payload: { ...payload },
});

// INDEX DISPATCH
export const processStatusIndexFail = (payload) => ({
  type: processStatus.PROCESS_STATUS_INDEX_FAIL,
  payload,
});

export const processStatusIndexFetch = () => ({
  type: processStatus.PROCESS_STATUS_INDEX_FETCH,
});

export const processStatusIndexSuccess = (payload) => ({
  type: processStatus.PROCESS_STATUS_INDEX_SUCCESS,
  payload: {
    ...payload,
  },
});

// SHOW DISPATCH
export const processStatusShowFail = (payload) => ({
  type: processStatus.PROCESS_STATUS_SHOW_FAIL,
  errors: {
    ...payload,
  },
});
export const processStatusShowFetch = () => ({
  type: processStatus.PROCESS_STATUS_SHOW_FETCH,
});
export const processStatusShowSuccess = (payload) => ({
  type: processStatus.PROCESS_STATUS_SHOW_SUCCESS,
  payload: {
    ...payload,
  },
});

// STORE DISPATCH
export const processStatusStoreFail = (payload) => {
  return {
    type: processStatus.PROCESS_STATUS_STORE_FAIL,
    errors: {
      ...payload,
    },
  };
};

export const processStatusStoreFetch = () => ({
  type: processStatus.PROCESS_STATUS_STORE_FETCH,
});
export const processStatusStoreSuccess = (payload) => ({
  type: processStatus.PROCESS_STATUS_STORE_SUCCESS,
  payload: { ...payload },
});

// UPDATE DISPATCH
export const processStatusUpdateFail = (payload) => {
  return {
    type: processStatus.PROCESS_STATUS_UPDATE_FAIL,
    errors: {
      ...payload,
    },
  };
};
export const processStatusUpdateFetch = () => ({
  type: processStatus.PROCESS_STATUS_UPDATE_FETCH,
});
export const processStatusUpdateSuccess = (payload) => ({
  type: processStatus.PROCESS_STATUS_UPDATE_SUCCESS,
  payload: {
    ...payload,
  },
});
