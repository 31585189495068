import Option from '../option/model';
export const MODULE_NAME = 'Rates';
export const ROUTE = '/rates';

export const RELATIONS = ['vendor', 'rate_list', 'rate_details'];

export const rateModel = {
  id: null,
  rate_list: { id: null, name: null, currency_id: null },
  status: null,
  created_id: null,
  created_at: null,
  updated_at: null,
  updated_id: null,
  rate_details: [],
  vendor_id: null,
  date_start: '',
  date_end: '',
};

export const rateDetailModel = {
  item_id: null,
  rate_id: null,
  optionables: { ...Option },
  commission: 0,
  public_price: null,
  created_at: null,
  updated_at: null,
};
