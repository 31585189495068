import PropTypes from 'prop-types';
import api from '../api';

const cruiseArrivalService = {
  cruiseArrivalDelete(id) {
    return new Promise((resolve, reject) => {
      api
        .delete(`/cruise_arrivals/${id}`)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },

  cruiseArrivalIndex(page = null, items = null) {
    return new Promise((resolve, reject) => {
      api
        .get(`/cruise_arrivals${page ? `/page/${page}` : ''}${items ? `/items/${items}` : ''}`)
        .then((response) => response.data)
        .then(resolve)
        .catch(reject);
    });
  },

  cruiseArrivalShow(id) {
    return new Promise((resolve, reject) => {
      api
        .get(`/cruise_arrivals/${id.id}`)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },

  cruiseArrivalStore(data) {
    return new Promise((resolve, reject) => {
      api
        .post('/cruise_arrivals', data)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },

  cruiseArrivalUpdate(id, data) {
    return new Promise((resolve, reject) => {
      api
        .put(`/cruise_arrivals/${id}`, data)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },
};

cruiseArrivalService.propTypes = {
  cruiseArrivalIndex: PropTypes.func.isRequired,
  cruiseArrivalShow: PropTypes.func.isRequired,
};

export default cruiseArrivalService;
