import shippingHotelCompanyService from './service';
import {
  shippingHotelCompanyDeleteFail,
  shippingHotelCompanyDeleteFetch,
  shippingHotelCompanyDeleteSuccess,
  shippingHotelCompanyIndexFail,
  shippingHotelCompanyIndexFetch,
  shippingHotelCompanyIndexSuccess,
  shippingHotelCompanyShowSuccess,
  shippingHotelCompanyStoreFail,
  shippingHotelCompanyStoreFetch,
  shippingHotelCompanyStoreSuccess,
  shippingHotelCompanyUpdateFail,
  shippingHotelCompanyUpdateFetch,
  shippingHotelCompanyUpdateSuccess,
  shippingHotelCompanyModalOpen,
  shippingHotelCompanyModalClose,
  shippingHotelCompanyActionTypeStore,
  shippingHotelCompanyActionTypeUpdate,
} from './dispatch';


export const shippingHotelCompanyDelete = (id) => async (dispatch) => {
  dispatch(shippingHotelCompanyDeleteFetch());
  try {
    const { data } = await shippingHotelCompanyService.shippingHotelCompanyDelete(id);
    dispatch(shippingHotelCompanyDeleteSuccess({ payload: data }));
  } catch (e) {
    const { error } = e;
    dispatch(shippingHotelCompanyDeleteFail({ error }));
  }
};

export const shippingHotelCompanyIndex = (forRelation = false, page = null, items = null) => async (
  dispatch,
  getState
) => {
  dispatch(shippingHotelCompanyIndexFetch());
  try {
    //If is not paginates values, Get default value from state
    if (!forRelation) {
      page = page ? page : getState().shippingHotelCompanies.payload.pagination.current_page;
      items = items ? items : getState().shippingHotelCompanies.payload.pagination.per_page;
    }
    const { data, meta } = await shippingHotelCompanyService.shippingHotelCompanyIndex(page, items);
    dispatch(shippingHotelCompanyIndexSuccess({ shippingHotelCompanies: data, pagination: meta }));
  } catch (e) {
    dispatch(shippingHotelCompanyIndexFail(e));
  }
};

export const shippingHotelCompanyShow = (data) => async (dispatch) => {
  dispatch(shippingHotelCompanyShowSuccess({ shippingHotelCompany: data }));
};

export const shippingHotelCompanyStore = (data) => async (dispatch) => {
  dispatch(shippingHotelCompanyStoreFetch());
  try {
    const { message } = await shippingHotelCompanyService.shippingHotelCompanyStore(data);
    dispatch(shippingHotelCompanyStoreSuccess({ message }));
    dispatch(shippingHotelCompanyCloseModal());
    dispatch(shippingHotelCompanyIndex());
  } catch (e) {
    const { errors, message } = e;
    dispatch(shippingHotelCompanyStoreFail({ errors, message }));
  }
};

export const shippingHotelCompanyUpdate = (id, data) => async (dispatch) => {
  dispatch(shippingHotelCompanyUpdateFetch());
  try {
    const { message } = await shippingHotelCompanyService.shippingHotelCompanyUpdate(id, data);
    dispatch(shippingHotelCompanyUpdateSuccess({ message }));
    dispatch(shippingHotelCompanyCloseModal());
    dispatch(shippingHotelCompanyIndex());
  } catch (e) {
    const { errors, message } = e;
    dispatch(shippingHotelCompanyUpdateFail({ errors, message }));
  }
};

export const shippingHotelCompanyOpenModal = () => (dispatch) => {
  dispatch(shippingHotelCompanyModalOpen());
};

export const shippingHotelCompanyCloseModal = () => (dispatch) => {
  dispatch(shippingHotelCompanyModalClose());
};

export const shippingHotelCompanyActionStore = () => (dispatch) => {
  dispatch(shippingHotelCompanyActionTypeStore({ actionType: 'store' }));
};

export const shippingHotelCompanyActionUpdate = () => (dispatch) => {
  dispatch(shippingHotelCompanyActionTypeUpdate({ actionType: 'update' }));
};
