import PropTypes from 'prop-types';
import api from '../api';

const sloteService = {
  sloteDelete(id) {
    return new Promise((resolve, reject) => {
      api
        .delete(`/slots/${id}`)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },

  sloteIndex(relation = null, params = null, page = null, items = null) {
    return new Promise((resolve, reject) => {
      api
        .get(
          `/slots${page ? `/page/${page}` : ''}${items ? `/items/${items}` : ''}${
            relation ? `/${relation}` : ''
          }${params ? `?${params}` : ''}`
        )
        .then((response) => response.data)
        .then(resolve)
        .catch(reject);
    });
  },

  sloteDetails(rate_id) {
    return new Promise((resolve, reject) => {
      api
        .get(`/rates/item_list/${rate_id}`)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },

  sloteShow(id) {
    return new Promise((resolve, reject) => {
      api
        .get(`/rates/${id.id}`)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },

  sloteStore(data) {
    return new Promise((resolve, reject) => {
      api
        .post('/slots', data)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },

  ////////////////
  sloteUpdate(id, data) {
    return new Promise((resolve, reject) => {
      api
        .put(`/slots/${id}`, data)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },
  ///////////

  optionSloteIndex(relation = null, params = null, page = null, items = null) {
    return new Promise((resolve, reject) => {
      api
        .get(
          `/options${page ? `/page/${page}` : ''}${items ? `/items/${items}` : ''}${
            relation ? `/${relation}` : ''
          }${params ? `?${params}` : ''}`
        )
        .then((response) => response.data)
        .then(resolve)
        .catch(reject);
    });
  },
};

sloteService.propTypes = {
  sloteIndex: PropTypes.func.isRequired,
  sloteShow: PropTypes.func.isRequired,
};

export default sloteService;
