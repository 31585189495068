const types = {
  // FOR CRUD CRUISE_ARRIVAL
  CRUISE_ARRIVAL_DESTROY_FAIL: 'CRUISE_ARRIVAL_DESTROY_FAIL',
  CRUISE_ARRIVAL_DESTROY_FETCH: 'CRUISE_ARRIVAL_DESTROY_FETCH',
  CRUISE_ARRIVAL_DESTROY_SUCCESS: 'CRUISE_ARRIVAL_DESTROY_SUCCESS',
  CRUISE_ARRIVAL_INDEX_FAIL: 'CRUISE_ARRIVAL_INDEX_FAIL',
  CRUISE_ARRIVAL_INDEX_FETCH: 'CRUISE_ARRIVAL_INDEX_FETCH',
  CRUISE_ARRIVAL_INDEX_SUCCESS: 'CRUISE_ARRIVAL_INDEX_SUCCESS',
  CRUISE_ARRIVAL_SHOW_FAIL: 'CRUISE_ARRIVAL_SHOW_FAIL',
  CRUISE_ARRIVAL_SHOW_FETCH: 'CRUISE_ARRIVAL_SHOW_FETCH',
  CRUISE_ARRIVAL_SHOW_SUCCESS: 'CRUISE_ARRIVAL_SHOW_SUCCESS',
  CRUISE_ARRIVAL_STORE_FAIL: 'CRUISE_ARRIVAL_STORE_FAIL',
  CRUISE_ARRIVAL_STORE_FETCH: 'CRUISE_ARRIVAL_STORE_FETCH',
  CRUISE_ARRIVAL_STORE_SUCCESS: 'CRUISE_ARRIVAL_STORE_SUCCESS',
  CRUISE_ARRIVAL_UPDATE_FAIL: 'CRUISE_ARRIVAL_UPDATE_FAIL',
  CRUISE_ARRIVAL_UPDATE_FETCH: 'CRUISE_ARRIVAL_UPDATE_FETCH',
  CRUISE_ARRIVAL_UPDATE_SUCCESS: 'CRUISE_ARRIVAL_UPDATE_SUCCESS',
  // FOR MODAL ITEM
  CRUISE_ARRIVAL_MODAL_OPEN: ' CRUISE_ARRIVAL_MODAL_OPEN',
  CRUISE_ARRIVAL_MODAL_CLOSE: 'CRUISE_ARRIVAL_MODAL_CLOSE',
  CRUISE_ARRIVAL_ACTION_TYPE_STORE: 'CRUISE_ARRIVAL_ACTION_CREATE_TYPE',
  CRUISE_ARRIVAL_ACTION_TYPE_UPDATE: 'CRUISE_ARRIVAL_ACTION_UPDATE_TYPE',
};

export const typeStates = {
  FAIL: '_FAIL',
  FETCH: '_FETCH',
  SUCCESS: '_SUCCESS',
};

export default types;
