import React, { Component } from 'react';
import { Form, Grid, FormField, Select } from 'semantic-ui-react';
import { sloteModel, sloteDetailModel, sloteDetailOption } from './model';
import { connect } from 'react-redux';
import { sloteStore, sloteUpdate, slotePaginate } from './actions';
import { optionIndex } from '../option/actions';
import _ from 'lodash';
import update from 'immutability-helper';
import PropTypes from 'prop-types';

class SloteForm extends Component {
  constructor(props) {
    super(props);
    const { actionType, slot, fnIndexOptions } = props;
    fnIndexOptions(true);
    this.state = {
      model: actionType === 'store' ? sloteModel : slot,
      relations: {
        options: [],
      },
      errorForm: {},
    };
  }

  componentWillReceiveProps(nextProps) {
    const { options, errors } = nextProps;
    this.setState({ errorForm: { ...errors.errors } });
    this.setState(() => {
      //options
      const enableOption = options.filter((option) => option.status === 1);
      let selectOption = {};
      selectOption.options = enableOption.map((option) =>
        Object.keys(option)
          .filter((key) => {
            return key === 'id' || key === 'name';
          })
          .reduce((obj) => {
            obj = { value: option.code, text: option.name, key: option.id };
            return obj;
          }, {})
      );
      return {
        relations: {
          ...selectOption,
        },
      };
    });
  }
  changeValue = (input) => {
    const { name, value } = input;
    let newValue = {};
    newValue[name] = value;
    this.setState(
      ({ model }) => ({ model: { ...model, ...newValue } }),
      () => {
        this.setState(({ errorForm }) => {
          let newErrors = Object.keys(errorForm)
            .filter((key) => key !== name)
            .reduce((obj, key) => {
              obj[key] = errorForm[key];
              return obj;
            }, {});
          return { errorForm: newErrors };
        });
      }
    );
  };

  changeValueDetail = (input) => {
    let newValue = {};
    newValue[input.name] = input.value;
    //  Props options
    const { options } = this.props;
    const { model } = this.state;
    const { id, name, code } = _.find(options, newValue);
    const newModel = update(model, {
      optionables: { $set: { id, name, code, option_details: [] } },
    });
    this.setState((prevState) => ({ ...prevState, model: newModel }));
  };

  changeOptions = (input) => {
    const { value } = input;
    const { options } = this.props;
    const { model } = this.state;
    const { optionables } = model;
    const { code } = optionables;
    const optionFind = _.find(options, { code: code });
    let selectedOption = value.map((value) => {
      let optionFound = optionFind.option_details.find((option) => option.id === value);
      optionFound = { ...optionFound };
      return optionFound;
    });

    const newModel = update(model, {
      optionables: {
        option_details: { $set: selectedOption },
      },
    });
    this.setState((prevState) => ({ ...prevState, model: newModel }));
  };

  render() {
    const { name, email, vendor_id, website } = this.state.errorForm;
    const { model, relations } = this.state;
    const defaultValues =
      model.optionables.option_details === undefined
        ? false
        : model.optionables.option_details.map((defaultValue) => defaultValue.id);
    const { options } = this.props;
    let optionDetails = [];
    if (model.optionables.code !== undefined && options.length !== 0) {
      const { option_details } = _.find(options, { code: model.optionables.code });
      optionDetails = option_details.map((option) =>
        Object.keys(option)
          .filter((key) => {
            return key === 'id' || key === 'name';
          })
          .reduce((obj) => {
            obj = { value: option.id, text: option.name, key: option.id };
            return obj;
          }, {})
      );
    }
    return (
      <Form
        id={'form'}
        onSubmit={(e) => {
          const { model } = this.state;
          const { actionType, fnSloteStore, fnslotUpdate } = this.props;
          switch (actionType) {
            case 'store':
              fnSloteStore(model);
              break;
            case 'update':
              fnslotUpdate(model.id, model);
              break;
            default:
              break;
          }
        }}
      >
        <Grid stackable columns={1}>
          <Grid.Column>
            <Form.Input
              value={model.code}
              name={'code'}
              autoComplete={'off'}
              onChange={(e, data) => this.changeValue(data)}
              // error={name === undefined ? false : { content: name, pointing: 'below' }}
              label="Code:"
              placeholder="Add Code"
            />
          </Grid.Column>
          <Grid.Column>
            <Form.Input
              value={model.name}
              name={'name'}
              autoComplete={'off'}
              onChange={(e, data) => this.changeValue(data)}
              // error={email === undefined ? false : { content: email, pointing: 'below' }}
              label="Name:"
              placeholder="Name"
            />
          </Grid.Column>
          <Grid.Column>
            <Form.Input
              value={model.capacity}
              name={'capacity'}
              autoComplete={'off'}
              // error={website === undefined ? false : { content: website, pointing: 'below' }}
              // onChange={(e, data) => this.changeValue(data)}
              onChange={(e, data) => {
                const newValue = {};
                const { name, value } = data;
                newValue.name = name;
                newValue.value = value > 0 ? parseInt(value) : 0;
                this.changeValue(newValue);
              }}
              label="Capacity:"
              type={'number'}
              placeholder="Add Capacity"
            />
          </Grid.Column>
          <Grid.Column>
            <Form.Select
              value={model.optionables.code}
              name={'code'}
              label="Options:"
              // error={vendor_id === undefined ? false : { content: vendor_id, pointing: 'below' }}
              placeholder="Add option"
              options={relations.options}
              onChange={(e, data) => this.changeValueDetail(data)}
            />
          </Grid.Column>

          {model.optionables.code && (
            <Grid.Column>
              <FormField
                control={Select}
                multiple
                label={'Option Details'}
                placeholder={'Choose option'}
                options={optionDetails}
                defaultValue={defaultValues}
                onChange={(e, data) => this.changeOptions(data)}
              />
            </Grid.Column>
          )}
        </Grid>
      </Form>
    );
  }
}

const mapStateToProps = (state) => ({
  isFetching: state.slots.isFetching,
  errors: state.slots.errors,
  isFetchingOptions: state.options.isFetching,
  slots: state.slots.payload.slots,
  slot: state.slots.payload.slot,
  options: state.options.payload.options,
  fnIndexOptions: PropTypes.func,
  actionType: state.slots.payload.actionType,
});

const mapDispatchToProps = (dispatch) => ({
  fnSloteStore: (data) => {
    dispatch(sloteStore(data));
  },
  // fnSloteIndex: () => {
  //   dispatch(slotePaginate(true));
  // },
  fnIndexOptions: (forRelation, params) => {
    dispatch(optionIndex(forRelation, params));
  },

  fnslotUpdate: (id, data) => {
    dispatch(sloteUpdate(id, data));
  },
});

SloteForm.propTypes = {
  isFetching: PropTypes.bool,
  errors: PropTypes.PropTypes.exact({
    errors: PropTypes.object,
    message: PropTypes.string,
  }),
  actionType: PropTypes.oneOf(['store', 'update']),
  fnSloteStore: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SloteForm);
