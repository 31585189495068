const types = {
  // FOR CRUD DEPARTURES
  VENDOR_CATEGORY_INDEX_FAIL: 'VENDOR_CATEGORY_INDEX_FAIL',
  VENDOR_CATEGORY_INDEX_FETCH: 'VENDOR_CATEGORY_INDEX_FETCH',
  VENDOR_CATEGORY_INDEX_SUCCESS: 'VVENDOR_CATEGORY_INDEX_SUCCESS',
  VENDOR_CATEGORY_SHOW_FAIL: 'VENDOR_CATEGORY_SHOW_FAIL',
  VENDOR_CATEGORY_SHOW_FETCH: 'VENDOR_CATEGORY_SHOW_FETCH',
  VENDOR_CATEGORY_SHOW_SUCCESS: 'VENDOR_CATEGORY_SHOW_SUCCESS',
};

export const typeStates = {
  FAIL: '_FAIL',
  FETCH: '_FETCH',
  SUCCESS: '_SUCCESS',
};

export default types;
