export const MODULE_NAME = 'Currency';
export const ROUTE = '/currencies';

const Currency = {
  id: null,
  name: null,
  code: null,
  currency_id: null,
  created_at: null,
  updated_at: null,
};

export default Currency;
