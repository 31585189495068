import React, { Component } from 'react';

class Dashboard extends Component {
  render() {
    return (
      <>
        <div className="row">
          <div className="col-md-6 col-xl-4">
            <div className="card mb-3 widget-content bg-midnight-bloom">
              <div className="widget-content-wrapper text-white">
                <div className="widget-content-left">
                  <div className="widget-heading">Total Orders</div>
                  <div className="widget-subheading">Last year expenses</div>
                </div>
                <div className="widget-content-right">
                  <div className="widget-numbers text-white">
                    <span>1896</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-xl-4">
            <div className="card mb-3 widget-content bg-arielle-smile">
              <div className="widget-content-wrapper text-white">
                <div className="widget-content-left">
                  <div className="widget-heading">Clients</div>
                  <div className="widget-subheading">Total Clients Profit</div>
                </div>
                <div className="widget-content-right">
                  <div className="widget-numbers text-white">
                    <span>$ 568</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-xl-4">
            <div className="card mb-3 widget-content bg-grow-early">
              <div className="widget-content-wrapper text-white">
                <div className="widget-content-left">
                  <div className="widget-heading">Followers</div>
                  <div className="widget-subheading">People Interested</div>
                </div>
                <div className="widget-content-right">
                  <div className="widget-numbers text-white">
                    <span>46%</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-xl-none d-lg-block col-md-6 col-xl-4">
            <div className="card mb-3 widget-content bg-premium-dark">
              <div className="widget-content-wrapper text-white">
                <div className="widget-content-left">
                  <div className="widget-heading">Products Sold</div>
                  <div className="widget-subheading">Revenue streams</div>
                </div>
                <div className="widget-content-right">
                  <div className="widget-numbers text-warning">
                    <span>$14M</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-xl-4">
            <div className="card mb-3 widget-content">
              <div className="widget-content-outer">
                <div className="widget-content-wrapper">
                  <div className="widget-content-left">
                    <div className="widget-heading">Total Orders</div>
                    <div className="widget-subheading">Last year expenses</div>
                  </div>
                  <div className="widget-content-right">
                    <div className="widget-numbers text-success">1896</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-xl-4">
            <div className="card mb-3 widget-content">
              <div className="widget-content-outer">
                <div className="widget-content-wrapper">
                  <div className="widget-content-left">
                    <div className="widget-heading">Products Sold</div>
                    <div className="widget-subheading">Revenue streams</div>
                  </div>
                  <div className="widget-content-right">
                    <div className="widget-numbers text-warning">$3M</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-xl-4">
            <div className="card mb-3 widget-content">
              <div className="widget-content-outer">
                <div className="widget-content-wrapper">
                  <div className="widget-content-left">
                    <div className="widget-heading">Followers</div>
                    <div className="widget-subheading">People Interested</div>
                  </div>
                  <div className="widget-content-right">
                    <div className="widget-numbers text-danger">45,9%</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-xl-none d-lg-block col-md-6 col-xl-4">
            <div className="card mb-3 widget-content">
              <div className="widget-content-outer">
                <div className="widget-content-wrapper">
                  <div className="widget-content-left">
                    <div className="widget-heading">Income</div>
                    <div className="widget-subheading">Expected totals</div>
                  </div>
                  <div className="widget-content-right">
                    <div className="widget-numbers text-focus">$147</div>
                  </div>
                </div>
                <div className="widget-progress-wrapper">
                  <div className="progress-bar-sm progress-bar-animated-alt progress">
                    <div
                      className="progress-bar bg-info"
                      role="progressbar"
                      aria-valuenow="54"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{ width: '54%' }}
                    ></div>
                  </div>
                  <div className="progress-sub-label">
                    <div className="sub-label-left">Expenses</div>
                    <div className="sub-label-right">100%</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-lg-3">
            <div className="card-shadow-danger mb-3 widget-chart widget-chart2 text-left card">
              <div className="widget-content">
                <div className="widget-content-outer">
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left pr-2 fsize-1">
                      <div className="widget-numbers mt-0 fsize-3 text-danger">71%</div>
                    </div>
                    <div className="widget-content-right w-100">
                      <div className="progress-bar-xs progress">
                        <div
                          className="progress-bar bg-danger"
                          role="progressbar"
                          aria-valuenow="71"
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style={{ width: '71%' }}
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="widget-content-left fsize-1">
                    <div className="text-muted opacity-6">Income Target</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="card-shadow-success mb-3 widget-chart widget-chart2 text-left card">
              <div className="widget-content">
                <div className="widget-content-outer">
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left pr-2 fsize-1">
                      <div className="widget-numbers mt-0 fsize-3 text-success">54%</div>
                    </div>
                    <div className="widget-content-right w-100">
                      <div className="progress-bar-xs progress">
                        <div
                          className="progress-bar bg-success"
                          role="progressbar"
                          aria-valuenow="54"
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style={{ width: '54%' }}
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="widget-content-left fsize-1">
                    <div className="text-muted opacity-6">Expenses Target</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="card-shadow-warning mb-3 widget-chart widget-chart2 text-left card">
              <div className="widget-content">
                <div className="widget-content-outer">
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left pr-2 fsize-1">
                      <div className="widget-numbers mt-0 fsize-3 text-warning">32%</div>
                    </div>
                    <div className="widget-content-right w-100">
                      <div className="progress-bar-xs progress">
                        <div
                          className="progress-bar bg-warning"
                          role="progressbar"
                          aria-valuenow="32"
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style={{ width: '32%' }}
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="widget-content-left fsize-1">
                    <div className="text-muted opacity-6">Spendings Target</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="card-shadow-info mb-3 widget-chart widget-chart2 text-left card">
              <div className="widget-content">
                <div className="widget-content-outer">
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left pr-2 fsize-1">
                      <div className="widget-numbers mt-0 fsize-3 text-info">89%</div>
                    </div>
                    <div className="widget-content-right w-100">
                      <div className="progress-bar-xs progress">
                        <div
                          className="progress-bar bg-info"
                          role="progressbar"
                          aria-valuenow="89"
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style={{ width: '89%' }}
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="widget-content-left fsize-1">
                    <div className="text-muted opacity-6">Totals Target</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Dashboard;
