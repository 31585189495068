import PointSaleTypes from './types';
import transportationTypes from '../transportation/types';
// DESTROY DISPATCH
export const pointSaleDeleteFail = (payload) => {
  return {
    type: PointSaleTypes.POINT_SALE_DESTROY_FAIL,
    errors: {
      ...payload,
    },
  };
};
export const pointSaleDeleteFetch = () => ({
  type: PointSaleTypes.POINT_SALE_DESTROY_FETCH,
});
export const pointSaleDeleteSuccess = (payload) => ({
  type: PointSaleTypes.POINT_SALE_DESTROY_SUCCESS,
  payload: { ...payload },
});

// INDEX DISPATCH
export const pointSaleIndexFail = (payload) => ({
  type: PointSaleTypes.POINT_SALE_INDEX_FAIL,
  payload,
});

export const pointSaleIndexFetch = () => ({
  type: PointSaleTypes.POINT_SALE_INDEX_FETCH,
});

export const pointSaleIndexSuccess = (payload) => ({
  type: PointSaleTypes.POINT_SALE_INDEX_SUCCESS,
  payload: {
    ...payload,
  },
});

// SHOW DISPATCH
export const pointSaleShowFail = (payload) => ({
  type: PointSaleTypes.POINT_SALE_SHOW_FAIL,
  errors: {
    ...payload,
  },
});
export const pointSaleShowFetch = () => ({
  type: PointSaleTypes.POINT_SALE_SHOW_FETCH,
});
export const pointSaleShowSuccess = (payload) => ({
  type: PointSaleTypes.POINT_SALE_SHOW_SUCCESS,
  payload: {
    ...payload,
  },
});

// STORE DISPATCH
export const pointSaleStoreFail = (payload) => {
  return {
    type: PointSaleTypes.POINT_SALE_STORE_FAIL,
    errors: {
      ...payload,
    },
  };
};

export const pointSaleStoreFetch = () => ({
  type: PointSaleTypes.POINT_SALE_STORE_FETCH,
});
export const pointSaleStoreSuccess = (payload) => ({
  type: PointSaleTypes.POINT_SALE_STORE_SUCCESS,
  payload: { ...payload },
});

// UPDATE DISPATCH
export const pointSaleUpdateFail = (payload) => {
  return {
    type: PointSaleTypes.POINT_SALE_UPDATE_FAIL,
    errors: {
      ...payload,
    },
  };
};
export const pointSaleUpdateFetch = () => ({
  type: PointSaleTypes.POINT_SALE_UPDATE_FETCH,
});
export const pointSaleUpdateSuccess = (payload) => ({
  type: PointSaleTypes.POINT_SALE_UPDATE_SUCCESS,
  payload: {
    ...payload,
  },
});
//MODALS DISPATCH
export const pointSaleModalOpen = () => ({
  type: PointSaleTypes.POINT_SALE_MODAL_OPEN,
});
export const pointSaleModalClose = () => ({
  type: PointSaleTypes.POINT_SALE_MODAL_CLOSE,
});

//ACTION TYPES DISPATCH
export const pointSaleActionTypeStore = (payload) => ({
  type: PointSaleTypes.POINT_SALE_ACTION_TYPE_STORE,
  payload: { ...payload },
});
export const pointSaleActionTypeUpdate = (payload) => ({
  type: PointSaleTypes.POINT_SALE_ACTION_TYPE_UPDATE,
  payload: { ...payload },
});
