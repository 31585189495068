import PropTypes from 'prop-types';
import api from '../api';

const meetingPointService = {
  meetingPointDelete(id) {
    return new Promise((resolve, reject) => {
      api
        .delete(`/meeting_points/${id}`)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },

  meetingPointIndex(relation = null, params = null, page = null, items = null) {
    return new Promise((resolve, reject) => {
      api
        .get(
          `/meeting_points${page ? `/page/${page}` : ''}${items ? `/items/${items}` : ''}${
            relation ? `/${relation}` : ''
          }${params ? `?${params}` : ''}`
        )
        .then((response) => response.data)
        .then(resolve)
        .catch(reject);
    });
  },

  meetingPointShow(id) {
    return new Promise((resolve, reject) => {
      api
        .get(`/meeting_points/${id}`)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },

  meetingPointStore(data) {
    return new Promise((resolve, reject) => {
      api
        .post('/meeting_points', data)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },

  meetingPointUpdate(id, data) {
    return new Promise((resolve, reject) => {
      api
        .put(`/meeting_points/${id}`, data)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },
  itemMeetingPoints(item_id) {
    return new Promise((resolve, reject) => {
      api
        .get(`/items/meeting_points/${item_id}`)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },
};

export default meetingPointService;
