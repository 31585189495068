import slotTypes from './types';
// DESTROY DISPATCH
export const sloteDeleteFail = (payload) => {
  return {
    type: slotTypes.SLOTE_DESTROY_FAIL,
    errors: {
      ...payload,
    },
  };
};
export const sloteDeleteFetch = () => ({
  type: slotTypes.SLOTE_DESTROY_FETCH,
});
export const sloteDeleteSuccess = (payload) => ({
  type: slotTypes.SLOTE_DESTROY_SUCCESS,
  payload,
});

// INDEX DISPATCH
export const sloteIndexFail = (payload) => ({
  type: slotTypes.SLOTE_INDEX_FAIL,
  payload,
});

export const sloteIndexFetch = () => ({
  type: slotTypes.SLOTE_INDEX_FETCH,
});

export const sloteIndexSuccess = (payload) => ({
  type: slotTypes.SLOTE_INDEX_SUCCESS,
  payload: {
    ...payload,
  },
});

// SHOW DISPATCH
export const sloteShowFail = (payload) => ({
  type: slotTypes.SLOTE_SHOW_FAIL,
  errors: {
    ...payload,
  },
});
export const sloteShowFetch = () => ({
  type: slotTypes.SLOTE_SHOW_FETCH,
});
export const sloteShowSuccess = (payload) => ({
  type: slotTypes.SLOTE_SHOW_SUCCESS,
  payload: {
    ...payload,
  },
});

// STORE DISPATCH
export const sloteStoreFail = (payload) => {
  return {
    type: slotTypes.SLOTE_STORE_FAIL,
    errors: {
      ...payload,
    },
  };
};

export const sloteStoreFetch = () => ({
  type: slotTypes.SLOTE_STORE_FETCH,
});
export const sloteStoreSuccess = (payload) => ({
  type: slotTypes.SLOTE_STORE_SUCCESS,
  payload,
});

// UPDATE DISPATCH
export const sloteUpdateFail = (payload) => {
  return {
    type: slotTypes.SLOTE_UPDATE_FAIL,
    errors: {
      ...payload,
    },
  };
};
export const sloteUpdateFetch = () => ({
  type: slotTypes.SLOTE_UPDATE_FETCH,
});
export const sloteUpdateSuccess = (payload) => ({
  type: slotTypes.SLOTE_UPDATE_SUCCESS,
  payload: {
    ...payload,
  },
});

//Modal
export const sloteModalOpen = () => ({
  type: slotTypes.SLOTE_MODAL_OPEN,
});
export const sloteModalClose = () => ({
  type: slotTypes.SLOTE_MODAL_CLOSE,
});
export const sloteActionTypeStore = (payload) => ({
  type: slotTypes.SLOTE_ACTION_TYPE_STORE,
  payload: { ...payload },
});
export const sloteActionTypeUpdate = (payload) => ({
  type: slotTypes.SLOTE_ACTION_TYPE_UPDATE,
  payload: { ...payload },
});
