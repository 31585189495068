export const MODULE_NAME = 'Transportations';
export const ROUTE = '/transportations';
export const RELATIONS = ["slots"];

const Transportation = {
  id: null,
  name: null,
  status: null,
  created_id: null,
  created_at: null,
  updated_at: null,
  updated_id: null,
  transportation_category_id: null,
  slots: [],
};

export default Transportation;
