import paymentMethodService from './service';
import {
  paymentMethodDeleteFail,
  paymentMethodDeleteFetch,
  paymentMethodDeleteSuccess,
  paymentMethodIndexFail,
  paymentMethodIndexFetch,
  paymentMethodIndexSuccess,
  paymentMethodShowFail,
  paymentMethodShowFetch,
  paymentMethodShowSuccess,
  paymentMethodStoreFail,
  paymentMethodStoreFetch,
  paymentMethodStoreSuccess,
  paymentMethodUpdateFail,
  paymentMethodUpdateFetch,
  paymentMethodUpdateSuccess,
} from './dispatch';

export const paymentMethodDelete = (id) => async (dispatch) => {
  dispatch(paymentMethodDeleteFetch());
  try {
    const { data } = await paymentMethodService.paymentMethodDelete(id);
    dispatch(paymentMethodDeleteSuccess({ payload: data }));
  } catch (e) {
    const { error } = e;
    dispatch(paymentMethodDeleteFail({ error }));
  }
};

export const paymentMethodIndex = (forRelation = false, page = null, items = null) => async (
  dispatch,
  getState
) => {
  dispatch(paymentMethodIndexFetch());
  try {
    if (!forRelation) {
      //If is not paginates values, Get default value from state
      page = page ? page : getState().paymentMethods.payload.pagination.current_page;
      items = items ? items : getState().paymentMethods.payload.pagination.per_page;
    }

    const { data, meta } = await paymentMethodService.paymentMethodIndex(page, items);
    dispatch(paymentMethodIndexSuccess({ paymentMethods: data, pagination: meta }));
  } catch (e) {
    dispatch(paymentMethodIndexFail(e));
  }
};

export const paymentMethodShow = (id) => async (dispatch) => {
  dispatch(paymentMethodShowFetch());
  try {
    const { data } = await paymentMethodService.paymentMethodShow(id);
    dispatch(paymentMethodShowSuccess({ paymentMethod: data }));
  } catch (e) {
    const { error } = e;
    dispatch(paymentMethodShowFail({ error }));
  }
};

export const paymentMethodStore = (data) => async (dispatch) => {
  dispatch(paymentMethodStoreFetch());
  try {
    const { message } = await paymentMethodService.paymentMethodStore(data);
    dispatch(paymentMethodStoreSuccess(message));
  } catch (e) {
    const { errors, message } = e;
    dispatch(paymentMethodStoreFail({ errors, message }));
  }
};

export const paymentMethodUpdate = (id, data) => async (dispatch) => {
  dispatch(paymentMethodUpdateFetch());
  try {
    const { message } = await paymentMethodService.paymentMethodUpdate(id, data);
    dispatch(paymentMethodUpdateSuccess({ message }));
  } catch (e) {
    const { errors, message } = e;
    dispatch(paymentMethodUpdateFail({ errors, message }));
  }
};
