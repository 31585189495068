import React, { Component } from 'react';
import { Button, Header, Icon } from 'semantic-ui-react';
import NavBar from '../common/Navbar';
import SideBar from '../common/SideBar';
import ReduxToastr from 'react-redux-toastr';

class Layout extends Component {
  constructor(props) {
    super(props);
    console.log('constructor layout');
  }

  componentWillUnmount() {
    console.log('bye :(');
  }

  shouldComponentUpdate(prevProps) {
    /*
    Con esto evitamos que el maldito layout ejecute el render cada vez solo porque el maldito key de cada ruta cambia,
    Esto causa conflicto porque los hijos se vuelven a regenerar.
    */
    const { key } = this.props.location;
    return prevProps.location.key === key;
  }

  render() {
    const { children, location } = this.props;
    return (
      <div className={'app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header'}>
        <ReduxToastr />
        <NavBar />
        <div className={'app-main'}>
          <SideBar {...location} />
          <div className={'app-main__outer'}>
            <div className={'app-main__inner'}>{children}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default Layout;
