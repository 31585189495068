import currencyTypes from './types';
// DESTROY DISPATCH
export const currencyDeleteFail = (payload) => {
  return {
    type: currencyTypes.CURRENCY_DESTROY_FAIL,
    errors: {
      ...payload,
    },
  };
};
export const currencyDeleteFetch = () => ({
  type: currencyTypes.CURRENCY_DESTROY_FETCH,
});
export const currencyDeleteSuccess = (payload) => ({
  type: currencyTypes.CURRENCY_DESTROY_SUCCESS,
  payload,
});

// INDEX DISPATCH
export const currencyIndexFail = (payload) => ({
  type: currencyTypes.CURRENCY_INDEX_FAIL,
  payload,
});

export const currencyIndexFetch = () => ({
  type: currencyTypes.CURRENCY_INDEX_FETCH,
});

export const currencyIndexSuccess = (payload) => ({
  type: currencyTypes.CURRENCY_INDEX_SUCCESS,
  payload: {
    ...payload,
  },
});

// SHOW DISPATCH
export const currencyShowFail = (payload) => ({
  type: currencyTypes.CURRENCY_SHOW_FAIL,
  errors: {
    ...payload,
  },
});
export const currencyShowFetch = () => ({
  type: currencyTypes.CURRENCY_SHOW_FETCH,
});
export const currencyShowSuccess = (payload) => ({
  type: currencyTypes.CURRENCY_SHOW_SUCCESS,
  payload: {
    ...payload,
  },
});

// STORE DISPATCH
export const currencyStoreFail = (payload) => {
  return {
    type: currencyTypes.CURRENCY_STORE_FAIL,
    errors: {
      ...payload,
    },
  };
};

export const currencyStoreFetch = () => ({
  type: currencyTypes.CURRENCY_STORE_FETCH,
});
export const currencyStoreSuccess = (payload) => ({
  type: currencyTypes.CURRENCY_STORE_SUCCESS,
  payload,
});

// UPDATE DISPATCH
export const currencyUpdateFail = (payload) => {
  return {
    type: currencyTypes.CURRENCY_UPDATE_FAIL,
    errors: {
      ...payload,
    },
  };
};
export const currencyUpdateFetch = () => ({
  type: currencyTypes.CURRENCY_UPDATE_FETCH,
});
export const currencyUpdateSuccess = (payload) => ({
  type: currencyTypes.CURRENCY_UPDATE_SUCCESS,
  payload: {
    ...payload,
  },
});
