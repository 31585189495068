import PropTypes from 'prop-types';
import api from '../api';

const saleService = {
  saleDelete(id) {
    return new Promise((resolve, reject) => {
      api
        .delete(`/items/${id}`)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },

  saleIndex(relation = null, params = null, page = null, items = null) {
    return new Promise((resolve, reject) => {
      api
        .get(
          `/agencies${page ? `/page/${page}` : ''}${items ? `/items/${items}` : ''}${
            relation ? `/${relation}` : ''
          }${params ? `?${params}` : ''}`
        )
        .then((response) => response.data)
        .then(resolve)
        .catch(reject);
    });
  },

  saleShow(id) {
    return new Promise((resolve, reject) => {
      api
        .get(`/agencies/${id.id}`)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },

  saleStore(data) {
    return new Promise((resolve, reject) => {
      api
        .post('/agencies', data)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },

  saleUpdate(id, data) {
    return new Promise((resolve, reject) => {
      api
        .put(`/agencies/${id}`, data)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },
};

saleService.propTypes = {
  saleIndex: PropTypes.func.isRequired,
  saleShow: PropTypes.func.isRequired,
};

export default saleService;
