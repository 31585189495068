import types from './types';
import optionModel from './model';
import { errorModel } from '../app/model';

export const INITIAL_STATE = {
  isFetching: false,
  payload: {
    option: { ...optionModel },
    options: [],
    message: null,
  },
  errors: { ...errorModel },
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.OPTION_INDEX_FAIL:
      return {
        ...state,
        isFetching: false,
        errors: { ...state.errors, ...action.errors },
      };
    case types.OPTION_INDEX_FETCH:
      return {
        ...state,
        isFetching: true,
      };
    case types.OPTION_INDEX_SUCCESS:
      return {
        ...state,
        isFetching: false,
        payload: { ...state.payload, ...action.payload },
      };
    default:
      return state;
  }
};

export default reducer;
