import React, { Component, Fragment } from 'react';
import { Message, Grid } from 'semantic-ui-react';
import style from './loginLayout.module.css';
import ReduxToastr from 'react-redux-toastr';

class LoginLayout extends Component {
  render() {
    const { children, history } = this.props;
    return (
      <Fragment>
        <ReduxToastr />
        <Grid textAlign="center" verticalAlign="middle">
          <Grid.Column className={style.grid_column}>
            {React.cloneElement(children, { history: history })}
            <Message>
              <a href="">Forgot Password</a>
            </Message>
          </Grid.Column>
        </Grid>
      </Fragment>
    );
  }
}

export default LoginLayout;
