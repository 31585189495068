import transportationTypes from './types';
import departureTypes from '../departure/types';
// DESTROY DISPATCH
export const transportationDeleteFail = (payload) => {
  return {
    type: transportationTypes.TRANSPORTATION_DESTROY_FAIL,
    errors: {
      ...payload,
    },
  };
};
export const transportationDeleteFetch = () => ({
  type: transportationTypes.TRANSPORTATION_DESTROY_FETCH,
});
export const transportationDeleteSuccess = (payload) => ({
  type: transportationTypes.TRANSPORTATION_DESTROY_SUCCESS,
  payload,
});

// INDEX DISPATCH
export const transportationIndexFail = (payload) => ({
  type: transportationTypes.TRANSPORTATION_INDEX_FAIL,
  payload,
});

export const transportationIndexFetch = () => ({
  type: transportationTypes.TRANSPORTATION_INDEX_FETCH,
});

export const transportationIndexSuccess = (payload) => ({
  type: transportationTypes.TRANSPORTATION_INDEX_SUCCESS,
  payload: {
    ...payload,
  },
});

// SHOW DISPATCH
export const transportationShowFail = (payload) => ({
  type: transportationTypes.TRANSPORTATION_SHOW_FAIL,
  errors: {
    ...payload,
  },
});
export const transportationShowFetch = () => ({
  type: transportationTypes.TRANSPORTATION_SHOW_FETCH,
});
export const transportationShowSuccess = (payload) => ({
  type: transportationTypes.TRANSPORTATION_SHOW_SUCCESS,
  payload: {
    ...payload,
  },
});

// STORE DISPATCH
export const transportationStoreFail = (payload) => {
  return {
    type: transportationTypes.TRANSPORTATION_STORE_FAIL,
    errors: {
      ...payload,
    },
  };
};

export const transportationStoreFetch = () => ({
  type: transportationTypes.TRANSPORTATION_STORE_FETCH,
});
export const transportationStoreSuccess = (payload) => ({
  type: transportationTypes.TRANSPORTATION_STORE_SUCCESS,
  payload,
});

// UPDATE DISPATCH
export const transportationUpdateFail = (payload) => {
  return {
    type: transportationTypes.TRANSPORTATION_UPDATE_FAIL,
    errors: {
      ...payload,
    },
  };
};
export const transportationUpdateFetch = () => ({
  type: transportationTypes.TRANSPORTATION_UPDATE_FETCH,
});
export const transportationUpdateSuccess = (payload) => ({
  type: transportationTypes.TRANSPORTATION_UPDATE_SUCCESS,
  payload: {
    ...payload,
  },
});
//MODALS DISPATCH
export const transportationModalOpen = () => ({
  type: transportationTypes.TRANSPORTATION_MODAL_OPEN,
});
export const transportationModalClose = () => ({
  type: transportationTypes.TRANSPORTATION_MODAL_CLOSE,
});

//ACTION TYPES DISPATCH
export const transportationActionTypeStore = (payload) => ({
  type: transportationTypes.TRANSPORTATION_ACTION_TYPE_STORE,
  payload: { ...payload },
});
export const transportationActionTypeUpdate = (payload) => ({
  type: transportationTypes.TRANSPORTATION_ACTION_TYPE_UPDATE,
  payload: { ...payload },
});
