import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Dimmer, Loader, Table, Button } from 'semantic-ui-react';
import { Menu, Item, contextMenu, animation, theme } from 'react-contexify';
import {
  shippingHotelCompanyDelete,
  shippingHotelCompanyIndex,
  shippingHotelCompanyShow,
  shippingHotelCompanyStore,
  shippingHotelCompanyUpdate,
  shippingHotelCompanyActionStore,
  shippingHotelCompanyActionUpdate,
  shippingHotelCompanyCloseModal,
  shippingHotelCompanyOpenModal,
} from './actions';
import { SHIPPING_HOTEL_COMPANY_MODULE_NAME } from '../constants';
import {
  TitleHead,
  ContextMenu,
  Pagination,
  Modal as ModalShippingHotelCompany,
} from '../app/common';
import PropTypes from 'prop-types';
import ShippingHotelCompanyForm from './ShippingHotelCompanyForm';

class ShippingHotelCompany extends Component {
  state = {
    isModalOpen: false,
  };

  constructor(props) {
    super(props);
    console.log(this.props);
  }

  componentDidMount() {
    this.props.fnShippingHotelCompanyIndex();
  }

  toRender = {
    id: {
      type: 'number',
      visible: false,
      name: '',
    },
    name: {
      type: 'string',
      visible: true,
      name: 'Name',
    },
    status: {
      type: 'string',
      visible: true,
      name: 'Status',
    },
  };

  renderTable = (data) => {
    const allowed = [];
    Object.keys(this.toRender).map((k) => {
      allowed.push(k);
    });

    return data.map((ag) =>
      Object.keys(ag)
        .filter((key) => allowed.includes(key))
        .reduce((obj, key) => {
          // console.log()
          obj[key] = { ...{ value: ag[key] }, ...this.toRender[key] };
          return obj;
        }, {})
    );
  };
  openContextMenu = (e, data) => {
    const {
      fnShippingHotelCompanyDelete,
      fnShippingHotelCompanyModalOpen,
      fnShippingHotelCompanyActionStore,
      fnShippingHotelCompanyActionUpdate,
      fnShippingHotelCompanyShow,
    } = this.props;
    e.preventDefault();
    contextMenu.show({
      id: 'menu_id',
      event: e,
      props: {
        data: { ...data },
        fnDelete: fnShippingHotelCompanyDelete,
        fnModalOpen: fnShippingHotelCompanyModalOpen,
        fnActionStore: fnShippingHotelCompanyActionStore,
        fnActionUpdate: fnShippingHotelCompanyActionUpdate,
        fnShow: fnShippingHotelCompanyShow,
      },
    });
  };
  render() {
    const {
      payload,
      isFetching,
      fnShippingHotelCompanyIndex,
      isModalOpen,
      fnShippingHotelCompanyModalClose,
    } = this.props;
    const { shippingHotelCompanies, pagination, shippingHotelCompany } = payload;
    return (
      <Fragment>
        <TitleHead title={SHIPPING_HOTEL_COMPANY_MODULE_NAME} />
        <div className={'row'}>
          <div className={'col-md-12'}>
            <ModalShippingHotelCompany
              isOpen={isModalOpen}
              actionType={payload.actionType}
              modalTitle={SHIPPING_HOTEL_COMPANY_MODULE_NAME}
              size={'small'}
              isFetchingButton={isFetching && isModalOpen}
              onClose={fnShippingHotelCompanyModalClose}
            >
              <ShippingHotelCompanyForm />
            </ModalShippingHotelCompany>
            <div className={'main-card mb-3 card'}>
              <div className={'card-body'}>
                <h5 className={'card-title'}>
                  {SHIPPING_HOTEL_COMPANY_MODULE_NAME.substr(
                    0,
                    SHIPPING_HOTEL_COMPANY_MODULE_NAME.length - 1
                  )}{' '}
                  Lists
                </h5>
                <div className={'row'}>
                  <Pagination
                    title={`Show ${SHIPPING_HOTEL_COMPANY_MODULE_NAME.toLowerCase()} by`}
                    {...pagination}
                    fnIndex={fnShippingHotelCompanyIndex}
                  />
                  <div className="col-md-12">
                    <Dimmer.Dimmable blurring={true} dimmed={isFetching}>
                      <Dimmer active={isFetching} inverted={true}>
                        <Loader>Loading</Loader>
                      </Dimmer>
                      <Table compact={true} celled={true}>
                        <Table.Header>
                          <Table.Row>
                            {Object.keys(this.toRender).map((key, index) => {
                              return index === 0 ? (
                                <Fragment key={1}>
                                  <Table.HeaderCell>#</Table.HeaderCell>
                                  {this.toRender[key].visible && (
                                    <Table.HeaderCell>{this.toRender[key].name}</Table.HeaderCell>
                                  )}
                                </Fragment>
                              ) : (
                                this.toRender[key].visible && (
                                  <Table.HeaderCell>{this.toRender[key].name}</Table.HeaderCell>
                                )
                              );
                            })}
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {this.renderTable(shippingHotelCompanies).map((data, index) => {
                            const { per_page, to, total } = pagination;
                            const row = (total > per_page ? to - per_page : to - total) + index + 1;
                            const rows = [];
                            //Pushing Rows number
                            if (data.id) {
                              rows.push(<Table.Cell>{row}</Table.Cell>);
                            }
                            // Push Other cells
                            Object.keys(data).filter((key) => {
                              if (data[key].visible) {
                                rows.push(<Table.Cell>{data[key].value}</Table.Cell>);
                              }
                            });
                            return (
                              <Table.Row
                                onContextMenu={(e) =>
                                  this.openContextMenu(e, shippingHotelCompanies[index])
                                }
                              >
                                {rows}
                              </Table.Row>
                            );
                          })}
                        </Table.Body>
                      </Table>
                      <ContextMenu />
                    </Dimmer.Dimmable>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*{user.id !== null ? alert(`Usuario Cargado ${user.name}`) : ''}*/}
        </div>
      </Fragment>
    );
  }
}

const mapStateProps = (state, ownProps = {}) => ({
  isFetching: state.shippingHotelCompanies.isFetching,
  payload: state.shippingHotelCompanies.payload,
  errors: state.shippingHotelCompanies.errors,
  isModalOpen: state.shippingHotelCompanies.isModalOpen,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  fnShippingHotelCompanyIndex: (forRelation, page, items) => {
    dispatch(shippingHotelCompanyIndex(forRelation, page, items));
  },
  fnShippingHotelCompanyDelete: (id) => {
    dispatch(shippingHotelCompanyDelete(id));
  },
  fnShippingHotelCompanyShow: (id) => {
    dispatch(shippingHotelCompanyShow(id));
  },
  fnShippingHotelCompanyStore: (data) => {
    dispatch(shippingHotelCompanyStore(data));
  },
  fnShippingHotelCompanyUpdate: (id, data) => {
    dispatch(shippingHotelCompanyUpdate(id, data));
  },
  fnShippingHotelCompanyModalOpen: () => {
    dispatch(shippingHotelCompanyOpenModal());
  },
  fnShippingHotelCompanyModalClose: () => {
    dispatch(shippingHotelCompanyCloseModal());
  },
  fnShippingHotelCompanyActionStore: () => {
    dispatch(shippingHotelCompanyActionStore());
  },
  fnShippingHotelCompanyActionUpdate: () => {
    dispatch(shippingHotelCompanyActionUpdate());
  },
});

ShippingHotelCompany.propTypes = {
  fnShippingHotelCompanyDelete: PropTypes.func,
  fnShippingHotelCompanyShow: PropTypes.func,
  fnShippingHotelCompanyModalOpen: PropTypes.func,
  fnShippingHotelCompanyModalClose: PropTypes.func,
  fnShippingHotelCompanyActionStore: PropTypes.func,
  fnShippingHotelCompanyActionUpdate: PropTypes.func,
  isFetching: PropTypes.bool,
  isModalOpen: PropTypes.bool,
  payload: PropTypes.exact({
    shippingHotelCompany: PropTypes,
    shippingHotelCompanies: PropTypes,
    pagination: PropTypes,
    message: PropTypes.string,
    actionType: PropTypes.oneOf(['store', 'update']),
  }),
  errors: PropTypes.object,
};

export default connect(
  mapStateProps,
  mapDispatchToProps
)(ShippingHotelCompany);
