import api from '../api';

const userService = {
  userDelete(id) {
    return new Promise((resolve, reject) => {
      api
        .delete(`/users/${id}`)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },

  userIndex(page = null, items = null) {
    return new Promise((resolve, reject) => {
      api
        .get(`/users${page ? `/page/${page}` : ''}${items ? `/items/${items}` : ''}`)
        .then((response) => response.data)
        .then(resolve)
        .catch(reject);
    });
  },
  userShow(id) {
    return new Promise((resolve, reject) => {
      api
        .get(`/users/${id}`)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },

  userStore(data) {
    return new Promise((resolve, reject) => {
      api
        .post('/user', data)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },

  userUpdate(id, data) {
    return new Promise((resolve, reject) => {
      api
        .put(`/users/${id}`, data)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },
};

export default userService;
