export const paginationModel = {
  current_page: 1,
  from: null,
  last_page: null,
  per_page: 10,
  to: null,
  total: null,
};

export const errorModel = {
  message: null,
  errors: {},
};
