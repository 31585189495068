import vendorCategoryService from './service';
import {
  vendorCategoryIndexFail,
  vendorCategoryIndexFetch,
  vendorCategoryIndexSuccess,
  vendorCategoryShowFail,
  vendorCategoryShowFetch,
  vendorCategoryShowSuccess,
} from './dispatch';

export const vendorCategoryIndex = (forRelation = false, page = null, items = null) => async (
  dispatch,
  getState
) => {
  dispatch(vendorCategoryIndexFetch());
  try {
    //If is not paginates values, Get default value from state
    if (!forRelation) {
      page = page ? page : getState().vendorCategories.payload.pagination.current_page;
      items = items ? items : getState().vendorCategories.payload.pagination.per_page;
    }
    const { data, meta } = await vendorCategoryService.vendorCategoryIndex(page, items);
    dispatch(vendorCategoryIndexSuccess({ vendorCategories: data, pagination: meta }));
  } catch (e) {
    dispatch(vendorCategoryIndexFail(e));
  }
};

export const vendorCategoryShow = (id) => async (dispatch) => {
  dispatch(vendorCategoryShowFetch());
  try {
    const { data } = await vendorCategoryService.vendorShow(id);
    dispatch(vendorCategoryShowSuccess({ vendor: data }));
  } catch (e) {
    const { error } = e;
    dispatch(vendorCategoryShowFail({ error }));
  }
};

