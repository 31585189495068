import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dimmer, Loader, Table } from 'semantic-ui-react';
import { contextMenu } from 'react-contexify';
import {
  departureDelete,
  departureShow,
  departureOpenModal,
  departureCloseModal,
  departureActionStore,
  departureActionUpdate,
  departurePaginate,
} from './actions';
import { DEPARTURE_MODULE_NAME } from '../constants';
import { TitleHead, ContextMenu, Pagination, Modal as ModalDeparture } from '../app/common';
import DepartureForm from './DepartureForm';

class Departure extends Component {
  componentDidMount() {
    this.props.fnDeparturePaginate();
  }

  toRender = {
    id: {
      type: 'number',
      visible: false,
      name: '',
    },
    name: {
      type: 'string',
      visible: true,
      name: 'name',
    },
    departure_time: {
      type: 'number',
      visible: true,
      name: 'Departure Time',
    },
    description: {
      type: 'string',
      visible: false,
      name: 'Description',
    },
    status: {
      type: 'string',
      visible: true,
      name: 'Status',
    },
  };

  renderTable = (data) => {
    const allowed = [];
    Object.keys(this.toRender).map((k) => {
      allowed.push(k);
    });

    return data.map((ag) =>
      Object.keys(ag)
        .filter((key) => allowed.includes(key))
        .reduce((obj, key) => {
          obj[key] = { ...{ value: ag[key] }, ...this.toRender[key] };
          return obj;
        }, {})
    );
  };

  openContextMenu = (e, data) => {
    const {
      fnDepartureDelete,
      fnDepartureModalOpen,
      fnDepartureActionStore,
      fnDepartureActionUpdate,
      fnDepartureShow,
    } = this.props;
    e.preventDefault();
    contextMenu.show({
      id: 'menu_id',
      event: e,
      props: {
        data: { ...data },
        fnDelete: fnDepartureDelete,
        fnModalOpen: fnDepartureModalOpen,
        fnActionStore: fnDepartureActionStore,
        fnActionUpdate: fnDepartureActionUpdate,
        fnShow: fnDepartureShow,
      },
    });
  };

  render() {
    const {
      payload,
      isFetching,
      fnDeparturePaginate,
      isModalOpen,
      fnDepartureModalClose,
    } = this.props;
    const { departures, pagination } = payload;
    return (
      <Fragment>
        <TitleHead title={DEPARTURE_MODULE_NAME} />
        <div className={'row'}>
          <div className={'col-md-12'}>
            <ModalDeparture
              isOpen={isModalOpen}
              actionType={payload.actionType}
              modalTitle={DEPARTURE_MODULE_NAME}
              isFetchingButton={isFetching && isModalOpen}
              onClose={fnDepartureModalClose}
            >
              <DepartureForm />
            </ModalDeparture>
            <div className={'main-card mb-3 card'}>
              <div className={'card-body'}>
                <h5 className={'card-title'}>
                  {/*Name in singular :v*/}
                  {DEPARTURE_MODULE_NAME.substr(0, DEPARTURE_MODULE_NAME.length - 1)} Lists
                </h5>
                <div className={'row'}>
                  <Pagination
                    title={`Show ${DEPARTURE_MODULE_NAME.toLowerCase()} by`}
                    {...pagination}
                    fnIndex={fnDeparturePaginate}
                  />
                  <div className="col-md-12">
                    <Dimmer.Dimmable blurring={true} dimmed={isFetching}>
                      <Dimmer active={isFetching} inverted={true}>
                        <Loader>Loading</Loader>
                      </Dimmer>
                      <Table compact={true} celled={true}>
                        <Table.Header>
                          <Table.Row>
                            {Object.keys(this.toRender).map((key, index) => {
                              return index === 0 ? (
                                <Fragment key={1}>
                                  <Table.HeaderCell>#</Table.HeaderCell>
                                  {this.toRender[key].visible && (
                                    <Table.HeaderCell>{this.toRender[key].name}</Table.HeaderCell>
                                  )}
                                </Fragment>
                              ) : (
                                this.toRender[key].visible && (
                                  <Table.HeaderCell>{this.toRender[key].name}</Table.HeaderCell>
                                )
                              );
                            })}
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {this.renderTable(departures).map((data, index) => {
                            const { per_page, to, total } = pagination;
                            const row = (total > per_page ? to - per_page : to - total) + index + 1;
                            const rows = [];
                            //Pushing Rows number
                            if (data.id) {
                              rows.push(<Table.Cell>{row}</Table.Cell>);
                            }
                            // Push Other cells
                            Object.keys(data).filter((key) => {
                              if (data[key].visible) {
                                rows.push(<Table.Cell>{data[key].value}</Table.Cell>);
                              }
                            });
                            return (
                              <Table.Row
                                onContextMenu={(e) => this.openContextMenu(e, departures[index])}
                              >
                                {rows}
                              </Table.Row>
                            );
                          })}
                        </Table.Body>
                      </Table>
                      <ContextMenu />
                    </Dimmer.Dimmable>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateProps = (state, ownProps = {}) => ({
  isFetching: state.departures.isFetching,
  payload: state.departures.payload,
  errors: state.departures.errors,
  isModalOpen: state.departures.isModalOpen,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  fnDeparturePaginate: (forRelation, page, items) => {
    dispatch(departurePaginate(forRelation, page, items));
  },
  fnDepartureDelete: (id) => {
    dispatch(departureDelete(id));
  },
  fnDepartureShow: (id) => {
    dispatch(departureShow(id));
  },
  fnDepartureModalOpen: () => {
    dispatch(departureOpenModal());
  },
  fnDepartureModalClose: () => {
    dispatch(departureCloseModal());
  },
  fnDepartureActionStore: () => {
    dispatch(departureActionStore());
  },
  fnDepartureActionUpdate: () => {
    dispatch(departureActionUpdate());
  },
});

Departure.defaultProps = {
  payload: { message: null },
};

Departure.propTypes = {
  fnDepartureDelete: PropTypes.func,
  fnDepartureShow: PropTypes.func,
  fnDepartureModalOpen: PropTypes.func,
  fnDepartureModalClose: PropTypes.func,
  fnDepartureActionStore: PropTypes.func,
  fnDepartureActionUpdate: PropTypes.func,
  isFetching: PropTypes.bool,
  isModalOpen: PropTypes.bool,
  payload: PropTypes.exact({
    departure: PropTypes,
    departures: PropTypes,
    pagination: PropTypes,
    message: PropTypes.string,
    actionType: PropTypes.oneOf(['store', 'update']),
  }),
  errors: PropTypes.object,
};

export default connect(
  mapStateProps,
  mapDispatchToProps
)(Departure);
