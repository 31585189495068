import React, { Component } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { Form, Tab } from 'semantic-ui-react';
import {
  ItemTabDeparture,
  ItemTabInformation,
  ItemTabMeetingPoint,
  ItemTabTransportation,
  ItemTabOption,
} from './ItemTabs';

import ModelItem from './model';
import { connect } from 'react-redux';
import { itemStore, itemUpdate } from './actions';
import { categoryIndex } from './../category/actions';
import { meetingPointIndex } from '../meeting_point/actions';
import { transportationCategoryIndex } from '../transportation_category/actions';
import { departureIndex } from '../departure/actions';
import { optionIndex } from '../option/actions';
import { OptionDetail } from '../option/model';

class ItemForm extends Component {
  constructor(props) {
    super(props);
    const {
      fnIndexCategory,
      fnMeetingPointIndex,
      // actionType,
      // item,
      fnTransportationCategoryIndex,
      fnDepartureIndex,
      fnOptionIndex,
    } = props;
    fnIndexCategory();
    fnMeetingPointIndex();
    fnTransportationCategoryIndex(true);
    fnDepartureIndex();
    fnOptionIndex(true);
    this.state = {
      model: { ...ModelItem },
      relations: { categories: [], meetingPoints: [], transportationCategories: [] },
      errorForm: {},
    };
  }

  // componentDidMount() {
  //   const { actionType, item } = this.props;
  //   const model = actionType === 'store' ? ModelItem : item;
  //   this.state = {
  //     model: { ...model },
  //   };
  // }

  componentWillReceiveProps(nextProps) {
    const {
      errors,
      categories,
      meetingPoints,
      transportationCategories,
      actionType,
      item,
    } = nextProps;
    if (actionType === 'update') {
      this.setState({ model: item });
    }
    this.setState({ errorForm: { ...errors.errors } });
    this.setState(() => {
      //Categories
      const enableCategories = categories.filter((category) => category.status === 1);
      let selectCategories = {};
      selectCategories.categories = enableCategories.map((category) =>
        Object.keys(category)
          .filter((key) => {
            return key === 'id' || key === 'name';
          })
          .reduce((obj) => {
            obj = { value: category.id, text: category.name, key: category.id };
            return obj;
          }, {})
      );

      //MeetingPoints
      const enableMeetingPoints = meetingPoints.filter((meetingPoint) => meetingPoint.status === 1);
      let selectMeetingPoints = {};
      selectMeetingPoints.meetingPoints = enableMeetingPoints.map((meetingPoint) =>
        Object.keys(meetingPoint)
          .filter((key) => {
            return key === 'id' || key === 'name';
          })
          .reduce((obj) => {
            obj = { value: meetingPoint.id, text: meetingPoint.name, key: meetingPoint.id };
            return obj;
          }, {})
      );

      //Transportation Categories
      const enableTransportationCategories = transportationCategories.filter(
        (transportationCategory) => transportationCategory.status === 1
      );

      let selectedTransportationCategories = {};
      selectedTransportationCategories.transportationCategories = enableTransportationCategories.map(
        (transportationCategory) =>
          Object.keys(transportationCategory)
            .filter((key) => key === 'id' || key === 'name')
            .reduce(
              () => ({
                value: transportationCategory.id,
                key: transportationCategory.id,
                text: transportationCategory.name,
              }),
              {}
            )
      );
      return {
        relations: {
          ...selectCategories,
          ...selectMeetingPoints,
          ...selectedTransportationCategories,
        },
      };
    });
  }

  changeValue = (input) => {
    const { name, value } = input;
    let newValue = {};
    newValue[name] = value;
    this.setState(
      ({ model }) => ({ model: { ...model, ...newValue } }),
      () => {
        this.setState(({ errorForm }) => {
          let newErrors = Object.keys(errorForm)
            .filter((key) => key !== name)
            .reduce((obj, key) => {
              obj[key] = errorForm[key];
              return obj;
            }, {});
          return { errorForm: newErrors };
        });
      }
    );
  };

  changeMeetingPoint = (input) => {
    const { value } = input;
    const { meetingPoints } = this.props;
    let selectedMeetingPoint = value.map((value) => {
      let meetingFound = meetingPoints.find((meetingPoint) => meetingPoint.id === value);
      meetingFound = { ...meetingFound, departures: [] };
      return meetingFound;
    });
    //Lets set 'departures' to a empty array.
    this.setState(({ model }) => {
      return { model: { ...model, item_details: selectedMeetingPoint } };
    });
  };

  changeTransportationCategories = (input) => {
    const { value } = input;
    const { transportationCategories } = this.props;
    let selectedTransportationCategory = value.map((value) => {
      let categoryFound = transportationCategories.find((category) => category.id === value);
      categoryFound = { ...categoryFound, transportations: [] };
      return categoryFound;
    });
    this.setState(({ model }) => {
      return { model: { ...model, item_transportations: selectedTransportationCategory } };
    });
  };

  changeDeparture = (input, index) => {
    const { value } = input;
    const { departures } = this.props;
    let selectedDepartures = value.map((value) =>
      departures.find((departure) => departure.id === value)
    );
    this.setState(({ model }) => {
      const { item_details } = model;
      const newState = item_details.map((detail, key) => {
        let newValues = { ...detail };
        if (key === index) {
          newValues = { ...detail, departures: selectedDepartures };
        }
        return newValues;
      });
      return { model: { ...model, item_details: newState } };
    });
  };

  changeTransportation = (input, index) => {
    const { value } = input;
    const { transportationCategories } = this.props;
    const { model } = this.state;
    const transportationCategoryId = model.item_transportations[index].id;
    let selectedTransportations = value.map((value) => {
      const elementFound = transportationCategories.find(
        (transportationCategory) => transportationCategory.id === transportationCategoryId
      );
      const { transportations } = elementFound;
      return transportations.find((transportation) => transportation.id === value);
    });
    this.setState(({ model }) => {
      const { item_transportations } = model;
      const newState = item_transportations.map((transportation, key) => {
        let newValues = { ...transportation };
        if (key === index) {
          newValues = { ...transportation, transportations: selectedTransportations };
        }
        return newValues;
      });
      return { model: { ...model, item_transportations: newState } };
    });
  };

  changeOption = (input) => {
    const { value } = input;
    const { options } = this.props;
    let selectedOption = options.find((option) => option.id === value);
    selectedOption = { ...selectedOption, option_details: [] };
    this.setState(({ model }) => ({ model: { ...model, item_option: { ...selectedOption } } }));
  };

  changeOptionDetails = (input) => {
    const { value } = input;
    const { options } = this.props;
    const { model } = this.state;
    const optionId = model.item_option.id;
    let selectedOptionDetails = value.map((value) => {
      const elementFound = options.find((option) => option.id === optionId);
      const { option_details } = elementFound;
      let optionFound = option_details.find((detail) => detail.id === value);
      optionFound = { ...OptionDetail, ...optionFound };
      return optionFound;
    });
    this.setState(({ model }) => ({
      model: {
        ...model,
        item_option: { ...model.item_option, option_details: selectedOptionDetails },
      },
    }));
  };

  changeOptionDetailsPrice = (input) => {
    const { value, index } = input;
    let valueint = parseInt(value);
    const { model } = this.state;
    const { item_option } = model;
    const { option_details } = item_option;

    let newState = update(option_details, {
      [index]: {
        price: { $set: valueint },
      },
    });
    this.setState({
      model: { ...model, item_option: { ...model.item_option, option_details: newState } },
    });
  };
  panes = () => {
    const { model } = this.state;
    const permissions = true;
    const currentPanes = [
      {
        menuItem: 'Information',
        render: () => <ItemTabInformation {...this.state} changeValue={this.changeValue} />,
      },
      {
        menuItem: 'Transportations',
        render: () => (
          <ItemTabTransportation
            {...this.state}
            changeTransportationCategories={this.changeTransportationCategories}
            changeTransportation={this.changeTransportation}
          />
        ),
      },
      {
        menuItem: 'Meeting Points',
        render: () => (
          <ItemTabMeetingPoint {...this.state} changeMeetingPoint={this.changeMeetingPoint} />
        ),
      },
    ];
    if (model.item_details === undefined ? false : model.item_details.length > 0) {
      currentPanes.push({
        menuItem: 'Departures',
        render: () => <ItemTabDeparture {...this.state} changeDeparture={this.changeDeparture} />,
      });
    }
    if (permissions) {
      currentPanes.push({
        menuItem: 'Item Options',
        render: () => (
          <ItemTabOption
            {...this.state}
            changeOption={this.changeOption}
            changeOptionDetails={this.changeOptionDetails}
            changeOptionDetailsPrice={this.changeOptionDetailsPrice}
          />
        ),
      });
    }

    return currentPanes;
  };

  render() {
    return (
      <Form
        id={'form'}
        onSubmit={(e) => {
          const { model } = this.state;
          const { actionType, fnItemStore, fnItemUpdate } = this.props;
          switch (actionType) {
            case 'store':
              fnItemStore(model);
              break;
            case 'update':
              fnItemUpdate(model.id, model);
              break;
            default:
              break;
          }
        }}
      >
        <Tab menu={{ fluid: true, vertical: true, tabular: true }} panes={this.panes()} />
      </Form>
    );
  }
}

const mapStateProps = (state) => ({
  isFetching: state.items.isFetching,
  errors: state.items.errors,
  isFetchingCategory: state.categories.isFetching,
  categories: state.categories.payload.categories,
  actionType: state.items.payload.actionType,
  item: state.items.payload.item,
  meetingPoints: state.meetingPoints.payload.meetingPoints,
  transportationCategories: state.transportationCategories.payload.transportationCategories,
  departures: state.departures.payload.departures,
  options: state.options.payload.options,
});

const mapDispatchToProps = (dispatch) => ({
  fnItemStore: (data) => {
    dispatch(itemStore(data));
  },
  fnItemUpdate: (id, data) => {
    dispatch(itemUpdate(id, data));
  },
  fnIndexCategory: () => {
    dispatch(categoryIndex(true));
  },
  fnMeetingPointIndex: (forRelation, params) => {
    dispatch(meetingPointIndex(forRelation, params));
  },
  fnTransportationCategoryIndex: (forRelation, params) => {
    dispatch(transportationCategoryIndex(forRelation, params));
  },
  fnDepartureIndex: (forRelation, params) => {
    dispatch(departureIndex(forRelation, params));
  },
  fnOptionIndex: (forRelation, params) => {
    dispatch(optionIndex(forRelation, params));
  },
});

ItemForm.propTypes = {
  isFetching: PropTypes.bool,
  errors: PropTypes.object,
  isFetchingCategory: PropTypes.bool,
  categories: PropTypes.array,
  actionType: PropTypes.oneOf(['store', 'update']),
  item: PropTypes.object,
  meetingPoints: PropTypes.array,
  transportationCategories: PropTypes.array,
  departures: PropTypes.array,
  fnItemStore: PropTypes.func,
  fnItemUpdate: PropTypes.func,
  fnIndexCategory: PropTypes.func,
  fnMeetingPointIndex: PropTypes.func,
  fnTransportationCategoryIndex: PropTypes.func,
  fnDepartureIndex: PropTypes.func,
  fnOptionIndex: PropTypes.func,
};

export default connect(
  mapStateProps,
  mapDispatchToProps
)(ItemForm);
