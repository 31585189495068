import PropTypes from 'prop-types';
import api from '../api';

const currencyService = {
  currencyDelete(id) {
    return new Promise((resolve, reject) => {
      api
        .delete(`/currencies/${id}`)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },

  currencyIndex(page = null, items = null) {
    return new Promise((resolve, reject) => {
      api
        .get(`/currencies${page ? `/page/${page}` : ''}${items ? `/items/${items}` : ''}`)
        .then((response) => response.data)
        .then(resolve)
        .catch(reject);
    });
  },

  currencyShow(id) {
    return new Promise((resolve, reject) => {
      api
        .get(`/currencies/${id}`)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },

  currencyStore(data) {
    return new Promise((resolve, reject) => {
      api
        .post('/currencies', data)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },

  currencyUpdate(id, data) {
    return new Promise((resolve, reject) => {
      api
        .put(`/currencies/${id}`, data)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },
};

currencyService.propTypes = {
  currencyIndex: PropTypes.func.isRequired,
  currencyShow: PropTypes.func.isRequired,
};

export default currencyService;
