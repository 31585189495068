import appService from './service';
import {
  localStorageFail,
  localStorageDestroyAuthentication,
  localStorageStoreAuthentication,
  localStorageFetch,
  setScrollOnApp,
} from './dispatch';

export const appStoreAuthentication = (credentials) => (dispatch) => {
  dispatch(localStorageFetch());
  try {
    appService
      .appStorageAuthentication(credentials)
      .then(() => {
        return appService.appShowAuthentication();
      })
      .then((credentials) => {
        const data = {};
        data.isAuthenticated = credentials !== null;
        dispatch(localStorageStoreAuthentication(data));
      });
  } catch (e) {
    dispatch(localStorageFail(e));
  }
};

export const appDestroyAuthentication = () => (dispatch) => {
  dispatch(localStorageFetch());
  try {
    appService
      .appDestroyAuthentication()
      .then(() => {
        return appService.appShowAuthentication();
      })
      .then((credentials) => {
        const data = {};
        data.isAuthenticated = credentials !== null;
        dispatch(localStorageDestroyAuthentication(data));
      });
  } catch (e) {
    localStorageFail(e);
  }
};

export const appCheckStorage = () => (dispatch) => {
  dispatch(localStorageFetch());
  try {
    appService.appShowAuthentication().then((credentials) => {
      const data = {};
      data.isAuthenticated = credentials !== null;
      dispatch(localStorageStoreAuthentication(data));
    });
  } catch (e) {
    localStorageFail(e);
  }
};

export const appSetScrollPosition = (position) => (dispatch) => {
  dispatch(setScrollOnApp({ scrollPosition: position }));
};

/*
try {
  localStorage.setItem(key, value);
} catch(e) {
  if (isQuotaExceeded(e)) {
    // Storage full, maybe notify user or do some clean-up
  }
}


* function isQuotaExceeded(e) {
  var quotaExceeded = false;
  if (e) {
    if (e.code) {
      switch (e.code) {
        case 22:
          quotaExceeded = true;
          break;
        case 1014:
          // Firefox
          if (e.name === 'NS_ERROR_DOM_QUOTA_REACHED') {
            quotaExceeded = true;
          }
          break;
      }
    } else if (e.number === -2147024882) {
      // Internet Explorer 8
      quotaExceeded = true;
    }
  }
  return quotaExceeded;
}*/
