export const MODULE_NAME = 'Point Sales';
export const ROUTE = '/point-sales';

const pointSale = {
  id: null,
  name: '',
  vendor_id: null,
  status: null,
  created_id: null,
  created_at: null,
  updated_at: null,
};

export default pointSale;
