import axios from 'axios';
import store from './store';
import { appDestroyAuthentication } from './app/actions';

const BASE_URL = process.env.REACT_APP_API_URL;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers.put['Content-Type'] = 'application/x-www-form-urlencoded';
axios.interceptors.request.use((config) => {
  const appCredentials = localStorage.getItem('app_credentials');
  let bearer = null;
  if (appCredentials !== null) {
    const { token } = JSON.parse(appCredentials);
    bearer = `Bearer ${token}`;
  }
  config.headers.Authorization = bearer;
  return config;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    switch (error.request.status) {
      case 401:
        let url = error.request.responseURL;
        url = url.split('/');
        url = url[url.length - 1];
        if (url !== 'login') {
          // localStorage.removeItem('app_credentials');
          store.dispatch(appDestroyAuthentication());
        }
        break;
    }
    return Promise.reject(error);
  }
);

const API = {
  get(path) {
    return axios.get(`${BASE_URL}${path}`);
  },

  post(path, params) {
    return axios.post(`${BASE_URL}${path}`, params);
  },

  put(path, params) {
    return axios.put(`${BASE_URL}${path}`, params);
  },

  delete(path) {
    return axios.delete(`${BASE_URL}${path}`);
  },
};

export default API;
