export const MODULE_NAME = 'Vendors Categories';
export const ROUTE = '/vendors-categories';

const VendorCategory = {
  id: null,
  name: null,
  status: null,
  created_id: null,
  created_at: null,
  updated_at: null,
};

export default VendorCategory;
