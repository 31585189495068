const types = {
  // FOR CRUD DEPARTURES
  SALE_DESTROY_FAIL: 'SALE_DESTROY_FAIL',
  SALE_DESTROY_FETCH: 'SALE_DESTROY_FETCH',
  SALE_DESTROY_SUCCESS: 'SALE_DESTROY_SUCCESS',
  SALE_INDEX_FAIL: 'SALE_INDEX_FAIL',
  SALE_INDEX_FETCH: 'SALE_INDEX_FETCH',
  SALE_INDEX_SUCCESS: 'SALE_INDEX_SUCCESS',
  SALE_SHOW_FAIL: 'SALE_SHOW_FAIL',
  SALE_SHOW_FETCH: 'SALE_SHOW_FETCH',
  SALE_SHOW_SUCCESS: 'SALE_SHOW_SUCCESS',
  SALE_STORE_FAIL: 'SALE_STORE_FAIL',
  SALE_STORE_FETCH: 'SALE_STORE_FETCH',
  SALE_STORE_SUCCESS: 'SALE_STORE_SUCCESS',
  SALE_UPDATE_FAIL: 'SALE_UPDATE_FAIL',
  SALE_UPDATE_FETCH: 'SALE_UPDATE_FETCH',
  SALE_UPDATE_SUCCESS: 'SALE_UPDATE_SUCCESS',
  SALE_LOAD_RELATION_FETCH: 'SALE_LOAD_RELATION_FETCH',
  SALE_LOAD_RELATION_SUCCESS: 'SALE_LOAD_RELATION_SUCCESS',
  // FOR MODAL SALE
  SALE_MODAL_OPEN: ' SALE_MODAL_OPEN',
  SALE_MODAL_CLOSE: 'SALE_MODAL_CLOSE',
  SALE_ACTION_TYPE_STORE: 'SALE_ACTION_CREATE_TYPE',
  SALE_ACTION_TYPE_UPDATE: 'SALE_ACTION_UPDATE_TYPE',
};

export const typeStates = {
  FAIL: '_FAIL',
  FETCH: '_FETCH',
  SUCCESS: '_SUCCESS',
};

export default types;
