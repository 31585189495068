import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Form, Tab, Confirm, Grid } from 'semantic-ui-react';
import { saleModel, saleDetailModel, saleDetailOption, saleReferenceModel } from './model';
import SaleFormDetail from './SaleFormDetail';
import update from 'immutability-helper';
import { connect } from 'react-redux';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import { saleCloseModal, saleStore, saleUpdate } from './actions';
import moment from 'moment';
import { DateInput } from 'semantic-ui-calendar-react';
import { vendorIndex } from './../vendor/actions';
import { departureIndex, meetingPointDepartures } from './../departure/actions';
import { sellerIndex } from './../sellers/actions';
import { shippingHotelCompanyIndex } from './../shipping_hotel_company/actions';
import { itemIndex } from './../item/actions';
import { itemMeetingPoints } from '../meeting_point/actions';
import { rateIndex, rateDetails } from '../rate/actions';

class SaleForm extends Component {
  handleTabChange = (e, { activeIndex }) => {
    this.setState({ activeIndex });
  };

  constructor(props) {
    super(props);
    const { fnSalevendor, fnSaleShippingHotelCompany, fnSaleItems } = props;
    fnSalevendor();
    fnSaleShippingHotelCompany();
    fnSaleItems();
    this.state = {
      model: { ...saleModel },
      relations: {
        vendors: [],
        sellers: [],
        shippingHotelCompanies: [],
        rate_lists: [],
        rate_details: [],
      },
      errorForm: {},
      activeIndex: 0,
      value: '',
      showConfirm: false,
      resultConfirm: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    const {
      errors,
      vendors,
      sellers,
      shippingHotelCompanies,
      items,
      departures,
      rates,
      actionType,
      sale,
      meetingPoints,
      fnSaleSeller,
      fnIndexRate,
    } = nextProps;
    if (actionType === 'update') {
      this.setState({ model: sale });
      if (nextProps.sale.vendor_id !== this.props.sale.vendor_id) {
        fnSaleSeller(true);
        const param = {};
        param['vendor_id'] = nextProps.sale.vendor_id;
        fnIndexRate(true, param);
      }
    }
    Object.keys(errors.errors).map((error) => {
      switch (error) {
        case 'sale_references':
          if (
            this.props.errors.errors.sale_references !== undefined &&
            Object.keys(errors.errors.sale_references).length !==
              Object.keys(this.props.errors.errors.sale_references).length
          ) {
            this.setState({ errorForm: { ...errors.errors } });
          }
          break;
        default:
          if (Object.keys(errors.errors).length !== Object.keys(this.props.errors.errors).length) {
            this.setState({ errorForm: { ...errors.errors } });
          }
      }
    });
    this.setState(({ relations }) => {
      //vendors
      const enableVendors = vendors.filter((vendor) => vendor.status === 1);
      let selectVendors = {};
      selectVendors.vendors = enableVendors.map((vendor) =>
        Object.keys(vendor)
          .filter((key) => {
            return key === 'id' || key === 'name';
          })
          .reduce((obj) => {
            obj = { value: vendor.id, text: vendor.name, key: vendor.id };
            return obj;
          }, {})
      );
      //sellers
      const vendor_id = this.state.model.vendor_id;
      let selectSeller = {};
      if (vendor_id !== null) {
        const enableSellers = sellers.filter(
          (sellers) => sellers.status === 1 && vendor_id === sellers.vendor_id
        );
        selectSeller.sellers = enableSellers.map((seller) =>
          Object.keys(seller)
            .filter((key) => {
              return key === 'id' || key === 'name';
            })
            .reduce((obj) => {
              obj = { value: seller.id, text: seller.name, key: seller.id };
              return obj;
            }, {})
        );
      }
      //Departures
      const enableDepartures = departures.filter((departures) => departures.status === 1);
      let selectDepartures = {};
      selectDepartures.departures = enableDepartures.map((departure) =>
        Object.keys(departure)
          .filter((key) => {
            return key === 'id' || key === 'name';
          })
          .reduce((obj) => {
            obj = { value: departure.id, text: departure.name, key: departure.id };
            return obj;
          }, {})
      );

      ////////////Rates
      let selectRateList = {};
      selectRateList.rate_lists = rates.map((rateList) =>
        Object.keys(rateList.rate_list)
          .filter((key) => {
            return key === 'id' || key === 'name';
          })
          .reduce((obj) => {
            obj = {
              value: rateList.rate_list.id,
              text: rateList.rate_list.name,
              key: rateList.rate_list.id,
            };
            return obj;
          }, {})
      );
      //
      //Hotel Shipping Company
      const enableShippingHotelCompanies = shippingHotelCompanies.filter(
        (shippingHotelCompanies) => shippingHotelCompanies.status === 1
      );
      let selectShippingHotelCompanies = {};
      selectShippingHotelCompanies.shippingHotelCompanies = enableShippingHotelCompanies.map(
        (shippingHotelCompany) =>
          Object.keys(shippingHotelCompany)
            .filter((key) => {
              return key === 'id' || key === 'name';
            })
            .reduce((obj) => {
              obj = {
                value: shippingHotelCompany.id,
                text: shippingHotelCompany.name,
                key: shippingHotelCompany.id,
              };
              return obj;
            }, {})
      );
      //Items
      const enableItems = items.filter((items) => items.status === 1);
      let selectItems = {};
      selectItems.items = enableItems.map((item) =>
        Object.keys(item)
          .filter((key) => {
            return key === 'id' || key === 'name';
          })
          .reduce((obj) => {
            obj = { value: item.id, text: item.name, key: item.id };
            return obj;
          }, {})
      );

      //MeetingPoints
      const enableMeetingPoints = meetingPoints.filter((meetingPoint) => meetingPoint.status === 1);
      const selectMeetingPoints = {};
      selectMeetingPoints.meetingPoints = enableMeetingPoints.map((meeting_point) =>
        Object.keys(meeting_point)
          .filter((key) => {
            return key === 'id' || key === 'name';
          })
          .reduce((obj) => {
            obj = {
              value: meeting_point.id,
              text: meeting_point.name,
              key: meeting_point.id,
            };
            return obj;
          }, {})
      );
      return {
        relations: {
          ...relations,
          ...selectRateList,
          ...selectVendors,
          ...selectSeller,
          ...selectDepartures,
          ...selectShippingHotelCompanies,
          ...selectItems,
          ...selectMeetingPoints,
        },
      };
    });
  }
  showConfirm = () => this.setState({ showConfirm: true });
  handleConfirm = () => {
    let prevVal = this.state.model;
    this.setState((prevState) => {
      return { showConfirm: false, model: { ...prevState.model, sale_details: [] } };
    });
  };
  handleCancel = () => {
    let prevVal = this.state.model.rate_id;
    this.setState((prevState) => {
      return { showConfirm: false, model: { ...prevState.model } };
    });
  };

  changeMeetingPoint = (value) => {
    const { fnItemMeetingPoints } = this.props;
    fnItemMeetingPoints(value);
  };
  changeDeparture = (value) => {
    const { fnDepartureMeeting } = this.props;
    fnDepartureMeeting(value);
  };
  changeValue = (input) => {
    let { name, value } = input;
    let newValue = {};
    newValue[name] = value;
    let prevVal = this.state.model;
    if (name === 'rate_id' && this.state.model.sale_details.length !== 0) {
      this.setState((prevState) => {
        return { ...prevState, model: { ...prevState.model, sale_details: [] } };
      });
    }
    if (name === 'vendor_id' && this.state.model.sale_details.length !== 0) {
      this.setState((prevState) => {
        return { ...prevState, model: { ...prevState.model, sale_details: [] } };
      });
    }
    this.setState(
      ({ model }) => ({ model: { ...model, ...newValue } }),
      () => {
        this.setState(({ errorForm }) => {
          let newErrors = Object.keys(errorForm)
            .filter((key) => key !== name)
            .reduce((obj, key) => {
              obj[key] = errorForm[key];
              return obj;
            }, {});
          return { errorForm: newErrors };
        });
      }
    );
  };
  getChildrenForItem = (index, item_id) => {
    const { rate_details } = this.state.relations;

    let ItemList = rate_details.find((item) => item.id === item_id);
    const Childs =
      ItemList === undefined
        ? Object.assign({ pax: 0 }, ItemList.option)
        : ItemList.optionables.option_details.map((option, key) => {
            return Object.assign({}, option, { pax: 0 });
          });
    this.setState((prevState) => {
      const { model } = prevState;
      const newState = model.sale_details.map((detail, key) => {
        let value = { ...detail };
        if (key === index) {
          const options = { ...ItemList.optionables, option_details: Childs };
          value = { ...detail, options: options };
        }
        return value;
      });
      return { ...prevState, model: { ...prevState.model, sale_details: newState } };
    });
  };

  onChangeValueChildren = (index, input) => {
    let { name, value, keyIndex } = input;
    value = parseInt(value);
    const { model } = this.state;
    const { sale_details } = model;
    let codeOption =
      sale_details[index].options.code === null
        ? sale_details[index].options.code
        : sale_details[index].options.code;
    if (codeOption !== null) {
      let newState2 = update(sale_details, {
        [index]: {
          options: {
            option_details: {
              [keyIndex]: { pax: { $set: value } },
            },
          },
        },
      });
      this.setState((prevState) => ({
        ...prevState,
        model: { ...prevState.model, sale_details: newState2 },
      }));
    } else {
      let newState2 = update(sale_details, {
        [index]: {
          options: {
            pax: { $set: value },
          },
        },
      });
      this.setState((prevState) => ({
        ...prevState,
        model: { ...prevState.model, sale_details: newState2 },
      }));
    }
  };
  onChangeReferences = (input) => {
    let { name, value } = input;
    let newValue = {};
    newValue[name] = value;
    this.setState(
      ({ model }) => ({
        model: { ...model, sale_references: { ...model.sale_references, ...newValue } },
      }),
      () => {
        this.setState((prevState) => {
          const { errorForm } = prevState;
          if (errorForm.sale_references !== undefined) {
            let newErrors = Object.keys(errorForm.sale_references)
              .filter((key) => key !== name)
              .reduce((obj, key) => {
                obj[key] = errorForm.sale_references[key];
                return obj;
              }, {});
            return {
              ...prevState,
              errorForm: { ...prevState.errorForm, sale_references: newErrors },
            };
          }
        });
      }
    );
  };
  onChangeDetails = (input, key) => {
    const { name, value } = input;
    let newValue = {};
    newValue[name] = value;
    this.setState(
      ({ model }) => {
        const newState = model.sale_details.map((detail, k) => {
          let values = { ...detail };
          if (k === key) {
            values = { ...detail, ...newValue };
          }
          return values;
        });
        return { model: { ...model, sale_details: newState } };
      },
      () => {
        this.setState(({ errorForm }) => {
          const { sale_details } = errorForm;
          let newErrors = { ...errorForm };
          if (sale_details !== undefined) {
            const newErrorDetails = sale_details.map((detail) => {
              let values = { ...detail };
              if (key === detail.index) {
                values = Object.keys(detail)
                  .filter((key) => key !== name)
                  .reduce((obj, key) => {
                    obj[key] = detail[key];
                    return obj;
                  }, {});
              }
              return values;
            });

            newErrors = { errorForm: { ...errorForm, sale_details: newErrorDetails } };
          }
          return newErrors;
        });
      }
    );
  };

  onAddItem = () => {
    this.setState(
      ({ model }) => {
        const detailModel = { ...saleDetailModel };
        const saleDetails = model.sale_details;
        saleDetails.push(detailModel);
        return {
          model: { ...model, sale_details: saleDetails },
        };
      },
      /*Borrar Despues de terner los datos reales*/ () => {
        //Items
      }
    );
  };
  onUpdateItem = (i) => {
    this.setState((state) => {
      const list = state.list.map((item, j) => {
        if (j === i) {
          return item + 1;
        } else {
          return item;
        }
      });
      return {
        list,
      };
    });
  };
  onRemoveItem = (i) => {
    this.setState(({ model }) => {
      const lists = model.sale_details.filter((item, j) => i !== j);
      const saleDetails = lists;
      return {
        model: { ...model, sale_details: saleDetails },
      };
    });
  };

  render() {
    const {
      vendor_id,
      seller_id,
      total,
      rate_id,
      date_operation,
      rate_list_id,
    } = this.state.errorForm;
    const {
      client_lastname,
      folio,
      reference_folio,
      client_name,
      shipping_hotel_company_id,
      balance,
    } =
      this.state.errorForm.sale_references === undefined
        ? ''
        : this.state.errorForm.sale_references;
    const { relations, model, activeIndex, showConfirm } = this.state;
    let sellerID = model.seller_id !== null ? model.seller_id : 0;

    const panes = [
      {
        menuItem: 'Sale',
        render: () => (
          <Tab.Pane>
            <Grid>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <div className={'col-auto'}>
                    <Form.Input
                      value={model.folio}
                      name={'folio'}
                      autoComplete={'off'}
                      onChange={(e, data) => this.changeValue(data)}
                      error={folio === undefined ? false : { content: folio, pointing: 'below' }}
                      label="Folio:"
                      readOnly={true}
                    />
                  </div>
                </Grid.Column>
                <Grid.Column>
                  <DateInput
                    placeholder={'Date'}
                    name={'date_operation'}
                    dateFormat={'YYYY-MM-DD'}
                    value={model.date_operation}
                    label={'Date:'}
                    animation="none"
                    error={
                      date_operation === undefined
                        ? false
                        : { content: date_operation, pointing: 'below' }
                    }
                    minDate={moment().format('YYYY-MM-DD')}
                    popupPosition={'bottom center'}
                    icon={false}
                    closable={true}
                    autoComplete="off"
                    onChange={(e, data) => this.changeValue(data)}
                  />
                </Grid.Column>
              </Grid.Row>

              <Grid.Row columns={2}>
                <Grid.Column>
                  <div className={'col-auto'}>
                    <Form.Select
                      value={model.vendor_id}
                      name={'vendor_id'}
                      label="Vendors:"
                      placeholder="Add vendor"
                      options={relations.vendors}
                      error={
                        vendor_id === undefined ? false : { content: vendor_id, pointing: 'below' }
                      }
                      onChange={(e, data) => {
                        const { fnSaleSeller, fnIndexRate } = this.props;
                        this.changeValue(data);
                        fnSaleSeller();
                        const param = {};
                        param[data.name] = data.value;
                        fnIndexRate(true, param);
                      }}
                    />
                  </div>
                </Grid.Column>
                <Grid.Column>
                  <div className={'col-auto'}>
                    <Form.Select
                      value={model.seller_id}
                      name={'seller_id'}
                      label="Sellers:"
                      placeholder="Add seller"
                      options={relations.sellers}
                      error={
                        seller_id === undefined ? false : { content: seller_id, pointing: 'below' }
                      }
                      onChange={(e, data) => this.changeValue(data)}
                    />
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Reference',
        render: () => (
          <Tab.Pane>
            <Grid stackable>
              <Grid.Row columns={3}>
                <Grid.Column>
                  <div className={'col-auto'}>
                    <Form.Input
                      value={model.sale_references.client_name}
                      name={'client_name'}
                      autoComplete={'off'}
                      onChange={(e, data) => this.onChangeReferences(data)}
                      error={
                        client_name === undefined
                          ? false
                          : { content: client_name, pointing: 'below' }
                      }
                      label="Name:"
                      placeholder="Add Name"
                    />
                  </div>
                </Grid.Column>
                <Grid.Column>
                  <div className={'col-auto'}>
                    <Form.Input
                      value={model.sale_references.client_lastname}
                      name={'client_lastname'}
                      autoComplete={'off'}
                      onChange={(e, data) => this.onChangeReferences(data)}
                      error={
                        client_lastname === undefined
                          ? false
                          : { content: client_lastname, pointing: 'below' }
                      }
                      label="Last Name:"
                      placeholder="Add Last Name"
                    />
                  </div>
                </Grid.Column>
                <Grid.Column>
                  <div className={'col-auto'}>
                    <Form.Input
                      value={model.sale_references.reference_folio}
                      name={'reference_folio'}
                      autoComplete={'off'}
                      onChange={(e, data) => this.onChangeReferences(data)}
                      error={
                        reference_folio === undefined
                          ? false
                          : { content: reference_folio, pointing: 'below' }
                      }
                      label="Folio:"
                      placeholder="Add folio"
                    />
                  </div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={3}>
                <Grid.Column>
                  <div className={'col-auto'}>
                    <Form.Input
                      value={model.sale_references.email}
                      name={'email'}
                      autoComplete={'off'}
                      onChange={(e, data) => this.onChangeReferences(data)}
                      // error={
                      //   client_name === undefined
                      //     ? false
                      //     : { content: client_name, pointing: 'below' }
                      // }
                      label="Email (Optional):"
                      placeholder="Add Email"
                    />
                  </div>
                </Grid.Column>
                <Grid.Column>
                  <div className={'col-auto'}>
                    <Form.Input
                      value={model.sale_references.phone}
                      name={'phone'}
                      autoComplete={'off'}
                      onChange={(e, data) => {
                        const newValue = {};
                        const { name, value } = data;
                        newValue.name = name;
                        newValue.value = value > 0 ? parseInt(value) : 0;
                        this.onChangeReferences(newValue);
                      }}
                      // onChange={(e, data) => this.onChangeReferences(data)}
                      // error={
                      //   client_lastname === undefined
                      //     ? false
                      //     : { content: client_lastname, pointing: 'below' }
                      // }
                      label="Phone (Optional):"
                      placeholder="Add phone"
                    />
                  </div>
                </Grid.Column>
                <Grid.Column>
                  <div className={'col-auto'}>
                    <Form.Input
                      value={model.sale_references.ubication}
                      name={'ubication'}
                      autoComplete={'off'}
                      onChange={(e, data) => this.onChangeReferences(data)}
                      // error={
                      //   reference_folio === undefined
                      //     ? false
                      //     : { content: reference_folio, pointing: 'below' }
                      // }
                      label="Ubication (Optional):"
                      placeholder="Add ubication"
                    />
                  </div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <div className={'col-auto'}>
                    <Form.Select
                      value={model.sale_references.shipping_hotel_company_id}
                      name={'shipping_hotel_company_id'}
                      label="Hotel/Cruise:"
                      placeholder="Add hotel or cruise"
                      options={relations.shippingHotelCompanies}
                      error={
                        shipping_hotel_company_id === undefined
                          ? false
                          : { content: shipping_hotel_company_id, pointing: 'below' }
                      }
                      onChange={(e, data) => this.onChangeReferences(data)}
                    />
                  </div>
                </Grid.Column>
                <Grid.Column>
                  <div className={'col-auto'}>
                    <Form.Input
                      value={model.sale_references.balance}
                      name={'balance'}
                      autoComplete={'off'}
                      onChange={(e, data) => {
                        const newValue = {};
                        const { name, value } = data;
                        newValue.name = name;
                        newValue.value = value > 0 ? parseInt(value) : 0;
                        this.onChangeReferences(newValue);
                      }}
                      error={
                        balance === undefined ? false : { content: balance, pointing: 'below' }
                      }
                      label="Balance (Optional):"
                      placeholder="Add Balance"
                    />
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Details',
        render: () => (
          <Tab.Pane>
            <Form.Group className={'form-detalles'} columns={1}>
              <Form.Select
                name={'rate_list_id'}
                value={model.rate_list_id}
                label="Rate List:"
                error={
                  rate_list_id === undefined ? false : { content: rate_list_id, pointing: 'below' }
                }
                placeholder="Add list"
                options={relations.rate_lists}
                onChange={(e, data) => {
                  const { fnRateDetails, rates } = this.props;
                  const rateId = _.find(rates, { rate_list_id: data.value });
                  this.changeValue(data);
                  this.changeValue({ name: 'rate_id', value: rateId.id });
                  fnRateDetails(data.value).then((data) => {
                    const rateDetails = {};
                    rateDetails.rate_details = data.data;
                    this.setState((prevState) => ({
                      relations: { ...prevState.relations, ...rateDetails },
                    }));
                  });
                }}
              />
              <Form.Button
                color={'green'}
                type="button"
                onClick={this.onAddItem}
                disabled={!this.state.model.rate_list_id}
              >
                Add
              </Form.Button>
            </Form.Group>

            {model.sale_details.map((list, key) => {
              const { sale_details } =
                this.state.errorForm.sale_details !== undefined
                  ? this.state.errorForm
                  : { sale_details: [] };
              let ErrorDetail = { ...saleDetailModel };
              if (sale_details.length !== 0) {
                ErrorDetail = sale_details.find((sale_detail) => sale_detail.index === key);
                ErrorDetail = { ...saleDetailModel, ...ErrorDetail };
              }
              return (
                <SaleFormDetail
                  relations={relations}
                  detalles={model.sale_details}
                  {...list}
                  errorDetails={ErrorDetail}
                  keyItem={key}
                  changeMeeting={this.changeMeetingPoint}
                  changeDeparture={this.changeDeparture}
                  onChangeDetails={this.onChangeDetails}
                  onRemoveItem={this.onRemoveItem}
                  getChildrenForItem={this.getChildrenForItem}
                  onChangeValueChildren={this.onChangeValueChildren}
                />
              );
            })}
          </Tab.Pane>
        ),
      },
    ];
    return (
      <React.Fragment>
        <Form
          id={'form'}
          // size={'huge'}
          onSubmit={(e) => {
            const { model } = this.state;
            const { actionType, fnSaleStore, fnSaleUpdate } = this.props;
            //Intercept to remove 0 values on details
            const interceptDetails = model.sale_details.map((detail) => {
              const detailFiltered = detail.options.option_details.filter((opt) => opt.pax > 0);
              return { ...detail, options: { ...detail.options, option_details: detailFiltered } };
            });
            const newModel = { ...model, sale_details: interceptDetails };
            switch (actionType) {
              case 'store':
                fnSaleStore(newModel);
                break;
              case 'update':
                fnSaleUpdate(newModel.id, newModel);
                break;
              default:
                break;
            }
          }}
        >
          <Tab
            menu={{ fluid: true, vertical: true, tabular: true }}
            panes={panes}
            activeIndex={activeIndex}
            onTabChange={this.handleTabChange}
          />
        </Form>
      </React.Fragment>
    );
  }
}

SaleForm.propTypes = {
  fnSaleStore: PropTypes.func,
  fnSaleUpdate: PropTypes.func,
  fnSaleDeparture: PropTypes.func,
  fnSalevendor: PropTypes.func,
  fnSaleSeller: PropTypes.func,
  fnSaleRateList: PropTypes.func,
  fnSaleShippingHotelCompany: PropTypes.func,
  fnSaleItems: PropTypes.func,
  fnSaleModalClose: PropTypes.func,
  fnIndexRate: PropTypes.func,
  fnRateDetails: PropTypes.func,
  fnItemMeetingPoints: PropTypes.func,
  fnMeetingPointDepartures: PropTypes.func,
  isFetching: PropTypes.bool,
  errors: PropTypes.object,
  isFetchingVendors: PropTypes.bool,
  vendors: PropTypes.array,
  isFetchingSellers: PropTypes.bool,
  sellers: PropTypes.array,
  isFetchingDepartures: PropTypes.bool,
  departures: PropTypes.array,
  isFetchingshippingHotelCompanies: PropTypes.bool,
  shippingHotelCompanies: PropTypes.array,
  isFetchingItems: PropTypes.bool,
  items: PropTypes.array,
  isFetchingRates: PropTypes.bool,
  rates: PropTypes.array,
  meetingPoints: PropTypes.array,
  actionType: PropTypes.string,
  sale: PropTypes.array,
};

const mapStateProps = (state) => ({
  isFetching: state.sales.isFetching,
  errors: state.sales.errors,
  isFetchingVendors: state.vendors.isFetching,
  vendors: state.vendors.payload.vendors,
  isFetchingSellers: state.sellers.isFetching,
  sellers: state.sellers.payload.sellers,
  isFetchingDepartures: state.departures.isFetching,
  departures: state.departures.payload.departures,
  isFetchingshippingHotelCompanies: state.shippingHotelCompanies.isFetching,
  shippingHotelCompanies: state.shippingHotelCompanies.payload.shippingHotelCompanies,
  isFetchingItems: state.items.isFetching,
  items: state.items.payload.items,
  isFetchingRates: state.rates.isFetching,
  rates: state.rates.payload.rates,
  meetingPoints: state.meetingPoints.payload.meetingPoints,
  actionType: state.sales.payload.actionType,
  sale: state.sales.payload.sale,
});

const mapDispatchToProps = (dispatch) => ({
  fnSaleStore: (data) => {
    dispatch(saleStore(data));
  },
  fnSaleUpdate: (id, data) => {
    dispatch(saleUpdate(id, data));
  },
  fnSaleDeparture: () => {
    dispatch(departureIndex(true));
  },
  fnItemMeetingPoints: (item_id) => {
    dispatch(itemMeetingPoints(item_id));
  },
  fnSalevendor: () => {
    dispatch(vendorIndex(true));
  },
  fnSaleSeller: () => {
    dispatch(sellerIndex(true));
  },
  fnSaleShippingHotelCompany: () => {
    dispatch(shippingHotelCompanyIndex(true));
  },
  fnSaleItems: () => {
    dispatch(itemIndex(true));
  },
  fnSaleModalClose: () => {
    dispatch(saleCloseModal());
  },
  fnIndexRate: (forRelation, params) => {
    dispatch(rateIndex(forRelation, params));
  },
  fnRateDetails: (rate_id) => {
    return dispatch(rateDetails(rate_id));
  },
  fnDepartureMeeting: (meeting_id) => {
    dispatch(meetingPointDepartures(meeting_id));
  }
});

export default connect(
  mapStateProps,
  mapDispatchToProps
)(SaleForm);
