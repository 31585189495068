import PropTypes from 'prop-types';
import api from '../api';

const itemService = {
  itemDelete(id) {
    return new Promise((resolve, reject) => {
      api
        .delete(`/items/${id}`)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },

  itemIndex(relation = null, params = null, page = null, items = null) {
    return new Promise((resolve, reject) => {
      api
        .get(
          `/items${page ? `/page/${page}` : ''}${items ? `/items/${items}` : ''}${
            relation ? `/${relation}` : ''
          }${params ? `?${params}` : ''}`
        )
        .then((response) => response.data)
        .then(resolve)
        .catch(reject);
    });
  },

  itemShow(id, relation = null) {
    return new Promise((resolve, reject) => {
      api
        .get(`/items/${id}${relation ? `/${relation}` : ''}`)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },

  itemStore(data) {
    return new Promise((resolve, reject) => {
      api
        .post('/items', data)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },

  itemUpdate(id, data) {
    return new Promise((resolve, reject) => {
      api
        .put(`/items/${id}`, data)
        .then((response) => response.data)
        .then(resolve)
        .catch((error) => JSON.parse(error.request.response))
        .then(reject);
    });
  },
};

itemService.propTypes = {
  itemIndex: PropTypes.func.isRequired,
  itemShow: PropTypes.func.isRequired,
};

export default itemService;
