import optionService from './service';
import { optionIndexFail, optionIndexFetch, optionIndexSuccess } from './dispatch';
import { RELATIONS } from './model';
import appService from '../app/service';

export const optionIndex = (forRelation = false, params = null) => async (dispatch) => {
  dispatch(optionIndexFetch());
  try {
    forRelation = forRelation ? appService.appSerialize(RELATIONS) : null;
    let serializedParams = '';
    if (params && typeof params === 'object') {
      Object.keys(params).map((param, key, array) => {
        serializedParams += `${param}=${params[param]}`;
        if (array[key + 1] !== undefined) serializedParams += '&';
      });
    } else serializedParams = params;
    const { data } = await optionService.optionIndex(forRelation, serializedParams);
    dispatch(optionIndexSuccess({ options: data }));
  } catch (e) {
    dispatch(optionIndexFail(e));
  }
};
