import vendorService from './service';
import {
  vendorDeleteFail,
  vendorDeleteFetch,
  vendorDeleteSuccess,
  vendorIndexFail,
  vendorIndexFetch,
  vendorIndexSuccess,
  vendorShowFail,
  vendorShowFetch,
  vendorShowSuccess,
  vendorStoreFail,
  vendorStoreFetch,
  vendorStoreSuccess,
  vendorUpdateFail,
  vendorUpdateFetch,
  vendorUpdateSuccess,
  vendorModalOpen,
  vendorModalClose,
  vendorActionTypeStore,
  vendorActionTypeUpdate,
} from './dispatch';
import meetingPointService from '../meeting_point/service';
import { RELATIONS } from '../vendor/model';
import appService from '../app/service';

export const vendorDelete = (id) => async (dispatch) => {
  dispatch(vendorDeleteFetch());
  try {
    const { data, message } = await vendorService.vendorDelete(id);
    dispatch(vendorDeleteSuccess({ payload: data, message: message }));
    dispatch(vendorModalClose());
    dispatch(vendorIndex());
  } catch (e) {
    const { error } = e;
    dispatch(vendorDeleteFail({ error }));
  }
};

export const vendorIndex = (forRelation = false, page = null, items = null) => async (
  dispatch,
  getState
) => {
  dispatch(vendorIndexFetch());
  try {
    //If is not paginates values, Get default value from state
    if (!forRelation) {
      page = page ? page : getState().vendors.payload.pagination.current_page;
      items = items ? items : getState().vendors.payload.pagination.per_page;
    }
    const { data, meta } = await vendorService.vendorIndex(page, items);
    dispatch(vendorIndexSuccess({ vendors: data, pagination: meta }));
  } catch (e) {
    dispatch(vendorIndexFail(e));
  }
};
export const vendorShow = (data) => (dispatch) => {
  dispatch(vendorShowSuccess({ vendor: data }));
};

export const vendorPaginate = (
  forRelation = false,
  params = null,
  page = null,
  items = null
) => async (dispatch, getState) => {
  dispatch(vendorIndexFetch());
  try {
    //If is not paginates values, Get default value from state
    page = page ? page : getState().vendors.payload.pagination.current_page;
    items = items ? items : getState().vendors.payload.pagination.per_page;
    forRelation = forRelation ? appService.appSerialize(RELATIONS) : null;
    let serializedParams = '';
    if (params && typeof params === 'object') {
      Object.keys(params).map((param, key, array) => {
        serializedParams += `${param}=${params[param]}`;
        if (array[key + 1] !== undefined) serializedParams += '&';
      });
    } else serializedParams = params;

    const { data, meta } = await vendorService.vendorIndex(
      forRelation,
      serializedParams,
      page,
      items
    );
    const { pagination } = getState().vendors.payload;
    dispatch(vendorIndexSuccess({ vendors: data, pagination: { ...pagination, ...meta } }));
  } catch (e) {
    dispatch(vendorIndexFail(e));
  }
};

export const vendorStore = (data) => async (dispatch) => {
  dispatch(vendorStoreFetch());
  try {
    const { message } = await vendorService.vendorStore(data);
    dispatch(vendorStoreSuccess({ message }));
    dispatch(vendorModalClose());
    dispatch(vendorPaginate(true));
  } catch (e) {
    const { errors, message } = e;
    dispatch(vendorStoreFail({ errors, message }));
  }
};

export const vendorUpdate = (id, data) => async (dispatch) => {
  dispatch(vendorUpdateFetch());
  try {
    const { message } = await vendorService.vendorUpdate(id, data);
    dispatch(vendorUpdateSuccess({ message }));
    dispatch(vendorCloseModal());
    dispatch(vendorPaginate(true));
  } catch (e) {
    const { errors, message } = e;
    dispatch(vendorUpdateFail({ errors, message }));
  }
};

export const vendorOpenModal = () => (dispatch) => {
  dispatch(vendorModalOpen());
};

export const vendorCloseModal = () => (dispatch) => {
  dispatch(vendorModalClose());
};

export const vendorActionStore = () => (dispatch) => {
  dispatch(vendorActionTypeStore({ actionType: 'store' }));
};

export const vendorActionUpdate = () => (dispatch) => {
  dispatch(vendorActionTypeUpdate({ actionType: 'update' }));
};
