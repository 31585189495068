import vendorCategoryTypes from './types';

// INDEX DISPATCH
export const vendorCategoryIndexFail = (payload) => ({
  type: vendorCategoryTypes.VENDOR_CATEGORY_INDEX_FAIL,
  payload,
});

export const vendorCategoryIndexFetch = () => ({
  type: vendorCategoryTypes.VENDOR_CATEGORY_INDEX_FETCH,
});

export const vendorCategoryIndexSuccess = (payload) => ({
  type: vendorCategoryTypes.VENDOR_CATEGORY_INDEX_SUCCESS,
  payload: {
    ...payload,
  },
});

// SHOW DISPATCH
export const vendorCategoryShowFail = (payload) => ({
  type: vendorCategoryTypes.VENDOR_CATEGORY_SHOW_FAIL,
  errors: {
    ...payload,
  },
});
export const vendorCategoryShowFetch = () => ({
  type: vendorCategoryTypes.VENDOR_CATEGORY_SHOW_FETCH,
});
export const vendorCategoryShowSuccess = (payload) => ({
  type: vendorCategoryTypes.VENDOR_CATEGORY_SHOW_SUCCESS,
  payload: {
    ...payload,
  },
});
