import movementTypeTypes from './types';
// DESTROY DISPATCH
export const movementTypeDeleteFail = (payload) => {
  return {
    type: movementTypeTypes.MOVEMENT_TYPE_DESTROY_FAIL,
    errors: {
      ...payload,
    },
  };
};
export const movementTypeDeleteFetch = () => ({
  type: movementTypeTypes.MOVEMENT_TYPE_DESTROY_FETCH,
});
export const movementTypeDeleteSuccess = (payload) => ({
  type: movementTypeTypes.MOVEMENT_TYPE_DESTROY_SUCCESS,
  payload,
});

// INDEX DISPATCH
export const movementTypeIndexFail = (payload) => ({
  type: movementTypeTypes.MOVEMENT_TYPE_INDEX_FAIL,
  payload,
});

export const movementTypeIndexFetch = () => ({
  type: movementTypeTypes.MOVEMENT_TYPE_INDEX_FETCH,
});

export const movementTypeIndexSuccess = (payload) => ({
  type: movementTypeTypes.MOVEMENT_TYPE_INDEX_SUCCESS,
  payload: {
    ...payload,
  },
});

// SHOW DISPATCH
export const movementTypeShowFail = (payload) => ({
  type: movementTypeTypes.MOVEMENT_TYPE_SHOW_FAIL,
  errors: {
    ...payload,
  },
});
export const movementTypeShowFetch = () => ({
  type: movementTypeTypes.MOVEMENT_TYPE_SHOW_FETCH,
});
export const movementTypeShowSuccess = (payload) => ({
  type: movementTypeTypes.MOVEMENT_TYPE_SHOW_SUCCESS,
  payload: {
    ...payload,
  },
});

// STORE DISPATCH
export const movementTypeStoreFail = (payload) => {
  return {
    type: movementTypeTypes.MOVEMENT_TYPE_STORE_FAIL,
    errors: {
      ...payload,
    },
  };
};

export const movementTypeStoreFetch = () => ({
  type: movementTypeTypes.MOVEMENT_TYPE_STORE_FETCH,
});
export const movementTypeStoreSuccess = (payload) => ({
  type: movementTypeTypes.MOVEMENT_TYPE_STORE_SUCCESS,
  payload,
});

// UPDATE DISPATCH
export const movementTypeUpdateFail = (payload) => {
  return {
    type: movementTypeTypes.MOVEMENT_TYPE_UPDATE_FAIL,
    errors: {
      ...payload,
    },
  };
};
export const movementTypeUpdateFetch = () => ({
  type: movementTypeTypes.MOVEMENT_TYPE_UPDATE_FETCH,
});
export const movementTypeUpdateSuccess = (payload) => ({
  type: movementTypeTypes.MOVEMENT_TYPE_UPDATE_SUCCESS,
  payload: {
    ...payload,
  },
});
