import paymentMethodTypes from './types';
// DESTROY DISPATCH
export const paymentMethodDeleteFail = (payload) => {
  return {
    type: paymentMethodTypes.PAYMENT_METHOD_DESTROY_FAIL,
    errors: {
      ...payload,
    },
  };
};
export const paymentMethodDeleteFetch = () => ({
  type: paymentMethodTypes.PAYMENT_METHOD_DESTROY_FETCH,
});
export const paymentMethodDeleteSuccess = (payload) => ({
  type: paymentMethodTypes.PAYMENT_METHOD_DESTROY_SUCCESS,
  payload,
});

// INDEX DISPATCH
export const paymentMethodIndexFail = (payload) => ({
  type: paymentMethodTypes.PAYMENT_METHOD_INDEX_FAIL,
  payload,
});

export const paymentMethodIndexFetch = () => ({
  type: paymentMethodTypes.PAYMENT_METHOD_INDEX_FETCH,
});

export const paymentMethodIndexSuccess = (payload) => ({
  type: paymentMethodTypes.PAYMENT_METHOD_INDEX_SUCCESS,
  payload: {
    ...payload,
  },
});

// SHOW DISPATCH
export const paymentMethodShowFail = (payload) => ({
  type: paymentMethodTypes.PAYMENT_METHOD_SHOW_FAIL,
  errors: {
    ...payload,
  },
});
export const paymentMethodShowFetch = () => ({
  type: paymentMethodTypes.PAYMENT_METHOD_SHOW_FETCH,
});
export const paymentMethodShowSuccess = (payload) => ({
  type: paymentMethodTypes.PAYMENT_METHOD_SHOW_SUCCESS,
  payload: {
    ...payload,
  },
});

// STORE DISPATCH
export const paymentMethodStoreFail = (payload) => {
  return {
    type: paymentMethodTypes.PAYMENT_METHOD_STORE_FAIL,
    errors: {
      ...payload,
    },
  };
};

export const paymentMethodStoreFetch = () => ({
  type: paymentMethodTypes.PAYMENT_METHOD_STORE_FETCH,
});
export const paymentMethodStoreSuccess = (payload) => ({
  type: paymentMethodTypes.PAYMENT_METHOD_STORE_SUCCESS,
  payload,
});

// UPDATE DISPATCH
export const paymentMethodUpdateFail = (payload) => {
  return {
    type: paymentMethodTypes.PAYMENT_METHOD_UPDATE_FAIL,
    errors: {
      ...payload,
    },
  };
};
export const paymentMethodUpdateFetch = () => ({
  type: paymentMethodTypes.PAYMENT_METHOD_UPDATE_FETCH,
});
export const paymentMethodUpdateSuccess = (payload) => ({
  type: paymentMethodTypes.PAYMENT_METHOD_UPDATE_SUCCESS,
  payload: {
    ...payload,
  },
});
