export const RELATIONS = ['option_details'];

export const Option = {
  id: null,
  name: null,
  code: null,
  option_details: [],
};

export const OptionDetail = {
  option_id: null,
  price: 0,
  name: null,
};

export default Option;
