import React, { Component } from 'react';
import { Form, Grid } from 'semantic-ui-react';
import modelPointSale from './model';
import { connect } from 'react-redux';
import { pointSaleStore, pointSaleUpdate } from './actions';
import { vendorCategoryIndex } from '../vendor_category/actions';
import PropTypes from 'prop-types';

class PointSaleForm extends Component {
  constructor(props) {
    super(props);
    const { actionType, pointSale } = props;
    this.state = {
      model: actionType === 'store' ? modelPointSale : pointSale,
      relations: {
        vendor_categories: [],
      },
      errorForm: {},
    };
    const { fnVendorCategories } = props;
    fnVendorCategories();
  }

  componentWillReceiveProps(nextProps) {
    console.log(nextProps);
    const { vendorsCategories, errors } = nextProps;
    Object.keys(errors.errors).map((error) => {
      if (Object.keys(errors.errors).length !== Object.keys(this.props.errors.errors).length) {
        this.setState({ errorForm: { ...errors.errors } });
      }
    });
    this.setState(() => {
      //vendors
      const enableVendorCategorie = vendorsCategories.filter((vendor) => vendor.status === 1);
      let selectVendorCategories = {};
      selectVendorCategories.vendorsCategories = enableVendorCategorie.map((vendor) =>
        Object.keys(vendor)
          .filter((key) => {
            return key === 'id' || key === 'name';
          })
          .reduce((obj) => {
            obj = { value: vendor.id, text: vendor.name, key: vendor.id };
            return obj;
          }, {})
      );
      return {
        relations: {
          ...selectVendorCategories,
        },
      };
    });
  }
  changeValue = (input) => {
    const { name, value } = input;
    let newValue = {};
    newValue[name] = value;
    this.setState(
      ({ model }) => ({ model: { ...model, ...newValue } }),
      () => {
        this.setState(({ errorForm }) => {
          let newErrors = Object.keys(errorForm)
            .filter((key) => key !== name)
            .reduce((obj, key) => {
              obj[key] = errorForm[key];
              return obj;
            }, {});
          return { errorForm: newErrors };
        });
      }
    );
  };
  render() {
    const { name, vendor_id } = this.state.errorForm;
    const { model, relations } = this.state;
    console.log(model)
    return (
      <Form
        id={'form'}
        onSubmit={(e) => {
          const { model } = this.state;
          const { actionType, fnPointSaleStore, fnPointSaleUpdate } = this.props;
          switch (actionType) {
            case 'store':
              fnPointSaleStore(model);
              break;
            case 'update':
              fnPointSaleUpdate(model.id, model);
              break;
            default:
              break;
          }
        }}
      >
        <Grid stackable columns={1}>
          <Grid.Column>
            <Form.Input
              value={model.name}
              name={'name'}
              autoComplete={'off'}
              onChange={(e, data) => this.changeValue(data)}
              error={name === undefined ? false : { content: name, pointing: 'below' }}
              label="Name:"
              placeholder="Add Name"
            />
          </Grid.Column>
          <Grid.Column>
            <Form.Select
              value={model.vendor_id}
              name={'vendor_id'}
              label="Vendors:"
              error={vendor_id === undefined ? false : { content: vendor_id, pointing: 'below' }}
              placeholder="Add Vendor Category"
              options={relations.vendorsCategories}
              onChange={(e, data) => this.changeValue(data)}
            />
          </Grid.Column>
        </Grid>
      </Form>
    );
  }
}

const mapStateToProps = (state) => ({
  isFetching: state.pointSales.isFetching,
  errors: state.pointSales.errors,
  isFetchingVendorsCategories: state.vendorCategories.isFetching,
  vendorsCategories: state.vendorCategories.payload.vendorCategories,
  actionType: state.pointSales.payload.actionType,
  pointSale: state.pointSales.payload.pointSale,
});

const mapDispatchToProps = (dispatch) => ({
  fnPointSaleStore: (data) => {
    dispatch(pointSaleStore(data));
  },
  fnVendorCategories: () => {
    dispatch(vendorCategoryIndex(true));
  },
  fnPointSaleUpdate: (id, data) => {
    dispatch(pointSaleUpdate(id, data));
  },
});

PointSaleForm.propTypes = {
  isFetching: PropTypes.bool,
  errors: PropTypes.PropTypes.exact({
    errors: PropTypes.object,
    message: PropTypes.string,
  }),
  actionType: PropTypes.oneOf(['store', 'update']),
  fnPointSaleStore: PropTypes.func,
  fnPointSaleUpdate: PropTypes.func,
  fnVendorCategories: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PointSaleForm);
