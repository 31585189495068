import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Dimmer, Loader, Table, Button } from 'semantic-ui-react';
import { Modal as ModalCruiseArrival, Pagination, TitleHead, ContextMenu } from '../app/common';
import FormCruiseArrival from './CruiseArrivalForm';
import { Menu, Item, contextMenu, animation, theme } from 'react-contexify';
import {
  cruiseArrivalDelete,
  cruiseArrivalIndex,
  cruiseArrivalShow,
  cruiseArrivalStore,
  cruiseArrivalUpdate,
  cruiseArrivalActionStore,
  cruiseArrivalActionUpdate,
  cruiseArrivalOpenModal,
  cruiseArrivalCloseModal,
} from './actions';
import { CRUISE_ARRIVAL_MODULE_NAME } from '../constants';
import PropTypes from 'prop-types';

class CruiseArrival extends Component {
  state = {
    actionType: null,
    data: null,
  };

  constructor(props) {
    super(props);
    console.log(this.props);
  }

  componentDidMount() {
    this.props.fnCruiseArrivalIndex();
  }

  toRender = {
    id: {
      type: 'number',
      visible: false,
      name: '',
    },
    date_arrival: {
      type: 'string',
      visible: true,
      name: 'Fecha de arrivo',
    },
    point_sale_id: {
      type: 'string',
      visible: true,
      name: 'Point Sale id',
    },
    status: {
      type: 'string',
      visible: true,
      name: 'Status',
    },
  };

  renderTable = (data) => {
    const allowed = [];
    Object.keys(this.toRender).map((k) => {
      allowed.push(k);
    });

    return data.map((ag) =>
      Object.keys(ag)
        .filter((key) => allowed.includes(key))
        .reduce((obj, key) => {
          // console.log()
          obj[key] = { ...{ value: ag[key] }, ...this.toRender[key] };
          return obj;
        }, {})
    );
  };

  openContextMenu = (e, data) => {
    const {
      fnCruiseArrivalModalOpen,
      fnCruiseArrivalShow,
      fnCruiseArrivalActionUpdate,
      fnCruiseArrivalDelete,
      fnCruiseArrivalActionStore,
    } = this.props;
    e.preventDefault();
    contextMenu.show({
      id: 'menu_id',
      event: e,
      props: {
        data: { ...data },
        fnModalOpen: fnCruiseArrivalModalOpen,
        fnShow: fnCruiseArrivalShow,
        fnActionUpdate: fnCruiseArrivalActionUpdate,
        fnDeletee: fnCruiseArrivalDelete,
        fnActionStore: fnCruiseArrivalActionStore,
      },
    });
  };

  render() {
    const {
      payload,
      isFetching,
      isModalOpen,
      fnCruiseArrivalIndex,
      fnCruiseArrivalModalClose,
    } = this.props;
    const { cruiseArrivals, pagination } = payload;
    return (
      <div className={'row'}>
        <div className={'col-md-12'}>
          <div className={'main-card mb-3 card'}>
            <div className={'card-body'}>
              <h5 className={'card-title'}>
                {CRUISE_ARRIVAL_MODULE_NAME.substr(0, CRUISE_ARRIVAL_MODULE_NAME.length - 1)} Lists
              </h5>
              <div className={'row'}>
                <Pagination
                  title={`Show ${CRUISE_ARRIVAL_MODULE_NAME.toLowerCase()} by`}
                  {...pagination}
                  fnIndex={fnCruiseArrivalIndex}
                />
              </div>
              <div className={'row'}>
                <div className="col-md-12">
                  <ModalCruiseArrival
                    isOpen={isModalOpen}
                    actionType={payload.actionType}
                    modalTitle={CRUISE_ARRIVAL_MODULE_NAME}
                    isFetchingButton={isFetching && isModalOpen}
                    onClose={fnCruiseArrivalModalClose}
                  >
                    <FormCruiseArrival />
                  </ModalCruiseArrival>
                  <Dimmer.Dimmable blurring={true} dimmed={isFetching}>
                    <Dimmer active={isFetching} inverted={true}>
                      <Loader>Loading</Loader>
                    </Dimmer>
                    <Table compact={true} celled={true}>
                      <Table.Header>
                        <Table.Row>
                          {Object.keys(this.toRender).map((key, index) => {
                            return index === 0 ? (
                              <Fragment key={1}>
                                <Table.HeaderCell>#</Table.HeaderCell>
                                {this.toRender[key].visible && (
                                  <Table.HeaderCell>{this.toRender[key].name}</Table.HeaderCell>
                                )}
                              </Fragment>
                            ) : (
                              this.toRender[key].visible && (
                                <Table.HeaderCell>{this.toRender[key].name}</Table.HeaderCell>
                              )
                            );
                          })}
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {cruiseArrivals.length > 0 ? (
                          this.renderTable(cruiseArrivals).map((data, index) => {
                            const { per_page, to, total } = pagination;
                            const row = (total > per_page ? to - per_page : to - total) + index + 1;
                            const rows = [];
                            //Pushing Rows number
                            if (data.id) {
                              rows.push(<Table.Cell>{row}</Table.Cell>);
                            }
                            // Push Other cells
                            Object.keys(data).filter((key) => {
                              if (data[key].visible) {
                                rows.push(<Table.Cell>{data[key].value}</Table.Cell>);
                              }
                            });
                            return (
                              <Table.Row
                                key={cruiseArrivals[index].id}
                                onContextMenu={(e) =>
                                  this.openContextMenu(e, cruiseArrivals[index])
                                }
                              >
                                {rows}
                              </Table.Row>
                            );
                          })
                        ) : (
                          <Table.Row onContextMenu={(e) => this.openContextMenu(e, [])}>
                            <Table.Cell>Table is empty...</Table.Cell>
                          </Table.Row>
                        )}
                      </Table.Body>
                    </Table>
                    <ContextMenu
                      canUpdate={cruiseArrivals.length > 0}
                      canStatus={cruiseArrivals.length > 0}
                    />
                  </Dimmer.Dimmable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateProps = (state, ownProps = {}) => ({
  isFetching: state.cruiseArrivals.isFetching,
  payload: state.cruiseArrivals.payload,
  errors: state.cruiseArrivals.errors,
  isModalOpen: state.cruiseArrivals.isModalOpen,
  actionType: state.cruiseArrivals.payload.actionType,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  fnCruiseArrivalIndex: (forRelation, page, items) => {
    dispatch(cruiseArrivalIndex(forRelation, page, items));
  },
  fnCruiseArrivalDelete: (id) => {
    dispatch(cruiseArrivalDelete(id));
  },
  fnCruiseArrivalShow: (id) => {
    dispatch(cruiseArrivalShow(id));
  },
  fnCruiseArrivalModalOpen: () => {
    dispatch(cruiseArrivalOpenModal());
  },
  fnCruiseArrivalModalClose: () => {
    dispatch(cruiseArrivalCloseModal());
  },
  fnCruiseArrivalActionStore: () => {
    dispatch(cruiseArrivalActionStore());
  },
  fnCruiseArrivalActionUpdate: () => {
    dispatch(cruiseArrivalActionUpdate());
  },
  fnCruiseArrivalStore: (data) => {
    dispatch(cruiseArrivalStore(data));
  },
  fnCruiseArrivalUpdate: (id, data) => {
    dispatch(cruiseArrivalUpdate(id, data));
  },
});

CruiseArrival.propTypes = {
  fnCruiseArrivalIndex: PropTypes.func,
  fnCruiseArrivalDelete: PropTypes.func,
  fnCruiseArrivalShow: PropTypes.func,
  fnCruiseArrivalModalOpen: PropTypes.func,
  fnCruiseArrivalModalClose: PropTypes.func,
  fnCruiseArrivalActionStore: PropTypes.func,
  fnCruiseArrivalActionUpdate: PropTypes.func,
  fnCruiseArrivalStore: PropTypes.func,
  fnCruiseArrivalUpdate: PropTypes.func,
  isFetching: PropTypes.bool,
  isModalOpen: PropTypes.bool,
  payload: PropTypes.exact({
    cruiseArrival: PropTypes,
    cruiseArrivals: PropTypes,
    pagination: PropTypes,
    message: PropTypes.string,
    actionType: PropTypes.oneOf(['store', 'update']),
  }),
  errors: PropTypes.object,
};

export default connect(
  mapStateProps,
  mapDispatchToProps
)(CruiseArrival);
