import vendorTypes from './types';
// DESTROY DISPATCH
export const vendorDeleteFail = (payload) => {
  return {
    type: vendorTypes.VENDOR_DESTROY_FAIL,
    errors: {
      ...payload,
    },
  };
};
export const vendorDeleteFetch = () => ({
  type: vendorTypes.VENDOR_DESTROY_FETCH,
});
export const vendorDeleteSuccess = (payload) => ({
  type: vendorTypes.VENDOR_DESTROY_SUCCESS,
  payload: { ...payload },
});

// INDEX DISPATCH
export const vendorIndexFail = (payload) => ({
  type: vendorTypes.VENDOR_INDEX_FAIL,
  payload,
});

export const vendorIndexFetch = () => ({
  type: vendorTypes.VENDOR_INDEX_FETCH,
});

export const vendorIndexSuccess = (payload) => ({
  type: vendorTypes.VENDOR_INDEX_SUCCESS,
  payload: {
    ...payload,
  },
});

// SHOW DISPATCH
export const vendorShowFail = (payload) => ({
  type: vendorTypes.VENDOR_SHOW_FAIL,
  errors: {
    ...payload,
  },
});
export const vendorShowFetch = () => ({
  type: vendorTypes.VENDOR_SHOW_FETCH,
});
export const vendorShowSuccess = (payload) => ({
  type: vendorTypes.VENDOR_SHOW_SUCCESS,
  payload: {
    ...payload,
  },
});

// STORE DISPATCH
export const vendorStoreFail = (payload) => {
  return {
    type: vendorTypes.VENDOR_STORE_FAIL,
    errors: {
      ...payload,
    },
  };
};

export const vendorStoreFetch = () => ({
  type: vendorTypes.VENDOR_STORE_FETCH,
});
export const vendorStoreSuccess = (payload) => ({
  type: vendorTypes.VENDOR_STORE_SUCCESS,
  payload: { ...payload },
});

// UPDATE DISPATCH
export const vendorUpdateFail = (payload) => {
  return {
    type: vendorTypes.VENDOR_UPDATE_FAIL,
    errors: {
      ...payload,
    },
  };
};
export const vendorUpdateFetch = () => ({
  type: vendorTypes.VENDOR_UPDATE_FETCH,
});
export const vendorUpdateSuccess = (payload) => ({
  type: vendorTypes.VENDOR_UPDATE_SUCCESS,
  payload: {
    ...payload,
  },
});

//Modal
export const vendorModalOpen = () => ({
  type: vendorTypes.VENDOR_MODAL_OPEN,
});
export const vendorModalClose = () => ({
  type: vendorTypes.VENDOR_MODAL_CLOSE,
});
export const vendorActionTypeStore = (payload) => ({
  type: vendorTypes.VENDOR_ACTION_TYPE_STORE,
  payload: { ...payload },
});
export const vendorActionTypeUpdate = (payload) => ({
  type: vendorTypes.VENDOR_ACTION_TYPE_UPDATE,
  payload: { ...payload },
});
