import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Dimmer, Loader, Table, Button } from 'semantic-ui-react';
import { Menu, contextMenu, animation, theme } from 'react-contexify';
import {
  pointSaleDelete,
  pointSaleIndex,
  pointSaleShow,
  pointSaleStore,
  pointSaleUpdate,
  pointSaleActionStore,
  pointSaleActionUpdate,
  pointSaleCloseModal,
  pointSaleOpenModal,
} from './actions';
import { POINT_SALE_MODULE_NAME } from '../constants';
import { TitleHead, ContextMenu, Pagination, Modal as ModalPointSale } from '../app/common';
import PointSaleForm from './PointSaleForm';
import PropTypes from 'prop-types';

class PointSales extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.fnPointSaleIndex();
  }

  toRender = {
    id: {
      type: 'number',
      visible: false,
      name: '',
    },
    name: {
      type: 'string',
      visible: true,
      name: 'nombre',
    },
    vendor_id: {
      type: 'string',
      visible: true,
      name: 'Canal de distribución ID',
    },
    status: {
      type: 'number',
      visible: true,
      name: 'Status',
    },
  };

  renderTable = (data) => {
    const allowed = [];
    Object.keys(this.toRender).map((k) => {
      allowed.push(k);
    });

    return data.map((ag) =>
      Object.keys(ag)
        .filter((key) => allowed.includes(key))
        .reduce((obj, key) => {
          // console.log()
          obj[key] = { ...{ value: ag[key] }, ...this.toRender[key] };
          return obj;
        }, {})
    );
  };

  openContextMenu = (e, data) => {
    const {
      fnPointSaleDelete,
      fnPointSaleModalOpen,
      fnPointSaleActionStore,
      fnPointSaleActionUpdate,
      fnPointSaleShow,
    } = this.props;
    e.preventDefault();
    contextMenu.show({
      id: 'menu_id',
      event: e,
      props: {
        data: { ...data },
        fnDelete: fnPointSaleDelete,
        fnModalOpen: fnPointSaleModalOpen,
        fnActionStore: fnPointSaleActionStore,
        fnActionUpdate: fnPointSaleActionUpdate,
        fnShow: fnPointSaleShow,
      },
    });
  };
  render() {
    const {
      payload,
      isFetching,
      fnPointSaleIndex,
      isModalOpen,
      fnPointSaleModalClose,
    } = this.props;
    const { pointSales, pagination, pointSale } = payload;
    return (
      <Fragment>
        <TitleHead title={POINT_SALE_MODULE_NAME} />
        <div className={'row'}>
          <div className={'col-md-12'}>
            <ModalPointSale
              isOpen={isModalOpen}
              size={'mini'}
              actionType={payload.actionType}
              modalTitle={POINT_SALE_MODULE_NAME}
              isFetchingButton={isFetching && isModalOpen}
              onClose={fnPointSaleModalClose}
            >
              <PointSaleForm />
            </ModalPointSale>
            <div className={'main-card mb-3 card'}>
              <div className={'card-body'}>
                <h5 className={'card-title'}>
                  {POINT_SALE_MODULE_NAME.substr(0, POINT_SALE_MODULE_NAME.length - 1)} Lists
                </h5>
                <div className={'row'}>
                  <Pagination
                    title={`Show ${POINT_SALE_MODULE_NAME.toLowerCase()} by`}
                    {...pagination}
                    fnIndex={fnPointSaleIndex}
                  />
                  <div className="col-md-12">
                    <Dimmer.Dimmable blurring={true} dimmed={isFetching}>
                      <Dimmer active={isFetching} inverted={true}>
                        <Loader>Loading</Loader>
                      </Dimmer>
                      <Table compact={true} celled={true}>
                        <Table.Header>
                          <Table.Row>
                            {Object.keys(this.toRender).map((key, index) => {
                              return index === 0 ? (
                                <Fragment key={1}>
                                  <Table.HeaderCell>#</Table.HeaderCell>
                                  {this.toRender[key].visible && (
                                    <Table.HeaderCell>{this.toRender[key].name}</Table.HeaderCell>
                                  )}
                                </Fragment>
                              ) : (
                                this.toRender[key].visible && (
                                  <Table.HeaderCell>{this.toRender[key].name}</Table.HeaderCell>
                                )
                              );
                            })}
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {this.renderTable(pointSales).map((data, index) => {
                            const { per_page, to, total } = pagination;
                            const row = (total > per_page ? to - per_page : to - total) + index + 1;
                            const rows = [];
                            //Pushing Rows number
                            if (data.id) {
                              rows.push(<Table.Cell>{row}</Table.Cell>);
                            }
                            // Push Other cells
                            Object.keys(data).filter((key) => {
                              if (data[key].visible) {
                                rows.push(<Table.Cell>{data[key].value}</Table.Cell>);
                              }
                            });
                            return (
                              <Table.Row
                                onContextMenu={(e) => this.openContextMenu(e, pointSales[index])}
                              >
                                {rows}
                              </Table.Row>
                            );
                          })}
                        </Table.Body>
                      </Table>
                      <ContextMenu />
                    </Dimmer.Dimmable>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*{user.id !== null ? alert(`Usuario Cargado ${user.name}`) : ''}*/}
        </div>
      </Fragment>
    );
  }
}

const mapStateProps = (state, ownProps = {}) => ({
  isFetching: state.pointSales.isFetching,
  payload: state.pointSales.payload,
  errors: state.pointSales.errors,
  isModalOpen: state.pointSales.isModalOpen,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  fnPointSaleIndex: (forRelation, page, items) => {
    dispatch(pointSaleIndex(forRelation, page, items));
  },
  fnPointSaleDelete: (id) => {
    dispatch(pointSaleDelete(id));
  },
  fnPointSaleShow: (id) => {
    dispatch(pointSaleShow(id));
  },
  fnPointSaleStore: (data) => {
    dispatch(pointSaleStore(data));
  },
  fnPointSaleUpdate: (id, data) => {
    dispatch(pointSaleUpdate(id, data));
  },
  fnPointSaleModalOpen: () => {
    dispatch(pointSaleOpenModal());
  },
  fnPointSaleModalClose: () => {
    dispatch(pointSaleCloseModal());
  },
  fnPointSaleActionStore: () => {
    dispatch(pointSaleActionStore());
  },
  fnPointSaleActionUpdate: () => {
    dispatch(pointSaleActionUpdate());
  },
});

PointSales.propTypes = {
  fnPointSaleDelete: PropTypes.func,
  fnPointSaleShow: PropTypes.func,
  fnPointSaleModalOpen: PropTypes.func,
  fnPointSaleModalClose: PropTypes.func,
  fnPointSaleActionStore: PropTypes.func,
  fnPointSaleActionUpdate: PropTypes.func,
  isFetching: PropTypes.bool,
  isModalOpen: PropTypes.bool,
  payload: PropTypes.exact({
    pointSale: PropTypes,
    pointSales: PropTypes,
    pagination: PropTypes,
    message: PropTypes.string,
    actionType: PropTypes.oneOf(['store', 'update']),
  }),
  errors: PropTypes.object,
};

export default connect(
  mapStateProps,
  mapDispatchToProps
)(PointSales);
