import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Dimmer, Loader, Table, Button } from 'semantic-ui-react';
import { Modal as ModalSale, Pagination, TitleHead, ContextMenu } from '../app/common';
// import { Modal as ModalSale, Pagination } from '../app/common';
import FormSale from './SaleForm';
import { Menu, Item, contextMenu, animation, theme } from 'react-contexify';
import {
  saleDelete,
  saleIndex,
  saleShowAsync,
  saleUpdate,
  saleOpenModal,
  saleCloseModal,
  saleActionStore,
  saleActionUpdate,
  salePaginate,
  test,
} from './actions';
import { RATE_MODULE_NAME, SALE_MODULE_NAME } from '../constants';
import PropTypes from 'prop-types';

class Sales extends Component {
  constructor(props) {
    super(props);
    this.state = {
      actionType: null,
      data: null,
    };
  }

  componentDidMount() {
    this.props.fntest(true);
  }

  toRender = {
    id: {
      type: 'number',
      visible: false,
      name: '',
    },
    folio: {
      type: 'string',
      visible: true,
      name: 'Folio',
    },
    date_operation: {
      type: 'string',
      visible: true,
      name: 'date',
    },
    vendor_id: {
      type: 'number',
      visible: true,
      name: 'vendor',
    },
    'reference.client_name': {
      relation: true,
      type: 'string',
      visible: true,
      name: 'Client Name',
    },
    status: {
      type: 'number',
      visible: true,
      name: 'Estado',
    },
  };

  renderTable = (data) => {
    const allowed = [];
    // console.log(this.toRender);
    Object.keys(this.toRender).map((k) => {
      const value = this.toRender[k];
      let key = '';
      if (value.relation === true) {
        key = k.split('.');
      } else key = k;
      allowed.push(key);
    });

    return data.map((ag) =>
      Object.keys(ag)
        .filter((key) => {
          return allowed.includes(key);
        })
        .reduce((obj, key) => {
          obj[key] = { ...{ value: ag[key] }, ...this.toRender[key] };
          return obj;
        }, {})
    );
  };

  openContextMenu = (e, data) => {
    const {
      fnSaleModalOpen,
      fnSaleShow,
      fnSaleActionUpdate,
      fnSaleActionStore,
      fnSaleDelete,
    } = this.props;
    e.preventDefault();
    contextMenu.show({
      id: 'menu_id',
      event: e,
      props: {
        data: { ...data },
        fnModalOpen: fnSaleModalOpen,
        fnShow: fnSaleShow,
        fnActionUpdate: fnSaleActionUpdate,
        fnDelete: fnSaleDelete,
        fnActionStore: fnSaleActionStore,
      },
    });
  };

  render() {
    const {
      payload,
      isFetching,
      isModalOpen,
      fnSalePaginate,
      fnSaleModalClose,
      fntest,
    } = this.props;
    const { sales, pagination } = payload;
    return (
      <div className={'row'}>
        <div className={'col-md-12'}>
          <div className={'main-card mb-3 card'}>
            <div className={'card-body'}>
              <h5 className={'card-title'}>
                {SALE_MODULE_NAME.substr(0, SALE_MODULE_NAME.length - 1)} Lists
              </h5>
              <div className={'row'}>
                <Pagination
                  title={`Show ${SALE_MODULE_NAME.toLowerCase()} by`}
                  {...pagination}
                  fnIndex={fntest}
                />
              </div>
              <div className={'row'}>
                <div className="col-md-12">
                  <ModalSale
                    isOpen={isModalOpen}
                    actionType={payload.actionType}
                    modalTitle={SALE_MODULE_NAME}
                    isFetchingButton={isFetching && isModalOpen}
                    onClose={fnSaleModalClose}
                  >
                    <FormSale />
                  </ModalSale>
                  <Dimmer.Dimmable blurring={true} dimmed={isFetching}>
                    <Dimmer active={isFetching} inverted={true}>
                      <Loader>Loading</Loader>
                    </Dimmer>
                    <Table compact={true} celled={true}>
                      <Table.Header>
                        <Table.Row>
                          {Object.keys(this.toRender).map((key, index) => {
                            return index === 0 ? (
                              <Fragment key={1}>
                                <Table.HeaderCell>#</Table.HeaderCell>
                                {this.toRender[key].visible && (
                                  <Table.HeaderCell>{this.toRender[key].name}</Table.HeaderCell>
                                )}
                              </Fragment>
                            ) : (
                              this.toRender[key].visible && (
                                <Table.HeaderCell>{this.toRender[key].name}</Table.HeaderCell>
                              )
                            );
                          })}
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {sales.length > 0 ? (
                          this.renderTable(sales).map((data, index) => {
                            const { per_page, to, total } = pagination;
                            const row = (total > per_page ? to - per_page : to - total) + index + 1;
                            const rows = [];
                            //Pushing Rows number
                            if (data.id) {
                              rows.push(<Table.Cell>{row}</Table.Cell>);
                            }
                            // Push Other cells
                            Object.keys(data).filter((key) => {
                              if (data[key].visible) {
                                rows.push(<Table.Cell>{data[key].value}</Table.Cell>);
                              }
                            });
                            return (
                              <Table.Row
                                key={sales[index].id}
                                onContextMenu={(e) => this.openContextMenu(e, sales[index])}
                              >
                                {rows}
                              </Table.Row>
                            );
                          })
                        ) : (
                          <Table.Row onContextMenu={(e) => this.openContextMenu(e, [])}>
                            <Table.Cell>Table is empty...</Table.Cell>
                          </Table.Row>
                        )}
                      </Table.Body>
                    </Table>
                    <ContextMenu canUpdate={sales.length > 0} canStatus={sales.length > 0}/>
                  </Dimmer.Dimmable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateProps = (state, ownProps = {}) => ({
  isFetching: state.sales.isFetching,
  payload: state.sales.payload,
  errors: state.sales.errors,
  isModalOpen: state.sales.isModalOpen,
  // actionType: state.sales.payload.actionType,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  fntest: (forRelation = true, page = null, items = null) => {
    dispatch(test(forRelation, null, page, items));
  },
  fnSaleIndex: (forRelation, page, items) => {
    dispatch(saleIndex(forRelation, page, items));
  },
  fnSaleDelete: (id) => {
    dispatch(saleDelete(id));
  },
  fnSaleUpdate: (id, data) => {
    dispatch(saleUpdate(id, data));
  },
  fnSaleShow: (id) => {
    dispatch(saleShowAsync(id));
  },
  fnSalePaginate: (forRelation, page, items) => {
    dispatch(salePaginate(forRelation, page, items));
  },
  fnSaleModalOpen: () => {
    dispatch(saleOpenModal());
  },
  fnSaleModalClose: () => {
    dispatch(saleCloseModal());
  },
  fnSaleActionStore: () => {
    dispatch(saleActionStore());
  },
  fnSaleActionUpdate: () => {
    dispatch(saleActionUpdate());
  },
});

Sales.propTypes = {
  fnSaleModalOpen: PropTypes.func,
  fnSaleModalClose: PropTypes.func,
  fnSaleShow: PropTypes.func,
  fnSaleActionStore: PropTypes.func,
  fnSaleActionUpdate: PropTypes.func,
  isFetching: PropTypes.bool,
  isModalOpen: PropTypes.bool,
  payload: PropTypes.exact({
    sale: PropTypes,
    sales: PropTypes,
    pagination: PropTypes,
    message: PropTypes.string,
    actionType: PropTypes.oneOf(['store', 'update']),
  }),
  errors: PropTypes.object,
};
export default connect(
  mapStateProps,
  mapDispatchToProps
)(Sales);
