import React from 'react';
import { Button, Header, Icon } from 'semantic-ui-react';

const TitleHead = ({ title }) => {
  return (
    <div className={'app-page-title'}>
      <div className={'page-title-wrapper'}>
        <div className={'page-title-heading'}>
          <div className={'page-title-icon'}>
            <i className={'pe-7s-users icon-gradient bg-mean-fruit'} />
          </div>
          <Header as="h1">{title}</Header>
        </div>
        <div className={'page-title-actions'}>
          <Button.Group>
            <Button color={'red'}>
              <Icon name={'file pdf'} />
              PDF
            </Button>
            <Button.Or text={''} />
            <Button positive={true}>
              <Icon name={'file excel'} />
              Excel
            </Button>
            <Button.Or text={''} />
            <Button primary={true}>
              {' '}
              <Icon name={'file word'} /> Word
            </Button>
          </Button.Group>
        </div>
      </div>
    </div>
  );
};

export default TitleHead;
