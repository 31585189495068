import categoryTypes from './types';
// DESTROY DISPATCH
export const categoryDeleteFail = (payload) => {
  return {
    type: categoryTypes.CATEGORY_DESTROY_FAIL,
    errors: {
      ...payload,
    },
  };
};
export const categoryDeleteFetch = () => ({
  type: categoryTypes.CATEGORY_DESTROY_FETCH,
});
export const categoryDeleteSuccess = (payload) => ({
  type: categoryTypes.CATEGORY_DESTROY_SUCCESS,
  payload,
});

// INDEX DISPATCH
export const categoryIndexFail = (payload) => ({
  type: categoryTypes.CATEGORY_INDEX_FAIL,
  payload,
});

export const categoryIndexFetch = () => ({
  type: categoryTypes.CATEGORY_INDEX_FETCH,
});

export const categoryIndexSuccess = (payload) => ({
  type: categoryTypes.CATEGORY_INDEX_SUCCESS,
  payload: {
    ...payload,
  },
});

// SHOW DISPATCH
export const categoryShowFail = (payload) => ({
  type: categoryTypes.CATEGORY_SHOW_FAIL,
  errors: {
    ...payload,
  },
});
export const categoryShowFetch = () => ({
  type: categoryTypes.CATEGORY_SHOW_FETCH,
});
export const categoryShowSuccess = (payload) => ({
  type: categoryTypes.CATEGORY_SHOW_SUCCESS,
  payload: {
    ...payload,
  },
});

// STORE DISPATCH
export const categoryStoreFail = (payload) => {
  return {
    type: categoryTypes.CATEGORY_STORE_FAIL,
    errors: {
      ...payload,
    },
  };
};

export const categoryStoreFetch = () => ({
  type: categoryTypes.CATEGORY_STORE_FETCH,
});
export const categoryStoreSuccess = (payload) => ({
  type: categoryTypes.CATEGORY_STORE_SUCCESS,
  payload,
});

// UPDATE DISPATCH
export const categoryUpdateFail = (payload) => {
  return {
    type: categoryTypes.CATEGORY_UPDATE_FAIL,
    errors: {
      ...payload,
    },
  };
};
export const categoryUpdateFetch = () => ({
  type: categoryTypes.CATEGORY_UPDATE_FETCH,
});
export const categoryUpdateSuccess = (payload) => ({
  type: categoryTypes.CATEGORY_UPDATE_SUCCESS,
  payload: {
    ...payload,
  },
});
