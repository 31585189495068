import paymentTypeService from './service';
import {
  paymentTypeDeleteFail,
  paymentTypeDeleteFetch,
  paymentTypeDeleteSuccess,
  paymentTypeIndexFail,
  paymentTypeIndexFetch,
  paymentTypeIndexSuccess,
  paymentTypeShowFail,
  paymentTypeShowFetch,
  paymentTypeShowSuccess,
  paymentTypeStoreFail,
  paymentTypeStoreFetch,
  paymentTypeStoreSuccess,
  paymentTypeUpdateFail,
  paymentTypeUpdateFetch,
  paymentTypeUpdateSuccess,
} from './dispatch';

export const paymentTypeDelete = (id) => async (dispatch) => {
  dispatch(paymentTypeDeleteFetch());
  try {
    const { data, message } = await paymentTypeService.paymentTypeDelete(id);
    dispatch(paymentTypeDeleteSuccess({ payload: data, message: message }));
  } catch (e) {
    const { error } = e;
    dispatch(paymentTypeDeleteFail({ error }));
  }
};

export const paymentTypeIndex = (forRelation = false, page = null, items = null) => async (
  dispatch,
  getState
) => {
  dispatch(paymentTypeIndexFetch());
  try {
    //If is not paginates values, Get default value from state
    if (!forRelation) {
      page = page ? page : getState().paymentTypes.payload.pagination.current_page;
      items = items ? items : getState().paymentTypes.payload.pagination.per_page;
    }

    const { data, meta } = await paymentTypeService.paymentTypeIndex(page, items);
    dispatch(paymentTypeIndexSuccess({ paymentTypes: data, pagination: meta }));
  } catch (e) {
    dispatch(paymentTypeIndexFail(e));
  }
};

export const paymentTypeShow = (id) => async (dispatch) => {
  dispatch(paymentTypeShowFetch());
  try {
    const { data } = await paymentTypeService.paymentTypeShow(id);
    dispatch(paymentTypeShowSuccess({ paymentType: data }));
  } catch (e) {
    const { error } = e;
    dispatch(paymentTypeShowFail({ error }));
  }
};

export const paymentTypeStore = (data) => async (dispatch) => {
  dispatch(paymentTypeStoreFetch());
  try {
    const { message } = await paymentTypeService.paymentTypeStore(data);
    dispatch(paymentTypeStoreSuccess({ message }));
  } catch (e) {
    const { errors, message } = e;
    dispatch(paymentTypeStoreFail({ errors, message }));
  }
};

export const paymentTypeUpdate = (id, data) => async (dispatch) => {
  dispatch(paymentTypeUpdateFetch());
  try {
    const { message } = await paymentTypeService.paymentTypeUpdate(id, data);
    dispatch(paymentTypeUpdateSuccess({ message }));
  } catch (e) {
    const { errors, message } = e;
    dispatch(paymentTypeUpdateFail({ errors, message }));
  }
};
