import Option from '../option/model';
export const MODULE_NAME = 'Slots';
export const ROUTE = '/slots';

export const RELATIONS = [];

export const sloteModel = {
  // id: null,
  code: null,
  name: null,
  optionables: {},
  capacity: null,
  // created_at: null,
  // updated_at: null,
  // updated_id: null,
};
