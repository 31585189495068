import sellerTypes from './types';
// DESTROY DISPATCH
export const sellerDeleteFail = (payload) => {
  return {
    type: sellerTypes.SELLER_DESTROY_FAIL,
    errors: {
      ...payload,
    },
  };
};
export const sellerDeleteFetch = () => ({
  type: sellerTypes.SELLER_DESTROY_FETCH,
});
export const sellerDeleteSuccess = (payload) => ({
  type: sellerTypes.SELLER_DESTROY_SUCCESS,
  payload,
});

// INDEX DISPATCH
export const sellerIndexFail = (payload) => ({
  type: sellerTypes.SELLER_INDEX_FAIL,
  payload,
});

export const sellerIndexFetch = () => ({
  type: sellerTypes.SELLER_INDEX_FETCH,
});

export const sellerIndexSuccess = (payload) => ({
  type: sellerTypes.SELLER_INDEX_SUCCESS,
  payload: {
    ...payload,
  },
});

// SHOW DISPATCH
export const sellerShowFail = (payload) => ({
  type: sellerTypes.SELLER_SHOW_FAIL,
  errors: {
    ...payload,
  },
});
export const sellerShowFetch = () => ({
  type: sellerTypes.SELLER_SHOW_FETCH,
});
export const sellerShowSuccess = (payload) => ({
  type: sellerTypes.SELLER_SHOW_SUCCESS,
  payload: {
    ...payload,
  },
});

// STORE DISPATCH
export const sellerStoreFail = (payload) => {
  return {
    type: sellerTypes.SELLER_STORE_FAIL,
    errors: {
      ...payload,
    },
  };
};

export const sellerStoreFetch = () => ({
  type: sellerTypes.SELLER_STORE_FETCH,
});
export const sellerStoreSuccess = (payload) => ({
  type: sellerTypes.SELLER_STORE_SUCCESS,
  payload,
});

// UPDATE DISPATCH
export const sellerUpdateFail = (payload) => {
  return {
    type: sellerTypes.SELLER_UPDATE_FAIL,
    errors: {
      ...payload,
    },
  };
};
export const sellerUpdateFetch = () => ({
  type: sellerTypes.SELLER_UPDATE_FETCH,
});
export const sellerUpdateSuccess = (payload) => ({
  type: sellerTypes.SELLER_UPDATE_SUCCESS,
  payload: {
    ...payload,
  },
});

export const sellerModalOpen = () => ({
  type: sellerTypes.SELLER_MODAL_OPEN,
});
export const sellerModalClose = () => ({
  type: sellerTypes.SELLER_MODAL_CLOSE,
});
export const sellerActionTypeStore = (payload) => ({
  type: sellerTypes.SELLER_ACTION_TYPE_STORE,
  payload: { ...payload },
});
export const sellerActionTypeUpdate = (payload) => ({
  type: sellerTypes.SELLER_ACTION_TYPE_UPDATE,
  payload: { ...payload },
});
