const types = {
  // FOR CRUD DEPARTURES
  ITEM_DESTROY_FAIL: 'ITEM_DESTROY_FAIL',
  ITEM_DESTROY_FETCH: 'ITEM_DESTROY_FETCH',
  ITEM_DESTROY_SUCCESS: 'ITEM_DESTROY_SUCCESS',
  ITEM_INDEX_FAIL: 'ITEM_INDEX_FAIL',
  ITEM_INDEX_FETCH: 'ITEM_INDEX_FETCH',
  ITEM_INDEX_SUCCESS: 'ITEM_INDEX_SUCCESS',
  ITEM_SHOW_FAIL: 'ITEM_SHOW_FAIL',
  ITEM_SHOW_FETCH: 'ITEM_SHOW_FETCH',
  ITEM_SHOW_SUCCESS: 'ITEM_SHOW_SUCCESS',
  ITEM_STORE_FAIL: 'ITEM_STORE_FAIL',
  ITEM_STORE_FETCH: 'ITEM_STORE_FETCH',
  ITEM_STORE_SUCCESS: 'ITEM_STORE_SUCCESS',
  ITEM_UPDATE_FAIL: 'ITEM_UPDATE_FAIL',
  ITEM_UPDATE_FETCH: 'ITEM_UPDATE_FETCH',
  ITEM_UPDATE_SUCCESS: 'ITEM_UPDATE_SUCCESS',
  ITEM_LOAD_RELATION_FETCH: 'ITEM_LOAD_RELATION_FETCH',
  ITEM_LOAD_RELATION_SUCCESS: 'ITEM_LOAD_RELATION_SUCCESS',
  // FOR MODAL ITEM
  ITEM_MODAL_OPEN: ' ITEM_MODAL_OPEN',
  ITEM_MODAL_CLOSE: 'ITEM_MODAL_CLOSE',
  ITEM_ACTION_TYPE_STORE: 'ITEM_ACTION_CREATE_TYPE',
  ITEM_ACTION_TYPE_UPDATE: 'ITEM_ACTION_UPDATE_TYPE',
};

export const typeStates = {
  FAIL: '_FAIL',
  FETCH: '_FETCH',
  SUCCESS: '_SUCCESS',
};

export default types;
