import React from 'react';
import { Link } from 'react-router-dom';
const SideBarItem = (props) => {
  return (
    <li
      className={props.active ? 'mm-active' : ''}
      onClick={(e) => {
        props.toggleMenu(props.keyMap);
      }}
    >
      <Link
        to={props.to}
        aria-expanded={props.active ? 'true' : 'false'}
        className={props.active ? 'mm-active' : ''}
      >
        <i className={`metismenu-icon ${props.icon}`} />
        {props.label}
        {!props.to && <i className="metismenu-state-icon pe-7s-angle-down caret-left" />}
      </Link>
      <ul className={`mm-collapse ${props.active ? 'mm-show' : ''}`}>{props.children}</ul>
    </li>
  );
};

export default SideBarItem;
