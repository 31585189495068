const types = {
  // FOR CRUD SELLER
  CURRENCY_DESTROY_FAIL: 'CURRENCY_DESTROY_FAIL',
  CURRENCY_DESTROY_FETCH: 'CURRENCY_DESTROY_FETCH',
  CURRENCY_DESTROY_SUCCESS: 'CURRENCY_DESTROY_SUCCESS',
  CURRENCY_INDEX_FAIL: 'CURRENCY_INDEX_FAIL',
  CURRENCY_INDEX_FETCH: 'CURRENCY_INDEX_FETCH',
  CURRENCY_INDEX_SUCCESS: 'CURRENCY_INDEX_SUCCESS',
  CURRENCY_SHOW_FAIL: 'CURRENCY_SHOW_FAIL',
  CURRENCY_SHOW_FETCH: 'CURRENCY_SHOW_FETCH',
  CURRENCY_SHOW_SUCCESS: 'CURRENCY_SHOW_SUCCESS',
  CURRENCY_STORE_FAIL: 'CURRENCY_STORE_FAIL',
  CURRENCY_STORE_FETCH: 'CURRENCY_STORE_FETCH',
  CURRENCY_STORE_SUCCESS: 'CURRENCY_STORE_SUCCESS',
  CURRENCY_UPDATE_FAIL: 'CURRENCY_UPDATE_FAIL',
  CURRENCY_UPDATE_FETCH: 'CURRENCY_UPDATE_FETCH',
  CURRENCY_UPDATE_SUCCESS: 'CURRENCY_UPDATE_SUCCESS',
  // FOR MODAL SELLER
  CURRENCY_MODAL_OPEN: 'CURRENCY_MODAL_OPEN',
  CURRENCY_MODAL_CLOSE: 'CURRENCY_MODAL_CLOSE',
  CURRENCY_ACTION_TYPE_STORE: 'CURRENCY_ACTION_CREATE_TYPE',
  CURRENCY_ACTION_TYPE_UPDATE: 'CURRENCY_ACTION_UPDATE_TYPE',
};

export const typeStates = {
  FAIL: '_FAIL',
  FETCH: '_FETCH',
  SUCCESS: '_SUCCESS',
};

export default types;
