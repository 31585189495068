const types = {
  // FOR CRUD TRANSPORTATION
  TRANSPORTATION_DESTROY_FAIL: 'TRANSPORTATION_DESTROY_FAIL',
  TRANSPORTATION_DESTROY_FETCH: 'TRANSPORTATION_DESTROY_FETCH',
  TRANSPORTATION_DESTROY_SUCCESS: 'TRANSPORTATION_DESTROY_SUCCESS',
  TRANSPORTATION_INDEX_FAIL: 'TRANSPORTATION_INDEX_FAIL',
  TRANSPORTATION_INDEX_FETCH: 'TRANSPORTATION_FETCH',
  TRANSPORTATION_INDEX_SUCCESS: 'TRANSPORTATION_INDEX_SUCCESS',
  TRANSPORTATION_SHOW_FAIL: 'TRANSPORTATION_SHOW_FAIL',
  TRANSPORTATION_SHOW_FETCH: 'TRANSPORTATION_SHOW_FETCH',
  TRANSPORTATION_SHOW_SUCCESS: 'TRANSPORTATION_SHOW_SUCCESS',
  TRANSPORTATION_STORE_FAIL: 'TRANSPORTATION_STORE_FAIL',
  TRANSPORTATION_STORE_FETCH: 'TRANSPORTATION_STORE_FETCH',
  TRANSPORTATION_STORE_SUCCESS: 'TRANSPORTATION_STORE_SUCCESS',
  TRANSPORTATION_UPDATE_FAIL: 'TRANSPORTATION_UPDATE_FAIL',
  TRANSPORTATION_UPDATE_FETCH: 'TRANSPORTATION_UPDATE_FETCH',
  TRANSPORTATION_UPDATE_SUCCESS: 'TRANSPORTATION_UPDATE_SUCCESS',
  // FOR MODAL
  TRANSPORTATION_MODAL_OPEN: 'TRANSPORTATION_MODAL_OPEN',
  TRANSPORTATION_MODAL_CLOSE: 'TRANSPORTATION_MODAL_CLOSE',
  TRANSPORTATION_ACTION_TYPE_STORE: 'TRANSPORTATION_ACTION_CREATE_TYPE',
  TRANSPORTATION_ACTION_TYPE_UPDATE: 'TRANSPORTATION_ACTION_UPDATE_TYPE',
};

export const typeStates = {
  FAIL: '_FAIL',
  FETCH: '_FETCH',
  SUCCESS: '_SUCCESS',
};

export default types;
