export const MODULE_NAME = 'Sellers';
export const ROUTE = '/sellers';

const Seller = {
  id: null,
  name: null,
  status: null,
  created_id: null,
  created_at: null,
  updated_at: null,
  updated_id: null,
  email: null,
  phone: null,
  vendor_id: null,
  website: null,
};

export default Seller;
