const types = {
  // FOR CRUD PAYMENT_METHOD
  PAYMENT_METHOD_DESTROY_FAIL: 'PAYMENT_METHOD_DESTROY_FAIL',
  PAYMENT_METHOD_DESTROY_FETCH: 'PAYMENT_METHOD_DESTROY_FETCH',
  PAYMENT_METHOD_DESTROY_SUCCESS: 'PAYMENT_METHOD_DESTROY_SUCCESS',
  PAYMENT_METHOD_INDEX_FAIL: 'PAYMENT_METHOD_INDEX_FAIL',
  PAYMENT_METHOD_INDEX_FETCH: 'PAYMENT_METHOD_INDEX_FETCH',
  PAYMENT_METHOD_INDEX_SUCCESS: 'PAYMENT_METHOD_INDEX_SUCCESS',
  PAYMENT_METHOD_SHOW_FAIL: 'PAYMENT_METHOD_SHOW_FAIL',
  PAYMENT_METHOD_SHOW_FETCH: 'PAYMENT_METHOD_SHOW_FETCH',
  PAYMENT_METHOD_SHOW_SUCCESS: 'PAYMENT_METHOD_SHOW_SUCCESS',
  PAYMENT_METHOD_STORE_FAIL: 'PAYMENT_METHOD_STORE_FAIL',
  PAYMENT_METHOD_STORE_FETCH: 'PAYMENT_METHOD_STORE_FETCH',
  PAYMENT_METHOD_STORE_SUCCESS: 'PAYMENT_METHOD_STORE_SUCCESS',
  PAYMENT_METHOD_UPDATE_FAIL: 'PAYMENT_METHOD_UPDATE_FAIL',
  PAYMENT_METHOD_UPDATE_FETCH: 'PAYMENT_METHOD_UPDATE_FETCH',
  PAYMENT_METHOD_UPDATE_SUCCESS: 'PAYMENT_METHOD_UPDATE_SUCCESS',
};

export const typeStates = {
  FAIL: '_FAIL',
  FETCH: '_FETCH',
  SUCCESS: '_SUCCESS',
};

export default types;
