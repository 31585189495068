import React, { useState } from 'react';
import { connect } from 'react-redux';

import {
  Grid,
  Input,
  Select,
  GridRow,
  GridColumn,
  FormField,
  List,
  ListItem,
  ListIcon,
  ListContent,
  ListHeader,
  ListDescription,
  Segment,
  Accordion,
  AccordionContent,
  AccordionTitle,
  Icon,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';

export const ItemTabInformation = (props) => {
  const { category_id, code, name, base_price } = props.errorForm;
  const { relations, model, changeValue } = props;
  {
  }
  return (
    <Grid columns={'equal'}>
      <GridRow>
        <GridColumn>
          <FormField
            control={Select}
            label={'Category'}
            fluid
            value={model.category_id}
            name={'category_id'}
            placeholder="Choose a category"
            options={relations.categories}
            error={category_id === undefined ? false : { content: code, pointing: 'below' }}
            onChange={(e, data) => changeValue(data)}
          />
        </GridColumn>
        <GridColumn>
          <FormField
            control={Input}
            fluid
            label={'Name'}
            value={model.name}
            name={'name'}
            autoComplete={'off'}
            onChange={(e, data) => changeValue(data)}
            error={name === undefined ? false : { content: name, pointing: 'below' }}
            placeholder="Type a name"
          />
        </GridColumn>
      </GridRow>
      <GridRow>
        <GridColumn>
          <FormField
            control={Input}
            label={'Code'}
            fluid
            value={model.code}
            name={'code'}
            autoComplete={'off'}
            onChange={(e, data) => changeValue(data)}
            error={code === undefined ? false : { content: code, pointing: 'below' }}
            placeholder="Type a code"
          />
        </GridColumn>
        <GridColumn>
          <FormField
            control={Input}
            type={'number'}
            label={'Price'}
            fluid
            value={model.base_price}
            name={'base_price'}
            autoComplete={'off'}
            onChange={(e, data) => {
              const newValue = {};
              const { name, value } = data;
              newValue.name = name;
              newValue.value = value > 0 ? parseInt(value) : 0;
              changeValue(newValue);
            }}
            error={base_price === undefined ? false : { content: base_price, pointing: 'below' }}
            placeholder="Enter a base price"
          />
        </GridColumn>
      </GridRow>
    </Grid>
  );
};

export const ItemTabMeetingPoint = (props) => {
  const { relations, changeMeetingPoint, model } = props;
  const { item_details } = model;
  let defaultValues;
  defaultValues = item_details === undefined ? false : item_details.map((detail) => detail.id);
  return (
    <Grid columns={'equal'}>
      <GridRow>
        <GridColumn>
          <FormField
            control={Select}
            multiple
            fluid
            label={'Meeting Points'}
            placeholder={'Choose meeting points'}
            options={relations.meetingPoints}
            onChange={(e, data) => changeMeetingPoint(data)}
            defaultValue={defaultValues}
          />
        </GridColumn>
      </GridRow>
      {item_details &&
        item_details.map((detail, key) => {
          return (
            <GridRow verticalAlign={'middle'} key={detail.id}>
              <GridColumn stretched>
                <Segment>
                  <List divided relaxed>
                    <ListItem>
                      <ListIcon name="flag" size="large" verticalAlign="middle" />
                      <ListContent>
                        <ListHeader>{detail.name}</ListHeader>
                        <ListDescription>{detail.description}</ListDescription>
                      </ListContent>
                    </ListItem>
                  </List>
                </Segment>
              </GridColumn>
            </GridRow>
          );
        })}
    </Grid>
  );
};

const ItemDeparture = (props) => {
  const [index, setIndex] = useState(null);
  const { model, departures, changeDeparture } = props;
  const { item_details } = model;
  return (
    <Grid columns={'equal'} relaxed>
      <GridRow>
        <GridColumn>
          {item_details &&
            item_details.map((detail, key) => {
              /*const selectedMeetings = departures.find((departure) => departure.id === detail.id);
              const { departures } = selectedMeetings;*/
              const enableDepartures = departures.filter((departure) => departure.status === 1);
              let selectedDepartures = enableDepartures.map((departure) =>
                Object.keys(departure)
                  .filter((key) => key === 'id' || key === 'name')
                  .reduce(
                    () => ({
                      value: departure.id,
                      text: departure.departure_time,
                      key: departure.id,
                    }),
                    {}
                  )
              );

              const defaultValues = detail.departures.map((departure) => departure.id);
              return (
                <Accordion fluid styled key={detail.id}>
                  <AccordionTitle
                    active={index === key}
                    index={key}
                    onClick={() => {
                      const newIndex = index === key ? null : key;
                      setIndex(newIndex);
                    }}
                  >
                    <Icon name={'dropdown'} />
                    {detail.name}
                  </AccordionTitle>
                  <AccordionContent active={index === key}>
                    <Grid centered>
                      <GridRow>
                        <GridColumn>
                          <FormField
                            control={Select}
                            multiple
                            fluid
                            defaultValue={defaultValues}
                            label={'Departures'}
                            placeholder={'Choose departures'}
                            options={selectedDepartures}
                            onChange={(e, data) => changeDeparture(data, key)}
                          />
                        </GridColumn>
                      </GridRow>
                      <GridRow>
                        <Grid columns={2}>
                          <GridRow verticalAlign={'middle'}>
                            {detail.departures &&
                              detail.departures.map((departure) => {
                                return (
                                  <GridColumn key={departure.id}>
                                    <Segment>
                                      <List divided relaxed>
                                        <ListItem>
                                          <ListIcon
                                            name="time"
                                            size="large"
                                            verticalAlign="middle"
                                          />
                                          <ListContent>
                                            <ListHeader>{`${departure.name} ${
                                              departure.departure_time
                                                ? `(${departure.departure_time})`
                                                : ''
                                            }`}</ListHeader>
                                            <ListDescription>
                                              {departure.description}
                                            </ListDescription>
                                          </ListContent>
                                        </ListItem>
                                      </List>
                                    </Segment>
                                  </GridColumn>
                                );
                              })}
                          </GridRow>
                        </Grid>
                      </GridRow>
                    </Grid>
                  </AccordionContent>
                </Accordion>
              );
            })}
        </GridColumn>
      </GridRow>
    </Grid>
  );
};

export const ItemTransportation = (props) => {
  const {
    relations,
    changeTransportationCategories,
    model,
    transportationCategories,
    changeTransportation,
  } = props;
  const { item_transportations } = model;
  const [index, setIndex] = useState(null);
  const defaultValues =
    item_transportations === undefined
      ? false
      : item_transportations.map((transportationCategory) => transportationCategory.id);
  return (
    <>
      <Grid columns={'equal'} relaxed>
        <GridRow>
          <GridColumn>
            <FormField
              control={Select}
              multiple
              fluid
              label={'Transportation Type'}
              placeholder={'Choose transportations'}
              options={relations.transportationCategories}
              onChange={(e, data) => changeTransportationCategories(data)}
              defaultValue={defaultValues}
            />
          </GridColumn>
        </GridRow>
      </Grid>
      <Grid columns={'equal'} relaxed>
        <GridRow>
          <GridColumn>
            {item_transportations &&
              item_transportations.map((transportation, key) => {
                const selectedTransportationCategory = transportationCategories.find(
                  (transportationCategory) => transportationCategory.id === transportation.id
                );
                const { transportations } = selectedTransportationCategory;
                const enableTransportations = transportations.filter(
                  (transportation) => transportation.status === 1
                );
                let selectedTransportations = enableTransportations.map((transportation) =>
                  Object.keys(transportation)
                    .filter((key) => key === 'id' || key === 'name')
                    .reduce(
                      () => ({
                        value: transportation.id,
                        text: transportation.name,
                        key: transportation.id,
                      }),
                      {}
                    )
                );
                const defaultValues = transportation.transportations.map(
                  (transportation) => transportation.id
                );
                return (
                  <Accordion fluid styled key={transportation.id}>
                    <AccordionTitle
                      active={index === key}
                      index={key}
                      onClick={() => {
                        const newIndex = index === key ? null : key;
                        setIndex(newIndex);
                      }}
                    >
                      <Icon name={'dropdown'} />
                      {transportation.name}
                    </AccordionTitle>
                    <AccordionContent active={index === key}>
                      <Grid centered>
                        <GridRow>
                          <GridColumn>
                            <FormField
                              control={Select}
                              multiple
                              fluid
                              label={'Transportations'}
                              placeholder={'Choose transportations'}
                              options={selectedTransportations}
                              onChange={(e, data) => changeTransportation(data, key)}
                              defaultValue={defaultValues}
                            />
                          </GridColumn>
                        </GridRow>
                        <GridRow>
                          <Grid columns={4}>
                            <GridRow verticalAlign={'middle'}>
                              {transportation.transportations &&
                                transportation.transportations.map((transport, key) => {
                                  return (
                                    <GridColumn key={key}>
                                      <Segment>
                                        <List divided relaxed>
                                          <ListItem>
                                            <ListIcon
                                              name="bus"
                                              size="large"
                                              verticalAlign="middle"
                                            />
                                            <ListContent>
                                              <ListHeader>{transport.name}</ListHeader>
                                            </ListContent>
                                          </ListItem>
                                        </List>
                                      </Segment>
                                    </GridColumn>
                                  );
                                })}
                            </GridRow>
                          </Grid>
                        </GridRow>
                      </Grid>
                    </AccordionContent>
                  </Accordion>
                );
              })}
          </GridColumn>
        </GridRow>
      </Grid>
    </>
  );
};

export const ItemOption = (props) => {
  const { model, changeOption, options, changeOptionDetails, changeOptionDetailsPrice } = props;
  const { item_option } = model;
  //Options
  const enableOptions = options.filter((option) => option.status === 1);
  const selectedOptions = enableOptions.map((option) =>
    Object.keys(option)
      .filter((key) => key === 'name' || key === 'id')
      .reduce(() => ({ value: option.id, key: option.id, text: option.name }), {})
  );

  //Option Details
  let selectedOptionDetails = [];
  const selectedOption = options.find((opt) => opt.id === item_option.id);
  if (selectedOption) {
    const { option_details } = selectedOption;
    const enableOptionDetails = option_details.filter((optionDetail) => optionDetail.status === 1);
    selectedOptionDetails = enableOptionDetails.map((optionDetail) =>
      Object.keys(optionDetail)
        .filter((key) => key === 'id' || key === 'name')
        .reduce(
          () => ({ value: optionDetail.id, text: optionDetail.name, key: optionDetail.id }),
          {}
        )
    );
  }
  // const { option_details } = item_option;
  // const defaultValues = item_option.option_details.map((detail) => detail.id);
  const defaultValues =
    item_option.option_details === undefined
      ? false
      : item_option.option_details.map((detail) => detail.id);
  console.log(defaultValues);
  const defaultValuesPrice =
    item_option.option_details === undefined
      ? false
      : item_option.option_details.map((detail) => detail);
  return (
    <Grid columns={'equal'}>
      <GridRow>
        <GridColumn>
          <FormField
            control={Select}
            fluid
            label={'Option'}
            placeholder={'Choose option'}
            options={selectedOptions}
            value={item_option === undefined ? false : item_option.id}
            onChange={(e, data) => changeOption(data)}
          />
        </GridColumn>
      </GridRow>
      {item_option.id && (
        <>
          <GridRow>
            <GridColumn>
              <FormField
                control={Select}
                multiple
                fluid
                label={'Option Details'}
                placeholder={'Choose option details'}
                options={selectedOptionDetails}
                value={defaultValues}
                onChange={(e, data) => changeOptionDetails(data)}
              />
            </GridColumn>
          </GridRow>
          <GridRow>
            {item_option.option_details.length > 0 &&
              item_option.option_details.map((option, key) => {
                return (
                  <GridColumn>
                    <FormField
                      control={Input}
                      index={key}
                      idPriceElement={option.id}
                      label={`${option.name}`}
                      placeholder={'Type a price'}
                      type={'number'}
                      value={option.price}
                      onChange={(e, data) => changeOptionDetailsPrice(data)}
                    />
                  </GridColumn>
                );
              })}
          </GridRow>
        </>
      )}
    </Grid>
  );
};

const CommonPropTypes = {
  model: PropTypes.object,
  relations: PropTypes.object,
  changeValue: PropTypes.func,
  errorForm: PropTypes.object,
};

ItemTabMeetingPoint.propTypes = { ...CommonPropTypes };
ItemTransportation.propTypes = { ...CommonPropTypes, transportationCategories: PropTypes.array };
ItemDeparture.propTypes = { ...CommonPropTypes, meetingPoints: PropTypes.array };
ItemTabInformation.propTypes = { ...CommonPropTypes };
ItemOption.propTypes = { ...CommonPropTypes, options: PropTypes.array };

export const ItemTabDeparture = connect(
  (state) => ({ departures: state.departures.payload.departures }),
  null
)(ItemDeparture);

export const ItemTabTransportation = connect(
  (state) => ({
    transportationCategories: state.transportationCategories.payload.transportationCategories,
  }),
  null
)(ItemTransportation);

export const ItemTabOption = connect(
  (state) => ({
    options: state.options.payload.options,
  }),
  null
)(ItemOption);
