import categoryService from './service';
import {
  categoryDeleteFail,
  categoryDeleteFetch,
  categoryDeleteSuccess,
  categoryIndexFail,
  categoryIndexFetch,
  categoryIndexSuccess,
  categoryShowFail,
  categoryShowFetch,
  categoryShowSuccess,
  categoryStoreFail,
  categoryStoreFetch,
  categoryStoreSuccess,
  categoryUpdateFail,
  categoryUpdateFetch,
  categoryUpdateSuccess,
} from './dispatch';

export const categoryDelete = (id) => async (dispatch) => {
  dispatch(categoryDeleteFetch());
  try {
    const { data, message } = await categoryService.categoryDelete(id);
    dispatch(categoryDeleteSuccess({ payload: data, message: message }));
  } catch (e) {
    const { error } = e;
    dispatch(categoryDeleteFail({ error }));
  }
};

export const categoryIndex = (forRelation = false, page = null, items = null) => async (
  dispatch,
  getState
) => {
  dispatch(categoryIndexFetch());
  try {
    //If is not paginates values, Get default value from state
    if (!forRelation) {
      page = page ? page : getState().categories.payload.pagination.current_page;
      items = items ? items : getState().categories.payload.pagination.per_page;
    }
    const { data, meta } = await categoryService.categoryIndex(page, items);
    dispatch(categoryIndexSuccess({ categories: data, pagination: meta }));
  } catch (e) {
    dispatch(categoryIndexFail(e));
  }
};

export const categoryShow = (id) => async (dispatch) => {
  dispatch(categoryShowFetch());
  try {
    const { data } = await categoryService.categoryShow(id);
    dispatch(categoryShowSuccess({ category: data }));
  } catch (e) {
    const { error } = e;
    dispatch(categoryShowFail({ error }));
  }
};

export const categoryStore = (data) => async (dispatch) => {
  dispatch(categoryStoreFetch());
  try {
    const { message } = await categoryService.categoryStore(data);
    dispatch(categoryStoreSuccess(message));
  } catch (e) {
    const { errors, message } = e;
    dispatch(categoryStoreFail({ errors, message }));
  }
};

export const categoryUpdate = (id, data) => async (dispatch) => {
  dispatch(categoryUpdateFetch());
  try {
    const { message } = await categoryService.categoryUpdate(id, data);
    dispatch(categoryUpdateSuccess({ message }));
  } catch (e) {
    const { errors, message } = e;
    dispatch(categoryUpdateFail({ errors, message }));
  }
};
