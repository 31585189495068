import cruiseArrivalService from './service';
import {
  cruiseArrivalDeleteFail,
  cruiseArrivalDeleteFetch,
  cruiseArrivalDeleteSuccess,
  cruiseArrivalIndexFail,
  cruiseArrivalIndexFetch,
  cruiseArrivalIndexSuccess,
  cruiseArrivalShowFail,
  cruiseArrivalShowFetch,
  cruiseArrivalShowSuccess,
  cruiseArrivalStoreFail,
  cruiseArrivalStoreFetch,
  cruiseArrivalStoreSuccess,
  cruiseArrivalUpdateFail,
  cruiseArrivalUpdateFetch,
  cruiseArrivalUpdateSuccess,
  cruiseArrivalModalOpen,
  cruiseArrivalModalClose,
  cruiseArrivalActionTypeStore,
  cruiseArrivalActionTypeUpdate,
} from './dispatch';

export const cruiseArrivalDelete = (id) => async (dispatch) => {
  dispatch(cruiseArrivalDeleteFetch());
  try {
    const { data } = await cruiseArrivalService.cruiseArrivalDelete(id);
    dispatch(cruiseArrivalDeleteSuccess({ payload: data }));
  } catch (e) {
    const { error } = e;
    dispatch(cruiseArrivalDeleteFail({ error }));
  }
};

export const cruiseArrivalIndex = (forRelation = false, page = null, items = null) => async (
  dispatch,
  getState
) => {
  dispatch(cruiseArrivalIndexFetch());
  try {
    //If is not paginates values, Get default value from state
    if (!forRelation) {
      page = page ? page : getState().cruiseArrivals.payload.pagination.current_page;
      items = items ? items : getState().cruiseArrivals.payload.pagination.per_page;
    }

    const { data, meta } = await cruiseArrivalService.cruiseArrivalIndex(page, items);
    dispatch(cruiseArrivalIndexSuccess({ cruiseArrivals: data, pagination: meta }));
  } catch (e) {
    dispatch(cruiseArrivalIndexFail(e));
  }
};

export const cruiseArrivalShow = (id) => async (dispatch) => {
  dispatch(cruiseArrivalShowFetch());
  try {
    const { data } = await cruiseArrivalService.cruiseArrivalShow(id);
    dispatch(cruiseArrivalShowSuccess({ cruiseArrival: data }));
  } catch (e) {
    const { error } = e;
    dispatch(cruiseArrivalShowFail({ error }));
  }
};

export const cruiseArrivalStore = (data) => async (dispatch) => {
  dispatch(cruiseArrivalStoreFetch());
  try {
    const { message } = await cruiseArrivalService.cruiseArrivalStore(data);
    dispatch(cruiseArrivalModalClose());
    dispatch(cruiseArrivalStoreSuccess(message));
    dispatch(cruiseArrivalIndex());
  } catch (e) {
    const { errors, message } = e;
    dispatch(cruiseArrivalStoreFail({ errors, message }));
  }
};

export const cruiseArrivalUpdate = (id, data) => async (dispatch) => {
  dispatch(cruiseArrivalUpdateFetch());
  try {
    const { message } = await cruiseArrivalService.cruiseArrivalUpdate(id, data);
    dispatch(cruiseArrivalModalClose());
    dispatch(cruiseArrivalStoreSuccess(message));
    dispatch(cruiseArrivalIndex());
  } catch (e) {
    const { errors, message } = e;
    dispatch(cruiseArrivalUpdateFail({ errors, message }));
  }
};

export const cruiseArrivalOpenModal = () => (dispatch) => {
  dispatch(cruiseArrivalModalOpen());
};

export const cruiseArrivalCloseModal = () => (dispatch) => {
  dispatch(cruiseArrivalModalClose());
};

export const cruiseArrivalActionStore = () => (dispatch) => {
  dispatch(cruiseArrivalActionTypeStore({ actionType: 'store' }));
};

export const cruiseArrivalActionUpdate = () => (dispatch) => {
  dispatch(cruiseArrivalActionTypeUpdate({ actionType: 'update' }));
};
