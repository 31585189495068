import React, { Component } from 'react';
import { Form, Segment, Button, Message, Header } from 'semantic-ui-react';
import logo from '../app/assets/images/logo.png';
import { loginUser } from './actions';
import { connect } from 'react-redux';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      model: {
        email: '',
        password: '',
      },
      errorForm: {},
      message: null,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { errors } = nextProps;
    this.setState({
      errorForm: { ...errors.errors },
      message: errors.message,
    });
  }

  changeValue = (input) => {
    const { name, value } = input;
    let newValue = {};
    newValue[name] = value;
    this.setState(
      ({ model }) => ({ model: { ...model, ...newValue } }),
      () => {
        this.setState(({ errorForm }) => {
          let newErrors = Object.keys(errorForm)
            .filter((key) => key !== name)
            .reduce((obj, key) => {
              obj[key] = errorForm[key];
              return obj;
            }, {});
          return { errorForm: newErrors, message: null };
        });
      }
    );
  };

  render() {
    const { message, errorForm } = this.state;
    const { email, password } = errorForm;
    const { isFetching, fnLogin, errors } = this.props;
    return (
      <Form size="large" error={true}>
        <Header as="h2" color="teal" textAlign="center">
          {/*<img src={logo} alt="logo" className="image" />*/} Log-in to your account
        </Header>
        <Message error={true} content={message} />
        <Segment>
          <Form.Input
            name={'email'}
            error={email === undefined ? false : { content: email, pointing: 'below' }}
            fluid={true}
            icon="user"
            iconPosition="left"
            placeholder="E-mail address"
            onChange={(e, data) => this.changeValue(data)}
          />
          <Form.Input
            name={'password'}
            error={password === undefined ? false : { content: password, pointing: 'below' }}
            fluid={true}
            icon="lock"
            iconPosition="left"
            placeholder="Password"
            type="password"
            onChange={(e, data) => this.changeValue(data)}
          />
          <Button
            loading={isFetching}
            color="teal"
            fluid={true}
            size="large"
            onClick={(e) => {
              const { model } = this.state;
              e.preventDefault();
              fnLogin(model);
            }}
          >
            Login
          </Button>
        </Segment>
      </Form>
    );
  }
}

const mapStateToProps = (state) => ({
  isFetching: state.login.isFetching,
  errors: state.login.errors,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  fnLogin: (data) => {
    const { history } = ownProps;
    dispatch(loginUser(data, history));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
