import React, { Component } from 'react';
import { appDestroyAuthentication } from '../actions';
import { connect } from 'react-redux';

import avatar from './../assets/images/avatars/user_account_profile_avatar.png';

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userMenuOpen: false,
    };
  }

  toggleAction = (keyState) => {
    this.setState((prevState) => {
      return Object.keys(prevState)
        .filter((k) => k === keyState)
        .reduce((obj, key) => {
          obj[key] = !prevState[key];
          return obj;
        }, {});
    });
  };
  render() {
    const { userMenuOpen } = this.state;
    return (
      <div className="app-header header-shadow">
        <div className="app-header__logo">
          <div className="" />
          <div className="header__pane ml-auto">
            <div>
              <button
                type="button"
                className="hamburger close-sidebar-btn hamburger--elastic"
                data-class="closed-sidebar"
              >
                <span className="hamburger-box">
                  <span className="hamburger-inner" />
                </span>
              </button>
            </div>
          </div>
        </div>
        <div className="app-header__mobile-menu">
          <div>
            <button type="button" className="hamburger hamburger--elastic mobile-toggle-nav">
              <span className="hamburger-box">
                <span className="hamburger-inner" />
              </span>
            </button>
          </div>
        </div>
        <div className="app-header__menu">
          <span>
            <button
              type="button"
              className="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav" onClick={() => this.toggleAction('userMenuOpen')}>
              <span className="btn-icon-wrapper">
                <i className="fa fa-ellipsis-v fa-w-6" />
              </span>
            </button>
            <div
                tabIndex={-1}
                role="menu"
                aria-hidden="true"
                className={`app-header__content ${
                    userMenuOpen ? 'header-mobile-open show' : ''
                    }`}
            >
                        <button type="button" tabIndex={0} className="dropdown-item">
                            <i aria-hidden="true" className="fa-user disabled icon icon-menu icon-green"></i> User Account
                        </button>


                        <button type="button" aria-expanded="false" className="dropdown-item" tabIndex={0} onClick={() => {
                            const { fnAppDestroyAuthentication } = this.props;
                            fnAppDestroyAuthentication();
                        }}>
                           <i aria-hidden="true" className="fa-power-off disabled icon icon-menu icon-red"></i> Logout
                        </button>
                    </div>
          </span>
        </div>
        <div className="app-header__content">
          <div className="app-header-left">
            <div className="search-wrapper">
              <div className="input-holder">
                <input type="text" className="search-input" placeholder="Type to search" />
                <button className="search-icon">
                  <span />
                </button>
              </div>
              <button className="close" />
            </div>
            <ul className="header-menu nav">
              <li className="nav-item">
                <a href="/#" className="nav-link">
                  <i className="nav-link-icon fa fa-database" />
                  Statistics
                </a>
              </li>
              <li className="btn-group nav-item">
                <a className="nav-link">
                  <i className="nav-link-icon fa fa-edit" />
                  Projects
                </a>
              </li>
              <li className="dropdown nav-item">
                <a className="nav-link">
                  <i className="nav-link-icon fa fa-cog" />
                  Settings
                </a>
              </li>
            </ul>
          </div>
          <div className="app-header-right">
            <div className="header-btn-lg pr-0" onClick={() => this.toggleAction('userMenuOpen')}>
              <div className="widget-content p-0">
                <div className="widget-content-wrapper">
                  <div className="widget-content-left">
                    <div className="btn-group">
                      <a
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        className="p-0 btn"
                      >
                        <img width={42} className="rounded-circle" src={avatar} alt="" />
                      </a>

                    </div>
                  </div>
                  <div className="widget-content-left  ml-3 header-user-info">
                    <div className="widget-heading">Alina Mclourd</div>
                    <div className="widget-subheading">VP People Manager</div>
                  </div>
                  <div className="widget-content-right header-user-info ml-3">
                    {/*<button*/}
                      {/*type="button"*/}
                      {/*className="btn-shadow p-1 btn btn-primary btn-sm show-toastr-example"*/}
                    {/*>*/}
                      {/*<i className="fa text-white fa-calendar pr-1 pl-1" />*/}
                    {/*</button>*/}
                      <i className="fa fa-angle-down ml-2 opacity-8" />
                  </div>
                    <div
                        tabIndex={-1}
                        role="menu"
                        aria-hidden="true"
                        className={`dropdown-menu dropdown-menu-right ${
                            userMenuOpen ? 'show' : ''
                            }`}
                    >
                        <button type="button" tabIndex={0} className="dropdown-item">
                            User Account
                        </button>
                        <div tabIndex={-1} className="dropdown-divider" />
                        <button
                            type="button"
                            tabIndex={0}
                            className="dropdown-item"
                            onClick={() => {
                                const { fnAppDestroyAuthentication } = this.props;
                                fnAppDestroyAuthentication();
                            }}
                        >
                            Logout
                        </button>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const DispatchToProps = (dispatch) => ({
  fnAppDestroyAuthentication: () => {
    dispatch(appDestroyAuthentication());
  },
});

export default connect(
  mapStateToProps,
  DispatchToProps
)(NavBar);
