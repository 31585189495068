export const MODULE_NAME = 'Transportation Categories';
export const ROUTE = '/transportation-categories';
export const RELATIONS = ['transportations'];

const TransportationCategory = {
  id: null,
  name: null,
  status: null,
  created_id: null,
  created_at: null,
  updated_at: null,
  updated_id: null,
};

export default TransportationCategory;
