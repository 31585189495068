export const MODULE_NAME = 'Shipping Hotel Companies';
export const ROUTE = '/shipping-hotel-companies';

const ShippingHotelCompany = {
  id: null,
  name: null,
  status: null,
  created_id: null,
  created_at: null,
  updated_at: null,
  updated_id: null,
};

export default ShippingHotelCompany;
