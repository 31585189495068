import rateService from './service';
import {
  rateDeleteFail,
  rateDeleteFetch,
  rateDeleteSuccess,
  rateIndexFail,
  rateIndexFetch,
  rateIndexSuccess,
  rateShowFail,
  rateShowFetch,
  rateShowSuccess,
  rateStoreFail,
  rateStoreFetch,
  rateStoreSuccess,
  rateUpdateFail,
  rateUpdateFetch,
  rateUpdateSuccess,
  rateActionTypeStore,
  rateActionTypeUpdate,
  rateModalClose,
  rateModalOpen,
} from './dispatch';
import appService from '../app/service';
import { RELATIONS } from './model';

export const rateDelete = (id) => async (dispatch) => {
  dispatch(rateDeleteFetch());
  dispatch(rateIndexFetch());
  try {
    const { data } = await rateService.rateDelete(id);
    dispatch(rateDeleteSuccess({ payload: data }));
    dispatch(ratePaginate());
  } catch (e) {
    const { error } = e;
    dispatch(rateDeleteFail({ error }));
  }
};

export const ratePaginate = (forRelation = false, page = null, items = null) => async (
  dispatch,
  getState
) => {
  dispatch(rateIndexFetch());
  try {
    //If is not paginates values, Get default value from state
    page = page ? page : getState().rates.payload.pagination.current_page;
    items = items ? items : getState().rates.payload.pagination.per_page;
    // forRelation = forRelation ? appService.appSerialize(RELATIONS) : null;

    const { data, meta } = await rateService.rateIndex(forRelation, null, page, items);
    dispatch(rateIndexSuccess({ rates: data, pagination: meta }));
  } catch (e) {
    dispatch(rateIndexFail(e));
  }
};

export const rateIndex = (forRelation = false, params = null) => async (dispatch) => {
  dispatch(rateIndexFetch());
  try {
    forRelation = forRelation ? appService.appSerialize(RELATIONS) : null;
    let serializedParams = '';
    if (params && typeof params === 'object') {
      Object.keys(params).map((param, key, array) => {
        serializedParams += `${param}=${params[param]}`;
        if (array[key + 1] !== undefined) serializedParams += '&';
      });
    } else serializedParams = params;
    const { data, meta } = await rateService.rateIndex(forRelation, serializedParams);
    dispatch(rateIndexSuccess({ rates: data, pagination: meta }));
  } catch (e) {
    dispatch(rateIndexFail(e));
  }
};

export const rateShow = (id) => async (dispatch) => {
  dispatch(rateShowFetch());
  try {
    const { data } = await rateService.rateShow(id);
    dispatch(rateShowSuccess({ rate: data }));
  } catch (e) {
    const { error } = e;
    dispatch(rateShowFail({ error }));
  }
};

export const rateStore = (data) => async (dispatch) => {
  dispatch(rateStoreFetch());
  dispatch(rateIndexFetch());
  try {
    const { message } = await rateService.rateStore(data);
    dispatch(rateModalClose());
    dispatch(rateStoreSuccess(message));
    dispatch(ratePaginate());
    // dispatch(rateIndexSuccess());
  } catch (e) {
    const { errors, message } = e;
    dispatch(rateStoreFail({ errors, message }));
  }
};

export const rateUpdate = (id, data) => async (dispatch) => {
  dispatch(rateUpdateFetch());
  dispatch(rateIndexFetch());
  try {
    const { message } = await rateService.rateUpdate(id, data);
    dispatch(rateModalClose());
    dispatch(rateUpdateSuccess({ message }));
    dispatch(ratePaginate());
  } catch (e) {
    const { errors, message } = e;
    dispatch(rateUpdateFail({ errors, message }));
  }
};

export const rateOpenModal = () => (dispatch) => {
  dispatch(rateModalOpen());
};

export const rateCloseModal = () => (dispatch) => {
  dispatch(rateModalClose());
};

export const rateActionStore = () => (dispatch) => {
  dispatch(rateActionTypeStore({ actionType: 'store' }));
};

export const rateActionUpdate = () => (dispatch) => {
  dispatch(rateActionTypeUpdate({ actionType: 'update' }));
};

export const rateDetails = (rate_id) => async () => {
  let data = {};
  try {
    data = await rateService.rateDetails(rate_id);
  } catch (e) {
    console.log(e);
    data.data = [];
  }

  return data;
};
